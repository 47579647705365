<template>
  <div
    :class="['customConfirm', { isShowMask: text.mask }]"
    v-if="isShow"
  >
    <div class="box">
      <div class="top">
        <span>{{ text.title }}</span>
        <i
          class="el-icon-close close"
          @click="isShow = false"
        ></i>
      </div>

      <div class="content">
        {{ text.content }}
      </div>

      <div class="footer">
        <el-button
          class="confirm_btn"
          :style="text.cancelValStyle"
          @click="confirm"
        >
          {{ text.confirmVal }}
        </el-button>
        <el-button
          class="cancel_btn"
          :style="text.confirmValStyle"
          @click="cancel"
        >
          {{ text.cancelVal }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
      text: {
        mask: true, // 是否开启遮罩
        title: "我是头部", // 标题
        content: "我是内容", // 内容
        confirmVal: "确定", // 确定按钮值
        cancelVal: "取消", // 取消按钮值
        cancelValStyle: "", // 确定按钮样式
        confirmValStyle: "" // 取消按钮样式
      }
    };
  },
  methods: {
    confirm() {
      this.isShow = false;
    },
    cancel() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.customConfirm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 500px;
    background-color: #252d46;
    .top {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      height: 70px;
      line-height: 70px;
      background-color: #202c4e;
      border-bottom: 1px solid #39465d;
      .close {
        font-size: 16px;
        color: #a2b0cb;
      }
    }

    .content {
      padding: 10px 20px;
      min-height: 100px;
      max-height: 400px;
      color: #606266;
    }

    .footer {
      height: 50px;
      text-align: center;
      font-size: 14px;

      .confirm_btn {
        background-color: #388bff;
        border: 1px solid #495873;
      }

      .cancel_btn {
        margin-left: 10px;
        border: 1px solid #3c4b66;
      }
    }
  }
}
.isShowMask {
  opacity: 0.5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  transform: none;
}
</style>
