// import "babel-polyfill";
import init from "../init.js";
import isMatch from "lodash/isMatch";
import api from "@/js/api.js";
export default {
  parseSearch() {
    let obj = {};
    location.search &&
      location.search
        .slice(1)
        .split("&")
        .forEach(item => {
          let inArr = item.split("=");
          obj[inArr[0]] = inArr[1].slice(0);
        });

    return obj;
  },
  // 判断值的数据类型
  getType(v) {
    Object.prototype.toString
      .call(v)
      .slice(8, -1)
      .toLocaleLowerCase();
  },
  // 给递归的数据赋一些值
  fillIndexToRecursionData(
    list = [],
    {
      recursionKey = "children",
      fillIndexKey = "index",
      handleIndex = o => o,
      handleItem = o => o,
      handLeafNode = o => o
    } = {}
  ) {
    let fn = (arr, coorArr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        item = handleItem(item);
        let newArr = [...coorArr];
        newArr.push(i);
        item[fillIndexKey] = handleIndex(newArr);
        if (item[recursionKey] && item[recursionKey].length > 0) {
          fn(item[recursionKey], newArr);
        } else {
          item = handLeafNode(item);
          item.isRootNode = true;
        }
      }
    };
    fn(list, []);
    return list;
  },
  // getFirstLeafNode(list = [], { recursionKey = "children" } = {}) {
  //   if (!list || list.length === 0) return null;

  //   let fn = arr => {
  //     while (arr[recursionKey] && arr[recursionKey].length > 0) {
  //       return fn(arr.children[0]);
  //     }
  //     return {...arr};
  //   };
  //   return fn(list[0]);
  // },
  getFirstRootNodeCoor(list = [], { recursionKey = "children" } = {}) {
    if (!list || list.length === 0) return null;

    let fn = (arr, coorArr) => {
      while (arr[recursionKey] && arr[recursionKey].length > 0) {
        let newCoor = [...coorArr, 0];
        return fn(arr[recursionKey][0], newCoor);
      }
      return [...coorArr];
    };
    return fn(list[0], [0]);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoor(
    list = [],
    coorArr = [],
    { recursionKey = "children" } = {}
  ) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;

    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          return inGetTarget(arr[coor][recursionKey], ++i);
        } else {
          return null;
        }
      } else {
        return arr[coor];
      }
    };

    return inGetTarget(list, 0);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoorDeep(
    list = [],
    coorArr = [],
    { recursionKey = "children" } = {}
  ) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;
    let deepArr = [];
    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      let newObj = {};
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          for (var attr in arr[coor]) {
            if (attr !== recursionKey) {
              newObj[attr] = arr[coor][attr];
            }
          }
          deepArr.push({
            ...newObj
          });
          return inGetTarget(arr[coor][recursionKey], ++i);
        } else {
          return null;
        }
      } else {
        deepArr.push({
          ...arr[coor]
        });
        return deepArr;
      }
    };

    return inGetTarget(list, 0);
  },

  // 根据属性获取递归数据位置的值
  queryRecursionData(
    list = [],
    target = {},
    { recursionKey = "children" } = {}
  ) {
    if (Object.keys(target).length === 0) return null;
    let notGetTarget = true;
    let result = null;
    let fn = arr => {
      if (notGetTarget && arr.length > 0) {
        for (let item of arr) {
          if (isMatch(item, target)) {
            notGetTarget = false;
            result = item;
            break;
          } else {
            if (item[recursionKey] && item[recursionKey].length > 0) {
              fn(item[recursionKey]);
            }
          }
        }
      }
    };

    fn(list);
    return result;
  },
  browserType() {
    let userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
      // 判断是否Opera浏览器
      return "Opera";
    }
    if (userAgent.indexOf("Firefox") > -1) {
      // 判断是否Firefox浏览器
      return "FF";
    }
    if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
      // 判断是否Safari浏览器
      return "Safari";
    }
    if (
      userAgent.indexOf("compatible") > -1 &&
      userAgent.indexOf("MSIE") > -1 &&
      !isOpera
    ) {
      // 判断是否IE浏览器
      return "IE";
    }
  },
  downLoadFile(
    name,
    data,
    type = {
      type: ""
    }
  ) {
    const blob = new Blob([data], type);
    if ("download" in document.createElement("a")) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } else {
      navigator.msSaveBlob(blob, name);
    }
  },
  phoneCheck(phone) {
    return /^1[3456789]\d{9}$/.test(phone);
  },
  /**
   * 计算表格高度
   * @param { dom } outerDom 最外层元素
   * @param  { dom | number } innerDoms 需减掉高度的元素
   * @return { Number } 计算后的高度
   */
  calcTableHeight(outerDom, ...innerDoms) {
    if (!outerDom) return "auto";
    const outerHeight = outerDom.offsetHeight;
    let [innerHeightSum, res] = [0, 0, 0];
    let innerHeightList = [];
    innerDoms.forEach(item => {
      if (typeof item === "number") {
        innerHeightList.push(item);
      } else {
        innerHeightList.push(item.offsetHeight);
      }
    });
    const reducer = (a, b) => a + b;
    innerHeightSum = innerHeightList.reduce(reducer, 0);
    res = outerHeight - innerHeightSum;
    return res;
  },
  // 转换大于10000的数值
  processData(val = 0, decimal0 = 2, decimal1) {
    val = Number(val);
    if (Math.abs(val) >= 10000) {
      return (val / 10000).toFixed(decimal0) + "万";
    } else if (val === 0) {
      return val;
    } else {
      const decimal =
        decimal1 !== null && decimal1 !== undefined ? decimal1 : decimal0;
      return val.toFixed(decimal).toLocaleString();
    }
  },
  // 判断数值类型并转换数值
  processDataType(val, decimal0 = 2, decimal1) {
    if (val === null || val === undefined) {
      return "--";
    } else {
      return this.processData(val, decimal0, decimal1);
    }
  },
  // 获取月份第一天和最后一天
  getMonthFirstLastDay(m = "") {
    let date = m ? new Date(m) : new Date();
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    const firstDay = init.Dateformat(new Date(date.setDate(1)), "yyyy-MM-dd");
    const lastDay = init.Dateformat(
      new Date(nextMonthFirstDay - oneDay),
      "yyyy-MM-dd"
    );
    return [firstDay, lastDay];
  },
  // 获取当年第一天和最后一天
  getYearFirstLastDay(y = "") {
    if (!y) y = new Date().getFullYear();
    let firstDay = new Date(y, 0, 1);
    let lastDay = new Date(y, 12, 0);
    firstDay = init.Dateformat(firstDay, "yyyy-MM-dd");
    lastDay = init.Dateformat(lastDay, "yyyy-MM-dd");
    return [firstDay, lastDay];
  },
  isDuringDate(beginDateStr, endDateStr) {
    let curDate = new Date();
    let beginDate = new Date(beginDateStr);
    let endDate = new Date(endDateStr);
    if (curDate >= beginDate && curDate <= endDate) return true;
    return false;
  },
  /** 获取指定月份的最后一天 */
  getCurrentMonthLast(date) {
    var endDate = new Date(date); // date 是需要传递的时间如：2018-08
    var month = endDate.getMonth();
    var nextMonth = ++month;
    var nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var dateString = new Date(nextMonthFirstDay - oneDay);
    return dateString; // toLocaleDateString() 返回 如：2018/8/31
  },
  /** 格式化为"yyyy-MM-dd" */
  formatTime(searchForm, isStart = true) {
    const { type, date } = searchForm;
    const time = new Date(date);
    const year = time.getFullYear();
    const thisYear = new Date().getFullYear();
    if (type === "month") {
      const month = time.getMonth();
      const startTime = init.Dateformat(new Date(year, month, 1), "yyyy-MM-dd");
      const endTime = init.Dateformat(
        this.getCurrentMonthLast(date),
        "yyyy-MM-dd"
      );
      if (isStart) {
        return startTime;
      } else {
        if (this.isDuringDate(startTime, endTime)) {
          return init.Dateformat(new Date(), "yyyy-MM-dd");
        } else {
          return endTime;
        }
      }
    } else if (type === "year") {
      const startTime = init.Dateformat(new Date(year, 0, 1), "yyyy-MM-dd");
      if (isStart) {
        return startTime;
      } else {
        if (year === thisYear) {
          return init.Dateformat(new Date(), "yyyy-MM-dd");
        } else {
          return init.Dateformat(new Date(year, 11, 31), "yyyy-MM-dd");
        }
      }
    } else {
      return date;
    }
  },
  // 获取上一个月、上一年的时间
  getLastMYTime(dateTime) {
    const startTime = new Date(dateTime.startTime);
    const endTime = new Date(dateTime.endTime);
    const startDay = startTime.getDate();
    const endDay = endTime.getDate();
    const month = startTime.getMonth();
    const year = startTime.getFullYear();
    const lastMonth = month - 1;
    const lastYear = year - 1;
    const nextDay = new Date(endTime.setDate(endTime.getDate() + 1));
    const isMonthLastDay = nextDay.getDate() === 1; // 是当月最后一天
    const isYearLastDay = nextDay.getDate() === 1 && month === 11; // 是当年最后一天
    const lastMonthLastDay = this.getCurrentMonthLast(`${year}-${month}`);
    const lastYearMonthLastDay = this.getCurrentMonthLast(
      `${lastYear}-${month + 1}`
    );
    let lastMonthEndTime = "";
    let lastYearEndTime = "";
    if (isMonthLastDay || lastMonthLastDay.getDate() < endDay) {
      lastMonthEndTime = init.Dateformat(lastMonthLastDay, "yyyy-MM-dd");
    } else {
      lastMonthEndTime = init.Dateformat(
        new Date(year, lastMonth, endDay),
        "yyyy-MM-dd"
      );
    }
    if (isYearLastDay) {
      lastYearEndTime = init.Dateformat(
        new Date(lastYear, 12, 0),
        "yyyy-MM-dd"
      );
    } else {
      if (lastYearMonthLastDay.getDate() < endDay) {
        lastYearEndTime = init.Dateformat(lastYearMonthLastDay, "yyyy-MM-dd");
      } else {
        lastYearEndTime = init.Dateformat(
          new Date(lastYear, month, endDay),
          "yyyy-MM-dd"
        );
      }
    }
    const lastMonthStartTime = init.Dateformat(
      new Date(startTime.setMonth(lastMonth)),
      "yyyy-MM-dd"
    );
    const lastYearStartTime = init.Dateformat(
      new Date(lastYear, month, startDay),
      "yyyy-MM-dd"
    );
    return {
      lastMonthStartTime,
      lastMonthEndTime,
      lastYearStartTime,
      lastYearEndTime
    };
  },
  // 获取当年时间段的上一年同期时间段
  getLastYearTimeStr(dateTime) {
    const endTime = new Date(dateTime.endTime);
    const day = endTime.getDate();
    const month = endTime.getMonth();
    const lastYear = endTime.getFullYear() - 1;
    let firstDay = new Date(lastYear, 0, 1);
    let lastDay = new Date(lastYear, month, day);
    firstDay = init.Dateformat(firstDay, "yyyy-MM-dd");
    lastDay = init.Dateformat(lastDay, "yyyy-MM-dd");
    return [firstDay, lastDay];
  },
  // 倒计时
  timeDown(endtime) {
    endtime = "48:11:22"; // 这是你的倒计总时长(一般从后台获取的，这里我就不写后台获取代码了)
    var i = 0; // 定义一个计数器变量
    var ss = 0; // 剩余时间变量
    var list = endtime.split(":"); // 分割字符串得到 时，分，秒

    // 语法格式setInterval(fn,1000)
    window.setInterval(function() {
      return showTimeDown(
        parseInt(list[0]),
        parseInt(list[1]),
        parseInt(list[2])
      );
    }, 1000);

    function showTimeDown(h, m, s) {
      var s1 = h * 3600 + m * 60 + s; // 将倒计总时长转换为秒
      i++; // 每调用一次i就会+1
      ss = s1 - i; // 剩余时间=(总秒数-i)
      var day = Math.floor(ss / (3600 * 24)); // 计算天
      var hour = Math.floor(ss / 3600 - day * 24); // 计算时
      var minute = Math.floor((ss - (day * 24 * 3600 + hour * 3600)) / 60); // 计算分
      var second = Math.floor(
        ss - (day * 24 * 3600 + hour * 3600 + minute * 60)
      ); // 计算秒
      return day + "天" + hour + "时" + minute + "分" + second + "秒"; // 控制台输出
    }
  },
  // 获取当前时间戳转换为yyyy-MM-dd 00:00:00
  changeTimeType(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    let ms = " 00:00:00";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + ms;
    return currentdate;
  },
  // 获取当前时间戳转换为yyyy-MM-dd
  changeTimeTypeM(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
  },
  // 获取当前时间戳转换为yyyy-MM-dd
  changeTimeTypeStart(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    let ms = " 00:00:00";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + ms;
    return currentdate;
  },
  // 获取当前时间戳转换为yyyy-MM-dd
  changeTimeTypeEnd(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    let ms = " 23:59:59";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + ms;
    return currentdate;
  },
  // 将毫秒转换为yyyy-MM-dd
  timeTypeChange(dateTime) {
    let date = new Date(dateTime); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    // let h = date.getHours() + ":";
    // let m = date.getMinutes() + ":";
    // let s = date.getSeconds();
    return Y + M + D;
  },
  // 将毫秒转换为yyyy-MM-dd 00:00:00
  timeTypeChangeStart(dateTime) {
    let date = new Date(dateTime); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    // let h = date.getHours() + ":";
    // let m = date.getMinutes() + ":";
    // let s = date.getSeconds();
    return Y + M + D + " 00:00:00";
  },
  // 将毫秒转换为yyyy-MM-dd 23:59:59
  timeTypeChangeEnd(dateTime) {
    let date = new Date(dateTime); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    // let h = date.getHours() + ":";
    // let m = date.getMinutes() + ":";
    // let s = date.getSeconds();
    return Y + M + D + " 23:59:59";
  },
  // 输入框校验特殊字符、英文字符占比、
  checkSpecificKey(str) {
    var specialKey =
      "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*-（）——|{}【】‘；：”“'。，、？]‘'";
    let isChinese = /[\u4E00-\u9FA5]/g.test(str) || /[a-zA-z]$/.test(str);
    for (var i = 0; i < str.length; i++) {
      if (
        specialKey.indexOf(str.substr(i, 1)) !== -1 ||
        isChinese ||
        Number(str) < 0 ||
        Number(str) > 1
      ) {
        return false;
      }
    }
    return true;
  },
  // 输入框校验特殊字符、英文字符倍率、
  checkSpecificKeyRdio(str) {
    var specialKey =
      "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*-（）——|{}【】‘；：”“'。，、？]‘'";
    let isChinese = /[\u4E00-\u9FA5]/g.test(str) || /[a-zA-z]$/.test(str);
    for (var i = 0; i < str.length; i++) {
      if (
        specialKey.indexOf(str.substr(i, 1)) !== -1 ||
        isChinese ||
        Number(str) < 0
      ) {
        return false;
      }
    }
    return true;
  },
  // 输入框校验特殊字符、英文字符、
  areaCheck(str) {
    var specialKey =
      "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*-（）——|{}【】‘；：”“'。，、？]‘'";
    let isChinese = /[\u4E00-\u9FA5]/g.test(str) || /[a-zA-z]$/.test(str);
    for (var i = 0; i < str.length; i++) {
      if (
        specialKey.indexOf(str.substr(i, 1)) !== -1 ||
        isChinese ||
        Number(str) <= 0
      ) {
        return false;
      }
    }
    return true;
  },
  // 获取7天前时间 年月日
  getSevenDaysTime() {
    let now = new Date().getTime();
    let sevenDaysAgo = now - 6 * 24 * 60 * 60 * 1000;
    let date = new Date(sevenDaysAgo);
    let y = date.getFullYear(); // 年份
    let m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1; // 月份，注意：js里的月要加1
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); // 日
    // let h = date.getHours(); // 小时
    // let min = date.getMinutes(); // 分钟
    // let s = date.getSeconds(); // 秒
    return y + "-" + m + "-" + d;
  },
  // 获取当前时间 年月日
  getNowTime() {
    let now = new Date().getTime();
    let date = new Date(now);
    let y = date.getFullYear(); // 年份
    let m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1; // 月份，注意：js里的月要加1
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); // 日
    // let h = date.getHours(); // 小时
    // let min = date.getMinutes(); // 分钟
    // let s = date.getSeconds(); // 秒
    return y + "-" + m + "-" + d;
  },
  /**
   * 数据格式化
   * @param {Number} val 需要转换的值
   * @param {Number} num 需保留几位小数
   */
  moreFn(val, num = 1) {
    if (val > 10000) {
      return (val / 10000).toFixed(num) + "万";
    } else {
      return val;
    }
  },
  // 获取数值中最大值的索引
  getMaxIndex(arr) {
    let maxIndex = 0;
    if (arr.length > 0) {
      maxIndex = arr.indexOf(Math.max(...arr));
    }
    return maxIndex;
  },
  /**
   * 计算总数
   * @param {Array} list
   */
  totalNum(list) {
    let num = 0;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        num += Number(list[i].value);
      }
    }
    return this.moreFn(Math.round(num));
  },
  /**
   * 计算数组对象的百分比
   * @param {*}arr 原数组
   * @param {*} field 用来计算百分比的字段
   */
  getPercent(arr, field) {
    let res = arr.slice();
    if (Array.isArray(res)) {
      let num = 0;
      let typeNum = 100;
      for (let i = 0; i < res.length; i++) {
        num += Number(res[i][field]);
      }
      const maxNum = Math.max(...res.map(item => item[field]));
      let maxIdx = 0;
      res.forEach((item, idx) => {
        // 找出最大值的索引值
        if (item[field] === maxNum) maxIdx = idx;
        if (num > 0) {
          if (idx < res.length - 1) {
            item.percent = Math.round((item[field] * 100) / num);
            typeNum -= Math.round((item[field] * 100) / num);
          } else {
            // 特殊处理最后一个值
            if (typeNum < 0) {
              item.percent = "0";
              // 最大数重新赋值（需要减去最后一个负数值）
              res[maxIdx].percent = Math.round((maxNum * 100) / num) + typeNum;
            } else {
              item.percent = typeNum;
            }
          }
        } else {
          item.percent = "0";
        }
      });
    }
    return res;
  },
  // 上报埋点
  locus(routePath, menuName, type, cryptoJS) {
    let userInfo = JSON.parse(sessionStorage.GLOBAL_IOC).userInfo;
    let account = cryptoJS.decrypt(userInfo.accountEnc);
    let userName = cryptoJS.decrypt(userInfo.nameCnEnc);
    let phone = cryptoJS.decrypt(userInfo.phoneEnc);
    let params = {
      uid: userInfo.uid,
      account,
      userName,
      phone,
      parkCode: localStorage.lay_selectedParkCode, // 园区编码
      periodCode:
        localStorage.lay_selectedParkCode === localStorage.lay_selectedPosCode
          ? ""
          : localStorage.lay_selectedPosCode, // 分期编码
      routeUri: routePath,
      routeName: menuName,
      type, // 0:系统登录, 1:园区切换登录，2:主动登出, 3:菜单页面跳转, 4:按钮页面跳转'
      websession: sessionStorage.IOC_WEB_SESSION,
      pointTime: new Date().getTime()
    };
    api({
      url: "/ioc/security/pointlog/locus",
      method: "post",
      data: params
    })
      .then(() => {})
      .catch(err => {
        console.log("err", err);
      });
  },
  getUrlParams(url = "") {
    var query = url.split("?")[1];
    query = query.split("&");
    var params = {};
    for (let i = 0; i < query.length; i++) {
      let q = query[i].split("=");
      if (q.length === 2) {
        params[q[0]] = q[1];
      }
    }
    return params; // 返回?号后面的参数名与参数值的数组
  }
};
