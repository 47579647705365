<template>
  <div id="web-page-box">
    <router-view v-if="showKey" :key="this.$route.fullPath" />
    <router-view v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showKey: false
    };
  },
  watch: {
    $route(newVal) {
      if (newVal.fullPath.includes("thirdIframe")) {
        this.showKey = true;
      } else {
        this.showKey = false;
      }
    }
  }
};
</script>

<style lang="less">
#page-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}
#page-container-left {
  height: 100%;
}
#page-container-right {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: calc(~"100% - 84px");
}

#page-content-container {
  position: relative;
  background-color: #060e21;
  height: calc(~"100% - 125px");
}
.dark {
  background-color: inherit;
  position: relative;
}
body > #nprogress {
  display: none;
}
// 此处是时间控件range选择时的背景色
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div,
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover {
  background: #0e1426;
}
// 控制时间控件vue2-datepicker-old 时分秒滚动的样式
.mx-time-column .mx-time-item:hover {
  background: #101b35 !important;
}
.portal_delete_dailog {
  border: transparent !important;
  border: none !important;
}
</style>
<style lang="less" scoped>
/deep/ .page-wrapper {
  overflow: visible !important;
}
</style>
