<template>
  <div class="nothingMore" >
    <div class="nothingMoreContent">
      <i class="iconfont" :class="iconfontClassStr"></i>
      <br>
      <span v-if="iconfontClassStr === 'icon-juxing'">{{ $t('i18nKey_35') }}</span>
      <span v-if="iconfontClassStr === 'icon-gantanhao'">{{ $t('i18nKey_1035') }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "noDataShowContent",
  props: {
    iconfontClassStr: {
      type: String,
      default: "icon-juxing"
    }
  },
  components: {}
};
</script>
<style lang="less" scoped>
.nothingMoreContent{
  &>span{
    color:#323b59;
  }
}
</style>
