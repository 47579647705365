import Vue from "vue";

import iocConfirm from "./components/common/iocConfirm/index.js";
import formCreate from "@form-create/element-ui";
import commonComponent from "./components/common/js/commonComponent"; // 公用全局组件引入
import clickoutside from "element-ui/src/utils/clickoutside";
import mcropper from "@/components/BaseVideo/mcropper.js";
import "./directive/directives.js";
import loadMore from "./directive/loadMore.js";
// import format from "@/common/dataobj/data.js";
import { RES_CODE_SUCCESS, RES_CODE_BIM_NOT_MODEL, RES_CODE_BIM_NOT_PARJING_MODEL, RES_CODE_BIM_NOT_PERSON_PATH } from "@/common/utils/constant";
let browserType = UtilFn.browserType();

if (window.isPosCodeWuHan) {
  let mapList = JSON.parse(sessionStorage.getItem("resetGistype"))["parkConfigList"]["mapConfig"];
  if (mapList) {
    if (mapList.indexOf("vrbim") > -1) {
      sessionStorage.setItem("gisType", "vrbim");
    } else {
      if (mapList.indexOf("gis") > -1) {
        sessionStorage.setItem("gisType", "gis");
      } else {
        sessionStorage.setItem("gisType", "gaode");
      }
    }
  }
}
// 此处监听URL是否带有 websession 参数，带有则将其截取存取sessionStore
if (location.search.indexOf("websession") !== -1) {
  let searchObj = {};
  location.search && location.search.slice(1).split("&").forEach(item => {
    let arr = item.split("=");
    searchObj[arr[0]] = arr.slice(1).join("&");
  });
  if (searchObj.websession) sessionStorage.setItem("IOC_WEB_SESSION", searchObj.websession);
}
/* 听云兼容 */
if (!window.TINGYUN) {
  var TINGYUN = window.TINGYUN = {};
  TINGYUN.createEvent = function() {
    var e = {};
    e.end = function() { };
    e.fail = function() { };
    return e;
  };
}

Vue.use(formCreate);
Vue.use(mcropper);
Vue.use(commonComponent);

Vue.config.productionTip = false;
Vue.prototype.$RES_CODE_SUCCESS = RES_CODE_SUCCESS;
Vue.prototype.$iocConfirm = iocConfirm;

Vue.prototype.$MAP_DEFAULT_ZOOM = {
  COUNTRY: 3,
  AREA: 5,
  CITY: 9,
  PARK: 18
};
Vue.prototype.$vuePubSub = new Vue();
Vue.directive("clickoutside", clickoutside);
Vue.directive("loadmore", loadMore);
Vue.directive("drag-stack", {
  bind: el => {
    el.classList.add("drag-stack");
    el.style["z-index"] = 1000;
    el.onclick = ev => {
      window.event ? (window.event.cancelBubble = true) : ev.stopPropagation();
      let tempArr = document.querySelectorAll(".drag-stack");
      for (let i = 0, len = tempArr.length; i < len; i++) {
        tempArr[i].style["z-index"] = 1000;
      }
      el.style["z-index"] = parseInt(el.style["z-index"]) + 1;
    };
  }
});

// 截流，控制在wait时间内func只会被触发一次
let throttleInitTime = 0;
const throttle = (func, wait) => {
  return function() {
    let pointer = this;
    var args = arguments;
    let nowTime = new Date();
    if (nowTime - throttleInitTime > wait) {
      func.apply(pointer, args);
      throttleInitTime = nowTime;
    }
  };
};
Vue.prototype.throttle = throttle;
function zero(s) {
  // 自动补零
  return s < 10 ? `0${s}` : s;
}

const Dateformat = (date, formatter, type) => {
  // 时间格式
  let f = formatter || "yyyy/MM/dd HH:mm:ss";
  const t = {};
  t.yyyy = date.getFullYear();
  t.MM = zero(date.getMonth() + 1);
  t.dd = zero(date.getDate());
  t.HH = zero(date.getHours());
  t.mm = zero(date.getMinutes());
  t.ss = zero(date.getSeconds());
  ["yyyy", "MM", "dd", "HH", "mm", "ss"].forEach(item => {
    f = f.replace(item, t[item]);
  });
  // if (formatter === "yyyy-MM-dd" && localStorage.getItem("IOC_WEB_LANG") === "en_US") {
  //   f = format.Enformat(t.yyyy, t.MM, t.dd);
  // }
  // if (formatter === "yyyy-MM-dd" && localStorage.getItem("IOC_WEB_LANG") === "th_TH") {
  //   f = format.Thformat(t.yyyy, t.MM, t.dd);
  // }
  return f;
};

// 时间戳转换为时间
const timestampToTime = (timestampStr, formatter) => {
  var timestamp = parseInt(timestampStr);
  if (timestampStr.length < 11) {
    timestamp = parseInt(timestampStr) * 1000;
  }
  const jstimestamp = Dateformat(new Date(timestamp), formatter);
  return jstimestamp;
};

Vue.prototype.Dateformat = Dateformat;
Vue.prototype.timestampToTime = timestampToTime;

let API_RES_CODE = {
  RES_CODE_BIM_NOT_MODEL,
  RES_CODE_BIM_NOT_PARJING_MODEL,
  RES_CODE_BIM_NOT_PERSON_PATH
};
export default {
  browserType,
  API_RES_CODE,
  Dateformat
};
