export default {
  executionTime: 'EN_执行总时长',
  cumulativeHappened: 'EN_累计发生',
  cumulativeProcessing: 'EN_累计处理',
  treatmentRate: 'EN_处理完成率',
  processingTimeliness: 'EN_处理及时率',
  timeoutProcessed: 'EN_超时未处理',
  processedTimeout: 'EN_超时后已处理',
  energyAIDiagnostics: 'EN_能效AI诊断',
  mandatory: 'EN_ 必填',
  IDCard: 'EN_ 身份证',
  vehicleLicense: 'EN_ 行驶证',
  driverLicense: 'EN_ 驾驶证',
  discountPackage: 'EN_ 优惠套餐',
  hangUp: "EN_ 已挂起",
  low: "EN_ 低",
  middle: "EN_ 中",
  high: "EN_ 高"
}