<template>
  <div class="page-box">
    <div v-if="hasLayoutMounted" class="dark page-wrapper">
      <ViewWebsocket />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script>
import ViewWebsocket from "@/components/view-websocket";
import { mapState, mapMutations, mapGetters } from "vuex";
import handleErr from "@/js/handle-err.js";
// let isLayoutMounted = false;

export default {
  name: "IocwebLayout",
  components: {
    ViewWebsocket
  },
  data() {
    return {
      websession: sessionStorage.getItem("IOC_WEB_SESSION"),
      BIMType: "1", // 当前加载模式只有1和3 所以默认改为1
      hasLayoutMounted: false
    };
  },
  computed: {
    ...mapState([
      "loading",
      "errStatus",
      "XHRTimestamp",
      "routePath",
      "isBIMMap"
    ]),
    ...mapGetters("baseUser", ["getDecItem"])
  },
  watch: {
    errStatus({ code }) {
      handleErr(code);
    },
    $route: {
      immediate: true,
      handler(to) {
        this.bimPath();
      }
    },
    XHRTimestamp(v) {
      window.GLOBAL_OBJ.eventHub.publish("XHRTimestampChange", v);
    }
  },
  methods: {
    ...mapMutations([
      "fillErrStatus",
      "fillBtnAuth",
      "fillIsBIMMap",
      "fillBIMType",
      "fillMapType",
      "module_fillUser",
      "fillUser",
      "module_fillAllPosLeafnodeCodeArr",
      "module_fillPositionArr"
    ]),
    ...mapMutations("basePosition", ["fillPositionInfo", "fillPositionList"]),
    handleErr,
    changeRoute(v) {
      window.history.pushState(null, null, v);
    },
    bimPath() {
      let pathName = location.pathname.split("/")[1];
      if (
        this.isBIMMap === "1" &&
        (location.pathname === "/facilityOperateStatistics" ||
          pathName === "facilityEventCenter" ||
          pathName === "sanitationEventCenter" ||
          location.pathname === "/lightControlMap" ||
          location.pathname === "/pointLocationInformation")
      ) {
        // window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", true);
        this.fillBIMType(this.BIMType);
      } else if (location.pathname === "/globalStatus") {
        // 综合态势只展示全景图 不可选择
        // window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", false);
        this.fillBIMType("1");
      } else {
        // window.GLOBAL_OBJ.eventHub.publish("bimSelectChange", false);
        this.fillBIMType("3");
      }
    },
    handleLayoutMounted({
      node = {},
      routeMap = {},
      parkConfigList = {},
      userPerm = {},
      positionList = [],
      corporationVO = {},
      parkList = []
    } = {}) {
      this.hasLayoutMounted = true;
      let { mapConfig = "" } = parkConfigList;
      let userInfo = {
        ...userPerm,
        ...corporationVO
      };
      this.fillUser(userInfo);
      this.module_fillUser(userInfo);
      this.fillPositionInfo(node);
      this.fillPositionList(positionList);
      this.fillBtnAuth(routeMap);

      let leafNodePosCodeArr = [];
      parkList = parkList instanceof Array ? parkList : [parkList];
      UtilFn.fillIndexToRecursionData(parkList, {
        handleItem(item) {
          item.label = item.posCn;
          return item;
        },
        fillIndexKey: "value",
        handleIndex(indexArr) {
          return indexArr.join("-");
        },
        handLeafNode(item) {
          item.posCode && leafNodePosCodeArr.push(item.posCode);
          return item;
        }
      });
      this.module_fillAllPosLeafnodeCodeArr(leafNodePosCodeArr);
      this.module_fillPositionArr(parkList);

      let configList = mapConfig.split(",");
      let config = configList[0] !== "vr" ? configList[0] : configList[1];
      window.GLOBAL_OBJ.eventHub.publish("mapListChange", configList);
      this.fillIsBIMMap(config === "bim" ? "1" : "0");
      this.fillMapType(config);
      this.BIMType = node.BIMType;
      this.bimPath();
      // if (isLayoutMounted) {
      //   // location.href = "/";
      // } else {
      //   isLayoutMounted = true;
      //   let bg = document.createElement("div");
      //   bg.className = "transparent-mask";
      //   document.querySelector(".lay-position-box").appendChild(bg);
      // }
    }
  },
  created() {
    window.GLOBAL_OBJ.eventHub.publish("layoutMounted", (data) => {
      this.hasLayoutMounted = true;
      this.handleLayoutMounted(data);
    });
  }
};
</script>
<style lang="less">
.page-wrapper {
  height: calc(100% - 4rem);
  margin-top: 4rem;
  overflow: auto;
}
.transparent-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9;
  display: none;
}
.el-button.is-plain:hover {
  background: none;
}
.el-time-spinner__item.active:not(.disabled) {
  color: #c0c4cc;
}
.el-picker-panel__footer {
  background: #1a213a;
}
.el-picker-panel__body {
  background: #1a213a;
}
.el-date-picker__header{
  margin: 0 12px 12px 12px;
}
.el-time-panel {
  background: #1a213a;
}
.el-picker-panel {
  color: #c0c4cc;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #606266;
}
.el-date-table th {
  color: #c0c4cc;
  border: 0;
}
.el-picker-panel__icon-btn {
  color: #c0c4cc;
}
.el-date-picker__header-label {
  color: #c0c4cc;
}
</style>
