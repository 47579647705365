import langData from "./langData.ts";
import webData from "./webData.ts";
import supplementary from "./supplementary.ts";
import common from "./common.ts";

export default Object.assign(
  {},
  {
    langData,
    webData,
    supplementary,
    common
  }
);
