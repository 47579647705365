import { getSpaceList } from "@/common/dataApi/itgHouse";

export default {
  namespaced: true,
  state: {
    houseInfo: {
      id: "", // 主键id
      name: "",
      unitSpaceId: "", // 单元空间id
      houseSpaceId: "", // 房屋空间id
      needUpdate: false // 更新房屋信息开关
    },
    houseDetail: {
      apartType: "",
      status: "",
      number: "",
      createdBy: "",
      createDate: "",
      spaceType: "",
      operator: "",
      building: "",
      houseName: "",
      selected: "",
      houseType: "",
      roomType: "",
      houseId: "",
      spaceId: ""
    },
    unitSpaceList: [],
    houseSpaceList: [],
    allEquipSelectList: [
      // 场景中所有已选择的设备
      {
        abilityList: [],
        compareType: "",
        compareTypeList: [],
        compareValue: "",
        firstValueMap: {},
        insertNum: 0,
        paramsType: "",
        secondValueMap: {},
        serviceSelect: [],
        serviceValueMap: {},
        tcaType: ""
      }
    ]
  },
  mutations: {
    changeHouseInfo(state, data) {
      state.houseInfo = Object.assign({}, state.houseInfo, data);
    },
    submitHouseDetail(state, data) {
      state.houseDetail = Object.assign({}, state.houseDetail, data);
    },
    changeListHouseDetail(state, houseData) {
      const { id } = houseData;
      const houseIndex = state.houseSpaceList.findIndex(item => item.id === id);
      const house = state.houseSpaceList[houseIndex];
      house &&
        state.houseSpaceList.splice(
          houseIndex,
          1,
          Object.assign({}, house, houseData)
        );
    },
    fillUnitSpaceList(state, data) {
      state.unitSpaceList = data;
    },
    fillHouseSpaceList(state, data) {
      state.houseSpaceList = data;
    },
    fillAllEquipSelectList(state, data) {
      state.allEquipSelectList = data;
    }
  },
  getters: {
    // 已交付
    isFinishStatus: state => +state.houseDetail.status === 2
  },
  actions: {
    async getUnitSpaceInfo({ dispatch, commit }, params) {
      const { parkCode = "" } = params;
      try {
        const data = await getSpaceList(params);
        if (Array.isArray(data) && data.length) {
          commit("fillUnitSpaceList", data);
          const { id, buildingUnitSpaceId } = data[0];
          commit("changeHouseInfo", {
            unitSpaceId: buildingUnitSpaceId,
            houseSpaceId: "",
            needUpdate: false,
            id
          });
          dispatch("getHouseSpaceInfo", {
            parkCode,
            buildingUnitSpaceId
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    async getHouseSpaceInfo({ commit }, params) {
      try {
        const data = await getSpaceList(params);
        commit("fillHouseSpaceList", data);
      } catch (err) {
        console.log("err", err);
      }
    }
  }
};
