export default [
  {
  // 登录
    path: "/login",
    // name: "loginPage",
    component: () => import(/* webpackChunkName: "loginPage" */ "@/pages/loginPage/loginPage"),
    children: [{
      path: "",
      component: () => import(/* webpackChunkName: "inlogin" */ "@/pages/loginPage/login")
    }, {
      path: "resetpwd",
      component: () => import(/* webpackChunkName: "resetpwd" */ "@/pages/loginPage/resetpwd") // 修改密码
    }, {
      path: "forget",
      component: () => import(/* webpackChunkName: "forget" */ "@/pages/loginPage/forget") // 忘记密码
    }, {
      path: "registerSuccess",
      component: () => import(/* webpackChunkName: "registerSuccess" */ "@/pages/loginPage/registerSuccess") // 注册成功页面
    }]
  },
  {
    path: "/checkPrivacy",
    component: () => import(/* webpackChunkName: "checkPrivacy" */ "@/pages/loginPage/checkPrivacy") // 查看隐私页面
  },
  {
    path: "/serviceAgreement",
    component: () => import(/* webpackChunkName: "serviceAgreement" */ "@/pages/loginPage/serviceAgreement") // 查看服务协议页面
  },
  {
    path: "/boShiYuIframeVehicleStatic",
    component: () => import(/* webpackChunkName: "boShiYuIframeVehicleStatic" */ "@/pages/vehicleManagement/vehicleManagementStatistics") // 车辆统计--车辆管理
  },
  {
    path: "/thirdLogin",
    component: () => import(/* webpackChunkName: "thirdLogin" */ "@/pages/loginPage/thirdLogin") // 三方单点登录
  }
];