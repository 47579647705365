export default {
  executionTime: 'TH_执行总时长',
  cumulativeHappened: 'TH_累计发生',
  cumulativeProcessing: 'TH_累计处理',
  treatmentRate: 'TH_处理完成率',
  processingTimeliness: 'TH_处理及时率',
  timeoutProcessed: 'TH_超时未处理',
  processedTimeout: 'TH_超时后已处理',
  energyAIDiagnostics: 'TH_能效AI诊断',
  mandatory: 'TH_ 必填',
  IDCard: 'TH_ 身份证',
  vehicleLicense: 'TH_ 行驶证',
  driverLicense: 'TH_ 驾驶证',
  discountPackage: 'TH_ 优惠套餐',
  hangUp: "TH_ 已挂起",
  low: "TH_ 低",
  middle: "TH_ 中",
  high: "TH_ 高"
}