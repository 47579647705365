import Vue from "vue";
import iocConfirm from "./index.vue";

let DialogConstructor = Vue.extend(iocConfirm);

export default function(text, onConfirm, onCancel) {
  return new Promise((resolve, reject) => {
    let dialogDom = new DialogConstructor({
      el: document.createElement("div")
    });
    document.body.appendChild(dialogDom.$el);

    dialogDom.text = Object.assign({}, dialogDom.text, text);

    // function closeConfirm() {
    //   dialogDom.isShow = false;
    // }

    // dialogDom.confirm = function() {
    //   resolve(closeConfirm);
    // };

    // dialogDom.cancel = function() {
    //   reject('取消')
    //   dialogDom.isShow = false;
    // };

    dialogDom.confirm = function() {
      dialogDom.isShow = false;
      onConfirm && onConfirm("confirm");
      !onConfirm && resolve("confirm");
    };

    dialogDom.cancel = function() {
      dialogDom.isShow = false;
      onCancel && onCancel("cancel");
      !onCancel && reject(new Error("cancel"));
    };
  });
}