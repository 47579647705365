import createPersistedState from "vuex-persistedstate";

let pluginArr = [];
let createPersistedState1 = createPersistedState({
  storage: window.sessionStorage,
  key: "GLOBAL_IOC",
  paths: [
    "userInfo", // 旧 用户信息
    "btnAuthObj", // 按钮权限
    "basePosition.positionArr", // 头部园区数据
    "baseUser._info", // 用户信息
    "navigation.allPosLeafnodeCodeArr", // 全部园区code数组

    "itgHouse.houseInfo", // 智慧人居当前房屋信息
    "isBIMMap" // 是否是BIM地图
  ]
});

pluginArr.push(createPersistedState1);

export default pluginArr;
