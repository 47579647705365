import comComponents from "comComponents";
const WEBSOCKET_URL = comComponents.CONSTANT.WEBSOCKET_URL;

export const BASEURL = window.COMMON_CONFIG.requestBaseUrl;
export const sysRegistry = {...comComponents.CONSTANT.sysRegistry, WEBSOCKET_URL};
export const iotAdminURL = comComponents.CONSTANT.iotAdminURL;// 云橙APP后台

export const obsBucketNameObj = comComponents.CONSTANT.obsBucketNameObj; // 当前环境下的basemgmt 和 safetymgmt桶名
export const comValidateObj = comComponents.comValidateObj;
export const isThServer = comComponents.CONSTANT.isThServer;
export const isSGJ = comComponents.CONSTANT.isSGJ;
export const layerCode = [
  // 顺序不要改动
  {
    iocEquipTypeLv1Code: "BCSS", // 摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 烟感
    iocEquipTypeLv2Code: "_BCSS08",
    iocEquipTypeLv3Code: "_020"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 门禁
    iocEquipTypeLv2Code: "_BCSS06",
    iocEquipTypeLv3Code: "_006"
  }
];

export const cameraCode = [
  {
    iocEquipTypeLv1Code: "BCSS", // 摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 人脸摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_001",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 车辆摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_002",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 高清摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_003",
    equipStatusCode: "在线"
  }
];
