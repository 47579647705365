import api from "@/js/api.js";
import commonUtils from "@/common/utils/common";

import { iotAdminURL } from "@/common/registry/sysRegistry";
export default {
  async checkPrivacy() {
    let result = await commonUtils.axiosData(
      "/ioc/security/v2/authority/account/privacy",
      "GET",
      null
    );
    return result.data;
  },
  async updatePrivacy() {
    let result = await commonUtils.axiosData(
      "/ioc/security/v2/authority/account/privacy",
      "POST",
      null
    );
    return result.data;
  },
  login({ commit }, { userId, password, accountType }) {
    return new Promise((resolve) => {
      api(
        {
          url: "/ioc/security/v2/authority/auth/token",
          method: "post",
          data: {
            userId,
            password,
            accountType,
            tokenType: "password",
            clientId: 1
          }
        },
        { addPositionParams: false, showErrTip: true }
      ).then(({ accessToken }) => {
        sessionStorage.setItem("IOC_WEB_SESSION", accessToken);
        resolve({ accessToken });
      });
    });
  },
  async getQRCode({ commit }, params) {
    let result = await commonUtils.axiosData(
      "/platformservice/login/getQRCode",
      "POST",
      params,
      iotAdminURL.uatURL
    );
    return result.data;
  },
  async destroyQRCode({ commit }, params) {
    let result = await commonUtils.axiosData(
      "/platformservice/login/destroyQRCode",
      "POST",
      params,
      iotAdminURL.uatURL
    );
    return result.data;
  },
  // 检查二维码是否有效 GET /qrcode/check/{sign}
  async checkQRCode({ commit }, sign) {
    let result = await api(
      {
        url: "/ioc/security/v2/authority/qrcode/check",
        method: "post",
        data: {
          sign
        }
      },
      { addPositionParams: false, handleErr: false, showLoading: false }
    );
    return result;
  }
};
