export default [
  {
    path: "/pwRedirect",
    name: "PWRedirect",
    component: () => import(/* webpackChunkName: "pwRedirect" */ "@/pages/loginPage/pwRedirect") // 密码修改成功页面
  },
  {
    // 404
    path: "/404",
    component: () => import(/* webpackChunkName: "404" */ "@/pages/404")
  }
  // {
  //   // 404
  //   path: "/:path/:path2?",
  //   component: () => import(/* webpackChunkName: "404" */ "@/pages/404")
  // },
  // {
  //   // 404
  //   path: "/:path/*",
  //   component: () => import(/* webpackChunkName: "404" */ "@/pages/404")
  // }
];
