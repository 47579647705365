import singleSpaVue from "single-spa-vue";

import "@/init.js";
import { vueI18n } from "@/I18N.js";
import "./style/index.css";
import store from "./store";
import App from "./App";
import router from "./router";
import comComponents from "comComponents";

import Vue from "vue";

for (let name in comComponents.IocChart) {
  Vue.component(name, comComponents.IocChart[name]);
}
if (window.TINGYUN && window.TINGYUN.wrapVueRouter) {
  window.TINGYUN.wrapVueRouter(router, {
    endDelay: 500 // 切换路由后延迟结束时间
  });
}
// 监控代码结束

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    store,
    i18n: vueI18n
  }
});

/**
 * 丹田项目获取数据
 */
const laySelectedPosCode = localStorage.getItem("lay_selectedPosCode");
// eslint-disable-next-line
if (isDanTianPark && isDanTianPark(laySelectedPosCode)) {
  let scriptEvent = document.createElement("script");
  scriptEvent.type = "text/javascript";
  scriptEvent.src = `/js/dantian/${laySelectedPosCode}/eventData1.js`;
  document.getElementById("indexHead").appendChild(scriptEvent);
}

// 打上gitcommit信息
if (!window.$WANRUI) {
  window.$WANRUI = {};
}
window.$WANRUI.IOCWEB_GITCOMMIT = process.env.VUE_APP_GITCOMMIT;
window.$WANRUI.IOCWEB_GITADDRESS = "https://git.vankeservice.com/ioc/ioc-web.git";

export const bootstrap = vueLifecycles.bootstrap;
export const mount = props => vueLifecycles.mount(props).then(() => {
});
export const unmount = vueLifecycles.unmount;
