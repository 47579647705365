<template>
  <el-dialog
    class="errorMessagetheme"
    :visible="visible"
    width="29.75rem"
    top="35vh"
    append-to-body
    :title="headerTitle"
    :before-close="erroMassaeClose"
  >
    <div class="errmage">
      <!-- <div class="errmage-close">
        <div class="errmage-close-f">{{headerTitle}}</div>
        <div
          class="errmage-close-s"
          @click="erroMassaeClose"
        >
          <span class="icon iconfont icon-guanbi"></span>
        </div>
      </div> -->
      <div
        class="errmage-sucss"
        v-show="errorShow === '1'"
      >
        <div class="errmage-sucss-t">
          <span class="iconfont icon-finish"></span>
        </div>
        <div class="errmage-sucss-f">{{messAgeText}}</div>
      </div>
      <div
        class="errmage-sucss errmage-error"
        v-show="errorShow === '2'"
      >
        <div class="errmage-sucss-t">
          <span class="iconfont icon-cuowu"></span>
        </div>
        <div class="errmage-sucss-f">{{messAgeText}}</div>
      </div>
      <div
        class="errmage-sucss errmage-error"
        v-show="errorShow === '3'"
      >
        <div class="errmage-sucss-t">
          <span class="iconfont icon-jinggao1"></span>
        </div>
        <div class="errmage-sucss-f">{{messAgeText}}</div>
        <div class="errmage-sucss-s">
          <div
            class="errmage-sucss-s-button queryButton"
            @click="erroMassaeQuery"
          >确定</div>
          <div
            class="errmage-sucss-s-button middleButton"
            v-if="isShow"
            @click="goToModify"
          >去修改</div>
          <div
            class="errmage-sucss-s-button rightButton"
            @click="erroMassaeClose"
          >{{rightButtontxt}}</div>
        </div>
      </div>
      <div
        class="errmage-sucss"
        v-show="errorShow === '4'"
      >
        <div class="errmage-sucss-t">
          <span class="iconfont icon-jinggao1"></span>
        </div>
        <div class="errmage-sucss-f">{{messAgeText}}</div>
      </div>
      <div
        class="errmage-sucss"
        v-show="errorShow === '5'"
        style="paddingBottom:20px;"
      >
        <div style="height:30px;padding:10px 0;color:#ff8a00;">
          <span
            class="iconfont icon-jinggao1"
            style="fontSize:30px;"
          ></span>
        </div>
        <div
          class="errmage-sucss-f"
          style="height:150px;overflow-y:auto;boxSizing:border-box"
        >{{messAgeText}}</div>
      </div>
    </div>
    <iframe
      v-if="isIframe"
      id="errorIframe"
      src
      transparent="transparent"
      allowTransparent="true"
      frameborder="0"
      style=" position:fixed;background-color:transparent;top:0px;left:0px;z-index:-10;width:100%;height:100%;opacity:0"
    ></iframe>
  </el-dialog>
</template>
<script>
export default {
  name: "errorMessage",
  components: {},
  props: {
    headerTitle: {
      type: String,
      default: "提示"
    },
    messAgeText: {
      // 提示信息
      type: String,
      default: "任务"
    },
    rightButtontxt: {
      type: String,
      default: "取消"
    },
    errorShow: {
      // 1为成功  2 为错误 3 为中性
      type: String,
      default: "1"
    },
    visible: {
      type: Boolean,
      default: false
    },
    isIframe: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    erroMassaeClose() {
      return new Promise((resolve, reject) => {
        resolve(this.$emit("erroMassaeClose"));
      });
    },
    erroMassaeQuery() {
      return new Promise((resolve, reject) => {
        resolve(this.$emit("erroMassaeQuery"));
      });
    },
    goToModify() {
      return new Promise((resolve, reject) => {
        resolve(this.$emit("goToModify"));
      });
    }
  },
  watch: {},
  computed: {}
};
</script>
<style lang="less" scoped>
@backcolor: #262f48;
@fcolor: #17c0cd;

.errorMessagetheme {
  .el-dialog__header {
    padding: 0 0;
    display: none;
  }
  .el-dialog__body {
    padding: 0 0;
  }
  .errmage {
    // width: 29.75rem;
    // background: @backcolor;
    filter: alpha(opacity=90);
    opacity: 0.9;
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    .errmage-close {
      position: relative;
      margin: auto;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #39465d;
      .errmage-close-f {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 80%;
        font-size: 1rem;
        color: #fff;
        line-height: 20px;
      }
      .errmage-close-s {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 10px;
        height: 20px;
        cursor: pointer;
        .icon-guanbi {
          color: #fff;
          font-size: 10px;
        }
      }
    }
    .errmage-sucss {
      position: relative;
      margin: auto;
      width: 100%;
      text-align: center;
      color: @status-success-color;
      .errmage-sucss-t {
        margin: auto;
        width: 100%;
        height: 6.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-finish {
          font-size: 3.75rem;
        }
        .icon-cuowu {
          font-size: 3.75rem;
          color: @btn-waring-color;
        }
        .icon-jinggao1 {
          position: absolute;
          top: 2rem;
          left: 12rem;
          font-size: 3.75rem;
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          color: @status-warning-color;
        }
      }
      .errmage-sucss-f {
        margin: auto;
        padding-bottom: 30px;
        width: 100%;
        color: #fff;
        font-size: 1rem;
        line-height: 30px;
        text-align: center;
        word-wrap: break-word;
      }
      .errmage-sucss-s {
        position: relative;
        margin: auto;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        .errmage-sucss-s-button {
          width: 4.6875rem;
          height: 30px;
          text-align: center;
          color: #fff;
          font-size: 1rem;
          line-height: 30px;
          background: @btn-default-color;
          border: 1px solid @btn-default-color;
          border-radius: 2px;
          cursor: pointer;
        }
        .queryButton {
          display: inline-block;
          height: 2.0625rem;
          border-radius: 0.125rem;
          text-align: center;
          color: #fff;
          font-size: 0.875rem;
          line-height: 2.0625rem;
          background-color: #3a7aea;
        }
        .queryButton:hover {
          background: @btn-hover-color;
          border: 1px solid @btn-hover-color;
        }
        .queryButton:focus {
          background: @btn-focus-color;
          border: 1px solid @btn-focus-color;
        }
        .middleButton {
          background: transparent;
          border: 1px solid @btn-close-color;
        }
        .middleButton:hover {
          background: transparent;
          border: 1px solid @btn-hover-color;
          color: @btn-hover-color;
        }
        .middleButton:focus {
          background: @btn-hover-color;
          border: 1px solid @btn-hover-color;
          color: #fff;
        }
        .rightButton {
          display: inline-block;
          height: 2.0625rem;
          background: transparent;
          border-radius: 0.125rem;
          text-align: center;
          color: #fff;
          font-size: 0.875rem;
          line-height: 2.0625rem;
          border: solid 0.0625rem #324059;
          margin-left: 20px;
        }
        .rightButton:hover {
          background: transparent;
        }
        .rightButton:focus {
          background: @btn-hover-color;
          border: 1px solid @btn-hover-color;
          color: #fff;
        }
      }
    }
    .errmage-error {
      color: @btn-waring-color;
    }
  }
}
.el-dialog__close.el-icon {
  font-size: 1rem;
}
</style>
<style lang="less" scoped>
.errmage-sucss-s{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
