
import Ajax from "@/js/api.js";
// import { RES_CODE_SUCCESS } from "@/common/utils/constant";
// const DATACENTERSELFSERVICE = Ajax.getRegistry("DATACENTER");
export default {
  namespaced: true,
  state: {
    tableData: [],
    suggestLabel: [],
    allLabel: [],
    tableTotal: 0,
    echartTotal: 0,
    HabitOrderData: {
      name: "",
      index: [],
      value: []
    },
    sexData: {
      index: [],
      value: [],
      name: ""
    },
    personTypeData: {
      index: [],
      value: [],
      name: ""
    },
    weekVisitData: {
      index: [],
      value: [],
      name: ""
    },
    personLifecycleData: {
      index: [],
      value: [],
      name: ""
    },
    orderTimeData: {
      index: [],
      value: [],
      name: ""
    },
    companyTopData: {
      index: [],
      value: [],
      name: ""
    },
    hotfoodTopData: {
      index: [],
      value: [],
      name: ""
    },
    monthMountData: {
      index: [],
      value: [],
      name: ""
    },
    analyzeTypeData: []
  },
  getters: {
    handleTableData(state) {
      let arr = state.tableData.map((item) => {
        return {
          ...item, action: "查看"
        };
      });
      return arr;
    },
    // 习惯下单日期
    handleHabitOrderData(state) {
      let valueArr = state.HabitOrderData.value;
      let indexArr = state.HabitOrderData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.HabitOrderData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 性别
    handleSexData(state) {
      let valueArr = state.sexData.value;
      let indexArr = state.sexData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.sexData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 人员分类
    handlePersonTypeData(state) {
      let valueArr = state.personTypeData.value;
      let indexArr = state.personTypeData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.personTypeData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 近一周园区访客
    handleWeekVisitData(state) {
      let valueArr = state.weekVisitData.value;
      let indexArr = state.weekVisitData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.weekVisitData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 云橙APP用户周期
    handleLifecycleData(state) {
      let valueArr = state.personLifecycleData.value;
      let indexArr = state.personLifecycleData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.personLifecycleData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 习惯下单时间
    handleOrderTimeData(state) {
      let valueArr = state.orderTimeData.value;
      let indexArr = state.orderTimeData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          name: indexArr[index]
        };
      });
      return {
        name: state.orderTimeData.name,
        seriesData,
        lenged: indexArr
      };
    },
    // 员工企业认证Top3
    handleCompanyTopData(state) {
      let valueArr = state.companyTopData.value;
      let rateArr = state.companyTopData.rate;
      let indexArr = state.companyTopData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          rate: rateArr[index],
          name: indexArr[index]
        };
      });
      return {
        name: state.companyTopData.name,
        seriesData
      };
    },
    // 热销商品
    handleHotfoodTopData(state) {
      let valueArr = state.hotfoodTopData.value;
      let rateArr = state.hotfoodTopData.rate;
      let indexArr = state.hotfoodTopData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          rate: rateArr[index],
          name: indexArr[index]
        };
      });
      return {
        name: state.hotfoodTopData.name,
        seriesData
      };
    },
    // 近一月消费金额
    handleMonthMountData(state) {
      let valueArr = state.monthMountData.value;
      let rateArr = state.monthMountData.rate;
      let indexArr = state.monthMountData.index;
      let seriesData = valueArr.map((item, index) => {
        return {
          value: item,
          rate: rateArr[index],
          name: indexArr[index]
        };
      });
      return {
        name: state.monthMountData.name,
        seriesData
      };
    }

  },
  mutations: {
    setTableData(state, data) {
      state.tableData = data;
      state.tableTotal = data.length > 0 ? data[0].total : 0;
    },
    setSuggestLabelData(state, data) {
      state.suggestLabel = data.map(item => {
        return {...item, select: false};
      });
    },
    setAllLabelData(state, data) {
      let arrfn = (data) => {
        for (let i = 0; i < data.length; i++) {
          if ("child" in data[i]) {
            arrfn(data[i].child);
          } else {
            data[i].select = false;
          }
        }
      };
      arrfn(data);
      state.allLabel = data;
    },
    setHabitOrderData(state, data) {
      state.HabitOrderData = data;
    },
    setAnalyzeTypeData(state, data) {
      state.analyzeTypeData = data.index.map(item => {
        return {
          name: item,
          select: true
        };
      });
    },
    changeAnalyze(state, data) {
      state.analyzeTypeData.forEach((item, index) => {
        if (index === data) {
          item.select = !item.select;
        }
      });
    },
    setSexData(state, data) {
      state.sexData = data;
    },
    setPersonTypeData(state, data) {
      state.personTypeData = data;
      state.echartTotal = data.total;
    },
    setWeekVisitData(state, data) {
      state.weekVisitData = data;
    },
    setPersonLifecycleData(state, data) {
      state.personLifecycleData = data;
    },
    setOrderTimeData(state, data) {
      state.orderTimeData = data;
    },
    setCompanyTopData(state, data) {
      state.companyTopData = data;
    },
    setHotfoodTopData(state, data) {
      state.hotfoodTopData = data;
    },
    setMonthMountData(state, data) {
      state.monthMountData = data;
    }
  },
  actions: {
    // 列表数据
    getTableData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setTableData", data);
          return false;
        }).catch(() => {
        return false;
      });
    },
    // 推荐标签
    getSuggestLabel({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setSuggestLabelData", data);
        });
    },
    // 获取所有标签
    getAllLabel({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setAllLabelData", data);
        });
    },
    // 获取习惯下单日期
    getHabitOrderData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setHabitOrderData", data);
        });
    },
    // 获取习惯下单时间
    getOrderTimeData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setOrderTimeData", data);
        });
    },
    // 获取分析指标
    getAnalyzeTypeData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setAnalyzeTypeData", data);
        });
    },
    // 性别
    getSexData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setSexData", data);
        });
    },
    // 人员分类
    getPersonTypeData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setPersonTypeData", data);
        });
    },
    // 近一周园区访客
    getWeekVisitData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setWeekVisitData", data);
        });
    },
    // 云橙APP用户周期
    getPersonLifecycleData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setPersonLifecycleData", data);
        });
    },
    // 员工企业认证Top3
    getCompanyTopData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setCompanyTopData", data);
        });
    },
    // 近一月热销商品Top3
    getHotfoodTopData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setHotfoodTopData", data);
        });
    },
    // 近一月消费金额
    getMonthMountData({commit}, {path, params}) {
      Ajax({
        url: path,
        method: "post",
        data: params
      }, { showLoading: false }).then(
        (data) => {
          commit("setMonthMountData", data);
        });
    }
  }
};
