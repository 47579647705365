export default {
  "executionTime": "Total execution time",
  "alarmType": "alarmType",
  i18nKey_1: "Last Modified",
  i18nKey_2: "Automatic meter reading details list",
  i18nKey_3: "Subsystem",
  i18nKey_4: "Asset type",
  i18nKey_5: "Status",
  i18nKey_6: "Registration time",
  i18nKey_7: "Reset",
  i18nKey_8: "Normal",
  i18nKey_9: "Account name/received phone no.",
  i18nKey_10: "account name",
  i18nKey_11: "Display mode",
  i18nKey_12: "Cloud type",
  i18nKey_13: "Park name",
  i18nKey_14: "Park code",
  i18nKey_15: "passed",
  i18nKey_16: "Undeleted",
  i18nKey_17: "Enabled",
  i18nKey_18: "Disabled",
  i18nKey_19: "Unfrozen",
  i18nKey_20: "Frozen",
  i18nKey_21: "Select the VIP arrival reminder type, the role is not filled in. Non-vip arrival reminder type, role is a required option.",
  i18nKey_22: "select",
  i18nKey_23: "S/N",
  i18nKey_24: "fail to edit",
  i18nKey_25: "Modify hybrid cloud configuration",
  i18nKey_26: "Successfully modified",
  i18nKey_27: "modify",
  i18nKey_28: "signal type",
  i18nKey_29: "Signal label",
  i18nKey_30: "New hybrid cloud configuration",
  i18nKey_31: "New",
  i18nKey_32: "Details",
  i18nKey_33: "Download template",
  i18nKey_34: "Property announcement",
  i18nKey_35: "No data that meets the query conditions",
  i18nKey_36: "The file format is incorrect, only excel files are supported",
  i18nKey_37: "Text",
  i18nKey_38: "Position",
  i18nKey_39: "Did not pass",
  i18nKey_40: "Not Enabled",
  i18nKey_41: "Network unavailable, please try again later.",
  i18nKey_42: "Push target",
  i18nKey_43: "Push alert type",
  i18nKey_44: "Push APP service",
  i18nKey_45: "Article",
  i18nKey_46: "Add time",
  i18nKey_47: "Add",
  i18nKey_48: "Tips",
  i18nKey_49: "Submit failed",
  i18nKey_50: "Submit successfully",
  i18nKey_51: "Submit",
  i18nKey_52: "Search",
  i18nKey_53: "The dead zone can only be composed of numbers and four arithmetic symbols",
  i18nKey_54: "Private Cloud",
  i18nKey_55: "Note: 'Mains', the first level, this level: take the data of the 'high-voltage metering cabinet'; lower level accumulation: take the data of the 'high-voltage outlet cabinet'; power loss rate exceeding >5% is abnormal.",
  i18nKey_56: "data",
  i18nKey_57: "Authorization",
  i18nKey_58: "Collection account name",
  i18nKey_59: "Incorrect phone number format",
  i18nKey_60: "Collect phone number",
  i18nKey_61: "Remaining",
  i18nKey_62: "Facilities sub-categories",
  i18nKey_63: "Facility system",
  i18nKey_64: "Facility name can't be null",
  i18nKey_65: "Facility name",
  i18nKey_66: "Facility classification modification",
  i18nKey_67: "Facility category name",
  i18nKey_68: "Facility classification code",
  i18nKey_69: "Facility level",
  i18nKey_70: "The facility alias can't be null",
  i18nKey_71: "Facility number",
  i18nKey_72: "Equipment classification details",
  i18nKey_73: "Merchant",
  i18nKey_74: "failed to delete!",
  i18nKey_75: "successfully deleted",
  i18nKey_76: "delete",
  i18nKey_77: "Are you sure you want to cancel?",
  i18nKey_78: "confirm delete?",
  i18nKey_79: "determine",
  i18nKey_80: "All",
  i18nKey_81: "Authority",
  i18nKey_82: "cancel",
  i18nKey_83: "Blockchain account",
  i18nKey_84: "Please select at least one alarm type",
  i18nKey_85: "Please select status",
  i18nKey_86: "Please select registration time range",
  i18nKey_87: "Please select the park",
  i18nKey_88: "Please select an application system",
  i18nKey_89: "Please select the facility category to be modified",
  i18nKey_90: "Please select signal type",
  i18nKey_91: "Please choose a location",
  i18nKey_92: "Please select push target",
  i18nKey_93: "Please select a facility system",
  i18nKey_94: "Please select the facility category",
  i18nKey_95: "Please select permission",
  i18nKey_96: "Please choose the type",
  i18nKey_97: "Please select the point type",
  i18nKey_98: "Please select the alarm type",
  i18nKey_99: "please select a type",
  i18nKey_100: "Please select the release time range",
  i18nKey_101: "Please enter your account name/receiving phone number",
  i18nKey_102: "Please enter the park name",
  i18nKey_103: "Please enter your phone number to search",
  i18nKey_104: "Please enter the credit account name",
  i18nKey_105: "Please enter your mobile phone number",
  i18nKey_106: "Please enter the facility name",
  i18nKey_107: "Please enter the facility number",
  i18nKey_108: "Please enter the contact name",
  i18nKey_109: "Please enter the contact phone number",
  i18nKey_110: "Please enter a contact person/phone number",
  i18nKey_111: "Please enter the points wallet",
  i18nKey_112: "Please enter company name/address",
  i18nKey_113: "Please enter company name",
  i18nKey_114: "Please enter company address",
  i18nKey_115: "Please enter the current account login password",
  i18nKey_116: "Please enter a note",
  i18nKey_117: "Please click OK, download the file, modify it and import it again.",
  i18nKey_118: "Please fill in as required",
  i18nKey_119: "Enable logo",
  i18nKey_120: "Enable/disable",
  i18nKey_121: "Enable",
  i18nKey_122: "Target type",
  i18nKey_123: "Contact name",
  i18nKey_124: "Contact phone number format is wrong",
  i18nKey_125: "Contact phone number",
  i18nKey_126: "Contact/Mobile Number",
  i18nKey_127: "Types of",
  i18nKey_128: "card",
  i18nKey_129: "Longitude and latitude can't be filled in",
  i18nKey_130: "thaw",
  i18nKey_131: "Role type",
  i18nKey_132: "Character",
  i18nKey_133: "Points wallet",
  i18nKey_134: "Points type",
  i18nKey_135: "Points reward",
  i18nKey_136: "Point value (yuan/min)",
  i18nKey_137: "Public cloud",
  i18nKey_138: "company information",
  i18nKey_139: "Company name/address",
  i18nKey_140: "Company Name",
  i18nKey_141: "company address",
  i18nKey_142: "personal",
  i18nKey_143: "The alarm name can only consist of letters, numbers, and Chinese",
  i18nKey_144: "The alarm name can't be null",
  i18nKey_145: "The alarm description can only consist of letters, numbers, and Chinese",
  i18nKey_146: "Alarm description can't be null",
  i18nKey_147: "Alarm type",
  i18nKey_148: "The alarm label can only consist of letters and numbers",
  i18nKey_149: "Alarm label can't be null",
  i18nKey_150: "Itemized dimension",
  i18nKey_151: "Analysis index",
  i18nKey_152: "Stage name",
  i18nKey_153: "Total issued quantity",
  i18nKey_154: "Total value of issuance (yuan)",
  i18nKey_155: "publish time",
  i18nKey_156: "Issuer",
  i18nKey_157: "Release record",
  i18nKey_158: "freeze",
  i18nKey_159: "Import failed, only the new version of excel file is supported",
  i18nKey_160: "Import failed",
  i18nKey_161: "Imported successfully",
  i18nKey_162: "Import",
  i18nKey_163: "Export failed, support maximum export data 10000",
  i18nKey_164: "Export",
  i18nKey_165: "Pending",
  i18nKey_166: "error",
  i18nKey_167: "This operation will permanently delete the file. Do you want to continue?",
  i18nKey_168: "Creation failed",
  i18nKey_169: "Create facility classification",
  i18nKey_170: "Created successfully",
  i18nKey_171: "Imported successfully",
  i18nKey_172: "Inquire",
  i18nKey_173: "operating",
  i18nKey_174: "edit",
  i18nKey_175: "Numbering",
  i18nKey_176: "Remarks",
  i18nKey_177: "Successfully saved",
  i18nKey_178: "save",
  i18nKey_179: "VCN configuration",
  i18nKey_180: "VCM configuration",
  i18nKey_181: "L can only be composed of numbers and four arithmetic symbols",
  i18nKey_182: "L can't be null",
  i18nKey_183: "LL can only be composed of numbers and four arithmetic symbols",
  i18nKey_184: "H can only be composed of numbers and four arithmetic symbols",
  i18nKey_185: "H can't be null",
  i18nKey_186: "HH can only be composed of numbers and four arithmetic symbols",
  i18nKey_187: "Saved yesterday",
  i18nKey_188: "Total discharge yesterday",
  i18nKey_189: "Total charge yesterday",
  i18nKey_190: "yesterday",
  i18nKey_191: "Energy used yesterday",
  i18nKey_192: "Energy consumption per square meter yesterday",
  i18nKey_193: "Repeat customers yesterday",
  i18nKey_194: "Yesterday",
  i18nKey_195: "Earliest admission time",
  i18nKey_196: "Latest execution time",
  i18nKey_197: "Latest execution scenarios",
  i18nKey_198: "Minimum",
  i18nKey_199: "Latest time to leave the park",
  i18nKey_200: "Leave the park at the latest $",
  i18nKey_201: "Minimum rent (hours)",
  i18nKey_202: "Minimum rent",
  i18nKey_203: "Last week",
  i18nKey_204: "Last month",
  i18nKey_205: "Last parking time (hours)",
  i18nKey_206: "Last opened",
  i18nKey_207: "Last payment time",
  i18nKey_208: "Last stop time",
  i18nKey_209: "Last three months",
  i18nKey_210: "Last update date: April 25, 2021",
  i18nKey_211: "Last visit time",
  i18nKey_212: "Recently visited",
  i18nKey_213: "No stopping record for the parking space in the last 30 days",
  i18nKey_214: "Last update time",
  i18nKey_215: "Last communication time",
  i18nKey_216: "Last upload time",
  i18nKey_217: "Last update time",
  i18nKey_218: "Can only choose 4 cameras at most",
  i18nKey_219: "Can only choose 5",
  i18nKey_220: "Can only add up to 5 message pushers",
  i18nKey_221: "Only upload up to 4 pictures",
  i18nKey_222: "Only 1 picture can be uploaded!",
  i18nKey_223: "Add up to 5 approvers",
  i18nKey_224: "Up to five",
  i18nKey_225: "Up to 4 strategies can be created",
  i18nKey_226: "Connect up to 3 cameras",
  i18nKey_227: "Up to 30 words",
  i18nKey_228: "Max",
  i18nKey_229: "Maximum alarms no less than or equal to the minimum alarms",
  i18nKey_230: "Lease details",
  i18nKey_231: "Lease start and end dates",
  i18nKey_232: "Lease start date",
  i18nKey_233: "Lease end date",
  i18nKey_234: "Tenant water consumption",
  i18nKey_235: "Tenant energy consumption",
  i18nKey_236: "Tenant",
  i18nKey_237: "Total electricity",
  i18nKey_238: "Total water energy consumption (t)",
  i18nKey_239: "Total",
  i18nKey_240: "Total events",
  i18nKey_241: "Total energy consumption proportion",
  i18nKey_242: "Total energy consumption target",
  i18nKey_243: "Total energy consumption (last month)",
  i18nKey_244: "Total energy consumption (this year)",
  i18nKey_245: "Total energy consumption",
  i18nKey_246: "Total switch amperage",
  i18nKey_247: "Main switch:",
  i18nKey_248: "Total savings",
  i18nKey_249: "Total value (yuan)",
  i18nKey_250: "Total quantity",
  i18nKey_251: "total",
  i18nKey_252: "Total charge and discharge chart",
  i18nKey_253: "Total table",
  i18nKey_254: "Brown",
  i18nKey_255: "Comprehensive passenger flow",
  i18nKey_256: "The number of characters can't exceed 10!",
  i18nKey_257: "Self-created",
  i18nKey_258: "Automated execution strategy",
  i18nKey_259: "Automatic re-recording",
  i18nKey_260: "You can only add up to 3 custom time ranges",
  i18nKey_261: "You can only add up to 3 custom amount ranges",
  i18nKey_262: "You can only add up to 3 custom times",
  i18nKey_263: "Customize",
  i18nKey_264: "Purple",
  i18nKey_265: "Sub-area energy consumption",
  i18nKey_266: "Energy consumption of sub-items",
  i18nKey_267: "Sub-level name",
  i18nKey_268: "Total original value of assets (yuan)",
  i18nKey_269: "Total assets statistics (by park)",
  i18nKey_270: "Total assets statistics (by type)",
  i18nKey_271: "Total assets",
  i18nKey_272: "Statistics of original value of assets (by park)",
  i18nKey_273: "Original asset value statistics (by type)",
  i18nKey_274: "Original value of assets (yuan)",
  i18nKey_275: "Original value of assets (USD)",
  i18nKey_276: "Asset details",
  i18nKey_277: "Asset location information",
  i18nKey_278: "Asset Statistics",
  i18nKey_279: "Asset description",
  i18nKey_280: "Asset type",
  i18nKey_281: "Basic asset information",
  i18nKey_282: "Asset trajectory",
  i18nKey_283: "Asset Number",
  i18nKey_284: "Asset RFID information",
  i18nKey_285: "support hotline",
  i18nKey_286: "Following",
  i18nKey_287: "Tracking task",
  i18nKey_288: "Track",
  i18nKey_289: "Status can't be null",
  i18nKey_290: "Status:",
  i18nKey_291: "Status",
  i18nKey_292: "Transfer facility warning failure",
  i18nKey_293: "Successful transfer facility warning",
  i18nKey_294: "Transfer to refund",
  i18nKey_295: "Conversion situation",
  i18nKey_296: "Conversion rate",
  i18nKey_297: "Forwarding failed",
  i18nKey_298: "Forward successfully",
  i18nKey_299: "Snap a picture",
  i18nKey_300: "Snapshoot",
  i18nKey_301: "Histogram",
  i18nKey_302: "Note: The actual electricity consumption on a certain day compared with the average electricity consumption of the last week increases or decreases to reach the set threshold, it is defined as electricity consumption exception",
  i18nKey_303: "Registration time",
  i18nKey_304: "registration failed",
  i18nKey_305: "Note: Confirm to delete? Operation will not restored",
  i18nKey_306: "Note: Confirm to delete? Operation will not recoverable!",
  i18nKey_307: "Note: Recommend to enter the target value of total energy consumption and public area energy consumption. The system can automatically calculate other values. After the monthly target value is generated, it can be edited by moving the mouse.",
  i18nKey_308: "Master bedroom",
  i18nKey_309: "Theme",
  i18nKey_310: "Main floor",
  i18nKey_311: "Monday",
  i18nKey_312: "Friday",
  i18nKey_313: "Weekly",
  i18nKey_314: "Thursday",
  i18nKey_315: "Wednesday",
  i18nKey_316: "Sunday",
  i18nKey_317: "Weekend passenger flow",
  i18nKey_318: "Saturday",
  i18nKey_319: "Tuesday",
  i18nKey_320: "Week",
  i18nKey_321: "Important warning",
  i18nKey_322: "Importance level",
  i18nKey_323: "Re-delegation",
  i18nKey_324: "Re-log in",
  i18nKey_325: "Redeploy",
  i18nKey_326: "Repeat type",
  i18nKey_327: "Repeat method",
  i18nKey_328: "Heavy pollution",
  i18nKey_329: "Key area to",
  i18nKey_330: "Major event",
  i18nKey_331: "Major",
  i18nKey_332: "Species",
  i18nKey_333: "Terminal",
  i18nKey_334: "Number of finishers",
  i18nKey_335: "The end point can't be the same as the start point",
  i18nKey_336: "End point can't be null",
  i18nKey_337: "End",
  i18nKey_338: "Zhongliang",
  i18nKey_339: "China",
  i18nKey_340: "Moderately polluted",
  i18nKey_341: "Smart water meter number",
  i18nKey_342: "Intelligent system",
  i18nKey_343: "Smart meter number",
  i18nKey_344: "Smart table code can't be null",
  i18nKey_345: "Smart table coding",
  i18nKey_346: "Manufacturer only composed of Chinese and English, numbers and _",
  i18nKey_347: "Manufacturer length can't exceed 50 characters",
  i18nKey_348: "Manufacturer",
  i18nKey_349: "Select at least one device as the execution action",
  i18nKey_350: "Check at least one time period",
  i18nKey_351: "Till now",
  i18nKey_352: "Instruction issuee cycle",
  i18nKey_353: "Designated days",
  i18nKey_354: "Designated time",
  i18nKey_355: "Designated company",
  i18nKey_356: "Designated times",
  i18nKey_357: "Indicator name",
  i18nKey_358: "indicator anaysis",
  i18nKey_359: "Special characters are entered in the position info",
  i18nKey_360: "Job information",
  i18nKey_361: "Total execution time (minutes)",
  i18nKey_362: "Execution time",
  i18nKey_363: "Perform actions:",
  i18nKey_364: "Implementation (times)",
  i18nKey_365: "Carried out",
  i18nKey_366: "Only support Chinese and English case input",
  i18nKey_367: "Only support Chinese and English case",
  i18nKey_368: "Only support Chinese and English, number, space and punctuation input, can wrap",
  i18nKey_369: "There is only an unpleasant axe, no firewood that can't be split.",
  i18nKey_370: "Only positive numbers can be input, and the maximum is 10 integer digits and 2 decimal places",
  i18nKey_371: "Only upload pictures in jpg/png format",
  i18nKey_372: "Only jpg, jpeg, png image files can be uploaded!",
  i18nKey_373: "Only excel files can be uploaded, and the suffix is ​​.xlsx",
  i18nKey_374: "Branch energy consumption statistics",
  i18nKey_375: "Branch power consumption analysis",
  i18nKey_376: "Branch selection",
  i18nKey_377: "Branch energy consumption flow diagram _",
  i18nKey_378: "Branch energy consumption alarms (times)",
  i18nKey_379: "Payment completion time",
  i18nKey_380: "Payment channel",
  i18nKey_381: "Payment start time",
  i18nKey_382: "Payment amount (yuan)",
  i18nKey_383: "payment method",
  i18nKey_384: "Payment number",
  i18nKey_385: "Alipay",
  i18nKey_386: "Expense/revenue",
  i18nKey_387: "Loading video component, please wait",
  i18nKey_388: "Exporting, please wait",
  i18nKey_389: "Normal",
  i18nKey_390: "Diagnostic measures (species)",
  i18nKey_391: "Diagnostic measures",
  i18nKey_392: "Diagnosis",
  i18nKey_393: "Line chart",
  i18nKey_394: "The discount factor must be between 0-1 and 1-2 decimal places can be reserved",
  i18nKey_395: "Discount factor",
  i18nKey_396: "Discount settings",
  i18nKey_397: "Discount description",
  i18nKey_398: "Discount",
  i18nKey_399: "Discounted price details",
  i18nKey_400: "Price after discount",
  i18nKey_401: "Photo generation time",
  i18nKey_402: "Picture type description",
  i18nKey_403: "Photo size",
  i18nKey_404: "Photo can't be null, please upload a picture",
  i18nKey_405: "Photo URL",
  i18nKey_406: "Lighting mode",
  i18nKey_407: "Electricity for lighting and sockets",
  i18nKey_408: "Lighting plan issued",
  i18nKey_409: "Lighting solution management",
  i18nKey_410: "Account login",
  i18nKey_411: "Account number",
  i18nKey_412: "Total bill electricity consumption is $kWh",
  i18nKey_413: "Bill consumption statistics from the beginning to today",
  i18nKey_414: "15 or 18 characters in length",
  i18nKey_415: "Cards",
  i18nKey_416: "Occupy parking space",
  i18nKey_417: "Unfold",
  i18nKey_418: "Viscosity",
  i18nKey_419: "Value-added service details",
  i18nKey_420: "Responsible manager",
  i18nKey_421: "Early train",
  i18nKey_422: "No park map",
  i18nKey_423: "No personnel track",
  i18nKey_424: "No data to export",
  i18nKey_425: "Suspension time",
  i18nKey_426: "Pause",
  i18nKey_427: "Not enabled",
  i18nKey_428: "Online gateway",
  i18nKey_429: "Online password",
  i18nKey_430: "Online check-in",
  i18nKey_431: "Online (a)",
  i18nKey_432: "Online",
  i18nKey_433: "On duty",
  i18nKey_434: "Operator’s phone number needs to be registered in the Yuncheng app",
  i18nKey_435: "Operation and maintenance information",
  i18nKey_436: "Running",
  i18nKey_437: "Operation strategy",
  i18nKey_438: "Operating parameters",
  i18nKey_439: "Allowed visit period",
  i18nKey_440: "Allowed visit period",
  i18nKey_441: "Cloud type",
  i18nKey_442: "Yuncheng Payment",
  i18nKey_443: "Yuncheng APP News",
  i18nKey_444: "Shuzhili APP",
  i18nKey_445: "Pass thousands of miles away and overcome thousands of difficulties.",
  i18nKey_446: "Monthly forecast",
  i18nKey_447: "Monthly consumption amount (yuan)",
  i18nKey_448: "Actual monthly electricity consumption (kWh)",
  i18nKey_449: "Monthly remaining amount (yuan)",
  i18nKey_450: "Monthly card limit",
  i18nKey_451: "Monthly card reviewer",
  i18nKey_452: "Monthly card expiration date",
  i18nKey_453: "Monthly truck",
  i18nKey_454: "Monthly card",
  i18nKey_455: "Monthly water consumption (tons)",
  i18nKey_456: "Monthly power consumption (kWh)",
  i18nKey_457: "Monthly recharge amount (yuan)",
  i18nKey_458: "Month",
  i18nKey_459: "Remote opening time",
  i18nKey_460: "Remote door open successfully",
  i18nKey_461: "Open the door remotely",
  i18nKey_462: "Source system personnel code input has special characters",
  i18nKey_463: "Source system name has special characters",
  i18nKey_464: "Source data encoding input has special characters",
  i18nKey_465: "Source data encoding",
  i18nKey_466: "Staff",
  i18nKey_467: "Total  square meters of the park greater than 0",
  i18nKey_468: "Total square meters of the park",
  i18nKey_469: "Park owns",
  i18nKey_470: "No personnel thermal data in the park",
  i18nKey_471: "No corresponding device data in the park",
  i18nKey_472: "No cleaning staff data in the park",
  i18nKey_473: "No security personnel data in the park",
  i18nKey_474: "Enterprises in the park",
  i18nKey_475: "Park blacklist",
  i18nKey_476: "Park visitors",
  i18nKey_477: "Park order",
  i18nKey_478: "Enterprise employees in the park",
  i18nKey_479: "Park vehicle color ranking",
  i18nKey_480: "Park vehicle brand ranking",
  i18nKey_481: "Park code",
  i18nKey_482: "Park cleaning",
  i18nKey_483: "Park bus",
  i18nKey_484: "yuan",
  i18nKey_485: "Threshold type",
  i18nKey_486: "Threshold incorrect! Please verify and confirm",
  i18nKey_487: "Threshold",
  i18nKey_488: "Domain account",
  i18nKey_489: "Reservation status",
  i18nKey_490: "Preview",
  i18nKey_491: "Minimum warning value is 0 and the maximum is an integer of 999",
  i18nKey_492: "Warning value status:",
  i18nKey_493: "Expected to leave",
  i18nKey_494: "Estimated time of departure",
  i18nKey_495: "Estimated visit time",
  i18nKey_496: "Prepaid",
  i18nKey_497: "Booking deadline",
  i18nKey_498: "Booking date",
  i18nKey_499: "Booker",
  i18nKey_500: "Booking",
  i18nKey_501: "Predictive value",
  i18nKey_502: "Predicted energy consumption value (kWh)",
  i18nKey_503: "Validity indicator",
  i18nKey_504: "Effective",
  i18nKey_505: "Return from key areas:",
  i18nKey_506: "Preferential package:",
  i18nKey_507: "Preferential enterprise",
  i18nKey_508: "Preferential strategy",
  i18nKey_509: "Optimization suggestions",
  i18nKey_510: "Excellent",
  i18nKey_511: "Water consumption statistics (t)",
  i18nKey_512: "Statistics of water consumption m³ cubic meters",
  i18nKey_513: "Water type",
  i18nKey_514: "Time (minutes)",
  i18nKey_515: "Energy use trend",
  i18nKey_516: "Energy consumption (kWh)",
  i18nKey_517: "Use energy",
  i18nKey_518: "Dosage",
  i18nKey_519: "User information confirmation",
  i18nKey_520: "User/group",
  i18nKey_521: "Electricity warning settings",
  i18nKey_522: "Abnormal percentage of electricity",
  i18nKey_523: "Abnormal power usage",
  i18nKey_524: "Electricity consumption statistics",
  i18nKey_525: "Power consumption statistics (kWh)",
  i18nKey_526: "Power consumption statistics kWh Kilowatt hour",
  i18nKey_527: "Electricity ranking",
  i18nKey_528: "Power consumption (kWh)",
  i18nKey_529: "Type of electricity",
  i18nKey_530: "Electricity profile",
  i18nKey_531: "Electricity load",
  i18nKey_532: "Electricity breakdown",
  i18nKey_533: "Electricity",
  i18nKey_534: "Application system code",
  i18nKey_535: "Application system number can't be null",
  i18nKey_536: "operating system",
  i18nKey_537: "User-oriented success",
  i18nKey_538: "User-oriented",
  i18nKey_539: "Successful application scenario",
  i18nKey_540: "Application scenario",
  i18nKey_541: "business license",
  i18nKey_542: "Business hours can't be less than one hour!",
  i18nKey_543: "Business hours",
  i18nKey_544: "Business start time",
  i18nKey_545: "Business end time",
  i18nKey_546: "Revenue",
  i18nKey_547: "English name",
  i18nKey_548: "Privacy Policy",
  i18nKey_549: "Silver",
  i18nKey_550: "UnionPay payment",
  i18nKey_551: "volume control:",
  i18nKey_552: "Since customer privacy is involved, identity verification is required",
  i18nKey_553: "Epidemic report record",
  i18nKey_554: "Epidemic prevention and control records",
  i18nKey_555: "Total number of exceptions (a)",
  i18nKey_556: "abnormal",
  i18nKey_557: "Search people with pictures",
  i18nKey_558: "Paid",
  i18nKey_559: "Existing record exists, whether to overwrite",
  i18nKey_560: "Already",
  i18nKey_561: "Invited",
  i18nKey_562: "Selected branch",
  i18nKey_563: "Device selected",
  i18nKey_564: "File selected for upload:",
  i18nKey_565: "Selected camera",
  i18nKey_566: "Selected business/tenant",
  i18nKey_567: "Selected building",
  i18nKey_568: "selected",
  i18nKey_569: "completed",
  i18nKey_570: "refunded",
  i18nKey_571: "stopped",
  i18nKey_572: "Route added",
  i18nKey_573: "This table has already been added!",
  i18nKey_574: "Submitted",
  i18nKey_575: "Locked",
  i18nKey_576: "Used",
  i18nKey_577: "Audited",
  i18nKey_578: "Cancelled",
  i18nKey_579: "Activated deployment control can't be deleted",
  i18nKey_580: "Activated",
  i18nKey_581: "The configured network device can't be deleted",
  i18nKey_582: "Already equipped",
  i18nKey_583: "$ Opened",
  i18nKey_584: "Turned on",
  i18nKey_585: "Checked out",
  i18nKey_586: "Over",
  i18nKey_587: "paid",
  i18nKey_588: "Written off",
  i18nKey_589: "Expired",
  i18nKey_590: "No more than three connected cameras",
  i18nKey_591: "0 cameras have been linked",
  i18nKey_592: "Camera linked",
  i18nKey_593: "$ Closed",
  i18nKey_594: "Number of visitors (person)",
  i18nKey_595: "Arrived",
  i18nKey_596: "Existed",
  i18nKey_597: "Processed",
  i18nKey_598: "Undeducted amount of bills issued (yuan)",
  i18nKey_599: "The bill has not been deducted",
  i18nKey_600: "Cumulative bill amount",
  i18nKey_601: "Timed out",
  i18nKey_602: "Move failed",
  i18nKey_603: "Mobile",
  i18nKey_604: "Move successfully",
  i18nKey_605: "The removed device will be restored to the 'device to be allocated' and can be added again if necessary. Do you want to remove the device from this area?",
  i18nKey_606: "Move out",
  i18nKey_607: "Instrument belongs to",
  i18nKey_608: "Instrument brand",
  i18nKey_609: "Instrument name",
  i18nKey_610: "Instrument category",
  i18nKey_611: "The instrument code can't be null",
  i18nKey_612: "Instrument code/number/name",
  i18nKey_613: "Meter number/building unit",
  i18nKey_614: "Meter number",
  i18nKey_615: "One Code",
  i18nKey_616: "One type",
  i18nKey_617: "One key reset",
  i18nKey_618: "One-click deactivation",
  i18nKey_619: "One key to open",
  i18nKey_620: "First-level department",
  i18nKey_621: "First level",
  i18nKey_622: "General warning",
  i18nKey_623: "One",
  i18nKey_624: "Owner",
  i18nKey_625: "Business event",
  i18nKey_626: "Business code",
  i18nKey_627: "The format name can't be null",
  i18nKey_628: "Business name",
  i18nKey_629: "Format",
  i18nKey_630: "Find a way for success, not a reason for failure.",
  i18nKey_631: "Invitation information",
  i18nKey_632: "Invitation time",
  i18nKey_633: "Invite visitors",
  i18nKey_634: "Invite information",
  i18nKey_635: "Invitation time",
  i18nKey_636: "More than 6 options of colors have been displayed optimally for you",
  i18nKey_637: "Serious pollution",
  i18nKey_638: "Critical alarm threshold",
  i18nKey_639: "Cycle Time",
  i18nKey_640: "Cycle",
  i18nKey_641: "Patrol group name",
  i18nKey_642: "Overview of patrol issues",
  i18nKey_643: "Patrol person",
  i18nKey_644: "Patrol name can't be null",
  i18nKey_645: "Patrol name can't exceed 200 characters",
  i18nKey_646: "Patrol route description",
  i18nKey_647: "Patrol route name",
  i18nKey_648: "Patrol route",
  i18nKey_649: "Patrol plan name",
  i18nKey_650: "Patrol schedule",
  i18nKey_651: "Patrol warning",
  i18nKey_652: "Patrol",
  i18nKey_653: "Select fire zone",
  i18nKey_654: "Choose a replacement device",
  i18nKey_655: "Selection period",
  i18nKey_656: "Choose street light",
  i18nKey_657: "Select point type",
  i18nKey_658: "Choose House Type",
  i18nKey_659: "Select management department",
  i18nKey_660: "Select association",
  i18nKey_661: "No traffic data in selected location, please reselect the area and try again",
  i18nKey_662: "Selected date range greater than 190 days, please reduce the selection and try again",
  i18nKey_663: "Select the level at least to the park",
  i18nKey_664: "Select label",
  i18nKey_665: "Entrance",
  i18nKey_666: "The serial number can only consist of Chinese, English and numbers",
  i18nKey_667: "The length of the serial number can't exceed 100 characters",
  i18nKey_668: "serial number",
  i18nKey_669: "Modify value-added services",
  i18nKey_670: "Successfully modify the early warning threshold",
  i18nKey_671: "Successfully revised the patrol plan",
  i18nKey_672: "The price must be disabled first to modify the information!",
  i18nKey_673: "To modify information, you need to stop the station first!",
  i18nKey_674: "Modify limited time discount",
  i18nKey_675: "Modify restricted vehicles",
  i18nKey_676: "Modify device",
  i18nKey_677: "Reading before modification",
  i18nKey_678: "Change the password successfully, please log in again",
  i18nKey_679: "Failed to modify spatial basic data",
  i18nKey_680: "Successfully modify the space basic data",
  i18nKey_681: "Modify the plan",
  i18nKey_682: "Modify the hybrid cloud configuration successfully",
  i18nKey_683: "Modify the data of the hybrid cloud",
  i18nKey_684: "Modify conference room resource maintenance",
  i18nKey_685: "Modify meeting room order",
  i18nKey_686: "Modify meeting room",
  i18nKey_687: "Modify the bus schedule",
  i18nKey_688: "Modify the remark name",
  i18nKey_689: "Modify VCM configuration",
  i18nKey_690: "Rest",
  i18nKey_691: "Sleep brightness",
  i18nKey_692: "Dormant",
  i18nKey_693: "Special characters in the name, please re-enter",
  i18nKey_694: "Name or phone number",
  i18nKey_695: "Name",
  i18nKey_696: "Gender can't be null",
  i18nKey_697: "Gender",
  i18nKey_698: "Model",
  i18nKey_699: "Monday",
  i18nKey_700: "Friday",
  i18nKey_701: "Sunday",
  i18nKey_702: "Thursday",
  i18nKey_703: "Wednesday",
  i18nKey_704: "Saturday",
  i18nKey_705: "Tuesday",
  i18nKey_706: "New subcategory",
  i18nKey_707: "Add sub-category",
  i18nKey_708: "Added value-added services",
  i18nKey_709: "Successfully added an early warning threshold",
  i18nKey_710: "New warning threshold",
  i18nKey_711: "New first-level format",
  i18nKey_712: "Add team members",
  i18nKey_713: "Add fire zone",
  i18nKey_714: "Add location",
  i18nKey_715: "Adding failed, please try again later",
  i18nKey_716: "New equipment",
  i18nKey_717: "New configuration",
  i18nKey_718: "New role",
  i18nKey_719: "Newly added additional facilities menu path: corporate services -> meeting room management -> value-added services!",
  i18nKey_720: "New plan",
  i18nKey_721: "Add conference room resource maintenance",
  i18nKey_722: "Add meeting room order",
  i18nKey_723: "Add meeting room",
  i18nKey_724: "New apartment",
  i18nKey_725: "Add additional facilities",
  i18nKey_726: "New house",
  i18nKey_727: "added successfully",
  i18nKey_728: "New product",
  i18nKey_729: "New policy effective time (up to 4 can be created)",
  i18nKey_730: "New label information",
  i18nKey_731: "New password can't same as the old password",
  i18nKey_732: "New password",
  i18nKey_733: "New customers",
  i18nKey_734: "New customer ratio",
  i18nKey_735: "Successful new patrol plan",
  i18nKey_736: "New restricted vehicles",
  i18nKey_737: "New face tracking",
  i18nKey_738: "New route",
  i18nKey_739: "Failed to create new space basic data",
  i18nKey_740: "The basic data of the new space was successfully created",
  i18nKey_741: "New plan",
  i18nKey_742: "New hybrid cloud configuration is successful",
  i18nKey_743: "Create a new ticket successfully",
  i18nKey_744: "New deployment control",
  i18nKey_745: "New VCM configuration",
  i18nKey_746: "Check result",
  i18nKey_747: "Less than or equal to",
  i18nKey_748: "Less than",
  i18nKey_749: "Hours cancellation, refund",
  i18nKey_750: "Hours to cancel",
  i18nKey_751: "The hour/day can't be less than 4 hours!",
  i18nKey_752: "Hour/day",
  i18nKey_753: "Hour",
  i18nKey_754: "Sales",
  i18nKey_755: "Push notification displayed in Yuncheng app message list",
  i18nKey_756: "Dispel the alarm successfully",
  i18nKey_757: "Water consumption",
  i18nKey_758: "Consumption amount",
  i18nKey_759: "Power consumption",
  i18nKey_760: "General consumption, continuing to pay attention",
  i18nKey_761: "Spending power",
  i18nKey_762: "Consumption Type",
  i18nKey_763: "Consumer purchase",
  i18nKey_764: "Fire fighting system/fire automatic alarm and linkage system/manual alarm button",
  i18nKey_765: "Fire fighting system/fire automatic alarm and linkage system/fire alarm host",
  i18nKey_766: "Fire fighting system/automatic fire alarm and linkage system/smoke fire detector",
  i18nKey_767: "Fire fighting system/fire automatic alarm and linkage system/heat detector",
  i18nKey_768: "Fire Fighting System",
  i18nKey_769: "Fire incident trends",
  i18nKey_770: "Fire district name",
  i18nKey_771: "Fire partition import template",
  i18nKey_772: "Fire zone id",
  i18nKey_773: "Fire Division",
  i18nKey_774: "The project site is located in the underground garages B1 and B2 of Jinqiao Wanchuang Center, equipped with three sets of 250kW/924.9kWh energy storage systems. The outlets are located on the sides of the 400V busbar of the 10kV line A 2# transformer (1000kVA), and the 10kV line B# 5# transformer (1000kVA)  and 6# transformer (2000kVA) ",
  i18nKey_775: "Project name",
  i18nKey_776: "Project Introduction",
  i18nKey_777: "Address",
  i18nKey_778: "Box size (inch)",
  i18nKey_779: "Adjacent areas can't be at the same location as the facility",
  i18nKey_780: "Adjacent area",
  i18nKey_781: "Offline payments",
  i18nKey_782: "Offline payment staff",
  i18nKey_783: "Offline payment",
  i18nKey_784: "The online refund input format is incorrect!",
  i18nKey_785: "Online refund",
  i18nKey_786: "Cancel online",
  i18nKey_787: "limitation factor",
  i18nKey_788: "The limited time discount is on the shelves, please do it after you leave the shelves!",
  i18nKey_789: "Limited time period",
  i18nKey_790: "Restricted vehicles",
  i18nKey_791: "Live picture",
  i18nKey_792: "Live video",
  i18nKey_793: "Display price",
  i18nKey_794: "display",
  i18nKey_795: "Download template (public area)",
  i18nKey_796: "Download template (room)",
  i18nKey_797: "Download template",
  i18nKey_798: "Download form template",
  i18nKey_799: "download",
  i18nKey_800: "Move down",
  i18nKey_801: "Next step",
  i18nKey_802: "Afternoon",
  i18nKey_803: "Decline",
  i18nKey_804: "Off shelf",
  i18nKey_805: "Lower level cumulative",
  i18nKey_806: "Down",
  i18nKey_807: "Recommended operating parameters for the next hour",
  i18nKey_808: "Command issued successfully",
  i18nKey_809: "Issue plan",
  i18nKey_810: "Order time",
  i18nKey_811: "Orderer",
  i18nKey_812: "System automatically rejects",
  i18nKey_813: "System predicts other",
  i18nKey_814: "System predicts permanent personnel",
  i18nKey_815: "System administrator",
  i18nKey_816: "The system code can only be Chinese, letters and underscores within 50 characters",
  i18nKey_817: "System code can't be null",
  i18nKey_818: "System 2",
  i18nKey_819: "System 1",
  i18nKey_820: "System",
  i18nKey_821: "Restroom monitoring strategy configuration",
  i18nKey_822: "Southwest",
  i18nKey_823: "False positive",
  i18nKey_824: "Property staff",
  i18nKey_825: "Property",
  i18nKey_826: "Five categories",
  i18nKey_827: "Five-level department",
  i18nKey_828: "Level 5",
  i18nKey_829: "5",
  i18nKey_830: "No show",
  i18nKey_831: "No record",
  i18nKey_832: "Unrelated event",
  i18nKey_833: "Unlinked camera",
  i18nKey_834: "No data that meets the query conditions",
  i18nKey_835: "Unable to get in-line data",
  i18nKey_836: "No such superior device",
  i18nKey_837: "No",
  i18nKey_838: "File only supports excel",
  i18nKey_839: "File size can't exceed 20M",
  i18nKey_840: "temperature",
  i18nKey_841: "Location full path",
  i18nKey_842: "Location ID",
  i18nKey_843: "Position",
  i18nKey_844: "Unknown",
  i18nKey_845: "No pusher is set, please add pusher!",
  i18nKey_846: "not initiated",
  i18nKey_847: "Not equipped",
  i18nKey_848: "Not entered",
  i18nKey_849: "Not separated",
  i18nKey_850: "has not started",
  i18nKey_851: "Not written off",
  i18nKey_852: "Undeducted amount",
  i18nKey_853: "Non-visitors (persons)",
  i18nKey_854: "No violations have occurred",
  i18nKey_855: "Total number not copied (a)",
  i18nKey_856: "The integer part of the latitude is 0-90",
  i18nKey_857: "latitude",
  i18nKey_858: "Repair content",
  i18nKey_859: "Repair result",
  i18nKey_860: "Maintenance person",
  i18nKey_861: "Maintain",
  i18nKey_862: "Maintenance status",
  i18nKey_863: "Maintenance business can only be composed of Chinese and English, numbers and _",
  i18nKey_864: "The length of the maintenance provider can't exceed 50 characters",
  i18nKey_865: "Maintainer",
  i18nKey_866: "Evidence of violation",
  i18nKey_867: "Reason for violation",
  i18nKey_868: "Violation details",
  i18nKey_869: "Stop time",
  i18nKey_870: "Parking place",
  i18nKey_871: "Number of violations",
  i18nKey_872: "Slightly bright",
  i18nKey_873: "Forgot password",
  i18nKey_874: "The network load is abnormal, please try again later",
  i18nKey_875: "Network loading abnormal, please try again later",
  i18nKey_876: "Gateway name",
  i18nKey_877: "The gateway is not online, and the plan delivery failed",
  i18nKey_878: "Vanke Park",
  i18nKey_879: "Vanke Xicheng District Park",
  i18nKey_880: "Vanke Haiding District Park",
  i18nKey_881: "Vanke Dongcheng District",
  i18nKey_882: "Vanke Chaoyang District Park",
  i18nKey_883: "Vanke Binhai New Area Park",
  i18nKey_884: "Van",
  i18nKey_885: "Late bus",
  i18nKey_886: "External location name",
  i18nKey_887: "External position code",
  i18nKey_888: "External company",
  i18nKey_889: "Refund status",
  i18nKey_890: "Refund requested",
  i18nKey_891: "Refund failed",
  i18nKey_892: "Refund processing",
  i18nKey_893: "Refund processing time",
  i18nKey_894: "Refund successful time",
  i18nKey_895: "Refund successfully",
  i18nKey_896: "Return",
  i18nKey_897: "Unsubscribe",
  i18nKey_898: "Recommend Signature",
  i18nKey_899: "Unity and cooperation, go all out.",
  i18nKey_900: "The way point can't be the same as the end point",
  i18nKey_901: "The way point can't be the same as the starting point",
  i18nKey_902: "Way",
  i18nKey_903: "The picture size can't exceed 3M!",
  i18nKey_904: "Picture size can't exceed 3M",
  i18nKey_905: "Picture View",
  i18nKey_906: "Picture cropping",
  i18nKey_907: "image",
  i18nKey_908: "Chart, report",
  i18nKey_909: "Chart",
  i18nKey_910: "Unified setting quota",
  i18nKey_911: "Statistical period (days)",
  i18nKey_912: "Statistics in detail",
  i18nKey_913: "Chart name",
  i18nKey_914: "Statistical days can only be positive integers",
  i18nKey_915: "Statistics Time:",
  i18nKey_916: "Statistics card configuration",
  i18nKey_917: "Statistics from the beginning to yesterday, the recharge amount is subject to the arrival of the account",
  i18nKey_918: "agree",
  i18nKey_919: "Only one cancellation rule can be set on the same day!",
  i18nKey_920: "Can't select more than 4 cameras at the same time",
  i18nKey_921: "Same period (kWh)",
  i18nKey_922: "Companion information settings",
  i18nKey_923: "Number of companions",
  i18nKey_924: "Year-on-year",
  i18nKey_925: "Year-on-year time",
  i18nKey_926: "YoY",
  i18nKey_927: "Access floor",
  i18nKey_928: "Pass",
  i18nKey_929: "Stop",
  i18nKey_930: "Deactivate",
  i18nKey_931: "The length of stay can only be a positive integer",
  i18nKey_932: "Length of stay (hours)",
  i18nKey_933: "Parking floor",
  i18nKey_934: "Docked",
  i18nKey_935: "Parking time",
  i18nKey_936: "Parking time",
  i18nKey_937: "Parking fee (within 24 hours)",
  i18nKey_938: "Parking lot charging mode",
  i18nKey_939: "Parking lot name",
  i18nKey_940: "Parking lot address",
  i18nKey_941: "Parking lot can't be null",
  i18nKey_942: "Parking strategy configuration",
  i18nKey_943: "Debugging equipment",
  i18nKey_944: "Add subclass",
  i18nKey_945: "Adding news pushers can only be used by registered users of the Yuncheng app.",
  i18nKey_946: "Add offline payment staff",
  i18nKey_947: "Add a limited time discount",
  i18nKey_948: "Add reminder",
  i18nKey_949: "Add package",
  i18nKey_950: "Add applicable enterprise",
  i18nKey_951: "Add face",
  i18nKey_952: "Add lighting rules",
  i18nKey_953: "Add company",
  i18nKey_954: "Add ride route",
  i18nKey_955: "Add a ride",
  i18nKey_956: "Day push event",
  i18nKey_957: "Days ago",
  i18nKey_958: "Day",
  i18nKey_959: "Replace the earliest",
  i18nKey_960: "Replace selected",
  i18nKey_961: "Replacement relationship",
  i18nKey_962: "Replace basemap",
  i18nKey_963: "body temperature",
  i18nKey_964: "Reminder",
  i18nKey_965: "Remind",
  i18nKey_966: "Prompt threshold",
  i18nKey_967: "Prompt text",
  i18nKey_968: "Prompt warning",
  i18nKey_969: "Tip: Only support jpg, png format picture upload, upload picture size doesn't exceed 3M",
  i18nKey_970: "Reminder: Due to customer privacy, you need to enter the password for second confirmation",
  i18nKey_971: "Tip: The end time must be greater than the start time",
  i18nKey_972: "Tips: The video picture delay is about 5~10 seconds, please wait patiently",
  i18nKey_973: "booked in advance",
  i18nKey_974: "The number of days in advance is limited to within 1-30 days",
  i18nKey_975: "Special",
  i18nKey_976: "Number of packages",
  i18nKey_977: "Package usage",
  i18nKey_978: "The package is being activated, please pause and operate!",
  i18nKey_979: "Package name",
  i18nKey_980: "Package Type",
  i18nKey_981: "Package card users",
  i18nKey_982: "Package card count",
  i18nKey_983: "Package card processing",
  i18nKey_984: "Package card",
  i18nKey_985: "Package payment amount (yuan)",
  i18nKey_986: "Package payment",
  i18nKey_987: "Locking",
  i18nKey_988: "Ownership (owner)",
  i18nKey_989: "All companies",
  i18nKey_990: "The selected time period can't be null",
  i18nKey_991: "The selected time period can't exceed two days",
  i18nKey_992: "The selected year must be between the start date and the end date",
  i18nKey_993: "Owned Park",
  i18nKey_994: "Affiliated company",
  i18nKey_995: "You can't choose your own directory",
  i18nKey_996: "Owning directory can't be null",
  i18nKey_997: "Owned Directory",
  i18nKey_998: "Belonging to",
  i18nKey_999: "Your company has entered special characters, please re-enter",
  i18nKey_1000: "Special characters entered by the company",
  i18nKey_1001: "Owning company can't be null",
  i18nKey_1002: "Company",
  i18nKey_1003: "Sub-item",
  i18nKey_1004: "Affiliation",
  i18nKey_1005: "Department",
  i18nKey_1006: "search history",
  i18nKey_1007: "Four categories",
  i18nKey_1008: "Four-level department",
  i18nKey_1009: "Level 4",
  i18nKey_1010: "4",
  i18nKey_1011: "Private Cloud",
  i18nKey_1012: "private",
  i18nKey_1013: "Note: The added approver will serve as the approver of property management, and up to 5 approvers can be added",
  i18nKey_1014: "Note: The energy consumption ratio of this level to the upper level exceeds >10%, which is abnormal. The total energy consumption of this level is the low-voltage incoming cabinet P11, P21, P31, P41;",
  i18nKey_1015: "Cumulative amount of billed water bill",
  i18nKey_1016: "Merchants who owe water charges",
  i18nKey_1017: "Undeducted amount of water recharge",
  i18nKey_1018: "Accumulated water recharge amount",
  i18nKey_1019: "Overview of utility bills",
  i18nKey_1020: "Hydropower details",
  i18nKey_1021: "Hydropower detailed list",
  i18nKey_1022: "Utility bill payment order",
  i18nKey_1023: "Water and electricity meter details data query",
  i18nKey_1024: "Water meter warning threshold:",
  i18nKey_1025: "Water meter serial number",
  i18nKey_1026: "Water meter number",
  i18nKey_1027: "Refresh",
  i18nKey_1028: "Brush cartoon line",
  i18nKey_1029: "Value is 0-60",
  i18nKey_1030: "Value is 0-120",
  i18nKey_1031: "Data resource",
  i18nKey_1032: "data source",
  i18nKey_1033: "Data anomaly monitoring details",
  i18nKey_1034: "Data abnormal (a)",
  i18nKey_1035: "Abnormal data",
  i18nKey_1036: "The data was not loaded successfully, please try again",
  i18nKey_1037: "Data time",
  i18nKey_1038: "The data can't be retrieved after deletion, confirm the deletion?",
  i18nKey_1039: "The data can't be restored after deletion, confirm the deletion?",
  i18nKey_1040: "Data Sources",
  i18nKey_1041: "Data summary_",
  i18nKey_1042: "Data loss (a)",
  i18nKey_1043: "The input length is not allowed to exceed 50 characters",
  i18nKey_1044: "The input length is not allowed to exceed 30 characters (letters,-and numbers are allowed)",
  i18nKey_1045: "The input length is not allowed to exceed 30 characters (Chinese characters, letters,-and numbers are allowed)",
  i18nKey_1046: "The input length is not allowed to exceed 2000 characters",
  i18nKey_1047: "The input length is not allowed to exceed 100 characters",
  i18nKey_1048: "Enter event title or device name",
  i18nKey_1049: "Enter camera alias",
  i18nKey_1050: "Enter keywords to filter",
  i18nKey_1051: "Enter the station number and press Enter",
  i18nKey_1052: "The number entered can't exceed the maximum value of 30",
  i18nKey_1053: "The number entered can't exceed the maximum value of 24",
  i18nKey_1054: "The number entered can't exceed business hours",
  i18nKey_1055: "Comfort",
  i18nKey_1056: "study",
  i18nKey_1057: "Authorized issuance",
  i18nKey_1058: "Authorization succeeded",
  i18nKey_1059: "Authorization",
  i18nKey_1060: "Interviewee's phone number:",
  i18nKey_1061: "Respondents:",
  i18nKey_1062: "Interviewed company information",
  i18nKey_1063: "The interviewed company can't be null",
  i18nKey_1064: "Interviewed company:",
  i18nKey_1065: "Interviewed company",
  i18nKey_1066: "The interviewed address can't be null",
  i18nKey_1067: "First entry time",
  i18nKey_1068: "Handling fee",
  i18nKey_1069: "Mobile phone number needs to be registered in the Yuncheng app",
  i18nKey_1070: "phone number",
  i18nKey_1071: "Enter data manually",
  i18nKey_1072: "Manual meter reading details",
  i18nKey_1073: "Create manually",
  i18nKey_1074: "Manual trigger",
  i18nKey_1075: "Manual editing",
  i18nKey_1076: "Manual:",
  i18nKey_1077: "Put away",
  i18nKey_1078: "Collection account name",
  i18nKey_1079: "Collection account",
  i18nKey_1080: "Collect phone number",
  i18nKey_1081: "Charge notice",
  i18nKey_1082: "There can be no duplicate options in paid facilities and free facilities!",
  i18nKey_1083: "Toll facilities",
  i18nKey_1084: "Charge amount",
  i18nKey_1085: "Paraphrase",
  i18nKey_1086: "Room temperature",
  i18nKey_1087: "Indoor humidity",
  i18nKey_1088: "Indoor",
  i18nKey_1089: "Applicable enterprise",
  i18nKey_1090: "Is it abnormal",
  i18nKey_1091: "Whether to loop",
  i18nKey_1092: "Whether to modify",
  i18nKey_1093: "Whether to complete the whole process",
  i18nKey_1094: "Whether to delete data",
  i18nKey_1095: "Whether to delete the checked data",
  i18nKey_1096: "Are you sure you want to download the chart image?",
  i18nKey_1097: "Are you sure to download the excel report?",
  i18nKey_1098: "Are you sure to delete the conference room and price information? It can't be restored after deletion!",
  i18nKey_1099: "Confirm to delete the station? It can't be restored after deletion!",
  i18nKey_1100: "Whether to enable monthly card limit",
  i18nKey_1101: "Whether to enable reminder policy",
  i18nKey_1102: "Whether to enable parking lot restrictions",
  i18nKey_1103: "Whether to enable",
  i18nKey_1104: "Have you left",
  i18nKey_1105: "Whether to make up",
  i18nKey_1106: "Video patrol statistics",
  i18nKey_1107: "Video patrol",
  i18nKey_1108: "The video was successfully intercepted, please go to the associated video in the event details on the left to view",
  i18nKey_1109: "Video capture",
  i18nKey_1110: "Video playback",
  i18nKey_1111: "The video picture delay is about 5-10 seconds, please be patient",
  i18nKey_1112: "Video assist",
  i18nKey_1113: "Video punch rate",
  i18nKey_1114: "Video",
  i18nKey_1115: "Event status statistics",
  i18nKey_1116: "Event status",
  i18nKey_1117: "Event reported successfully",
  i18nKey_1118: "Incident report",
  i18nKey_1119: "Event description can't exceed 1000 characters",
  i18nKey_1120: "Event description",
  i18nKey_1121: "Event type",
  i18nKey_1122: "Event level",
  i18nKey_1123: "Event level",
  i18nKey_1124: "Event processing time",
  i18nKey_1125: "Event handling",
  i18nKey_1126: "Event timeout processing configuration",
  i18nKey_1127: "Event title",
  i18nKey_1128: "Event number",
  i18nKey_1129: "Since October 1, 2019",
  i18nKey_1130: "Since October 01, 2019",
  i18nKey_1131: "status of use",
  i18nKey_1132: "Use domain account verification",
  i18nKey_1133: "Use mobile phone number verification",
  i18nKey_1134: "Use time",
  i18nKey_1135: "Utilization rate (%)",
  i18nKey_1136: "Usage rate",
  i18nKey_1137: "Use address:",
  i18nKey_1138: "Real-time video __",
  i18nKey_1139: "Real-time Air Quality Index",
  i18nKey_1140: "Real-time power (kWh)",
  i18nKey_1141: "Real-time meter reading",
  i18nKey_1142: "Actual value",
  i18nKey_1143: "The amount actually paid",
  i18nKey_1144: "The duration should not be less than 30 seconds",
  i18nKey_1145: "don't exceed one hour",
  i18nKey_1146: "Set at least one time",
  i18nKey_1147: "time setting",
  i18nKey_1148: "The time interval can't exceed 6 months",
  i18nKey_1149: "time interval",
  i18nKey_1150: "time limit",
  i18nKey_1151: "period",
  i18nKey_1152: "Point in time",
  i18nKey_1153: "Time can't be null",
  i18nKey_1154: "Time must be a non-zero positive integer",
  i18nKey_1155: "Time passenger flow statistics",
  i18nKey_1156: "Time",
  i18nKey_1157: "List of dishonest",
  i18nKey_1158: "Expiration time",
  i18nKey_1159: "Failure",
  i18nKey_1160: "Total remaining issuable",
  i18nKey_1161: "The remaining amount is less than the threshold",
  i18nKey_1162: "balance",
  i18nKey_1163: "Remaining degree",
  i18nKey_1164: "Remaining parking spaces",
  i18nKey_1165: "Remaining parking spaces",
  i18nKey_1166: "Province Abbreviation",
  i18nKey_1167: "Effective time",
  i18nKey_1168: "Effective date: May 14, 2021",
  i18nKey_1169: "Effective history",
  i18nKey_1170: "Generate QR code",
  i18nKey_1171: "Generate security alarms",
  i18nKey_1172: "Lift type",
  i18nKey_1173: "Shenzhen",
  i18nKey_1174: "Dark blue",
  i18nKey_1175: "Dark gray",
  i18nKey_1176: "ID number and passport number must be filled in",
  i18nKey_1177: "ID number",
  i18nKey_1178: "ID card:",
  i18nKey_1179: "Authentication",
  i18nKey_1180: "application status",
  i18nKey_1181: "Refund time",
  i18nKey_1182: "Applicant's company",
  i18nKey_1183: "Apply for cancellation",
  i18nKey_1184: "Apply for freight elevator",
  i18nKey_1185: "Camera monitoring",
  i18nKey_1186: "The camera is loading, please wait...",
  i18nKey_1187: "Number of cameras",
  i18nKey_1188: "Camera (a)",
  i18nKey_1189: "Camera",
  i18nKey_1190: "Camera number",
  i18nKey_1191: "Video camera",
  i18nKey_1192: "Set the warning value (days):",
  i18nKey_1193: "Set warning value",
  i18nKey_1194: "Setting business hours can effectively exclude passenger flow information during closing hours",
  i18nKey_1195: "Set the message pusher:",
  i18nKey_1196: "Set as main floor",
  i18nKey_1197: "Set successfully",
  i18nKey_1198: "Total number of facilities",
  i18nKey_1199: "Facilities sub-categories",
  i18nKey_1200: "Facility asset number",
  i18nKey_1201: "Facility status",
  i18nKey_1202: "Facility operating data",
  i18nKey_1203: "Facility validity period",
  i18nKey_1204: "Facility location",
  i18nKey_1205: "Facility topology",
  i18nKey_1206: "Facility statistics",
  i18nKey_1207: "Statistics of the number of facilities",
  i18nKey_1208: "Facility data preview",
  i18nKey_1209: "Facility data synchronization",
  i18nKey_1210: "Facility data export",
  i18nKey_1211: "Facility event statistics",
  i18nKey_1212: "Facility event alarm statistics (by type)",
  i18nKey_1213: "Facility event alarm statistics (by level)",
  i18nKey_1214: "Statistics of facility event alarm handling rate",
  i18nKey_1215: "Facility event alert",
  i18nKey_1216: "Facility event",
  i18nKey_1217: "Facilities schematic data",
  i18nKey_1218: "Facilities diagram",
  i18nKey_1219: "The length of the facility name can't exceed 50 characters",
  i18nKey_1220: "The facility name can't be null",
  i18nKey_1221: "Facility name",
  i18nKey_1222: "The facility description can only consist of Chinese and English, numbers and _",
  i18nKey_1223: "The length of the facility description can't exceed 200 characters",
  i18nKey_1224: "Facility description",
  i18nKey_1225: "Statistics of facility availability",
  i18nKey_1226: "Facility availability",
  i18nKey_1227: "Infrastructure basic data information",
  i18nKey_1228: "Facility basic data coding",
  i18nKey_1229: "Facilities image status",
  i18nKey_1230: "Facility image data",
  i18nKey_1231: "When the facility image is activated, the name can't be null",
  i18nKey_1232: "Facility image name",
  i18nKey_1233: "Facilities portrait",
  i18nKey_1234: "Number of facility failures",
  i18nKey_1235: "Facility Work Order",
  i18nKey_1236: "Facility alarm statistics",
  i18nKey_1237: "Facility alias",
  i18nKey_1238: "Overview of facility repair",
  i18nKey_1239: "Facilities repair",
  i18nKey_1240: "Design capacity",
  i18nKey_1241: "Equipment energy ranking",
  i18nKey_1242: "Equipment abnormality monitoring details",
  i18nKey_1243: "Equipment abnormal alarm-fresh fan failure!",
  i18nKey_1244: "Equipment abnormal",
  i18nKey_1245: "Device Information",
  i18nKey_1246: "Device pass record",
  i18nKey_1247: "Device data import template",
  i18nKey_1248: "Number of devices",
  i18nKey_1249: "Equipment sorting",
  i18nKey_1250: "Equipment name",
  i18nKey_1251: "Equipment health",
  i18nKey_1252: "Device alias",
  i18nKey_1253: "Equipment remark name",
  i18nKey_1254: "Device IP",
  i18nKey_1255: "Device ID",
  i18nKey_1256: "Device",
  i18nKey_1257: "Actual electricity consumption last week (kWh)",
  i18nKey_1258: "Last week",
  i18nKey_1259: "Energy used last month",
  i18nKey_1260: "Same period last month",
  i18nKey_1261: "Actual electricity consumption last month (KWh)",
  i18nKey_1262: "Last month average",
  i18nKey_1263: "Energy consumption per square meter last month",
  i18nKey_1264: "Cumulative energy consumption last month",
  i18nKey_1265: "last month",
  i18nKey_1266: "Move up",
  i18nKey_1267: "Energy used in the previous year",
  i18nKey_1268: "Electricity consumption in the same period of the previous year",
  i18nKey_1269: "The same period last year",
  i18nKey_1270: "Energy consumption per square meter last year",
  i18nKey_1271: "last year",
  i18nKey_1272: "Drop off",
  i18nKey_1273: "Morning",
  i18nKey_1274: "Rise",
  i18nKey_1275: "Put on the shelf",
  i18nKey_1276: "Up",
  i18nKey_1277: "Shanghai Jinqiao Wanchuang Center System 2",
  i18nKey_1278: "Shanghai Jinqiao Wanchuang Center System 1",
  i18nKey_1279: "Last position",
  i18nKey_1280: "Last reported time",
  i18nKey_1281: "Last meter reading date",
  i18nKey_1282: "Last meter reading",
  i18nKey_1283: "File upload failed",
  i18nKey_1284: "The uploaded image format is incorrect, please upload again",
  i18nKey_1285: "Upload picture can't be larger than 3M, please re-upload",
  i18nKey_1286: "upload image",
  i18nKey_1287: "Upload diagram",
  i18nKey_1288: "Upload failed, please upload again",
  i18nKey_1289: "upload failed",
  i18nKey_1290: "Uploader",
  i18nKey_1291: "Upload image",
  i18nKey_1292: "Upload housing information form",
  i18nKey_1293: "The name of the uploaded picture is not standardized, only numbers, letters, underscores, and horizontal lines are allowed",
  i18nKey_1294: "Reporting time",
  i18nKey_1295: "Reporter phone",
  i18nKey_1296: "Reporter",
  i18nKey_1297: "Energy consumption analysis of commercial tenants",
  i18nKey_1298: "Business points",
  i18nKey_1299: "Business",
  i18nKey_1300: "Store status",
  i18nKey_1301: "Shop location",
  i18nKey_1302: "Shop number",
  i18nKey_1303: "Product description",
  i18nKey_1304: "Business Name:",
  i18nKey_1305: "Mall traffic",
  i18nKey_1306: "Merchant/tenant selection",
  i18nKey_1307: "Business/Tenant Name",
  i18nKey_1308: "Business/tenant ranking of energy consumption per square meter",
  i18nKey_1309: "The price must be disabled first to delete the information!",
  i18nKey_1310: "To delete information, you need to stop the station first!",
  i18nKey_1311: "All device detailes delete after deletion, confirm to delete?",
  i18nKey_1312: "Node content delete after deletion. Suggest to move first and delete. Confirm to delete?",
  i18nKey_1313: "Can't be restored after deletion, are you sure to delete?",
  i18nKey_1314: "Failed to delete association",
  i18nKey_1315: "After deleting this warning threshold, the default threshold is set to 0. Confirm to delete it?",
  i18nKey_1316: "Deelete successfullyl, all shops under the node delete from the lease list",
  i18nKey_1317: "Delete",
  i18nKey_1318: "Scan to download Yuncheng APP",
  i18nKey_1319: "Scan QR code",
  i18nKey_1320: "Scan code equipment",
  i18nKey_1321: "Scan code login",
  i18nKey_1322: "Ternary lithium",
  i18nKey_1323: "Three categories",
  i18nKey_1324: "Tertiary department",
  i18nKey_1325: "Level 3",
  i18nKey_1326: "three",
  i18nKey_1327: "If there is no ID number, the passport number is required",
  i18nKey_1328: "Type of admission",
  i18nKey_1329: "If there are multiple stops, please enter in the following format: Vanke Yuncheng-Bao'an Center-Qianhai Bay-Bao'an Airport",
  i18nKey_1330: "Capacity",
  i18nKey_1331: "YoY",
  i18nKey_1332: "Day",
  i18nKey_1333: "There is a long way to go, and the ambition must be won.",
  i18nKey_1334: "Task status statistics",
  i18nKey_1335: "Task status",
  i18nKey_1336: "The task is being tracked. Jumping to the page will cause the tracking task to stop. Are you sure you need to jump to the page?",
  i18nKey_1337: "Task owner",
  i18nKey_1338: "Task delegator",
  i18nKey_1339: "Task number",
  i18nKey_1340: "Task starting, please don't delete",
  i18nKey_1341: "Task name can't be null",
  i18nKey_1342: "mission name",
  i18nKey_1343: "Task number",
  i18nKey_1344: "Certified company",
  i18nKey_1345: "Personnel overview (day)",
  i18nKey_1346: "Personnel tracking",
  i18nKey_1347: "Statistics on the proportion of personnel (on the day)",
  i18nKey_1348: "Personnel English name input with special characters",
  i18nKey_1349: "Personnel moved to",
  i18nKey_1350: "Person’s name entered with special characters",
  i18nKey_1351: "Personnel name can't be null",
  i18nKey_1352: "Person's name",
  i18nKey_1353: "Personnel information",
  i18nKey_1354: "Staff picture",
  i18nKey_1355: "Personnel statistics (by park)",
  i18nKey_1356: "Personnel statistics (by type)",
  i18nKey_1357: "People Statistics",
  i18nKey_1358: "Personnel ranking (yesterday/previous day)",
  i18nKey_1359: "Personnel mobile phone number can't be null",
  i18nKey_1360: "Staff heat",
  i18nKey_1361: "Person Type",
  i18nKey_1362: "Personnel pool selection",
  i18nKey_1363: "Personnel basic data information",
  i18nKey_1364: "Personnel basic data created successfully",
  i18nKey_1365: "Personnel basic data coding",
  i18nKey_1366: "There are special characters in the personnel basic data number input, please re-enter",
  i18nKey_1367: "Personnel basic data number",
  i18nKey_1368: "People track",
  i18nKey_1369: "Personnel management",
  i18nKey_1370: "Personnel comparison trend (near two weeks)",
  i18nKey_1371: "Person tag",
  i18nKey_1372: "Person ID",
  i18nKey_1373: "Personnel (last 12 weeks)",
  i18nKey_1374: "Personnel (last 12 months)",
  i18nKey_1375: "Overview of people",
  i18nKey_1376: "People flow statistics",
  i18nKey_1377: "Face tracking failed to start",
  i18nKey_1378: "Face tracking",
  i18nKey_1379: "Face pass",
  i18nKey_1380: "Face ID",
  i18nKey_1381: "Human face",
  i18nKey_1382: "Person times",
  i18nKey_1383: "People",
  i18nKey_1384: "Total passenger flow ranking in popular areas",
  i18nKey_1385: "Heat spread range",
  i18nKey_1386: "Hot spot",
  i18nKey_1387: "Confirm the changes",
  i18nKey_1388: "Confirm the new password",
  i18nKey_1389: "Confirm to add the user",
  i18nKey_1390: "Confirm to give up?",
  i18nKey_1391: "Confirm that this alarm is a misjudgment, this alarm information is invalid",
  i18nKey_1392: "Are you sure to reset this house",
  i18nKey_1393: "Are you sure about one-click reset",
  i18nKey_1394: "Are you sure you want to set the main floor?",
  i18nKey_1395: "Are you sure you want to delete this address?",
  i18nKey_1396: "Are you sure you want to open all $ circuits",
  i18nKey_1397: "Are you sure you want to close all $ circuits?",
  i18nKey_1398: "Are you sure you want to open this $ loop?",
  i18nKey_1399: "Are you sure you want to close these $ loops?",
  i18nKey_1400: "Are you sure to delete the device?",
  i18nKey_1401: "Sure to delete this user",
  i18nKey_1402: "Are you sure to delete the room?",
  i18nKey_1403: "Are you sure to delete these devices in bulk?",
  i18nKey_1404: "Are you sure to delete these apartment types in bulk?",
  i18nKey_1405: "Are you sure to remove the blacklist?",
  i18nKey_1406: "Determine that this alarm is a false positive",
  i18nKey_1407: "All day (24 hours)",
  i18nKey_1408: "Full screen playback",
  i18nKey_1409: "Full control switch",
  i18nKey_1410: "Open all",
  i18nKey_1411: "All meeting rooms",
  i18nKey_1412: "Close all",
  i18nKey_1413: "The responsibility month can't be null",
  i18nKey_1414: "Power month",
  i18nKey_1415: "Permission resources",
  i18nKey_1416: "same time last year",
  i18nKey_1417: "The cancellation refund limit can't exceed 100%!",
  i18nKey_1418: "No more than 10 cancellation rules!",
  i18nKey_1419: "Regional heat",
  i18nKey_1420: "Area name",
  i18nKey_1421: "Regional energy consumption per square meter ranking",
  i18nKey_1422: "Area type can't be null",
  i18nKey_1423: "Area type",
  i18nKey_1424: "Regional passenger flow",
  i18nKey_1425: "Regional visit ranking",
  i18nKey_1426: "area",
  i18nKey_1427: "Block",
  i18nKey_1428: "Please select at least one camera",
  i18nKey_1429: "Please select at least one person",
  i18nKey_1430: "Please add at least one person to save",
  i18nKey_1431: "Please enter the new password again",
  i18nKey_1432: "Please enter the password again",
  i18nKey_1433: "Please select the lease date range",
  i18nKey_1434: "Please select asset type",
  i18nKey_1435: "Please select a theme",
  i18nKey_1436: "Please select an important level",
  i18nKey_1437: "Please select repeat method",
  i18nKey_1438: "Please select lighting mode",
  i18nKey_1439: "Please choose the responsible manager",
  i18nKey_1440: "Please select the time of suspension",
  i18nKey_1441: "Please select the allowed visit period",
  i18nKey_1442: "Please select the booking start time",
  i18nKey_1443: "Please select the end time of the booking",
  i18nKey_1444: "Please choose preferential strategy",
  i18nKey_1445: "Please select an application system",
  i18nKey_1446: "Please select the business start time",
  i18nKey_1447: "Please select the closing time",
  i18nKey_1448: "Please select the exception type",
  i18nKey_1449: "Please select the instrument belongs to",
  i18nKey_1450: "Please select the instrument brand",
  i18nKey_1451: "Please select a category",
  i18nKey_1452: "Please select a department",
  i18nKey_1453: "Please select a format",
  i18nKey_1454: "Please select the vehicle to delete",
  i18nKey_1455: "Please select the facilities to be synchronized",
  i18nKey_1456: "Please select the file to upload",
  i18nKey_1457: "Please select the first picture of the meeting room to upload!",
  i18nKey_1458: "Please select the first image of the workstation to be uploaded!",
  i18nKey_1459: "Please select the banner picture to upload!",
  i18nKey_1460: "Please select the strategy to delete",
  i18nKey_1461: "Please select sleep brightness",
  i18nKey_1462: "Please select the order time",
  i18nKey_1463: "Please select a five-level classification",
  i18nKey_1464: "Please select a five-level department",
  i18nKey_1465: "Please choose a location",
  i18nKey_1466: "Please select the stop time",
  i18nKey_1467: "Please choose a way",
  i18nKey_1468: "Please select a parking lot",
  i18nKey_1469: "Please select package type",
  i18nKey_1470: "Please select four levels",
  i18nKey_1471: "Please select a four-level department",
  i18nKey_1472: "Please select to enter the actual payment amount",
  i18nKey_1473: "Please choose to enter the order amount",
  i18nKey_1474: "Please select the authorization start and end time",
  i18nKey_1475: "Please select whether it is a debugging device",
  i18nKey_1476: "Please choose whether to list",
  i18nKey_1477: "Please select event status",
  i18nKey_1478: "Please select event type",
  i18nKey_1479: "Please select event level",
  i18nKey_1480: "Please select event level",
  i18nKey_1481: "Please select time period",
  i18nKey_1482: "Please choose time",
  i18nKey_1483: "Please select the lift type",
  i18nKey_1484: "Please select application status",
  i18nKey_1485: "Please select camera",
  i18nKey_1486: "Please select facility status",
  i18nKey_1487: "Please select facility location",
  i18nKey_1488: "Please select facility image status",
  i18nKey_1489: "Please select the name of the facility image",
  i18nKey_1490: "Please select equipment",
  i18nKey_1491: "Please select store status",
  i18nKey_1492: "Please select shop location",
  i18nKey_1493: "Please select a merchant",
  i18nKey_1494: "Please select to delete data",
  i18nKey_1495: "Please select filter criteria",
  i18nKey_1496: "Please select scan code device",
  i18nKey_1497: "Please select a three-level classification",
  i18nKey_1498: "Please select a three-level department",
  i18nKey_1499: "Please select a date range",
  i18nKey_1500: "Please select task status",
  i18nKey_1501: "Please select the type of personnel",
  i18nKey_1502: "Please select personnel",
  i18nKey_1503: "Please select the responsibility month",
  i18nKey_1504: "Please select area type",
  i18nKey_1505: "Please select a region",
  i18nKey_1506: "Please select the type of arrears merchant",
  i18nKey_1507: "Please select the enabled state",
  i18nKey_1508: "Please select a brand",
  i18nKey_1509: "Please select the dispatch time",
  i18nKey_1510: "Please select age range",
  i18nKey_1511: "Please select the year",
  i18nKey_1512: "Please select the lock number",
  i18nKey_1513: "Please choose a route",
  i18nKey_1514: "Please select a building",
  i18nKey_1515: "Please select a building unit",
  i18nKey_1516: "Please select building",
  i18nKey_1517: "Please select floor space",
  i18nKey_1518: "Please select floor",
  i18nKey_1519: "Please select a six-level classification",
  i18nKey_1520: "Please choose the type",
  i18nKey_1521: "Please select the source system code",
  i18nKey_1522: "Please select start time",
  i18nKey_1523: "Please select a start date",
  i18nKey_1524: "Please select the parking time",
  i18nKey_1525: "Please select an entry time",
  i18nKey_1526: "Please select the end time",
  i18nKey_1527: "Please select the type of role",
  i18nKey_1528: "Please select the role type",
  i18nKey_1529: "Please select the billing type",
  i18nKey_1530: "Please select level",
  i18nKey_1531: "Please choose freight elevator",
  i18nKey_1532: "Please select activity status",
  i18nKey_1533: "Please select a circuit!",
  i18nKey_1534: "Please select the consequences",
  i18nKey_1535: "Please select contract",
  i18nKey_1536: "Please select a country",
  i18nKey_1537: "Please select the associated face library",
  i18nKey_1538: "Please select the fault level",
  i18nKey_1539: "Please select public area type",
  i18nKey_1540: "Please select station status",
  i18nKey_1541: "Please select the type of workstation!",
  i18nKey_1542: "Please select ticket status",
  i18nKey_1543: "Please select sensing brightness",
  i18nKey_1544: "Please select the type of the house",
  i18nKey_1545: "Please select a payment method",
  i18nKey_1546: "Please select a subdivision",
  i18nKey_1547: "Please select installment",
  i18nKey_1548: "Please select visitor status",
  i18nKey_1549: "Please select room location",
  i18nKey_1550: "Please select the time range for the invitation",
  i18nKey_1551: "Please select issue type",
  i18nKey_1552: "Please select a secondary classification",
  i18nKey_1553: "Please select a secondary department",
  i18nKey_1554: "Please select order status",
  i18nKey_1555: "Please select the point type",
  i18nKey_1556: "Please select a location",
  i18nKey_1557: "Please choose to the park",
  i18nKey_1558: "Please select the region",
  i18nKey_1559: "Please select the city",
  i18nKey_1560: "Please select a unit",
  i18nKey_1561: "Please select the handling mechanism",
  i18nKey_1562: "Please select the initialization time",
  i18nKey_1563: "Please select a parking time",
  i18nKey_1564: "Please select recharge type",
  i18nKey_1565: "Please select the ride time",
  i18nKey_1566: "Please select city",
  i18nKey_1567: "Please select the owner type",
  i18nKey_1568: "Please select a vehicle tag",
  i18nKey_1569: "Please select the depot name",
  i18nKey_1570: "Please select a parking lot",
  i18nKey_1571: "Please select the scene icon",
  i18nKey_1572: "Please choose a scenario strategy",
  i18nKey_1573: "Please select a scene",
  i18nKey_1574: "Please select a product",
  i18nKey_1575: "Please select a strategically associated park",
  i18nKey_1576: "Please select the street light associated with the strategy",
  i18nKey_1577: "Please select deployment status",
  i18nKey_1578: "Please select the deployment camera",
  i18nKey_1579: "Please select the type of deployment",
  i18nKey_1580: "Please select at least 1 card",
  i18nKey_1581: "Please select no more than $ cards",
  i18nKey_1582: "Please select no more than",
  i18nKey_1583: "Please select the bus schedule",
  i18nKey_1584: "Please select installation time",
  i18nKey_1585: "Please select GIS coordinate type",
  i18nKey_1586: "please choose",
  i18nKey_1587: "Please select the meeting room name first",
  i18nKey_1588: "Please select associated data first",
  i18nKey_1589: "Please save the changes first",
  i18nKey_1590: "don't add people repeatedly",
  i18nKey_1591: "don't add repeatedly",
  i18nKey_1592: "don't enter special characters!",
  i18nKey_1593: "don't enter special characters",
  i18nKey_1594: "No more than 6",
  i18nKey_1595: "Please complete the device information",
  i18nKey_1596: "Please complete the required fields",
  i18nKey_1597: "Please fill in the event description",
  i18nKey_1598: "Please fill in the certified company",
  i18nKey_1599: "Please fill in the license plate number",
  i18nKey_1600: "Please fill in the vehicle color",
  i18nKey_1601: "Please fill in the vehicle brand",
  i18nKey_1602: "Please fill in the strategy name",
  i18nKey_1603: "Please add applicable companies!",
  i18nKey_1604: "Please add a person tag",
  i18nKey_1605: "Please add a route",
  i18nKey_1606: "Please search or select a company",
  i18nKey_1607: "Please enter the minimum number of alarms",
  i18nKey_1608: "Please enter the minimum rent",
  i18nKey_1609: "Please enter the maximum number of alarms",
  i18nKey_1610: "Please enter at most 2 ~ 50 characters, one Chinese character counts as two characters.",
  i18nKey_1611: "Please enter at most 2 ~ 10 characters, one Chinese character counts as two characters. ",
  i18nKey_1612: "Please enter at most 1 ~ 50 characters, one Chinese character counts as two characters, and no spaces are allowed!",
  i18nKey_1613: "Please enter the total switch amperage",
  i18nKey_1614: "Please enter the custom QR code refresh time",
  i18nKey_1615: "Please enter asset description",
  i18nKey_1616: "Please enter asset number",
  i18nKey_1617: "Please enter the consultation phone",
  i18nKey_1618: "Please enter the terminal",
  i18nKey_1619: "Please enter the instruction issuing cycle",
  i18nKey_1620: "Please enter the branch name",
  i18nKey_1621: "Please enter payment order number",
  i18nKey_1622: "Please enter expenditure/income",
  i18nKey_1623: "Please enter a positive integer",
  i18nKey_1624: "Please enter a positive number (up to two decimal places)!",
  i18nKey_1625: "Please enter the correct format mobile phone number to search",
  i18nKey_1626: "Please enter the phone number in the correct format",
  i18nKey_1627: "Please enter correct verify code",
  i18nKey_1628: "Please enter the correct name and phone number",
  i18nKey_1629: "Please enter the correct phone number",
  i18nKey_1630: "please enter a valid phone number",
  i18nKey_1631: "Please enter the correct ID number",
  i18nKey_1632: "Please enter the correct passport number",
  i18nKey_1633: "Please enter the correct phone number",
  i18nKey_1634: "Please enter the correct number of times",
  i18nKey_1635: "Please enter the correct license plate number or mobile phone number",
  i18nKey_1636: "Please enter the correct license plate number",
  i18nKey_1637: "Please enter the correct remark name",
  i18nKey_1638: "Please enter the correct office phone",
  i18nKey_1639: "Please enter account or mobile number",
  i18nKey_1640: "Please enter the percentage",
  i18nKey_1641: "Please enter the original password",
  i18nKey_1642: "Please enter the park name",
  i18nKey_1643: "Please enter domain account",
  i18nKey_1644: "Please enter the warning value",
  i18nKey_1645: "Please enter the scheduled start time",
  i18nKey_1646: "Please enter the name of the booker",
  i18nKey_1647: "Please enter the instrument brand",
  i18nKey_1648: "Please enter the meter name",
  i18nKey_1649: "Please enter the meter number, equipment name, business/tenant name",
  i18nKey_1650: "Please enter the meter code/room number/floor",
  i18nKey_1651: "Please enter the meter code/number/name",
  i18nKey_1652: "Please enter the meter number/building unit",
  i18nKey_1653: "Please enter the meter number",
  i18nKey_1654: "Please enter the business name",
  i18nKey_1655: "please enter verification code",
  i18nKey_1656: "Please enter the patrol route description",
  i18nKey_1657: "Please enter patrol time",
  i18nKey_1658: "Please enter patrol person",
  i18nKey_1659: "Please enter the patrol route description",
  i18nKey_1660: "Please enter the name of the patrol route",
  i18nKey_1661: "Please enter the number of days to be counted",
  i18nKey_1662: "Please enter the total number of days to be monitored",
  i18nKey_1663: "Please enter a new password",
  i18nKey_1664: "Please enter a number less than 10",
  i18nKey_1665: "Please enter a price less than 1~20000",
  i18nKey_1666: "Please enter hour/day",
  i18nKey_1667: "Please enter the fire zone name",
  i18nKey_1668: "Please enter the detailed address (accurate to the number of the province, city and district)",
  i18nKey_1669: "Please enter the detailed address",
  i18nKey_1670: "Please enter the display price",
  i18nKey_1671: "Please enter the number of violations",
  i18nKey_1672: "Please enter your full name or phone number",
  i18nKey_1673: "Please enter an external location name",
  i18nKey_1674: "Please enter the parking lot name",
  i18nKey_1675: "Please enter the parking lot address",
  i18nKey_1676: "Please enter the number of packages",
  i18nKey_1677: "Please enter the number of times the package has been used",
  i18nKey_1678: "Please enter the package name",
  i18nKey_1679: "Please enter the company",
  i18nKey_1680: "Please enter search term",
  i18nKey_1681: "Please enter a numeric value",
  i18nKey_1682: "Please key in numbers",
  i18nKey_1683: "Please enter the quantity",
  i18nKey_1684: "Please enter the interviewed company",
  i18nKey_1685: "Please enter the interviewed address",
  i18nKey_1686: "Please enter the phone to search for a match",
  i18nKey_1687: "Please enter your phone number to search",
  i18nKey_1688: "Please enter your mobile phone number to search",
  i18nKey_1689: "Please enter your mobile phone number or domain account",
  i18nKey_1690: "Please enter phone number",
  i18nKey_1691: "Please enter the credit account name",
  i18nKey_1692: "Please enter your mobile phone number",
  i18nKey_1693: "Please enter the charge instructions",
  i18nKey_1694: "Please enter event number",
  i18nKey_1695: "Please enter a facility alias",
  i18nKey_1696: "Please enter the facility number",
  i18nKey_1697: "Please enter a device alias",
  i18nKey_1698: "Please enter the reporter phone number",
  i18nKey_1699: "Please enter the reporter",
  i18nKey_1700: "Please enter shop number",
  i18nKey_1701: "Please enter the business name",
  i18nKey_1702: "Please enter the business/tenant name",
  i18nKey_1703: "Please enter the capacity, it must be an integer",
  i18nKey_1704: "Please enter the capacity",
  i18nKey_1705: "Please enter the task owner",
  i18nKey_1706: "Please enter the task delegate",
  i18nKey_1707: "Please enter the task name",
  i18nKey_1708: "Please enter a task description",
  i18nKey_1709: "Please enter task or person name",
  i18nKey_1710: "Please enter the task number",
  i18nKey_1711: "Please enter the personnel basic data number",
  i18nKey_1712: "Please enter the heat range",
  i18nKey_1713: "Please enter block information",
  i18nKey_1714: "Please enter the starting point",
  i18nKey_1715: "Please enter the Chinese name of the company",
  i18nKey_1716: "Please enter the business email suffix",
  i18nKey_1717: "Please enter the company website",
  i18nKey_1718: "Please enter company name",
  i18nKey_1719: "Please enter the company's phone number",
  i18nKey_1720: "Please enter company introduction",
  i18nKey_1721: "Please enter ticket/unit price",
  i18nKey_1722: "Please enter the batch number",
  i18nKey_1723: "Please enter a nickname or phone search",
  i18nKey_1724: "Please enter a nickname",
  i18nKey_1725: "Please enter content",
  i18nKey_1726: "Please enter the Morgan platform meeting room ID",
  i18nKey_1727: "Please enter the pattern name",
  i18nKey_1728: "Please enter the area, it must be an integer",
  i18nKey_1729: "Please enter area",
  i18nKey_1730: "Please enter password",
  i18nKey_1731: "Please enter the door name",
  i18nKey_1732: "Please enter the effective length of stay to be analyzed per day",
  i18nKey_1733: "Please enter a route description",
  i18nKey_1734: "Please enter the street light name or address",
  i18nKey_1735: "Please enter the range",
  i18nKey_1736: "Please type your phone number",
  i18nKey_1737: "Please enter the accumulated rentable time",
  i18nKey_1738: "Please enter the space name/work station number",
  i18nKey_1739: "Please enter the name of the person who can book",
  i18nKey_1740: "Please enter a distance range",
  i18nKey_1741: "Please enter the old password",
  i18nKey_1742: "Please enter the amount",
  i18nKey_1743: "Please enter the host’s name/phone number",
  i18nKey_1744: "Please enter the role name",
  i18nKey_1745: "Please enter a role description",
  i18nKey_1746: "Please enter the reward quantity",
  i18nKey_1747: "Please enter the reward object/account number",
  i18nKey_1748: "Please enter the building name",
  i18nKey_1749: "Please enter a simple route",
  i18nKey_1750: "Please enter the price",
  i18nKey_1751: "Please enter the meter name",
  i18nKey_1752: "Please enter the meter number",
  i18nKey_1753: "Please enter the plan name",
  i18nKey_1754: "Please enter the name of the point type",
  i18nKey_1755: "Please enter the value of points",
  i18nKey_1756: "Please enter event name",
  i18nKey_1757: "Please enter an event",
  i18nKey_1758: "Please enter the name of the meeting room/booking company/meeting status",
  i18nKey_1759: "Please enter the meeting room name",
  i18nKey_1760: "Please enter a description of the meeting room",
  i18nKey_1761: "Please enter the house name",
  i18nKey_1762: "Please enter the number of people",
  i18nKey_1763: "Please enter a valid MAC address, such as 00-01-6c-06-A6-29 or 00:01:6c:06:A6:29",
  i18nKey_1764: "Please enter the name of the administrator",
  i18nKey_1765: "Please enter the name of the public area",
  i18nKey_1766: "Please enter the staff phone number",
  i18nKey_1767: "Please enter staff name",
  i18nKey_1768: "Please enter the number of stations",
  i18nKey_1769: "Please enter a profile",
  i18nKey_1770: "Please enter the price",
  i18nKey_1771: "Please enter the business license registration number",
  i18nKey_1772: "Please enter the person responsible for the ticket",
  i18nKey_1773: "Please enter the ticket number",
  i18nKey_1774: "Please enter the duration of keeping brightness after induction",
  i18nKey_1775: "Please enter the number of additional facilities",
  i18nKey_1776: "Please enter the name of the additional facility",
  i18nKey_1777: "Please enter the server IP or domain name",
  i18nKey_1778: "Please enter the item name",
  i18nKey_1779: "Please enter the room name",
  i18nKey_1780: "Please enter the legal person ID",
  i18nKey_1781: "Please enter the legal representative",
  i18nKey_1782: "Please enter the issued quantity",
  i18nKey_1783: "Please enter the quota, which must be an integer",
  i18nKey_1784: "Please enter the amount",
  i18nKey_1785: "Please enter how many minutes",
  i18nKey_1786: "Please enter order number/space name",
  i18nKey_1787: "Please enter the order number/ conference room name",
  i18nKey_1788: "Please enter order number",
  i18nKey_1789: "Please enter the cable square number",
  i18nKey_1790: "Please enter phone search",
  i18nKey_1791: "Please enter the meter number, equipment name, merchant/tenant name",
  i18nKey_1792: "Please enter the meter number",
  i18nKey_1793: "Please enter the third party ID",
  i18nKey_1794: "Please enter the address latitude and longitude",
  i18nKey_1795: "Please enter the address",
  i18nKey_1796: "Please enter the current account login password",
  i18nKey_1797: "Please enter the unit price (yuan/hour)",
  i18nKey_1798: "Please enter the processor",
  i18nKey_1799: "Please enter the tenant",
  i18nKey_1800: "Please enter the owner's name",
  i18nKey_1801: "Please enter the meter reader",
  i18nKey_1802: "Please enter the scene name",
  i18nKey_1803: "Please enter the name of the scene, no more than ten characters",
  i18nKey_1804: "Please enter a strategy name",
  i18nKey_1805: "Please enter the bottom of the form",
  i18nKey_1806: "Please enter the magnification",
  i18nKey_1807: "Please enter the half-day unit price (yuan/4 hours)",
  i18nKey_1808: "Please enter the shift number",
  i18nKey_1809: "Please enter the installation location",
  i18nKey_1810: "Please enter a decimal between 0-1",
  i18nKey_1811: "Please enter $ table number, equipment name, business/tenant name",
  i18nKey_1812: "please enter",
  i18nKey_1813: "Please upload a new version of excel file",
  i18nKey_1814: "Please upload an image",
  i18nKey_1815: "Please make sure that you have permission to access this information. All your operations will be recorded. If you don't operate for more than 30 minutes, you will need to re-verify",
  i18nKey_1816: "Request association list exception",
  i18nKey_1817: "Please contact the administrator to obtain related service permissions.",
  i18nKey_1818: "Please fill in the IT code, business code and space level name of the current level completely!",
  i18nKey_1819: "Please check the link in the address bar and visit again",
  i18nKey_1820: "Please log in to your account",
  i18nKey_1821: "please sign in",
  i18nKey_1822: "Please integrate the same formula",
  i18nKey_1823: "Clear",
  i18nKey_1824: "Light pollution",
  i18nKey_1825: "blue",
  i18nKey_1826: "Switch to full view",
  i18nKey_1827: "Switch to default scale",
  i18nKey_1828: "Switch image",
  i18nKey_1829: "Switch list",
  i18nKey_1830: "Merchants in arrears",
  i18nKey_1831: "the day before",
  i18nKey_1832: "Go to IOC login page",
  i18nKey_1833: "The start time can't be greater than or equal to the end time",
  i18nKey_1834: "The start or end duration can't be null",
  i18nKey_1835: "The starting or ending amount can't be null",
  i18nKey_1836: "The start or end times can't be null",
  i18nKey_1837: "Starting point",
  i18nKey_1838: "Starting number",
  i18nKey_1839: "Starting point can't be null",
  i18nKey_1840: "Activation date",
  i18nKey_1841: "Enable",
  i18nKey_1842: "Enterprise Chinese name",
  i18nKey_1843: "Corporate employees",
  i18nKey_1844: "Enterprise mailbox suffix",
  i18nKey_1845: "Enterprise English name",
  i18nKey_1846: "Enterprise address",
  i18nKey_1847: "Enterprise URL",
  i18nKey_1848: "Enterprise Merchant",
  i18nKey_1849: "Enterprise energy consumption ranking",
  i18nKey_1850: "Company Name",
  i18nKey_1851: "Business Daily Check-in_",
  i18nKey_1852: "Corporate Contact Number",
  i18nKey_1853: "Enterprise Contact Number",
  i18nKey_1854: "Enterprise type",
  i18nKey_1855: "Company Profile",
  i18nKey_1856: "Business account",
  i18nKey_1857: "Corporate visitors",
  i18nKey_1858: "Corporate",
  i18nKey_1859: "company address",
  i18nKey_1860: "Corporate logo",
  i18nKey_1861: "Enterprise",
  i18nKey_1862: "Energy consumption of its sub-items",
  i18nKey_1863: "Other",
  i18nKey_1864: "Other electricity",
  i18nKey_1865: "Other information",
  i18nKey_1866: "Other",
  i18nKey_1867: "Seven categories",
  i18nKey_1868: "Ordinary event",
  i18nKey_1869: "Ordinary",
  i18nKey_1870: "Weekday passenger flow",
  i18nKey_1871: "Average conversion time",
  i18nKey_1872: "Average length of stay",
  i18nKey_1873: "Average value",
  i18nKey_1874: "Average daily savings",
  i18nKey_1875: "Maximum number of issues per company",
  i18nKey_1876: "Average processing time:",
  i18nKey_1877: "Average processing time (minutes)",
  i18nKey_1878: "Leveling",
  i18nKey_1879: "Brands can only be composed of Chinese and English, numbers and _",
  i18nKey_1880: "Brand length can't exceed 100 characters",
  i18nKey_1881: "More than 6 options of brand and color have been displayed for you according to the best",
  i18nKey_1882: "Brand Analysis",
  i18nKey_1883: "Brand more than 6 options have been displayed for you according to the best",
  i18nKey_1884: "Brand:",
  i18nKey_1885: "Brand",
  i18nKey_1886: "Frequency",
  i18nKey_1887: "Frequency",
  i18nKey_1888: "Ticket/unit price",
  i18nKey_1889: "Batch modify templates based on exported data",
  i18nKey_1890: "Batch Edit",
  i18nKey_1891: "Batch creation",
  i18nKey_1892: "Configuration details",
  i18nKey_1893: "Distribution switch name",
  i18nKey_1894: "Distribution time",
  i18nKey_1895: "Successfully distributed",
  i18nKey_1896: "Rank",
  i18nKey_1897: "Female",
  i18nKey_1898: "You have a lot of tasks to be queried, please wait for the tasks to be executed before querying.",
  i18nKey_1899: "You have successfully changed your password, please log in to the platform again.",
  i18nKey_1900: "Do you want to suspend the selected package?",
  i18nKey_1901: "Do you want to suspend the selected allowance?",
  i18nKey_1902: "Do you want to suspend the selected meeting room?",
  i18nKey_1903: "Do you want to suspend the selected station?",
  i18nKey_1904: "Do you want to suspend the selected bus schedule?",
  i18nKey_1905: "Do you want to remove the selected limited time discount?",
  i18nKey_1906: "Do you want to list the selected limited time discount?",
  i18nKey_1907: "Do you want to activate the selected package?",
  i18nKey_1908: "Do you want to activate the selected allowance?",
  i18nKey_1909: "Do you want to activate the selected meeting room?",
  i18nKey_1910: "Do you want to activate the selected station?",
  i18nKey_1911: "Do you want to activate the selected bus schedule?",
  i18nKey_1912: "Do you want to clear all query history?",
  i18nKey_1913: "There is an overlap in the time you set",
  i18nKey_1914: "Are you sure you want to execute?",
  i18nKey_1915: "Are you sure you want to deactivate?",
  i18nKey_1916: "Are you sure you want to delete?",
  i18nKey_1917: "Are you sure you want to enable it?",
  i18nKey_1918: "Are you sure you want to delete the limited time discount?",
  i18nKey_1919: "Are you sure you want to delete the package?",
  i18nKey_1920: "You have not selected any data items!",
  i18nKey_1921: "You have not selected any data items",
  i18nKey_1922: "Hello and welcome to IOC!",
  i18nKey_1923: "You don’t have any company visitor data yet, you can",
  i18nKey_1924: "The page you visited doesn't exist",
  i18nKey_1925: "Your browser doesn't support HTML5",
  i18nKey_1926: "There is a fault in the device you currently selected, please arrange for someone to handle it first!",
  i18nKey_1927: "The large screen you currently selected is offline and the command is unreachable!",
  i18nKey_1928: "age range",
  i18nKey_1929: "year",
  i18nKey_1930: "year",
  i18nKey_1931: "Nickname can't exceed 12 characters in length",
  i18nKey_1932: "nickname",
  i18nKey_1933: "You have chosen the package to pay",
  i18nKey_1934: "You have chosen to suspend payment",
  i18nKey_1935: "You have entered $/$, compared with the data in the previous period, there are $ data abnormal ($), are you sure to submit",
  i18nKey_1936: "You have imported $ data, there are $ data abnormal, are you sure to submit",
  i18nKey_1937: "The strategy you edited has not been saved yet, are you sure to give up?",
  i18nKey_1938: "Are you sure you need to delete this policy? After deleting, you need to manually control the light switch.",
  i18nKey_1939: "Energy efficiency ratio COP (kW/kW)",
  i18nKey_1940: "Energy efficiency AI diagnosis",
  i18nKey_1941: "Energy consumption statistics",
  i18nKey_1942: "Waste of energy",
  i18nKey_1943: "Energy consumption monitoring",
  i18nKey_1944: "Energy consumption comparison",
  i18nKey_1945: "Energy consumption (kWh)",
  i18nKey_1946: "Internal business",
  i18nKey_1947: "male",
  i18nKey_1948: "Target value setting",
  i18nKey_1949: "Target value",
  i18nKey_1950: "Default (refresh at 00:00 the next day)",
  i18nKey_1951: "default",
  i18nKey_1952: "Morgan Platform Meeting Room ID",
  i18nKey_1953: "Pattern name",
  i18nKey_1954: "mode",
  i18nKey_1955: "Fuzzy query",
  i18nKey_1956: "Template download",
  i18nKey_1957: "Detail",
  i18nKey_1958: "Resend in seconds",
  i18nKey_1959: "After seconds, it will automatically jump to the IOC login page",
  i18nKey_1960: "second",
  i18nKey_1961: "Description can't be null",
  i18nKey_1962: "Interview",
  i18nKey_1963: "Area (m²)",
  i18nKey_1964: "Free facilities",
  i18nKey_1965: "Free quota (hour/month)",
  i18nKey_1966: "The allowance is being activated and no modification is allowed!",
  i18nKey_1967: "Password status",
  i18nKey_1968: "The password length is 8-20 characters!",
  i18nKey_1969: "The password must be 8-20 digits long and contain numbers and letters",
  i18nKey_1970: "If the password is entered incorrectly more than 5 times, you will log out after 10 seconds",
  i18nKey_1971: "The password strength doesn't match, at least 8 digits, including at least three types of numbers, uppercase and lowercase letters, and special characters",
  i18nKey_1972: "Incorrect password, please try again",
  i18nKey_1973: "Password can't contain spaces",
  i18nKey_1974: "Password must be 8-20 digits and letters",
  i18nKey_1975: "The password must include at least 2 of letters, numbers, and symbols",
  i18nKey_1976: "Meter",
  i18nKey_1977: "Door lock number",
  i18nKey_1978: "Access control limit",
  i18nKey_1979: "Access control method",
  i18nKey_1980: "Door name",
  i18nKey_1981: "Access control QR code reader",
  i18nKey_1982: "Access control PAD",
  i18nKey_1983: "Up to 6 lighting rules can be added to each strategy",
  i18nKey_1984: "Energy consumption per square meter (t/m²)",
  i18nKey_1985: "Energy consumption per square meter (kWh/m²)",
  i18nKey_1986: "Energy consumption per square meter",
  i18nKey_1987: "You can only track one task at a time. Are you sure you need to abandon the current tracking task and start a new tracking task?",
  i18nKey_1988: "No data, please select the building from the space tree below",
  i18nKey_1989: "No data!",
  i18nKey_1990: "No meeting schedule",
  i18nKey_1991: "User not found",
  i18nKey_1992: "Green and energy saving",
  i18nKey_1993: "green",
  i18nKey_1994: "Number of patrol aircraft",
  i18nKey_1995: "Route description",
  i18nKey_1996: "Route list",
  i18nKey_1997: "Route (article)",
  i18nKey_1998: "Route",
  i18nKey_1999: "Street lamp selection",
  i18nKey_2000: "Building Automation",
  i18nKey_2001: "Building unit",
  i18nKey_2002: "Building/floor/room",
  i18nKey_2003: "Building",
  i18nKey_2004: "Floor space",
  i18nKey_2005: "Floor",
  i18nKey_2006: "Six categories",
  i18nKey_2007: "Level 6",
  i18nKey_2008: "Temporary suspension fee",
  i18nKey_2009: "Temporary suspension payment amount (yuan)",
  i18nKey_2010: "Stop time",
  i18nKey_2011: "Pro parking",
  i18nKey_2012: "Stop",
  i18nKey_2013: "Temporary visitor",
  i18nKey_2014: "temporary",
  i18nKey_2015: "Range",
  i18nKey_2016: "Vehicles",
  i18nKey_2017: "Brightness display",
  i18nKey_2018: "Brightness setting",
  i18nKey_2019: "brightness",
  i18nKey_2020: "Lighting rules",
  i18nKey_2021: "The two passwords entered are inconsistent",
  i18nKey_2022: "Two charge and two discharge",
  i18nKey_2023: "good",
  i18nKey_2024: "contact number",
  i18nKey_2025: "Townhouse",
  i18nKey_2026: "Example: Ping An XXXXXX parking lot, 15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter, 60 capped. Futian District, Shenzhen City, Guangdong Province XXXXX",
  i18nKey_2027: "For example: 15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter, 60 capped.",
  i18nKey_2028: "Sign up now",
  i18nKey_2029: "log in immediately",
  i18nKey_2030: "Historical diagnosis",
  i18nKey_2031: "Historical statistics",
  i18nKey_2032: "End of historical video playback",
  i18nKey_2033: "Historical data",
  i18nKey_2034: "Historical curve",
  i18nKey_2035: "Historical track",
  i18nKey_2036: "historic version",
  i18nKey_2037: "Offline ",
  i18nKey_2038: "Offline",
  i18nKey_2039: "Leaving the comfort zone is the first step forward.",
  i18nKey_2040: "Outlet temperature of chiller",
  i18nKey_2041: "Cumulative consumption amount",
  i18nKey_2042: "Accumulated parking fee (CNY)",
  i18nKey_2043: "Accumulative temporary suspension of payment $ yuan, you can recommend the monthly card to him",
  i18nKey_2044: "Cumulative rentable time",
  i18nKey_2045: "The accumulative rent can often not be less than the minimum rent!",
  i18nKey_2046: "Cumulative payment>¥400",
  i18nKey_2047: "Cumulative visits",
  i18nKey_2048: "Cumulative visits",
  i18nKey_2049: "Cumulative energy saving potential started from October 1, 2019 to present",
  i18nKey_2050: "Cumulative energy saving potential (kWh)",
  i18nKey_2051: "Cumulative energy saving potential",
  i18nKey_2052: "Type can't be null",
  i18nKey_2053: "Types of",
  i18nKey_2054: "Category can't be null",
  i18nKey_2055: "Blue",
  i18nKey_2056: "From province",
  i18nKey_2057: "Source system device number",
  i18nKey_2058: "Source system",
  i18nKey_2059: "source",
  i18nKey_2060: "Reason for visiting",
  i18nKey_2061: "The start time of the visit period can't be greater than or equal to the end time",
  i18nKey_2062: "Type of visit",
  i18nKey_2063: "Idle state",
  i18nKey_2064: "Air conditioning electricity",
  i18nKey_2065: "Satisfactory air quality, basically no air pollution",
  i18nKey_2066: "Spatial location",
  i18nKey_2067: "Space name/work station number",
  i18nKey_2068: "Space name",
  i18nKey_2069: "Space type",
  i18nKey_2070: "Space basic data service coding",
  i18nKey_2071: "Spatial basic data modification",
  i18nKey_2072: "Spatial basic data details",
  i18nKey_2073: "Spatial basic data location",
  i18nKey_2074: "Spatial basic data creation",
  i18nKey_2075: "Spatial basic data coding",
  i18nKey_2076: "Space level name",
  i18nKey_2077: "Spatial hierarchy",
  i18nKey_2078: "living room",
  i18nKey_2079: "Total passenger flow (person-time)",
  i18nKey_2080: "Sex ratio of passenger flow",
  i18nKey_2081: "Availability range",
  i18nKey_2082: "Optional unit",
  i18nKey_2083: "Can be booked in advance from 0 to 30",
  i18nKey_2084: "Unlock type",
  i18nKey_2085: "The start time and end time can't be less than 30 minutes apart",
  i18nKey_2086: "The difference between the start time and the end time can't exceed 7 days",
  i18nKey_2087: "Start time can't be null",
  i18nKey_2088: "The start time can't be greater than the end time",
  i18nKey_2089: "Starting time",
  i18nKey_2090: "start installation",
  i18nKey_2091: "Tracking successfully turned on",
  i18nKey_2092: "Turn on tracking",
  i18nKey_2093: "Turn on selected",
  i18nKey_2094: "Open time",
  i18nKey_2095: "Open the door",
  i18nKey_2096: "After opening, the smart door will be updated according to the set time frequency",
  i18nKey_2097: "Passable after opening",
  i18nKey_2098: "After opening, visitors can view their own historical registration records",
  i18nKey_2099: "After it is turned on, mobile users can use this function, and it will not be displayed when it is turned off.",
  i18nKey_2100: "After it’s turned on, mobile users can use this function, but it’s not available after it’s turned off.",
  i18nKey_2101: "Door open state",
  i18nKey_2102: "Door opening frequency",
  i18nKey_2103: "Door opening record",
  i18nKey_2104: "Meeting mode",
  i18nKey_2105: "On/Off",
  i18nKey_2106: "On",
  i18nKey_2107: "Specific spatial position coding",
  i18nKey_2108: "Refuse",
  i18nKey_2109: "Residential Address",
  i18nKey_2110: "old password",
  i18nKey_2111: "The integer part of longitude is 0-180",
  i18nKey_2112: "longitude",
  i18nKey_2113: "Beijing, Tianjin, Shanghai, Chongqing, Hebei, Henan, Yunnan, Liaoning, Heilongjiang, Hunan, Anhui, Shandong, Xinjiang, Jiangsu, Zhejiang, Jiangxi, Hubei, Guangxi, Gansu, Shanxi, Menggu, Shaanxi, Jilin, Fujian, Guangdong, Qinghai, Tibet, Sichuan, Ningxia, Hainan,",
  i18nKey_2114: "No entry to the parking lot",
  i18nKey_2115: "Prohibition of monthly pass",
  i18nKey_2116: "Disable failed",
  i18nKey_2117: "Number of admissions in the past month",
  i18nKey_2118: "Average passenger flow in the past month",
  i18nKey_2119: "Number of days visited in the past month",
  i18nKey_2120: "Nearly one month",
  i18nKey_2121: "Nearly a year",
  i18nKey_2122: "Enter the park at the earliest $ in the past month",
  i18nKey_2123: "Haven't left in the past month",
  i18nKey_2124: "Haven't visited in the past month",
  i18nKey_2125: "Number of repeat customer visits in the past month",
  i18nKey_2126: "More than 400 in the past month",
  i18nKey_2127: "Nearly 7 days",
  i18nKey_2128: "Number of departures from the parking lot in the past 30 days",
  i18nKey_2129: "Number of parking lots in the past 30 days",
  i18nKey_2130: "Major event alarm in the past 24 hours",
  i18nKey_2131: "Nearly 24 hours",
  i18nKey_2132: "In progress",
  i18nKey_2133: "Parking time",
  i18nKey_2134: "Entry time",
  i18nKey_2135: "Emergency alert",
  i18nKey_2136: "Only once",
  i18nKey_2137: "Only show the number of video patrol alarms",
  i18nKey_2138: "Only planned patrols participate in statistics",
  i18nKey_2139: "Finance",
  i18nKey_2140: "Maximum length of the amount is 6 digits",
  i18nKey_2141: "Amount can only be an integer or a decimal within 2 digits",
  i18nKey_2142: "Pedestrian flow today",
  i18nKey_2143: "Traffic flow today",
  i18nKey_2144: "Actual electricity consumption today (kWh)",
  i18nKey_2145: "Today's passenger flow",
  i18nKey_2146: "Today's door opening frequency ranking",
  i18nKey_2147: "Number of repeat customers today",
  i18nKey_2148: "Successfully remove the blacklist",
  i18nKey_2149: "Remove blacklist",
  i18nKey_2150: "Screenshot failed",
  i18nKey_2151: "End duration can't be less than the start duration",
  i18nKey_2152: "End time needs to be greater than the start time",
  i18nKey_2153: "End time can't be null",
  i18nKey_2154: "End Time",
  i18nKey_2155: "End mode",
  i18nKey_2156: "Ending amount can't be less than the starting amount",
  i18nKey_2157: "End times can't be less than start times",
  i18nKey_2158: "Cost savings (yuan)",
  i18nKey_2159: "Cost savings",
  i18nKey_2160: "Holiday name can't be null",
  i18nKey_2161: "Festival",
  i18nKey_2162: "Energy saving potential (kWh)",
  i18nKey_2163: "Energy saving potential",
  i18nKey_2164: "Energy-saving type (species)",
  i18nKey_2165: "Energy saving type",
  i18nKey_2166: "Program Title:",
  i18nKey_2167: "Access voltage level (V)",
  i18nKey_2168: "Interface request failed",
  i18nKey_2169: "Host name",
  i18nKey_2170: "Host information",
  i18nKey_2171: "Receptionist phone",
  i18nKey_2172: "Shuttle bus occupancy rate",
  i18nKey_2173: "Than yesterday",
  i18nKey_2174: "Compared to yesterday",
  i18nKey_2175: "Compared to last week",
  i18nKey_2176: "Month-on-month",
  i18nKey_2177: "Compared to last month",
  i18nKey_2178: "Compared to last year",
  i18nKey_2179: "Compared with the actual value of the previous year",
  i18nKey_2180: "Year-on-year",
  i18nKey_2181: "Month-on-month",
  i18nKey_2182: "The day before",
  i18nKey_2183: "Payment details",
  i18nKey_2184: "Payment type",
  i18nKey_2185: "Role status",
  i18nKey_2186: "Role Name",
  i18nKey_2187: "Role description",
  i18nKey_2188: "Role list",
  i18nKey_2189: "Role price name",
  i18nKey_2190: "The reward quantity can't be less than zero",
  i18nKey_2191: "The reward quantity can't be zero",
  i18nKey_2192: "The reward quantity can't have decimals",
  i18nKey_2193: "Number of rewards",
  i18nKey_2194: "Number of reward points (a)",
  i18nKey_2195: "Reward points name",
  i18nKey_2196: "Reward sponsor",
  i18nKey_2197: "Reward selection",
  i18nKey_2198: "Reward type",
  i18nKey_2199: "Reward object/account number",
  i18nKey_2200: "Reward",
  i18nKey_2201: "Drag the file here, or click upload",
  i18nKey_2202: "Building selection",
  i18nKey_2203: "Building electrical system",
  i18nKey_2204: "Suggested value",
  i18nKey_2205: "Recommended executor",
  i18nKey_2206: "Recommended operating parameters",
  i18nKey_2207: "Easy route",
  i18nKey_2208: "Under repair",
  i18nKey_2209: "Surveillance cameras",
  i18nKey_2210: "Monitor screenshot",
  i18nKey_2211: "monitor:",
  i18nKey_2212: "Monitoring period (days)",
  i18nKey_2213: "Monitoring days can only be a positive integer",
  i18nKey_2214: "Monitor",
  i18nKey_2215: "Interval time range is within 1-60",
  i18nKey_2216: "Interval time (seconds)",
  i18nKey_2217: "Driver's license number has special characters",
  i18nKey_2218: "Driver's license number",
  i18nKey_2219: "Driver license:",
  i18nKey_2220: "Price setting",
  i18nKey_2221: "Price (/remaining times)",
  i18nKey_2222: "Many overtime work, leaving the park after 20:00 in the evening for 10 days in the past month",
  i18nKey_2223: "Relay status",
  i18nKey_2224: "Remember current account",
  i18nKey_2225: "Meter name",
  i18nKey_2226: "Abbreviation of meter",
  i18nKey_2227: "Meter number",
  i18nKey_2228: "Meter",
  i18nKey_2229: "Plan details",
  i18nKey_2230: "Plan name",
  i18nKey_2231: "Billing type",
  i18nKey_2232: "Hometown",
  i18nKey_2233: "Basic Information",
  i18nKey_2234: "Total value of points (CNY)",
  i18nKey_2235: "Total value of points",
  i18nKey_2236: "Points validity period (start)",
  i18nKey_2237: "Points validity period (end)",
  i18nKey_2238: "Points validity period",
  i18nKey_2239: "Points wallet",
  i18nKey_2240: "Point value (CNY/min)",
  i18nKey_2241: "Mechanical watch",
  i18nKey_2242: "Engine room power (kWh)",
  i18nKey_2243: "Obtain verification code",
  i18nKey_2244: "Failed to obtain video resource",
  i18nKey_2245: "Get time",
  i18nKey_2246: "Failed to obtain camera resource...",
  i18nKey_2247: "Failed to obtain device information!",
  i18nKey_2248: "Failed to obtain spatial data!",
  i18nKey_2249: "Get address latitude and longitude",
  i18nKey_2250: "Get table list exception",
  i18nKey_2251: "Freight elevator selection",
  i18nKey_2252: "Active status",
  i18nKey_2253: "Event details",
  i18nKey_2254: "Event name",
  i18nKey_2255: "Minimum event station rent no less than 30 days!",
  i18nKey_2256: "Event station",
  i18nKey_2257: "Event can't be null",
  i18nKey_2258: "Event",
  i18nKey_2259: "Member name",
  i18nKey_2260: "Member code",
  i18nKey_2261: "Meeting status",
  i18nKey_2262: "Meeting information",
  i18nKey_2263: "Meeting room details",
  i18nKey_2264: "First picture of meeting room",
  i18nKey_2265: "Meeting room use",
  i18nKey_2266: "Conference room name no special characters (Chinese characters, letters and numbers allowed)",
  i18nKey_2267: "Meeting room name",
  i18nKey_2268: "Meeting room description",
  i18nKey_2269: "Conference room service staff",
  i18nKey_2270: "Meeting room order details",
  i18nKey_2271: "Before the meeting: not started",
  i18nKey_2272: "Before the meeting:",
  i18nKey_2273: "Before the meeting",
  i18nKey_2274: "After the meeting:",
  i18nKey_2275: "Number of returning customers",
  i18nKey_2276: "Percentage of repeat customers",
  i18nKey_2277: "Repeat customers",
  i18nKey_2278: "Loop state",
  i18nKey_2279: "Circuit number/service area",
  i18nKey_2280: "Circuit number",
  i18nKey_2281: "Replay",
  i18nKey_2282: "Gray",
  i18nKey_2283: "Yellow",
  i18nKey_2284: "Environmental monitoring equipment",
  i18nKey_2285: "Environmental monitoring",
  i18nKey_2286: "Environmental alarm policy configuration",
  i18nKey_2287: "Chain energy consumption",
  i18nKey_2288: "Chain electricity consumption over the same period",
  i18nKey_2289: "Same period",
  i18nKey_2290: "Chain time",
  i18nKey_2291: "Chain energy consumption per square meter",
  i18nKey_2292: "Chain ratio",
  i18nKey_2293: "Welcome to IOC platform, please reset your password",
  i18nKey_2294: "Welcome to use the IOC platform, please log in to your account after changing your password",
  i18nKey_2295: "Welcome to IOC platform, please register",
  i18nKey_2296: "Central China",
  i18nKey_2297: "West China",
  i18nKey_2298: "South China",
  i18nKey_2299: "East China",
  i18nKey_2300: "North China Park",
  i18nKey_2301: "North China",
  i18nKey_2302: "Underline",
  i18nKey_2303: "Passport No.",
  i18nKey_2304: "Example of a unit name format: A1-3 room 2 hall",
  i18nKey_2305: "House name",
  i18nKey_2306: "Household number",
  i18nKey_2307: "Internet",
  i18nKey_2308: "Arc chart",
  i18nKey_2309: "Month forecast",
  i18nKey_2310: "Result",
  i18nKey_2311: "Postpaid",
  i18nKey_2312: "Red",
  i18nKey_2313: "Started working early and entered the park before 8:00 am for 10 days past month",
  i18nKey_2314: "Black",
  i18nKey_2315: "Blacklist events numbers",
  i18nKey_2316: "Blacklist event",
  i18nKey_2317: "Blacklist overview",
  i18nKey_2318: "blacklist",
  i18nKey_2319: "Number of people approved",
  i18nKey_2320: "Contract period:",
  i18nKey_2321: "Contract Number:",
  i18nKey_2322: "Contract",
  i18nKey_2323: "Struggle in partnership.",
  i18nKey_2324: "Merge",
  i18nKey_2325: "Water Consumption",
  i18nKey_2326: "Longest time-consuming Top3",
  i18nKey_2327: "Shortest time-consuming Top3",
  i18nKey_2328: "Power consumption (degrees)",
  i18nKey_2329: "Power consumption",
  i18nKey_2330: "Luxury cars",
  i18nKey_2331: "Behavioral characteristics",
  i18nKey_2332: "Driving license:",
  i18nKey_2333: "country",
  i18nKey_2334: "Country of Citizenship",
  i18nKey_2335: "Track time period",
  i18nKey_2336: "Trajectory exported successfully",
  i18nKey_2337: "Track view",
  i18nKey_2338: "Track",
  i18nKey_2339: "Rule",
  i18nKey_2340: "Guangzhou",
  i18nKey_2341: "Mangement head be Chinese, letters and underscores within 20 characters",
  i18nKey_2342: "Management head",
  i18nKey_2343: "Administrator proactively refund",
  i18nKey_2344: "Administrator name",
  i18nKey_2345: "Manage ownership",
  i18nKey_2346: "Management Approver",
  i18nKey_2347: "Management Department",
  i18nKey_2348: "Association abnormal",
  i18nKey_2349: "Related events",
  i18nKey_2350: "Associated camera successfully",
  i18nKey_2351: "Associated camera",
  i18nKey_2352: "Related facility events",
  i18nKey_2353: "Associate historical video __",
  i18nKey_2354: "Linked successfully",
  i18nKey_2355: "Associated license plate number",
  i18nKey_2356: "Related",
  i18nKey_2357: "Key words",
  i18nKey_2358: "Turn off tracking exception",
  i18nKey_2359: "Turn off tracking",
  i18nKey_2360: "Close selected",
  i18nKey_2361: "Turn off the alert",
  i18nKey_2362: "Turn off",
  i18nKey_2363: "Off",
  i18nKey_2364: "Account holder",
  i18nKey_2365: "Total frequency of failure",
  i18nKey_2366: "Failure details",
  i18nKey_2367: "Number of failed facilities",
  i18nKey_2368: "Statistics of failure facilities",
  i18nKey_2369: "Fault description",
  i18nKey_2370: "Source of failure",
  i18nKey_2371: "malfunction",
  i18nKey_2372: "The minimum rent for a fixed station should be no less than 3 months!",
  i18nKey_2373: "Fixed station",
  i18nKey_2374: "After the field is checked, the field is visible in the applet and APP users",
  i18nKey_2375: "supplier",
  i18nKey_2376: "Supply and distribution system",
  i18nKey_2377: "Power supply and distribution",
  i18nKey_2378: "Total lighting circuit $",
  i18nKey_2379: "Shared meeting room",
  i18nKey_2380: "Shared office order report_",
  i18nKey_2381: "Consensus, co-creation, sharing, sharing.",
  i18nKey_2382: "Open $ times",
  i18nKey_2383: "A total of $, successful $, error $",
  i18nKey_2384: "Congratulations, registration is successful!",
  i18nKey_2385: "Public cloud",
  i18nKey_2386: "Company Booking Top5",
  i18nKey_2387: "Public area electricity",
  i18nKey_2388: "Public area meter",
  i18nKey_2389: "Public district energy consumption ranking",
  i18nKey_2390: "The target value of public area energy consumption can't be greater than the target value of total energy consumption",
  i18nKey_2391: "Public area energy consumption (last month)",
  i18nKey_2392: "Public district name",
  i18nKey_2393: "Public area type",
  i18nKey_2394: "Public area",
  i18nKey_2395: "The public area can't be deleted",
  i18nKey_2396: "Common configuration",
  i18nKey_2397: "Public security blacklist",
  i18nKey_2398: "public security",
  i18nKey_2399: "Working day",
  i18nKey_2400: "Staff name",
  i18nKey_2401: "Staff phone",
  i18nKey_2402: "staff member",
  i18nKey_2403: "Company",
  i18nKey_2404: "Type of work",
  i18nKey_2405: "Station status",
  i18nKey_2406: "Number of stations",
  i18nKey_2407: "First Picture of Workstation",
  i18nKey_2408: "Station type",
  i18nKey_2409: "Introduction",
  i18nKey_2410: "The unit price of the workstation must be consistent with the unit with the least rent!",
  i18nKey_2411: "Station price",
  i18nKey_2412: "The number of station numbers must be consistent with the number of stations!",
  i18nKey_2413: "Station number",
  i18nKey_2414: "Business license registration number / unified social credit code",
  i18nKey_2415: "Job number",
  i18nKey_2416: "Ticket status",
  i18nKey_2417: "Work order owner",
  i18nKey_2418: "Work order delegate",
  i18nKey_2419: "Work order dispatched successfully",
  i18nKey_2420: "Work order number",
  i18nKey_2421: "Update time is yesterday's statistical time",
  i18nKey_2422: "Update time",
  i18nKey_2423: "Update failed!",
  i18nKey_2424: "update completed",
  i18nKey_2425: "More layers",
  i18nKey_2426: "More tasks",
  i18nKey_2427: "Drainage",
  i18nKey_2428: "Normal activities for all kinds of people",
  i18nKey_2429: "Personal points",
  i18nKey_2430: "personal",
  i18nKey_2431: "Per company",
  i18nKey_2432: "A",
  i18nKey_2433: "Alarm threshold",
  i18nKey_2434: "Alarm events and follow-up status",
  i18nKey_2435: "The device associated with the alarm event doesn't exist",
  i18nKey_2436: "Alarm event overview",
  i18nKey_2437: "Alarm event",
  i18nKey_2438: "Alarm description",
  i18nKey_2439: "Alarm list",
  i18nKey_2440: "Alarm type",
  i18nKey_2441: "Source of alarm",
  i18nKey_2442: "Alarm classification statistics",
  i18nKey_2443: "Number of alarms",
  i18nKey_2444: "Alarm view",
  i18nKey_2445: "Highlight",
  i18nKey_2446: "advanced",
  i18nKey_2447: "Induction dimly bright",
  i18nKey_2448: "Sensing brightness",
  i18nKey_2449: "sensory switch",
  i18nKey_2450: "Keep brightness time after induction",
  i18nKey_2451: "Sensing lighting time",
  i18nKey_2452: "The event status is pending and can't be reported temporarily",
  i18nKey_2453: "The event status is pending and can't be reported temporarily",
  i18nKey_2454: "The event status is processing and can't be reported temporarily",
  i18nKey_2455: "The task has unprocessed events and can't be reported temporarily",
  i18nKey_2456: "The person has no movement track during this time period",
  i18nKey_2457: "The person has no image and can't be deployed",
  i18nKey_2458: "The meeting room has been disabled and can't be executed",
  i18nKey_2459: "There are no dates available for this meeting room",
  i18nKey_2460: "No parking lot in this area",
  i18nKey_2461: "This ride has been activated, please pause it before operating!",
  i18nKey_2462: "The scene has been disabled and can't be executed",
  i18nKey_2463: "The deployment has been stopped and can't be modified",
  i18nKey_2464: "The deployment control has been activated and can't be modified",
  i18nKey_2465: "The control vehicle data has been deleted, it doesn't support modification or re-arrangement, please re-create the deployment control",
  i18nKey_2466: "The attachment only supports the new version of excel, PDF, jpg, rar, zip format",
  i18nKey_2467: "annex",
  i18nKey_2468: "Number of additional facilities",
  i18nKey_2469: "Additional facility name already exists!",
  i18nKey_2470: "Additional facility name",
  i18nKey_2471: "Load condition",
  i18nKey_2472: "payment method",
  i18nKey_2473: "Payment model",
  i18nKey_2474: "Payment methods",
  i18nKey_2475: "Secondary video settings",
  i18nKey_2476: "Service resources",
  i18nKey_2477: "Service providers can only be composed of Chinese and English, numbers and _",
  i18nKey_2478: "The length of the service provider can't exceed 50 characters",
  i18nKey_2479: "Service provider",
  i18nKey_2480: "Service area can't be null",
  i18nKey_2481: "Service Area",
  i18nKey_2482: "server information",
  i18nKey_2483: "Service Vendor",
  i18nKey_2484: "Number of copies",
  i18nKey_2485: "Share",
  i18nKey_2486: "Pink",
  i18nKey_2487: "Minutes can't be null",
  i18nKey_2488: "The minutes can't be less than 2 minutes and can't be greater than 10 minutes, and there can be no spaces!",
  i18nKey_2489: "minute",
  i18nKey_2490: "The proportion of sub-items can't be greater than 1",
  i18nKey_2491: "Percentage of sub-items",
  i18nKey_2492: "Itemized maintenance",
  i18nKey_2493: "Itemized statistics",
  i18nKey_2494: "Accumulated energy consumption by item (kWh)",
  i18nKey_2495: "Itemized meter data",
  i18nKey_2496: "Analysis index:",
  i18nKey_2497: "analysis",
  i18nKey_2498: "Passenger flow",
  i18nKey_2499: "Partition location",
  i18nKey_2500: "Partition",
  i18nKey_2501: "Staging code",
  i18nKey_2502: "Diversion situation",
  i18nKey_2503: "Category can't be null",
  i18nKey_2504: "classification",
  i18nKey_2505: "Minute",
  i18nKey_2506: "Non-public area",
  i18nKey_2507: "Non-working day",
  i18nKey_2508: "Non-Parking Model",
  i18nKey_2509: "Not required",
  i18nKey_2510: "Total discharge",
  i18nKey_2511: "Discharge capacity (kWh)",
  i18nKey_2512: "Visit source",
  i18nKey_2513: "Visitor status",
  i18nKey_2514: "Guest appointment",
  i18nKey_2515: "Visitor invitation information entry display content",
  i18nKey_2516: "Guest invitation",
  i18nKey_2517: "Visitor invitation statistics (nearly 30 days)",
  i18nKey_2518: "The visitor name can't be a special character",
  i18nKey_2519: "Visitor name can't be null",
  i18nKey_2520: "Visitor name",
  i18nKey_2521: "Visitor information can't be special characters",
  i18nKey_2522: "Visitor access",
  i18nKey_2523: "Number of visitors",
  i18nKey_2524: "Guest staff don't have this operation authority",
  i18nKey_2525: "Visitor statistics (by park)",
  i18nKey_2526: "Number of visitors-company ranking",
  i18nKey_2527: "Number of visitors (person)",
  i18nKey_2528: "Number of visitors",
  i18nKey_2529: "Number of visitors",
  i18nKey_2530: "Visitor management",
  i18nKey_2531: "Guest phone can't be null",
  i18nKey_2532: "Visitor phone",
  i18nKey_2533: "Visitor arrival time",
  i18nKey_2534: "Visitor reaching reminder",
  i18nKey_2535: "Reasons for visits",
  i18nKey_2536: "Visitors",
  i18nKey_2537: "Housing information",
  i18nKey_2538: "Property Type",
  i18nKey_2539: "House address",
  i18nKey_2540: "House ID",
  i18nKey_2541: "Room electricity",
  i18nKey_2542: "Vendor / tenant instrumentation",
  i18nKey_2543: "Room location",
  i18nKey_2544: "number of rooms",
  i18nKey_2545: "Room name",
  i18nKey_2546: "room number",
  i18nKey_2547: "real estate",
  i18nKey_2548: "Fire district",
  i18nKey_2549: "Back to login",
  i18nKey_2550: "return",
  i18nKey_2551: "Corporate identity card",
  i18nKey_2552: "Legal representative",
  i18nKey_2553: "Location",
  i18nKey_2554: "Number of occurrences",
  i18nKey_2555: "Time of occurrence",
  i18nKey_2556: "Number of issues (a)",
  i18nKey_2557: "Issue points",
  i18nKey_2558: "issued",
  i18nKey_2559: "QR code has expired",
  i18nKey_2560: "QR code access",
  i18nKey_2561: "QR code refresh time must be between 1 and 1439 minutes",
  i18nKey_2562: "QR code refresh time",
  i18nKey_2563: "QR code",
  i18nKey_2564: "Two on and one cycle on",
  i18nKey_2565: "Second category",
  i18nKey_2566: "Secondary department",
  i18nKey_2567: "Level 2",
  i18nKey_2568: "two",
  i18nKey_2569: "Children's room",
  i18nKey_2570: "Rated capacity (kWh)",
  i18nKey_2571: "Rated power (kW)",
  i18nKey_2572: "Ton",
  i18nKey_2573: "exchange",
  i18nKey_2574: "Object name",
  i18nKey_2575: "The external model can only be Chinese, letters and underscores within 50 characters",
  i18nKey_2576: "External model can't be null",
  i18nKey_2577: "External model",
  i18nKey_2578: "Sorry, you don’t have any permission, please contact the administrator",
  i18nKey_2579: "Circuit breaker configuration",
  i18nKey_2580: "Circuit breaker switching time configuration rules",
  i18nKey_2581: "SMS",
  i18nKey_2582: "The port number",
  i18nKey_2583: "Reading time point",
  i18nKey_2584: "Reading (kWh)",
  i18nKey_2585: "reading",
  i18nKey_2586: "Read plan",
  i18nKey_2587: "There is no retreat behind the fighter, and no danger ahead of courage.",
  i18nKey_2588: "freeze",
  i18nKey_2589: "dynamic password",
  i18nKey_2590: "Power consumption",
  i18nKey_2591: "Animation failed to load",
  i18nKey_2592: "northeast",
  i18nKey_2593: "Order Status",
  i18nKey_2594: "Order payment status",
  i18nKey_2595: "Order time range",
  i18nKey_2596: "order amount",
  i18nKey_2597: "Order number/space name",
  i18nKey_2598: "Order number / meeting room name",
  i18nKey_2599: "Order number",
  i18nKey_2600: "Order revenue",
  i18nKey_2601: "Store sales top5",
  i18nKey_2602: "digital watch",
  i18nKey_2603: "Total number of elevators",
  i18nKey_2604: "Elevator operation record",
  i18nKey_2605: "Elevator information",
  i18nKey_2606: "Elevator pictures",
  i18nKey_2607: "Elevator statistics",
  i18nKey_2608: "Elevator name:",
  i18nKey_2609: "Elevator name",
  i18nKey_2610: "Elevator type:",
  i18nKey_2611: "Elevator type",
  i18nKey_2612: "Elevator address",
  i18nKey_2613: "Elevator ID:",
  i18nKey_2614: "elevator",
  i18nKey_2615: "Total loss of transformer (kWh)",
  i18nKey_2616: "Power loss",
  i18nKey_2617: "Class 1 electrical appliances",
  i18nKey_2618: "Electric energy consumption (kWh)",
  i18nKey_2619: "Cable square number",
  i18nKey_2620: "phone",
  i18nKey_2621: "Accumulated electricity bills",
  i18nKey_2622: "Merchants who owe electricity bills",
  i18nKey_2623: "Undeducted amount of electricity recharge",
  i18nKey_2624: "Accumulated electricity recharge amount",
  i18nKey_2625: "Battery Type",
  i18nKey_2626: "Electric meter warning threshold:",
  i18nKey_2627: "Meter serial number",
  i18nKey_2628: "The meter name can't be null",
  i18nKey_2629: "Meter name",
  i18nKey_2630: "Sub-item maintenance of the electric meter is successful!",
  i18nKey_2631: "Detailed record of the power meter",
  i18nKey_2632: "The meter number or sub-item accounted for can't be null",
  i18nKey_2633: "Meter number",
  i18nKey_2634: "Point type",
  i18nKey_2635: "Click retry",
  i18nKey_2636: "Modification is allowed only when you click Stop, the policy will take effect within half an hour after you click Enable",
  i18nKey_2637: "Click to view",
  i18nKey_2638: "Third-party payment service fee (yuan)",
  i18nKey_2639: "Third party user",
  i18nKey_2640: "Third-party visitors",
  i18nKey_2641: "Third party ID",
  i18nKey_2642: "The first...week",
  i18nKey_2643: "Week $",
  i18nKey_2644: "The opening time of the lighting rule $ in the $ strategy can't be null",
  i18nKey_2645: "The effective time of the $th strategy can't be null",
  i18nKey_2646: "Address longitude and latitude input must be separated by English commas and can't be greater than 20 characters",
  i18nKey_2647: "Address latitude and longitude",
  i18nKey_2648: "Address management",
  i18nKey_2649: "Address doesn't support special characters (Chinese characters, letters and numbers are allowed)",
  i18nKey_2650: "Low voltage warning",
  i18nKey_2651: "Equal",
  i18nKey_2652: "Grade",
  i18nKey_2653: "Check-in time",
  i18nKey_2654: "Light switch",
  i18nKey_2655: "Number of lamps",
  i18nKey_2656: "Light sensor switch",
  i18nKey_2657: "Barrier QR Code Reader",
  i18nKey_2658: "PAD",
  i18nKey_2659: "Arrival situation",
  i18nKey_2660: "Amount to the account",
  i18nKey_2661: "To visitor flow",
  i18nKey_2662: "Import fire zone",
  i18nKey_2663: "Import failed, there is an error in the data in row $, please check and submit the import again later",
  i18nKey_2664: "Export conditions",
  i18nKey_2665: "Export failed, support maximum export data 10000",
  i18nKey_2666: "Export failed, there is no data to export",
  i18nKey_2667: "Exporting failed, there is no access control record on the current page!",
  i18nKey_2668: "Export failed, there is no order record on the current page!",
  i18nKey_2669: "The export failed, there is no travel record on the current page!",
  i18nKey_2670: "Export failed, there is no utility payment record on the current page",
  i18nKey_2671: "Export failed, there is no visitor record on the current page",
  i18nKey_2672: "Export failed",
  i18nKey_2673: "Successfully exported track",
  i18nKey_2674: "Package fee of the month",
  i18nKey_2675: "Income analysis of the month",
  i18nKey_2676: "Energy consumption of the month (kWh)",
  i18nKey_2677: "Public area energy consumption of the month (kWh)",
  i18nKey_2678: "Number of alarms in the month (times)",
  i18nKey_2679: "Current month",
  i18nKey_2680: "That day",
  i18nKey_2681: "New alarm on the day",
  i18nKey_2682: "Energy consumption of the day (kWh)",
  i18nKey_2683: "Temporary suspension fee",
  i18nKey_2684: "Energy consumption in public area on the day (kWh)",
  i18nKey_2685: "Number of check-in on the day",
  i18nKey_2686: "That day",
  i18nKey_2687: "Current state",
  i18nKey_2688: "There is currently no program, please set the program first!",
  i18nKey_2689: "Current operating parameters",
  i18nKey_2690: "Currently moved to",
  i18nKey_2691: "There is no order record on the current page and no export operation is required!",
  i18nKey_2692: "The current page is",
  i18nKey_2693: "There is no room at the current location, please select a room!",
  i18nKey_2694: "No approver is currently added, please add an approver",
  i18nKey_2695: "Current data resources",
  i18nKey_2696: "current time",
  i18nKey_2697: "The current browser doesn't support websocket",
  i18nKey_2698: "Current power",
  i18nKey_2699: "Current service resources",
  i18nKey_2700: "The current import file is checked incorrectly, please modify it locally and import it again!",
  i18nKey_2701: "Current parking space utilization rate",
  i18nKey_2702: "Current field",
  i18nKey_2703: "Current operating resources",
  i18nKey_2704: "Current bottom",
  i18nKey_2705: "The current Menu menu resource",
  i18nKey_2706: "That time",
  i18nKey_2707: "Unit:",
  i18nKey_2708: "Unit",
  i18nKey_2709: "Energy consumption per square meter",
  i18nKey_2710: "Unit: yuan",
  i18nKey_2711: "Unit: person",
  i18nKey_2712: "Unit: Times",
  i18nKey_2713: "Unit: kWh",
  i18nKey_2714: "Unit: hour",
  i18nKey_2715: "Unit: copies",
  i18nKey_2716: "Unit: minutes",
  i18nKey_2717: "Unit price/time",
  i18nKey_2718: "Unit price (yuan/hour)",
  i18nKey_2719: "Single tour time",
  i18nKey_2720: "Maximum length and date of single stay",
  i18nKey_2721: "Single deduction",
  i18nKey_2722: "To be paid",
  i18nKey_2723: "To be received",
  i18nKey_2724: "Standby brightness",
  i18nKey_2725: "Number of pending alarms",
  i18nKey_2726: "To be processed",
  i18nKey_2727: "Pending",
  i18nKey_2728: "To be installed",
  i18nKey_2729: "Represents abnormal",
  i18nKey_2730: "Represents abnormal data",
  i18nKey_2731: "greater than or equal to",
  i18nKey_2732: "More than 400",
  i18nKey_2733: "Greater than 2",
  i18nKey_2734: "more than the",
  i18nKey_2735: "Big business",
  i18nKey_2736: "Big screen position",
  i18nKey_2737: "Big screen name",
  i18nKey_2738: "Big screen playback settings",
  i18nKey_2739: "Dadao, of course, struggle in partnership.",
  i18nKey_2740: "Open alert",
  i18nKey_2741: "Punch card classification",
  i18nKey_2742: "Check in failed",
  i18nKey_2743: "Check in successfully",
  i18nKey_2744: "Top 5 ride companies",
  i18nKey_2745: "From $ to $, the failure occurred repeatedly once, the accumulated time is $ hours, which can cause waste throughout the year",
  i18nKey_2746: "Second bedroom",
  i18nKey_2747: "This operation will permanently delete the file. Do you want to continue?",
  i18nKey_2748: "Creation time",
  i18nKey_2749: "founder",
  i18nKey_2750: "Creation mode selection",
  i18nKey_2751: "Create the space level at least to the building!",
  i18nKey_2752: "Create points",
  i18nKey_2753: "Create room",
  i18nKey_2754: "Create scene",
  i18nKey_2755: "create",
  i18nKey_2756: "Triggering conditions:",
  i18nKey_2757: "Processing quantity",
  i18nKey_2758: "Processing time",
  i18nKey_2759: "Processor",
  i18nKey_2760: "Processing mechanism",
  i18nKey_2761: "Storage Room",
  i18nKey_2762: "Energy storage statistics",
  i18nKey_2763: "Kitchen",
  i18nKey_2764: "Initialization time",
  i18nKey_2765: "There have been $ violations",
  i18nKey_2766: "Entrance and exit busyness",
  i18nKey_2767: "Parking time",
  i18nKey_2768: "Playing time",
  i18nKey_2769: "Date of manufacture",
  i18nKey_2770: "Recharge type",
  i18nKey_2771: "Accumulated recharge amount",
  i18nKey_2772: "Top-up company",
  i18nKey_2773: "Recharge",
  i18nKey_2774: "Total charge",
  i18nKey_2775: "Charging capacity (kWh)",
  i18nKey_2776: "Ride payment status",
  i18nKey_2777: "Ride statistics",
  i18nKey_2778: "Travel time",
  i18nKey_2779: "city",
  i18nKey_2780: "Renting industry",
  i18nKey_2781: "The tenant name can't be null",
  i18nKey_2782: "Name of Lessee",
  i18nKey_2783: "Lessee industry",
  i18nKey_2784: "Lessee",
  i18nKey_2785: "Time for successful refund",
  i18nKey_2786: "Chengdu",
  i18nKey_2787: "Success is not about the strength, but how long it can last.",
  i18nKey_2788: "Owner's name",
  i18nKey_2789: "Owner type",
  i18nKey_2790: "Car owner phone",
  i18nKey_2791: "Total number of parking spaces",
  i18nKey_2792: "Number of parking spaces occupied",
  i18nKey_2793: "Parking space usage statistics",
  i18nKey_2794: "Statistics of parking space utilization rate",
  i18nKey_2795: "Parking space inquiry",
  i18nKey_2796: "License plate index",
  i18nKey_2797: "There are more than 6 options for license plate and color",
  i18nKey_2798: "More than 6 options for license plates and brands have been displayed for you according to the best",
  i18nKey_2799: "Can the license plate number be null",
  i18nKey_2800: "License plate number can't be null",
  i18nKey_2801: "License plate number",
  i18nKey_2802: "The license plate has more than 6 options, which have been displayed for you according to the best",
  i18nKey_2803: "License plate query",
  i18nKey_2804: "There are more than 6 options for license plate, brand and color",
  i18nKey_2805: "Traffic statistics",
  i18nKey_2806: "Vehicle color",
  i18nKey_2807: "Vehicle restriction",
  i18nKey_2808: "Number of vehicle packages and parking fees",
  i18nKey_2809: "Number of vehicle packages (cars)",
  i18nKey_2810: "Vehicle data information",
  i18nKey_2811: "Vehicle territory",
  i18nKey_2812: "Vehicle exit record",
  i18nKey_2813: "Vehicle color",
  i18nKey_2814: "Vehicle certification",
  i18nKey_2815: "Vehicle Brands",
  i18nKey_2816: "Vehicle analysis",
  i18nKey_2817: "Vehicle classification full path",
  i18nKey_2818: "Vehicle arrival reminder",
  i18nKey_2819: "Vehicle label",
  i18nKey_2820: "Vehicle",
  i18nKey_2821: "Train number",
  i18nKey_2822: "Parking lot restrictions",
  i18nKey_2823: "Depot location:",
  i18nKey_2824: "Depot location",
  i18nKey_2825: "Depot name:",
  i18nKey_2826: "Depot name",
  i18nKey_2827: "Parking lot statistics",
  i18nKey_2828: "Parking lot",
  i18nKey_2829: "More than $ times, monthly pass can't be processed",
  i18nKey_2830: "More than $ times, can't enter the parking lot",
  i18nKey_2831: "Exceeding the maximum export limit of 2000",
  i18nKey_2832: "Exceeding 10 maximum export limits",
  i18nKey_2833: "Successful reading",
  i18nKey_2834: "Copy",
  i18nKey_2835: "The meter reading date and meter reading must be filled in at the same time",
  i18nKey_2836: "Meter reading date",
  i18nKey_2837: "Meter reader",
  i18nKey_2838: "It can't be null during meter reading",
  i18nKey_2839: "During meter reading",
  i18nKey_2840: "Copy and record",
  i18nKey_2841: "Copy and show fine",
  i18nKey_2842: "Meter reading import",
  i18nKey_2843: "Number of people in the venue",
  i18nKey_2844: "Scene icon",
  i18nKey_2845: "Scene lock switch:",
  i18nKey_2846: "Number of scenes",
  i18nKey_2847: "Scene name",
  i18nKey_2848: "Scene ID",
  i18nKey_2849: "The vendor name can't be null",
  i18nKey_2850: "Trade Names",
  i18nKey_2851: "factory",
  i18nKey_2852: "Resident staff",
  i18nKey_2853: "conventional",
  i18nKey_2854: "product name",
  i18nKey_2855: "product",
  i18nKey_2856: "Remove alarm",
  i18nKey_2857: "Please enter your full name or mobile phone number for registered users",
  i18nKey_2858: "Query the number of ineffective interface data is wrong, please try again later",
  i18nKey_2859: "Please limit the query time range to 90 days",
  i18nKey_2860: "Query list failed",
  i18nKey_2861: "Check the discounted price",
  i18nKey_2862: "View the warning threshold",
  i18nKey_2863: "View limited time discounts",
  i18nKey_2864: "view image",
  i18nKey_2865: "View video",
  i18nKey_2866: "View camera",
  i18nKey_2867: "View device",
  i18nKey_2868: "View track",
  i18nKey_2869: "Check the bus schedule",
  i18nKey_2870: "View",
  i18nKey_2871: "Policy name can't be null",
  i18nKey_2872: "The policy name can't contain special characters",
  i18nKey_2873: "The policy name can't exceed 20 characters",
  i18nKey_2874: "Strategy name",
  i18nKey_2875: "measure temperature",
  i18nKey_2876: "measure time",
  i18nKey_2877: "WC",
  i18nKey_2878: "operator",
  i18nKey_2879: "Operation failed, please try again later",
  i18nKey_2880: "operation failed",
  i18nKey_2881: "Operation suggestions",
  i18nKey_2882: "restaurant",
  i18nKey_2883: "parameter",
  i18nKey_2884: "Reference data period",
  i18nKey_2885: "Top 5 Dishes Sales",
  i18nKey_2886: "Acquisition frequency",
  i18nKey_2887: "department:",
  i18nKey_2888: "Control status",
  i18nKey_2889: "The deployment time can't be null",
  i18nKey_2890: "The deployment time must be greater than or equal to the current time",
  i18nKey_2891: "Control period",
  i18nKey_2892: "Control camera",
  i18nKey_2893: "Control matching",
  i18nKey_2894: "Deployment control",
  i18nKey_2895: "Not repeating",
  i18nKey_2896: "Special characters are not supported, only Chinese and English numbers are supported",
  i18nKey_2897: "Unlimited time",
  i18nKey_2898: "Unlimited",
  i18nKey_2899: "Uncertain alert",
  i18nKey_2900: "The level can't be changed, the current level is",
  i18nKey_2901: "Can't export null data",
  i18nKey_2902: "Can't export more than 10,000 data",
  i18nKey_2903: "Unmovable",
  i18nKey_2904: "Can't add children",
  i18nKey_2905: "Can't be deleted",
  i18nKey_2906: "Not editable",
  i18nKey_2907: "not equal to",
  i18nKey_2908: "Supplementary recording period",
  i18nKey_2909: "Supplementary reading",
  i18nKey_2910: "BoYu Health Check-in_",
  i18nKey_2911: "Berth",
  i18nKey_2912: "Play settings:",
  i18nKey_2913: "Play window replacement mode",
  i18nKey_2914: "Play",
  i18nKey_2915: "Villa",
  i18nKey_2916: "Form template.xlsx",
  i18nKey_2917: "Bottom",
  i18nKey_2918: "Tag filtering",
  i18nKey_2919: "Transformer capacity (kVA)",
  i18nKey_2920: "Change time",
  i18nKey_2921: "Edit warning threshold",
  i18nKey_2922: "Edit",
  i18nKey_2923: "Numbering",
  i18nKey_2924: "Must be an integer",
  i18nKey_2925: "Must be a number or two significant decimals!",
  i18nKey_2926: "This week",
  i18nKey_2927: "Actual electricity consumption this month (KWh)",
  i18nKey_2928: "Cumulative energy consumption this month",
  i18nKey_2929: "Overview of passenger flow this month",
  i18nKey_2930: "Meeting room reservation this month",
  i18nKey_2931: "this month",
  i18nKey_2932: "Energy for this item",
  i18nKey_2933: "Water consumption in this period (t)",
  i18nKey_2934: "Energy consumption in this period",
  i18nKey_2935: "Current electricity consumption (kWh)",
  i18nKey_2936: "Current electricity consumption",
  i18nKey_2937: "Not copied/copied in this period (a)",
  i18nKey_2938: "Current time",
  i18nKey_2939: "Energy consumption per square meter in this period",
  i18nKey_2940: "This period",
  i18nKey_2941: "This year",
  i18nKey_2942: "Energy consumption at this level",
  i18nKey_2943: "Local temperature",
  i18nKey_2944: "Local humidity",
  i18nKey_2945: "Upload locally, upload photos no more than 3M",
  i18nKey_2946: "Local server",
  i18nKey_2947: "local",
  i18nKey_2948: "There are still $ tables that have not been entered this time. Do you want to continue to take effect?",
  i18nKey_2949: "The meter reading time can't be null",
  i18nKey_2950: "Date of this meter reading",
  i18nKey_2951: "The reading consumption of this meter reading is much larger than that of the last reading. Are you confirming the modification?",
  i18nKey_2952: "The reading of this meter reading can't be null",
  i18nKey_2953: "This meter reading",
  i18nKey_2954: "Total number of interviewed companies",
  i18nKey_2955: "Magnification",
  i18nKey_2956: "The remark name can only be Chinese, letters and underscores within 20 characters",
  i18nKey_2957: "Remark name",
  i18nKey_2958: "Spare (a)",
  i18nKey_2959: "Beijing",
  i18nKey_2960: "Repair type",
  i18nKey_2961: "Repair work order",
  i18nKey_2962: "Call the police",
  i18nKey_2963: "Scrap date",
  i18nKey_2964: "scrapped",
  i18nKey_2965: "Report",
  i18nKey_2966: "Warranty date",
  i18nKey_2967: "Cleaning",
  i18nKey_2968: "Half-day unit price (yuan/4 hours)",
  i18nKey_2969: "Office space order details",
  i18nKey_2970: "Office Phone",
  i18nKey_2971: "Shift number",
  i18nKey_2972: "Shuttle bus",
  i18nKey_2973: "Reason for the visit:",
  i18nKey_2974: "white",
  i18nKey_2975: "whitelist",
  i18nKey_2976: "Bus order report_",
  i18nKey_2977: "Bus ride record report",
  i18nKey_2978: "Bus schedule",
  i18nKey_2979: "Weekly",
  i18nKey_2980: "Monthly",
  i18nKey_2981: "By similarity",
  i18nKey_2982: "By time",
  i18nKey_2983: "On time",
  i18nKey_2984: "By day",
  i18nKey_2985: "Button resources",
  i18nKey_2986: "By year",
  i18nKey_2987: "View by item",
  i18nKey_2988: "installing",
  i18nKey_2989: "Installation location",
  i18nKey_2990: "Installation time",
  i18nKey_2991: "Installer",
  i18nKey_2992: "Number of RFID assets installed",
  i18nKey_2993: "Security Incident Trend",
  i18nKey_2994: "Security situation",
  i18nKey_2995: "Proportion of types of security incidents",
  i18nKey_2996: "Security personnel",
  i18nKey_2997: "VCM type",
  i18nKey_2998: "URL type",
  i18nKey_2999: "RFID usage rate (by type)",
  i18nKey_3000: "RFID usage rate",
  i18nKey_3001: "RFID number",
  i18nKey_3002: "PID number",
  i18nKey_3003: "Menu resources",
  i18nKey_3004: "MENU",
  i18nKey_3005: "MAC address",
  i18nKey_3006: "KPI upper limit",
  i18nKey_3007: "IT code and business code can only be a combination of letters, numbers and underscores!",
  i18nKey_3008: "IT coding",
  i18nKey_3009: "IOC Class 1 Equipment Classification Name",
  i18nKey_3010: "IOC Class I Equipment Classification Code",
  i18nKey_3011: "IOC Class III Equipment Classification Name",
  i18nKey_3012: "IOC Class III Equipment Classification Code",
  i18nKey_3013: "IOC spatial model coding",
  i18nKey_3014: "IOC secondary equipment classification name",
  i18nKey_3015: "IOC secondary equipment classification code",
  i18nKey_3016: "IOC (Intelligence Operation Center or Intelligent Operation Center) service agreement",
  i18nKey_3017: "GIS coordinate type",
  i18nKey_3018: "GIS coordinates",
  i18nKey_3019: "GIS synchronization",
  i18nKey_3020: "GIS synchronization",
  i18nKey_3021: "Echarts chart",
  i18nKey_3022: "C-phase current (A)",
  i18nKey_3023: "Phase B voltage (V)",
  i18nKey_3024: "Banner map",
  i18nKey_3025: "A phase current (A)",
  i18nKey_3026: "AI diagnosis processing",
  i18nKey_3027: "9 months",
  i18nKey_3028: "A combination of at least 2 of 8-20 letters, numbers and special characters",
  i18nKey_3029: "8-20 digits, letters, some special characters",
  i18nKey_3030: "6 months discount",
  i18nKey_3031: "6 months",
  i18nKey_3032: "3 months discount",
  i18nKey_3033: "3 months",
  i18nKey_3034: "1 year and above",
  i18nKey_3035: "0 point reading (kWh)",
  i18nKey_3036: "0 point reading (kWh)",
  i18nKey_3037: "Privacy Policy",
  i18nKey_3038: "Service Agreement",
  i18nKey_3039: "*Only supports png, gif, jpg format pictures and not larger than 3M",
  i18nKey_3040: "(Total energy consumption kWh)",
  i18nKey_3041: "(Automatically converted price)",
  i18nKey_3042: "(yuan)",
  i18nKey_3043: "(Quantity)",
  i18nKey_3044: "(Example: P85)",
  i18nKey_3045: "(Proportion of power loss)",
  i18nKey_3046: "(Required)",
  i18nKey_3047: "In the entire project, you have found a total of",
  i18nKey_3048: "$year actual value",
  i18nKey_3049: "$year target value",
  i18nKey_3050: "$ Loops failed to open",
  i18nKey_3051: "$ Loops opened successfully",
  i18nKey_3052: "January",
  i18nKey_3053: "May",
  i18nKey_3054: "April",
  i18nKey_3055: "October",
  i18nKey_3056: "November",
  i18nKey_3057: "December",
  i18nKey_3058: "March",
  i18nKey_3059: "Please enter the legal GIS coordinates, such as (20.17N, 134.53W)",
  i18nKey_3060: "July",
  i18nKey_3061: "June",
  i18nKey_3062: "September",
  i18nKey_3063: "February",
  i18nKey_3064: "August",
  i18nKey_3065: "GIS coordinates already exist, please re-enter",
  i18nKey_web_1: "Example",
  i18nKey_web_2: "Select file",
  i18nKey_web_3: "Upload all files",
  i18nKey_web_4: "Click on the file to get the download path",
  i18nKey_web_5: "Satellite effect",
  i18nKey_web_6: "City effect",
  i18nKey_web_7: "simulation",
  i18nKey_web_8: "Event generator",
  i18nKey_web_9: "Time:",
  i18nKey_web_10: "Event title:",
  i18nKey_web_11: "Event description:",
  i18nKey_web_12: "Event address:",
  i18nKey_web_13: "Alarm equipment:",
  i18nKey_web_14: "Event source:",
  i18nKey_web_15: "Visitor system",
  i18nKey_web_16: "Access control",
  i18nKey_web_17: "Fire fighting",
  i18nKey_web_18: "Surroundings",
  i18nKey_web_19: "Event level:",
  i18nKey_web_20: "Important warning",
  i18nKey_web_21: "Event type:",
  i18nKey_web_22: "Fire incident",
  i18nKey_web_23: "Security incident",
  i18nKey_web_24: "Generate event",
  i18nKey_web_25: "Vanke Technology Company",
  i18nKey_web_26: "all rights reserved",
  i18nKey_web_27: "Technical support phone: 000-00000000",
  i18nKey_web_28: "",
  i18nKey_web_29: "current position",
  i18nKey_web_30: "assets",
  i18nKey_web_31: "information",
  i18nKey_web_32: "Personnel modification cancel",
  i18nKey_web_33: "Video loading",
  i18nKey_web_34: "please wait",
  i18nKey_web_35: "Video buffering",
  i18nKey_web_36: "take",
  i18nKey_web_37: "Eliminate",
  i18nKey_web_38: "confirm",
  i18nKey_web_39: "Note: Suitable for table pages without nested components on the list page",
  i18nKey_web_40: "Need to pass in the class name",
  i18nKey_web_41: "To find what you need",
  i18nKey_web_42: "element",
  i18nKey_web_43: "original",
  i18nKey_web_44: "Need to set initial value properties",
  i18nKey_web_45: "Need to set the outermost label",
  i18nKey_web_46: "Misjudgment",
  i18nKey_web_47: "Report",
  i18nKey_web_48: "time",
  i18nKey_web_49: "location",
  i18nKey_web_50: "A certain building in Vanke Chengdu Park",
  i18nKey_web_51: "Video patrol manual report",
  i18nKey_web_52: "level",
  i18nKey_web_53: "Red emergency warning",
  i18nKey_web_54: "Orange emergency warning",
  i18nKey_web_55: "Yellow emergency warning",
  i18nKey_web_56: "Blue emergency warning",
  i18nKey_web_57: "description",
  i18nKey_web_58: "Particularly serious accident",
  i18nKey_web_59: "Major accident",
  i18nKey_web_60: "Major accident",
  i18nKey_web_61: "General accident",
  i18nKey_web_62: "Cannot be null, please enter content.",
  i18nKey_web_63: "Please enter your name or job number",
  i18nKey_web_64: "No data",
  i18nKey_web_65: "To modify",
  i18nKey_web_66: "task",
  i18nKey_web_67: "Accumulated energy consumption by item",
  i18nKey_web_68: "Select camera",
  i18nKey_web_69: "Security box",
  i18nKey_web_70: "unit:",
  i18nKey_web_71: "Unit: piece",
  i18nKey_web_72: "Unit: person-time",
  i18nKey_web_73: "The related data is abnormal and cannot be checked",
  i18nKey_web_74: "see details",
  i18nKey_web_75: "North Gate",
  i18nKey_web_76: "East gate",
  i18nKey_web_77: "Property security",
  i18nKey_web_78: "Single pie chart statistics",
  i18nKey_web_79: "minimize",
  i18nKey_web_80: "maximize",
  i18nKey_web_81: "The model is not mounted in the parking lot, please contact the admin to mount the model!",
  i18nKey_web_82: "Last day",
  i18nKey_web_83: "Select associated device",
  i18nKey_web_84: "device ID",
  i18nKey_web_85: "Public area energy consumption",
  i18nKey_web_86: "the day after",
  i18nKey_web_87: "last year",
  i18nKey_web_88: "Total power consumption",
  i18nKey_web_89: "Public area power consumption",
  i18nKey_web_90: "Public area",
  i18nKey_web_91: "Percentage",
  i18nKey_web_92: "Compare",
  i18nKey_web_93: "The same period",
  i18nKey_web_94: "unit",
  i18nKey_web_95: "Delete picture",
  i18nKey_web_96: "Tip: only support",
  i18nKey_web_97: "Upload pictures in a format, the size of the uploaded picture does not exceed 3",
  i18nKey_web_98: "size of picture",
  i18nKey_web_99: "Picture type",
  i18nKey_web_100: "Build time",
  i18nKey_web_101: "Upload image cannot be greater than 3",
  i18nKey_web_102: "Please upload again",
  i18nKey_web_103: "Quantity",
  i18nKey_web_104: "Cumulative total charge",
  i18nKey_web_105: "Cumulative total discharge",
  i18nKey_web_106: "Please enter the device name",
  i18nKey_web_107: "Selected sub-items",
  i18nKey_web_108: "Monitoring interval",
  i18nKey_web_109: "Please enter the minimum",
  i18nKey_web_110: "Please enter the maximum",
  i18nKey_web_111: "Successfully saved!",
  i18nKey_web_112: "Complete",
  i18nKey_web_113: "Select the associated monitor",
  i18nKey_web_114: "Select associated smoke sensor",
  i18nKey_web_115: "business type",
  i18nKey_web_116: "phone number",
  i18nKey_web_117: "Please enter your phone number",
  i18nKey_web_118: "Add",
  i18nKey_web_119: "The strategy name is composed of less than 20 characters, connecting lines, and underscores",
  i18nKey_web_120: "Monitoring period",
  i18nKey_web_121: "human traffic",
  i18nKey_web_122: "Smoking monitoring",
  i18nKey_web_123: "Monitoring threshold",
  i18nKey_web_124: "associated monitor",
  i18nKey_web_125: "Please select a monitor",
  i18nKey_web_126: "Number of people",
  i18nKey_web_127: "The number of people can only enter a positive integer greater than 0 and less than or equal to 99999",
  i18nKey_web_128: "Smoke concentration alarm threshold",
  i18nKey_web_129: "Associated smoke",
  i18nKey_web_130: "Please select smoke sensor",
  i18nKey_web_131: "Please enter the alarm threshold",
  i18nKey_web_132: "Threshold can only enter a positive integer greater than 0 and less than or equal to 5000",
  i18nKey_web_133: "This is a hint",
  i18nKey_web_134: "Tips",
  i18nKey_web_135: "concentration",
  i18nKey_web_136: "Moderate smoke",
  i18nKey_web_137: "cigarette",
  i18nKey_web_138: "Download image",
  i18nKey_web_139: "View alarm",
  i18nKey_web_140: "No eligible data temporarily",
  i18nKey_web_141: "Please enter a description",
  i18nKey_web_142: "Manual upload of video patrol",
  i18nKey_web_143: "Work order dispatch",
  i18nKey_web_144: "Select personnel",
  i18nKey_web_145: "Dispatch work orders",
  i18nKey_web_146: "Enter name or job number",
  i18nKey_web_147: "Alarm name",
  i18nKey_web_148: "Alarm status",
  i18nKey_web_149: "Warning level",
  i18nKey_web_150: "Alarm time",
  i18nKey_web_151: "Responsible",
  i18nKey_web_152: "Plan start time",
  i18nKey_web_153: "Please select the planned start time",
  i18nKey_web_154: "Plan end time",
  i18nKey_web_155: "Please select the end time of the plan",
  i18nKey_web_156: "Alarm location",
  i18nKey_web_157: "Previous",
  i18nKey_web_158: "Dispatch task",
  i18nKey_web_159: "Must choose a person",
  i18nKey_web_160: "New ticket",
  i18nKey_web_161: "Work order type",
  i18nKey_web_162: "Task details description",
  i18nKey_web_163: "Please enter a detailed description of the task",
  i18nKey_web_164: "Associated equipment",
  i18nKey_web_165: "Please select associated device",
  i18nKey_web_166: "Task level",
  i18nKey_web_167: "Please select task level",
  i18nKey_web_168: "Please choose the person responsible",
  i18nKey_web_169: "Please select the occurrence time ",
  i18nKey_web_170: "Please select a work order delegate",
  i18nKey_web_171: "Site contact",
  i18nKey_web_172: "Please select the on-site contact",
  i18nKey_web_173: "Created successfully",
  i18nKey_web_174: "Fill in the form manually",
  i18nKey_web_175: "The task detail description cannot be null",
  i18nKey_web_176: "Length in",
  i18nKey_web_177: "To",
  i18nKey_web_178: "Characters",
  i18nKey_web_179: "Related facilities",
  i18nKey_web_180: "Failed to create a new ticket",
  i18nKey_web_181: "Work order",
  i18nKey_web_182: "Work order dispatch completed",
  i18nKey_web_183: "no data",
  i18nKey_web_184: "This device has no superior device",
  i18nKey_web_185: "Please choose a location",
  i18nKey_web_186: "Please enter the work order delegate",
  i18nKey_web_187: "Please enter the site contact",
  i18nKey_web_188: "Option 1",
  i18nKey_web_189: "Golden cake",
  i18nKey_web_190: "Option 2",
  i18nKey_web_191: "Stewed Milk Beancurd",
  i18nKey_web_192: "Option 3",
  i18nKey_web_193: "Oyster omelet ",
  i18nKey_web_194: "Option 4",
  i18nKey_web_195: "Fine noodles",
  i18nKey_web_196: "Actual start time",
  i18nKey_web_197: "Actual end time",
  i18nKey_web_198: "Work order processing time",
  i18nKey_web_199: "Details of inspectors",
  i18nKey_web_200: "Position",
  i18nKey_web_201: "On-job situation",
  i18nKey_web_202: "Total number of work orders",
  i18nKey_web_203: "Processing",
  i18nKey_web_204: "Event dynamics",
  i18nKey_web_205: "Notify personnel, evacuate building personnel",
  i18nKey_web_206: "Dial 119, 120",
  i18nKey_web_207: "Get in touch with the emergency team and start deploying evacuation",
  i18nKey_web_208: "An alarm occurred on the smoke detector on the first floor of Building 1, Chengdu Park",
  i18nKey_web_209: "Local Upload",
  i18nKey_web_210: "Select from the staff library",
  i18nKey_web_211: "Upload to server",
  i18nKey_web_212: "personnel",
  i18nKey_web_213: "Gao Fei",
  i18nKey_web_214: "Gao Zhengwei",
  i18nKey_web_215: "Gao Linfei",
  i18nKey_web_216: "Li Chuangye",
  i18nKey_web_217: "Yao Yun",
  i18nKey_web_218: "avatar",
  i18nKey_web_219: "label",
  i18nKey_web_220: "Enter name or number",
  i18nKey_web_221: "Smoke",
  i18nKey_web_222: "Back to more layers",
  i18nKey_web_223: "code",
  i18nKey_web_224: "return to Background",
  i18nKey_web_225: "Chengdu Vanke Park-1st building 1 F",
  i18nKey_web_226: "District air conditioning fresh air unit",
  i18nKey_web_227: "level",
  i18nKey_web_228: "Cooling System",
  i18nKey_web_229: "Area",
  i18nKey_web_230: "Chengdu Vanke Park",
  i18nKey_web_231: "Owning system",
  i18nKey_web_232: "HVAC system",
  i18nKey_web_233: "Lin Sheng",
  i18nKey_web_234: "Subclass",
  i18nKey_web_235: "Subclass placeholder",
  i18nKey_web_236: "Facility description placeholder",
  i18nKey_web_237: "New task",
  i18nKey_web_238: "mission details",
  i18nKey_web_239: "Mine",
  i18nKey_web_240: "Unlink",
  i18nKey_web_241: "Connected",
  i18nKey_web_242: "Event-linked camera",
  i18nKey_web_243: "Surrounding camera",
  i18nKey_web_244: "Associated camera",
  i18nKey_web_245: "Processing log",
  i18nKey_web_246: "Notify employees and evacuate building personnel",
  i18nKey_web_247: "Notify employees and evacuate building personnel details",
  i18nKey_web_248: "founder:",
  i18nKey_web_249: "Big",
  i18nKey_web_250: "small:",
  i18nKey_web_251: "Incident time",
  i18nKey_web_252: "Location of the incident",
  i18nKey_web_253: "Received",
  i18nKey_web_254: "Reassign",
  i18nKey_web_255: "Task closing time",
  i18nKey_web_256: "Task assignment time",
  i18nKey_web_257: "Task assignee",
  i18nKey_web_258: "Live feedback",
  i18nKey_web_259: "Task notes",
  i18nKey_web_260: "task list",
  i18nKey_web_261: "see more",
  i18nKey_web_262: "Comprehensive Situation-National",
  i18nKey_web_263: "Statistics cards",
  i18nKey_web_264: "Comprehensive situation",
  i18nKey_web_265: "Security incident statistics",
  i18nKey_web_266: "Comprehensive situation display",
  i18nKey_web_267: "Please select no less than",
  i18nKey_web_268: "Please enter the meter name",
  i18nKey_web_269: "Electric meter",
  i18nKey_web_270: "Water meter",
  i18nKey_web_271: "Please enter the meter name",
  i18nKey_web_272: "Energy use today",
  i18nKey_web_273: "Month-on-month",
  i18nKey_web_274: "Year-on-year",
  i18nKey_web_275: "Tenant electricity",
  i18nKey_web_276: "Electricity for lighting",
  i18nKey_web_277: "Total daily energy consumption",
  i18nKey_web_278: "Enter event number",
  i18nKey_web_279: "Confirm Password",
  i18nKey_web_280: "password",
  i18nKey_web_281: "Please select the dispatcher",
  i18nKey_web_282: "The planned start time cannot be null",
  i18nKey_web_283: "The planned end time cannot be null",
  i18nKey_web_284: "Network loading abnormal",
  i18nKey_web_285: "mobile phone number",
  i18nKey_web_286: "mailbox",
  i18nKey_web_287: "age",
  i18nKey_web_288: "industry",
  i18nKey_web_289: "Welcome",
  i18nKey_web_290: "Welcome screen",
  i18nKey_web_291: "The control reason cannot be null",
  i18nKey_web_292: "Please select the pusher",
  i18nKey_web_293: "Please select the deployment control welcome screen",
  i18nKey_web_294: "Personnel details",
  i18nKey_web_295: "image",
  i18nKey_web_296: "Personnel location",
  i18nKey_web_297: "Vehicle Information",
  i18nKey_web_298: "message notification",
  i18nKey_web_299: "Jump to track view",
  i18nKey_web_300: "Park location",
  i18nKey_web_301: "Portrait label",
  i18nKey_web_302: "Delete basemap",
  i18nKey_web_303: "Times",
  i18nKey_web_304: "mark",
  i18nKey_web_305: "Similarity:",
  i18nKey_web_306: "Only display high similarity faces filtered by the system",
  i18nKey_web_307: "Information Sources:",
  i18nKey_web_308: "No suspect information",
  i18nKey_web_309: "This operation will completely delete the face map",
  i18nKey_web_310: "Enter the park blacklist to deploy control",
  i18nKey_web_311: "Park blacklist deployment control",
  i18nKey_web_312: "Personnel portrait",
  i18nKey_web_313: "Face information",
  i18nKey_web_314: "Suspect information",
  i18nKey_web_315: "Cancel deployment",
  i18nKey_web_316: "Cancel the park blacklist deployment control",
  i18nKey_web_317: "Interface status is abnormal",
  i18nKey_web_318: "Person has at least one mark",
  i18nKey_web_319: "fail to edit",
  i18nKey_web_320: "Modification failed, the interface status is abnormal",
  i18nKey_web_321: "Failed to replace the basemap",
  i18nKey_web_322: "This person enters the blacklist",
  i18nKey_web_323: "This person cancels the blacklist control",
  i18nKey_web_324: "operation failed",
  i18nKey_web_325: "Operation failed, the interface status exception",
  i18nKey_web_326: "Tracking period",
  i18nKey_web_327: "to",
  i18nKey_web_328: "Tracking status",
  i18nKey_web_329: "Please select tracking status",
  i18nKey_web_330: "Tracking camera",
  i18nKey_web_331: "Please select a tracking camera",
  i18nKey_web_332: "Data deleted successfully",
  i18nKey_web_333: "Successful operation",
  i18nKey_web_334: "Network loading abnormal, please try again later",
  i18nKey_web_335: "No warning",
  i18nKey_web_336: "identification number",
  i18nKey_web_337: "Information Sources",
  i18nKey_web_338: "Robert",
  i18nKey_web_339: "Get up at three in the morning",
  i18nKey_web_340: "",
  i18nKey_web_341: "Tomorrow 11:30 meeting report",
  i18nKey_web_342: "Tomorrow 14:30 meeting report",
  i18nKey_web_343: "Not supported by current browser",
  i18nKey_web_344: "Reminder window reached",
  i18nKey_web_345: "Reminder status",
  i18nKey_web_346: "Arrival reminder camera",
  i18nKey_web_347: "Welcome time",
  i18nKey_web_348: "Welcome state",
  i18nKey_web_349: "Please select welcome status",
  i18nKey_web_350: "Deploy control welcome screen",
  i18nKey_web_351: "Enter the welcome screen",
  i18nKey_web_352: "Enter the name of the welcome screen",
  i18nKey_web_353: "Choose welcome screen",
  i18nKey_web_354: "Vehicle information modification",
  i18nKey_web_355: "Car owner",
  i18nKey_web_356: "Characters input up to 300",
  i18nKey_web_357: "Cancel this modification request",
  i18nKey_web_358: "Suspension record details",
  i18nKey_web_359: "Left",
  i18nKey_web_360: "Parking",
  i18nKey_web_361: "Parking time",
  i18nKey_web_362: "Parking information",
  i18nKey_web_363: "Parking space",
  i18nKey_web_364: "Play record",
  i18nKey_web_365: "Check-in record",
  i18nKey_web_366: "Record details",
  i18nKey_web_367: "Camera name",
  i18nKey_web_368: "Check-in person",
  i18nKey_web_369: "Check-in time",
  i18nKey_web_370: "Not clocked in",
  i18nKey_web_371: "XX patrol group",
  i18nKey_web_372: "play time",
  i18nKey_web_373: "View now",
  i18nKey_web_374: "To be executed",
  i18nKey_web_375: "Sticking to fundamental principles in operation",
  i18nKey_web_376: "Leave the comfort zone",
  i18nKey_web_377: "Is the first step forward",
  i18nKey_web_378: "Cross thousands miles of mountains",
  i18nKey_web_379: "Overcome thousands of difficulties.",
  i18nKey_web_380: "There is no retreat behind the fighter",
  i18nKey_web_381: "There is no danger ahead of courage.",
  i18nKey_web_382: "Unity",
  i18nKey_web_383: "Do your best",
  i18nKey_web_384: "It's not about the strength",
  i18nKey_web_385: "It depends on how long it can last.",
  i18nKey_web_386: "To find a way to succeed",
  i18nKey_web_387: "No reason for failure.",
  i18nKey_web_388: "A long way to go",
  i18nKey_web_389: "Aspirational.",
  i18nKey_web_390: "Only unsharped axe",
  i18nKey_web_391: "No firewood that cannot be split.",
  i18nKey_web_392: "Latest reporting time",
  i18nKey_web_393: "Asset Name",
  i18nKey_web_394: "Nowadays",
  i18nKey_web_395: "when",
  i18nKey_web_396: "on",
  i18nKey_web_397: "No more data",
  i18nKey_web_398: "Week 1",
  i18nKey_web_399: "Electricity",
  i18nKey_web_400: "Building energy statistics",
  i18nKey_web_401: "Energy consumption statistics of commercial tenants",
  i18nKey_web_402: "The same time",
  i18nKey_web_403: "Total power consumption",
  i18nKey_web_404: "Data does not exist",
  i18nKey_web_405: "Creation date",
  i18nKey_web_406: "Remove",
  i18nKey_web_407: "The network load is abnormal, please try again later!",
  i18nKey_web_408: "Cancel success!",
  i18nKey_web_409: "Conference room code",
  i18nKey_web_410: "Please enter the conference room code",
  i18nKey_web_411: "Successful operation!",
  i18nKey_web_412: "Network load exception, please try again later!",
  i18nKey_web_413: "successfully deleted",
  i18nKey_web_414: "Select date",
  i18nKey_web_415: "Please select a date",
  i18nKey_web_416: "Meeting room list",
  i18nKey_web_417: "Sunday",
  i18nKey_web_418: "Reservation person",
  i18nKey_web_419: "Open the door",
  i18nKey_web_420: "close the door",
  i18nKey_web_421: "Unlock time",
  i18nKey_web_422: "Operate failed, no door opening record on the current page!",
  i18nKey_web_423: "Configuration",
  i18nKey_web_424: "date",
  i18nKey_web_425: "No data to export yet!",
  i18nKey_web_426: "BoYu Health Check-in",
  i18nKey_web_427: "Report the police on the epidemic",
  i18nKey_web_428: "Business daily clock in",
  i18nKey_web_429: "Confirm to delete? It cannot be restored after deletion!",
  i18nKey_web_430: "Please enter the person name ",
  i18nKey_web_431: "Please enter a contact number in the correct format",
  i18nKey_web_432: "Modify team members",
  i18nKey_web_433: "Only support CH, EN and numbers",
  i18nKey_web_434: "Please select facility level",
  i18nKey_web_435: "Facility classification",
  i18nKey_web_436: "Please enter the person in charge of management",
  i18nKey_web_437: "Please enter facility description",
  i18nKey_web_438: "device status",
  i18nKey_web_439: "Automatic fire alarm and linkage system",
  i18nKey_web_440: "Fire alarm host",
  i18nKey_web_441: "Smoke detector",
  i18nKey_web_442: "Thermal fire detector",
  i18nKey_web_443: "manual alarm button",
  i18nKey_web_444: "Upper limit",
  i18nKey_web_445: "availability",
  i18nKey_web_446: "failure rate",
  i18nKey_web_447: "Total alarms",
  i18nKey_web_448: "Distribute",
  i18nKey_web_449: "Successfully distributed!",
  i18nKey_web_450: "Barrier",
  i18nKey_web_451: "Network Error",
  i18nKey_web_452: "Camera 001",
  i18nKey_web_453: "start up",
  i18nKey_web_454: "No related events",
  i18nKey_web_455: "Misjudgment success",
  i18nKey_web_456: "Characters up to 1000",
  i18nKey_web_457: "Pending, cannot be reported temporarily",
  i18nKey_web_458: "Processing, cannot be reported temporarily",
  i18nKey_web_459: "The event status is pending and cannot be reported temporarily",
  i18nKey_web_460: "Starting face tracking",
  i18nKey_web_461: "New face deployment control",
  i18nKey_web_462: "New library",
  i18nKey_web_463: "Delete library",
  i18nKey_web_464: "More",
  i18nKey_web_465: "Search by name",
  i18nKey_web_466: "select all",
  i18nKey_web_467: "Select the node when you click the node",
  i18nKey_web_468: "Expand all nodes by default",
  i18nKey_web_469: "Only supports",
  i18nKey_web_470: "Format picture and no more than 3",
  i18nKey_web_471: "Please type in your name",
  i18nKey_web_472: "List type",
  i18nKey_web_473: "Please enter identification number",
  i18nKey_web_474: "Male",
  i18nKey_web_475: "Female",
  i18nKey_web_476: "Please enter residential address",
  i18nKey_web_477: "Expand all sections by default",
  i18nKey_web_478: "Please select list type",
  i18nKey_web_479: "Picture only supports",
  i18nKey_web_480: "Picture cannot be larger than 3",
  i18nKey_web_481: "Public security blacklist import template",
  i18nKey_web_482: "table",
  i18nKey_web_483: "Failed to delete",
  i18nKey_web_484: "Please select the object to be deleted",
  i18nKey_web_485: "confirm to delete",
  i18nKey_web_486: "Please upload a picture",
  i18nKey_web_487: "Face tracking is closing",
  i18nKey_web_488: "personnel:",
  i18nKey_web_489: "Monitoring screenshot",
  i18nKey_web_490: "Tracking time",
  i18nKey_web_491: "Number of warnings",
  i18nKey_web_492: "Shanghai area",
  i18nKey_web_493: "Shanghai",
  i18nKey_web_494: "Jinqiao Wanchuang",
  i18nKey_web_495: "floor",
  i18nKey_web_496: "B2",
  i18nKey_web_497: "parking lot",
  i18nKey_web_498: "Please enter a description of the patrol group",
  i18nKey_web_499: "Closing time",
  i18nKey_web_500: "Total number of events",
  i18nKey_web_501: "Number of pending events",
  i18nKey_web_502: "Total number of tasks",
  i18nKey_web_503: "Number of pending tasks",
  i18nKey_web_504: "Safety",
  i18nKey_web_505: "Always processed",
  i18nKey_web_506: "Total security incident",
  i18nKey_web_507: "Forward",
  i18nKey_web_508: "Riding company",
  i18nKey_web_509: "Company booking",
  i18nKey_web_510: "frequency",
  i18nKey_web_511: "Store sales",
  i18nKey_web_512: "Dishes sales",
  i18nKey_web_513: "Abnormal facilities",
  i18nKey_web_514: "Hang",
  i18nKey_web_515: "Upload time",
  i18nKey_web_516: "event",
  i18nKey_web_517: "The task has generated an alarm",
  i18nKey_web_518: "Can not be modified!",
  i18nKey_web_519: "The task is starting",
  i18nKey_web_520: "Please modify after pause!",
  i18nKey_web_521: "confirm to delete?",
  i18nKey_web_522: "Start and stop time",
  i18nKey_web_523: "Patrol time",
  i18nKey_web_524: "problem",
  i18nKey_web_525: "Yes",
  i18nKey_web_526: "No",
  i18nKey_web_527: "Closed",
  i18nKey_web_528: "Patrol route history",
  i18nKey_web_529: "All patrol history",
  i18nKey_web_530: "Alert",
  i18nKey_web_531: "Event name",
  i18nKey_web_532: "efficiency",
  i18nKey_web_533: "Vanke Wisdom Exhibition Area",
  i18nKey_web_534: "Major event alert",
  i18nKey_web_535: "Park",
  i18nKey_web_536: "Tianfu Yuncheng",
  i18nKey_web_537: "The project site is located in Jinqiao Wanchuang Center",
  i18nKey_web_538: "Underground garage, configure three sets, 250",
  i18nKey_web_539: "Energy storage system, outlets are located at 10",
  i18nKey_web_540: "Under A line 2",
  i18nKey_web_541: "Transformer (1000",
  i18nKey_web_542: "Bus side, 10",
  i18nKey_web_543: "B line 5",
  i18nKey_web_544: "Bus side and 6",
  i18nKey_web_545: "Transformer (2000",
  i18nKey_web_546: "Bus side",
  i18nKey_web_547: "Total charge and discharge report",
  i18nKey_web_548: "Charge",
  i18nKey_web_549: "Discharge",
  i18nKey_web_550: "Prompt content",
  i18nKey_web_551: "Box size",
  i18nKey_web_552: "Inch",
  i18nKey_web_553: "Number of subsystems",
  i18nKey_web_554: "user type",
  i18nKey_web_555: "Rated Capacity",
  i18nKey_web_556: "rated power",
  i18nKey_web_557: "Transformer capacity",
  i18nKey_web_558: "Access voltage level",
  i18nKey_web_559: "Total charge",
  i18nKey_web_560: "Total discharge",
  i18nKey_web_561: "Confirm download",
  i18nKey_web_562: "Report?",
  i18nKey_web_563: "Meter",
  i18nKey_web_564: "Point reading",
  i18nKey_web_565: "energy used",
  i18nKey_web_566: "Tenant electricity consumption",
  i18nKey_web_567: "Energy consumption changes",
  i18nKey_web_568: "Actual energy consumption value",
  i18nKey_web_569: "Target energy consumption value",
  i18nKey_web_570: "Forecast energy consumption value",
  i18nKey_web_571: "Predicted value: 1",
  i18nKey_web_572: "In the daily dimension,  the next-level sub-items of public area of total energy consumption, public area energy consumption and. In the monthly dimension, the next-level sub-items of total energy consumption, public area energy consumption, and public area",
  i18nKey_web_573: "Target value: 1",
  i18nKey_web_574: "In the monthly dimension, total energy consumption and public area energy consumption. 2",
  i18nKey_web_575: "In the annual dimension, total energy consumption and public area energy consumption",
  i18nKey_web_576: "Please enter the target value",
  i18nKey_web_577: "please input the value",
  i18nKey_web_578: "Annual actual value",
  i18nKey_web_579: "month",
  i18nKey_web_580: "Annual target value",
  i18nKey_web_581: "Public area energy consumption target",
  i18nKey_web_582: "Public area energy consumption",
  i18nKey_web_583: "Energy consumption per square meter",
  i18nKey_web_584: "Itemized energy",
  i18nKey_web_585: "Energy consumption alarm",
  i18nKey_web_586: "Quotient",
  i18nKey_web_587: "Tenant ranking of energy consumption per square meter",
  i18nKey_web_588: "Hydropower",
  i18nKey_web_589: "Energy consumption of the day",
  i18nKey_web_590: "Energy consumption of the month",
  i18nKey_web_591: "Energy consumption in the public area of the day",
  i18nKey_web_592: "Public area energy consumption of the month",
  i18nKey_web_593: "Total number of people",
  i18nKey_web_594: "Today's total energy consumption value",
  i18nKey_web_595: "Tenant ranking of energy consumption per m2",
  i18nKey_web_596: "Overview",
  i18nKey_web_597: "Park overview",
  i18nKey_web_598: "Please choose the time",
  i18nKey_web_599: "Same period last year:",
  i18nKey_web_600: "Last month:",
  i18nKey_web_601: "Compared with the previous year:",
  i18nKey_web_602: "Compared with last month:",
  i18nKey_web_603: "Last month",
  i18nKey_web_604: "Energy consumption change graph",
  i18nKey_web_605: "Suggest",
  i18nKey_web_606: "Instrument code",
  i18nKey_web_607: "Please enter the meter code",
  i18nKey_web_608: "Please enter the smart meter code",
  i18nKey_web_609: "Please enter the type",
  i18nKey_web_610: "username",
  i18nKey_web_611: "Park card payment",
  i18nKey_web_612: "WeChat Pay",
  i18nKey_web_613: "Electricity bill",
  i18nKey_web_614: "Water fee",
  i18nKey_web_615: "Merchant type",
  i18nKey_web_616: "Instrument type",
  i18nKey_web_617: "Business Name",
  i18nKey_web_618: "Last update time",
  i18nKey_web_619: "Recharge amount",
  i18nKey_web_620: "Recharge time",
  i18nKey_web_621: "six",
  i18nKey_web_622: "Individual merchants",
  i18nKey_web_623: "Export successfully",
  i18nKey_web_624: "Undeducted amount of recharge",
  i18nKey_web_625: "Undeducted amount of bills issued",
  i18nKey_web_626: "Electricity bill not deducted",
  i18nKey_web_627: "Water bill not deducted",
  i18nKey_web_628: "Overview of merchants in arrears",
  i18nKey_web_629: "Actual power consumption",
  i18nKey_web_630: "Total electricity consumption on the bill is",
  i18nKey_web_631: "Actual water consumption",
  i18nKey_web_632: "Total water consumption on the bill is",
  i18nKey_web_633: "Water consumption",
  i18nKey_web_634: "Details",
  i18nKey_web_635: "Contract Number",
  i18nKey_web_636: "Please select contract number",
  i18nKey_web_637: "Please enter the unit",
  i18nKey_web_638: "Threshold amount",
  i18nKey_web_639: "Please enter the threshold amount",
  i18nKey_web_640: "Contract period",
  i18nKey_web_641: "Electric meter threshold amount",
  i18nKey_web_642: "Water meter threshold amount",
  i18nKey_web_643: "log in",
  i18nKey_web_644: "registered",
  i18nKey_web_645: "Verification code",
  i18nKey_web_646: "Please enter the phone number",
  i18nKey_web_647: "Or Smart Operation Center",
  i18nKey_web_648: "Service Agreement",
  i18nKey_web_649: "Please select a meeting room name",
  i18nKey_web_650: "Please enter a certified company",
  i18nKey_web_651: "Please select a certified company",
  i18nKey_web_652: "Please select a booking date",
  i18nKey_web_653: "Booking time",
  i18nKey_web_654: "Please enter the order amount",
  i18nKey_web_655: "Please enter the actual amount",
  i18nKey_web_656: "Please enter most 2",
  i18nKey_web_657: "One Chinese character counts as two characters.",
  i18nKey_web_658: "Please select the scheduled end time",
  i18nKey_web_659: "The old password cannot be the same as the new password",
  i18nKey_web_660: "Scheduled date",
  i18nKey_web_661: "Payment status",
  i18nKey_web_662: "Payment time",
  i18nKey_web_663: "Turn down",
  i18nKey_web_664: "Pass",
  i18nKey_web_665: "WeChat",
  i18nKey_web_666: "Paying",
  i18nKey_web_667: "Cancel payment",
  i18nKey_web_668: "Payment successful",
  i18nKey_web_669: "Name",
  i18nKey_web_670: "Please enter a name",
  i18nKey_web_671: "Name is required",
  i18nKey_web_672: "Category",
  i18nKey_web_673: "Please select category",
  i18nKey_web_674: "Tenant selection",
  i18nKey_web_675: "Please enter a business",
  i18nKey_web_676: "Tenant name",
  i18nKey_web_677: "This period",
  i18nKey_web_678: "Electricity consumption",
  i18nKey_web_679: "The same period of the previous month",
  i18nKey_web_680: "The same period of the previous year",
  i18nKey_web_681: "Tenant energy consumption statistics",
  i18nKey_web_682: "Selected business",
  i18nKey_web_683: "Patrol plan",
  i18nKey_web_684: "Turn off video patrol",
  i18nKey_web_685: "Whether to pause video patrol",
  i18nKey_web_686: "Whether to stop video patrol",
  i18nKey_web_687: "No camera data in the park",
  i18nKey_web_688: "Untreated",
  i18nKey_web_689: "Pending",
  i18nKey_web_690: "Data, remaining",
  i18nKey_web_691: "Errors",
  i18nKey_web_692: "Reward type",
  i18nKey_web_693: "Success",
  i18nKey_web_694: "Failed",
  i18nKey_web_695: "Reward object",
  i18nKey_web_696: "Reward time",
  i18nKey_web_697: "Award person name",
  i18nKey_web_698: "The list is null and cannot be exported",
  i18nKey_web_699: "Credit name",
  i18nKey_web_700: "Please enter the point name",
  i18nKey_web_701: "Please select the point name",
  i18nKey_web_702: "Only positive integers can be entered",
  i18nKey_web_703: "Successfully issued",
  i18nKey_web_704: "Point type name",
  i18nKey_web_705: "Issue type",
  i18nKey_web_706: "Please select the creation time range",
  i18nKey_web_707: "expenditure",
  i18nKey_web_708: "Income side",
  i18nKey_web_709: "Please enter expenditure",
  i18nKey_web_710: "Transaction Type",
  i18nKey_web_711: "income",
  i18nKey_web_712: "integral",
  i18nKey_web_713: "transaction hour",
  i18nKey_web_714: "Please select reward time",
  i18nKey_web_715: "Transaction number",
  i18nKey_web_716: "Payer",
  i18nKey_web_717: "Number of transaction points",
  i18nKey_web_718: "trading status",
  i18nKey_web_719: "Successful transaction",
  i18nKey_web_720: "transaction failed",
  i18nKey_web_721: "Please enter income",
  i18nKey_web_722: "Please select points",
  i18nKey_web_723: "Transaction details",
  i18nKey_web_724: "To start",
  i18nKey_web_725: "Booking time",
  i18nKey_web_726: "Please choose a booking time",
  i18nKey_web_727: "Please select the station type",
  i18nKey_web_728: "User phone number",
  i18nKey_web_729: "Shared activity room",
  i18nKey_web_730: "Refunding",
  i18nKey_web_731: "Applying",
  i18nKey_web_732: "Shared office order report",
  i18nKey_web_733: "Please enter the space name",
  i18nKey_web_734: "Please select space type",
  i18nKey_web_735: "the company",
  i18nKey_web_736: "Please enter company",
  i18nKey_web_737: "Way of access",
  i18nKey_web_738: "Transit time",
  i18nKey_web_739: "Please select transit time",
  i18nKey_web_740: "Swipe",
  i18nKey_web_741: "Pass record",
  i18nKey_web_742: "Select camera",
  i18nKey_web_743: "Choose time period",
  i18nKey_web_744: "Zhongkonan",
  i18nKey_web_745: "Huawei",
  i18nKey_web_746: "All",
  i18nKey_web_747: "Query staff label is abnormal",
  i18nKey_web_748: "Please select floor first",
  i18nKey_web_749: "Please select a person tag",
  i18nKey_web_750: "Please select a camera",
  i18nKey_web_751: "Query time",
  i18nKey_web_752: "total people:",
  i18nKey_web_753: "Customer group analysis",
  i18nKey_web_754: "List of people",
  i18nKey_web_755: "registered user",
  i18nKey_web_756: "Visitor statistics",
  i18nKey_web_757: "By park",
  i18nKey_web_758: "Total number of people in the park",
  i18nKey_web_759: "Total visitors",
  i18nKey_web_760: "total people",
  i18nKey_web_761: "Sub-selection",
  i18nKey_web_762: "Itemized electricity consumption analysis",
  i18nKey_web_763: "Please enter the serial number",
  i18nKey_web_764: "Third party",
  i18nKey_web_765: "Please enter the third party",
  i18nKey_web_766: "Morgan Platform Meeting Room",
  i18nKey_web_767: "Please enter the Morgan platform meeting room",
  i18nKey_web_768: "Please select data source",
  i18nKey_web_769: "Please enter the most 1",
  i18nKey_web_770: "One Chinese character counts as two characters",
  i18nKey_web_771: "And there can be no spaces!",
  i18nKey_web_772: "Analyze energy consumption statistics",
  i18nKey_web_773: "Sub-item energy consumption statistics",
  i18nKey_web_774: "Please select the item",
  i18nKey_web_775: "Scenes",
  i18nKey_web_776: "Please enter the scene",
  i18nKey_web_777: "Policy setting",
  i18nKey_web_778: "Please select a policy setting",
  i18nKey_web_779: "The minute cannot be less than 2 minutes and cannot be greater than 10 minutes",
  i18nKey_web_780: "Data source type",
  i18nKey_web_781: "Scenario strategy",
  i18nKey_web_782: "Vanzhihui",
  i18nKey_web_783: "Vanke Production Office",
  i18nKey_web_784: "Successfully activated",
  i18nKey_web_785: "Deactivated successfully",
  i18nKey_web_786: "Please select meeting status",
  i18nKey_web_787: "Before the meeting: start",
  i18nKey_web_788: "After the meeting started: not started",
  i18nKey_web_789: "After the meeting starts: start",
  i18nKey_web_790: "Last execution time",
  i18nKey_web_791: "Scheduled business",
  i18nKey_web_792: "After the meeting",
  i18nKey_web_793: "New conference room scenario strategy",
  i18nKey_web_794: "Modify the meeting room scenario strategy",
  i18nKey_web_795: "Please enter the license plate number",
  i18nKey_web_796: "parking space",
  i18nKey_web_797: "Please enter a parking space",
  i18nKey_web_798: "No picture",
  i18nKey_web_799: "Vehicle management",
  i18nKey_web_800: "Vehicle query",
  i18nKey_web_801: "The difference between start and end time can‘t be more than 30 days",
  i18nKey_web_802: "Entry and exit records",
  i18nKey_web_803: "Vehicle parking statistics",
  i18nKey_web_804: "Purchase package record",
  i18nKey_web_805: "Query type",
  i18nKey_web_806: "number plate",
  i18nKey_web_807: "Parking number",
  i18nKey_web_808: "Please enter the license plate number",
  i18nKey_web_809: "Please enter the parking lot number",
  i18nKey_web_810: "Press down",
  i18nKey_web_811: "Build",
  i18nKey_web_812: "Vehicle entry record",
  i18nKey_web_813: "Total parking spaces",
  i18nKey_web_814: "Number of vehicle group",
  i18nKey_web_815: "Parking fee",
  i18nKey_web_816: "Within hours",
  i18nKey_web_817: "Drive out record",
  i18nKey_web_818: "Drive-in record",
  i18nKey_web_819: "No parking lot",
  i18nKey_web_820: "Departure time",
  i18nKey_web_821: "ticket",
  i18nKey_web_822: "unit price",
  i18nKey_web_823: "The bus schedule has been activated, please suspend the schedule before operate.",
  i18nKey_web_824: "Are you sure to delete this bus schedule? It cannot be restored after deletion!",
  i18nKey_web_825: "price",
  i18nKey_web_826: "fold",
  i18nKey_web_827: "Effective date",
  i18nKey_web_828: "Please enter the business name",
  i18nKey_web_829: "Please select order time range",
  i18nKey_web_830: "Payment amount",
  i18nKey_web_831: "Bus order report",
  i18nKey_web_832: "Billion",
  i18nKey_web_833: "Ten million",
  i18nKey_web_834: "million",
  i18nKey_web_835: "You haven't installed the video player yet. Do you want to download the video player plug-in now? After downloading, please install it yourself",
  i18nKey_web_836: "No results returned",
  i18nKey_web_837: "no result",
  i18nKey_web_838: "Level 7",
  i18nKey_web_839: "Level 8",
  i18nKey_web_840: "Level 9",
  i18nKey_web_841: "Level 10",
  i18nKey_web_842: "Level 11",
  i18nKey_web_843: "Level 12",
  i18nKey_web_844: "Facility data import",
  i18nKey_web_845: "Equipment name",
  i18nKey_web_846: "Source system code",
  i18nKey_web_847: "Source system description",
  i18nKey_web_848: "Source system device code",
  i18nKey_web_849: "Class 1 equipment name",
  i18nKey_web_850: "Class 1 equipment classification code",
  i18nKey_web_851: "Class name of secondary equipment",
  i18nKey_web_852: "Secondary equipment classification code",
  i18nKey_web_853: "Class III equipment category name",
  i18nKey_web_854: "Three-level equipment classification code",
  i18nKey_web_855: "Spatial model coding",
  i18nKey_web_856: "File size cannot exceed 20",
  i18nKey_web_857: "Upload successfully",
  i18nKey_web_858: "The batch number is",
  i18nKey_web_859: "Please sync to the main table later",
  i18nKey_web_860: "upload failed!",
  i18nKey_web_861: "Please upload a new version",
  i18nKey_web_862: "file",
  i18nKey_web_863: "Export failed!",
  i18nKey_web_864: "The batch number for synchronization is",
  i18nKey_web_865: "Confirm synchronization?",
  i18nKey_web_866: "Confirm to synchronize all facility data?",
  i18nKey_web_867: "Synchronization failed, there is no facility data to synchronize temporarily!",
  i18nKey_web_868: "Synchronization succeeded!",
  i18nKey_web_869: "Sync failed!",
  i18nKey_web_870: "Coordinate type",
  i18nKey_web_871: "coordinate",
  i18nKey_web_872: "Please select the person responsible for management",
  i18nKey_web_873: "The attachment only supports the new version",
  i18nKey_web_874: "format",
  i18nKey_web_875: "Not enabled",
  i18nKey_web_876: "address",
  i18nKey_web_877: "equipment number",
  i18nKey_web_878: "no data",
  i18nKey_web_879: "confirm delete",
  i18nKey_web_880: "Picture size cannot exceed 3",
  i18nKey_web_881: "Can only upload",
  i18nKey_web_882: "Format picture",
  i18nKey_web_883: "The classification must be selected to the third level",
  i18nKey_web_884: "Please enter legal",
  i18nKey_web_885: "Address, such as 00-01-6",
  i18nKey_web_886: "Or 00",
  i18nKey_web_887: "Coordinates, such as",
  i18nKey_web_888: "Coordinates already exist, please re-enter",
  i18nKey_web_889: "The length of the facility alias cannot exceed 16 characters",
  i18nKey_web_890: "The length of the facility name cannot exceed 64 characters",
  i18nKey_web_891: "The source system equipment number can only consist of 50 characters in Chinese and English, numbers and",
  i18nKey_web_892: "composition",
  i18nKey_web_893: "The external model can only be Chinese and English, numbers and underscores within 50 characters",
  i18nKey_web_894: "The integer part of longitude is -180 to 180",
  i18nKey_web_895: "The integer part of the latitude is -90 to 90",
  i18nKey_web_896: "The facility description can only be written in Chinese and English, numbers and",
  i18nKey_web_897: "Manufacturers can only use Chinese and English, numbers and",
  i18nKey_web_898: "Service providers can only use Chinese and English, numbers and",
  i18nKey_web_899: "Maintenance providers can only use Chinese and English, numbers and",
  i18nKey_web_900: "Brands can only be written in Chinese and English, numbers and",
  i18nKey_web_901: "The supported format is",
  i18nKey_web_902: "and",
  i18nKey_web_903: "Facility classification level",
  i18nKey_web_904: "Add level",
  i18nKey_web_905: "Facility importance",
  i18nKey_web_906: "The facility classification code can only consist of numbers, letters, and underscores",
  i18nKey_web_907: "Please enter the level to be added",
  i18nKey_web_908: "Please fill in the data completely, you cannot add data across levels",
  i18nKey_web_909: "Please enter the corresponding category code or category name",
  i18nKey_web_910: "Category code and category name cannot be empty",
  i18nKey_web_911: "View enterprise details",
  i18nKey_web_912: "Please enter the park code",
  i18nKey_web_913: "Microsoft Accor black",
  i18nKey_web_914: "Creation method",
  i18nKey_web_915: "Manual entry",
  i18nKey_web_916: "Batch Import",
  i18nKey_web_917: "Please enter the company's English name",
  i18nKey_web_918: "Business license registration number",
  i18nKey_web_919: "Unified social credit code",
  i18nKey_web_920: "Drag the file here, or",
  i18nKey_web_921: "Click upload",
  i18nKey_web_922: "File only supports",
  i18nKey_web_923: "Please enter the ID number in the correct format",
  i18nKey_web_924: "Maximum length is 50 characters",
  i18nKey_web_925: "New business",
  i18nKey_web_926: "Modify enterprise",
  i18nKey_web_927: "File, and the suffix is",
  i18nKey_web_928: "Total",
  i18nKey_web_929: "Bar, success",
  i18nKey_web_930: "Article, error",
  i18nKey_web_931: "Big screen connected",
  i18nKey_web_932: "Large screen can be associated",
  i18nKey_web_933: "Unlinked successfully",
  i18nKey_web_934: "Choose category",
  i18nKey_web_935: "Eight categories",
  i18nKey_web_936: "Nine categories",
  i18nKey_web_937: "Please choose a category",
  i18nKey_web_938: "Please choose the second category",
  i18nKey_web_939: "Please choose three categories",
  i18nKey_web_940: "Please choose four categories",
  i18nKey_web_941: "Please select five categories",
  i18nKey_web_942: "Please choose six categories",
  i18nKey_web_943: "Select location",
  i18nKey_web_944: "Failed to query group list",
  i18nKey_web_945: "Building",
  i18nKey_web_946: "The park cannot be empty",
  i18nKey_web_947: "Floor cannot be empty",
  i18nKey_web_948: "file name",
  i18nKey_web_949: "Facility creation method",
  i18nKey_web_950: "Please enter the port number",
  i18nKey_web_951: "Please input Username",
  i18nKey_web_952: "The address cannot be empty!",
  i18nKey_web_953: "The address format is wrong!",
  i18nKey_web_954: "The port number cannot be empty!",
  i18nKey_web_955: "The port number format is wrong!",
  i18nKey_web_956: "Please enter the circle name",
  i18nKey_web_957: "Please enter the circle code",
  i18nKey_web_958: "Configuration is successful",
  i18nKey_web_959: "Choose a campus",
  i18nKey_web_960: "Select subsystem",
  i18nKey_web_961: "Make an appointment to visit",
  i18nKey_web_962: "Please select the park subsystem",
  i18nKey_web_963: "Please select a person to make an appointment",
  i18nKey_web_964: "Please select the estimated time of departure",
  i18nKey_web_965: "Please select an appointment status",
  i18nKey_web_966: "Please select the visit time",
  i18nKey_web_967: "Please select the reason for the visit",
  i18nKey_web_968: "Select department",
  i18nKey_web_969: "Group name",
  i18nKey_web_970: "Please enter the group name",
  i18nKey_web_971: "Group description",
  i18nKey_web_972: "Can enter 100 characters",
  i18nKey_web_973: "Add group",
  i18nKey_web_974: "Group name cannot be empty",
  i18nKey_web_975: "Group name already exists",
  i18nKey_web_976: "The group description cannot be empty!",
  i18nKey_web_977: "Modify group information",
  i18nKey_web_978: "Choose hometown",
  i18nKey_web_979: "province",
  i18nKey_web_980: "Please select province",
  i18nKey_web_981: "Group information",
  i18nKey_web_982: "User Info",
  i18nKey_web_983: "User Department",
  i18nKey_web_984: "User role",
  i18nKey_web_985: "User group",
  i18nKey_web_986: "Modify user information",
  i18nKey_web_987: "500 characters can be input",
  i18nKey_web_988: "Add user",
  i18nKey_web_989: "load more",
  i18nKey_web_990: "Please select the user to add!",
  i18nKey_web_991: "No more",
  i18nKey_web_992: "Choose vehicle brand",
  i18nKey_web_993: "Select vehicle",
  i18nKey_web_994: "user",
  i18nKey_web_995: "Group",
  i18nKey_web_996: "No users",
  i18nKey_web_997: "No groups",
  i18nKey_web_998: "View group list",
  i18nKey_web_999: "please enter user name",
  i18nKey_web_1000: "Phone number search",
  i18nKey_web_1001: "Add group",
  i18nKey_web_1002: "Group list",
  i18nKey_web_1003: "Are you sure to delete?",
  i18nKey_web_1004: "No role list",
  i18nKey_web_1005: "Please select the user to be deleted",
  i18nKey_web_1006: "Please select the group to delete",
  i18nKey_web_1007: "Please select the user to be added",
  i18nKey_web_1008: "Please select the group to add",
  i18nKey_web_1009: "No more data",
  i18nKey_web_1010: "Select classification path",
  i18nKey_web_1011: "Basic vehicle data information",
  i18nKey_web_1012: "Vehicle classification code",
  i18nKey_web_1013: "License plate",
  i18nKey_web_1014: "Driving license number",
  i18nKey_web_1015: "Vehicle classification name",
  i18nKey_web_1016: "Vehicle parent classification code",
  i18nKey_web_1017: "Owner code",
  i18nKey_web_1018: "Label effective time",
  i18nKey_web_1019: "Label expiration time",
  i18nKey_web_1020: "Valid logo",
  i18nKey_web_1021: "Last updated by",
  i18nKey_web_1022: "Please enter the license plate",
  i18nKey_web_1023: "Please select a license plate",
  i18nKey_web_1024: "Please enter vehicle color",
  i18nKey_web_1025: "Please enter the driving license number",
  i18nKey_web_1026: "Please enter the full path of the vehicle classification",
  i18nKey_web_1027: "Please enter the vehicle category name",
  i18nKey_web_1028: "Please enter the person code",
  i18nKey_web_1029: "Please enter the unit",
  i18nKey_web_1030: "invalid",
  i18nKey_web_1031: "The license plate number cannot be empty!",
  i18nKey_web_1032: "The vehicle color cannot be empty!",
  i18nKey_web_1033: "The full path of vehicle classification cannot be empty!",
  i18nKey_web_1034: "Vehicle category name cannot be empty!",
  i18nKey_web_1035: "Owner name cannot be empty!",
  i18nKey_web_1036: "Company cannot be empty!",
  i18nKey_web_1037: "The vehicle label cannot be empty!",
  i18nKey_web_1038: "The effective time cannot be empty!",
  i18nKey_web_1039: "The expiration time cannot be empty!",
  i18nKey_web_1040: "The valid ID cannot be empty!",
  i18nKey_web_1041: "Please enter the correct license plate!",
  i18nKey_web_1042: "The driving license number or personnel code cannot contain Chinese!",
  i18nKey_web_1043: "blue",
  i18nKey_web_1044: "The planned end time should be greater than the planned start time",
  i18nKey_web_1045: "Class facilities",
  i18nKey_web_1046: "start date",
  i18nKey_web_1047: "End date",
  i18nKey_web_1048: "Facility maintenance records",
  i18nKey_web_1049: "Historical operation record",
  i18nKey_web_1050: "Facility alarm record",
  i18nKey_web_1051: "Maintenance start and end time",
  i18nKey_web_1052: "Maintenance department",
  i18nKey_web_1053: "Wang Xiaohu",
  i18nKey_web_1054: "Jinshajiang Road, Putuo District, Shanghai",
  i18nKey_web_1055: "do",
  i18nKey_web_1056: "All right",
  i18nKey_web_1057: "Engineering Department",
  i18nKey_web_1058: "The query time cannot be empty! ! !",
  i18nKey_web_1059: "I choose the  later one",
  i18nKey_web_1060: "Major:",
  i18nKey_web_1061: "Ordinary:",
  i18nKey_web_1062: "Enter event title or device alias",
  i18nKey_web_1063: "Send SMS 2",
  i18nKey_web_1064: "Start method",
  i18nKey_web_1065: "Manual",
  i18nKey_web_1066: "Automatic",
  i18nKey_web_1067: "temperature setting",
  i18nKey_web_1068: "Preset temperature",
  i18nKey_web_1069: "Return air temperature",
  i18nKey_web_1070: "Filter pressure difference",
  i18nKey_web_1071: "Water valve control",
  i18nKey_web_1072: "Power monitoring",
  i18nKey_web_1073: "Fan commands",
  i18nKey_web_1074: "Alert level",
  i18nKey_web_1075: "Alarm operation",
  i18nKey_web_1076: "Equipment Service Provider",
  i18nKey_web_1077: "Device Description",
  i18nKey_web_1078: "Equipment Brand",
  i18nKey_web_1079: "equipment manufactory",
  i18nKey_web_1080: "Equipment Maintenance Service",
  i18nKey_web_1081: "Facilities start time",
  i18nKey_web_1082: "Facility downtime",
  i18nKey_web_1083: "This run time",
  i18nKey_web_1084: "Maintenance date",
  i18nKey_web_1085: "Maintenance Responsible Person",
  i18nKey_web_1086: "Reasons for maintenance",
  i18nKey_web_1087: "Maintenance content",
  i18nKey_web_1088: "Maintenance results",
  i18nKey_web_1089: "Facility alarm record",
  i18nKey_web_1090: "Facility maintenance records",
  i18nKey_web_1091: "Switch setting",
  i18nKey_web_1092: "Turn on the lights",
  i18nKey_web_1093: "Turn off the lights",
  i18nKey_web_1094: "Application",
  i18nKey_web_1095: "Successful instruction, status update in about 30 seconds, please wait",
  i18nKey_web_1096: "Dimming",
  i18nKey_web_1097: "Tip: The brightness will only take effect when the light is on",
  i18nKey_web_1098: "Dimming success",
  i18nKey_web_1099: "important events",
  i18nKey_web_1100: "General event",
  i18nKey_web_1101: "Track now",
  i18nKey_web_1102: "Access control system",
  i18nKey_web_1103: "Video image",
  i18nKey_web_1104: "Associated picture",
  i18nKey_web_1105: "Upload",
  i18nKey_web_1106: "Associated video",
  i18nKey_web_1107: "Association control",
  i18nKey_web_1108: "Add deployment control",
  i18nKey_web_1109: "Out of control",
  i18nKey_web_1110: "Control Picture",
  i18nKey_web_1111: "Source System",
  i18nKey_web_1112: "disperse the crowd",
  i18nKey_web_1113: "To be accepted",
  i18nKey_web_1114: "Back to the background of the associated camera",
  i18nKey_web_1115: "Back to the background of the surrounding cameras",
  i18nKey_web_1116: "Add an associated camera back to the background",
  i18nKey_web_1117: "Delete the associated camera background return",
  i18nKey_web_1118: "Tip: After the distribute task is processed, the event will be closed automatically.",
  i18nKey_web_1119: "Distribution task",
  i18nKey_web_1120: "New task completed",
  i18nKey_web_1121: "Task distribution",
  i18nKey_web_1122: "Delegate tasks",
  i18nKey_web_1123: "distance",
  i18nKey_web_1124: "Please select the person to assign the task",
  i18nKey_web_1125: "please enter keyword to search",
  i18nKey_web_1126: "Please enter the task content",
  i18nKey_web_1127: "Successfully added task",
  i18nKey_web_1128: "Internet celebrity fraud",
  i18nKey_web_1129: "Hyun Bin",
  i18nKey_web_1130: "Camera 002",
  i18nKey_web_1131: "Camera 003",
  i18nKey_web_1132: "Camera 004",
  i18nKey_web_1133: "Camera 005",
  i18nKey_web_1134: "Automatically report events",
  i18nKey_web_1135: "model",
  i18nKey_web_1136: "Zhang San",
  i18nKey_web_1137: "Northern region",
  i18nKey_web_1138: "Southern region",
  i18nKey_web_1139: "Midwest region",
  i18nKey_web_1140: "Welcome screen selected",
  i18nKey_web_1141: "Selected camera",
  i18nKey_web_1142: "Number of online cameras",
  i18nKey_web_1143: "Total number of cameras",
  i18nKey_web_1144: "title",
  i18nKey_web_1145: "Event title must be entered",
  i18nKey_web_1146: "Please enter event description",
  i18nKey_web_1147: "Vanke Chengdu Park",
  i18nKey_web_1148: "Building 103",
  i18nKey_web_1149: "Incident reporting failed",
  i18nKey_web_1150: "Picture has been saved to",
  i18nKey_web_1151: "Image capture failed",
  i18nKey_web_1152: "error code:",
  i18nKey_web_1153: "Number of patrol groups",
  i18nKey_web_1154: "New patrol group",
  i18nKey_web_1155: "Video patrol list",
  i18nKey_web_1156: "Video patrol history",
  i18nKey_web_1157: "End",
  i18nKey_web_1158: "Equipment category",
  i18nKey_web_1159: "Event handler",
  i18nKey_web_1160: "User card number",
  i18nKey_web_1161: "Handler's cell phone",
  i18nKey_web_1162: "solution",
  i18nKey_web_1163: "user account",
  i18nKey_web_1164: "Treatment type",
  i18nKey_web_1165: "Handler fixed phone",
  i18nKey_web_1166: "Control type",
  i18nKey_web_1167: "Event notes",
  i18nKey_web_1168: "Only upload up to 5 pictures",
  i18nKey_web_1169: "Picture size cannot exceed 2",
  i18nKey_web_1170: "Format picture",
  i18nKey_web_1171: "The task name cannot exceed 50 characters",
  i18nKey_web_1172: "Face tracking",
  i18nKey_web_1173: "Vehicle trajectory",
  i18nKey_web_1174: "Reason for deployment",
  i18nKey_web_1175: "Please enter the reason for deployment, not more than 150 characters",
  i18nKey_web_1176: "Name:",
  i18nKey_web_1177: "Time period",
  i18nKey_web_1178: "Similarity",
  i18nKey_web_1179: "Figure",
  i18nKey_web_1180: "Whether to push the pop-up window",
  i18nKey_web_1181: "Please enter the property announcement",
  i18nKey_web_1182: "Notify staff",
  i18nKey_web_1183: "first name",
  i18nKey_web_1184: "Please enter your name or phone number",
  i18nKey_web_1185: "The reason for deployment cannot exceed 150 characters",
  i18nKey_web_1186: "Select from the vehicle library",
  i18nKey_web_1187: "Vehicle library selection",
  i18nKey_web_1188: "View warning",
  i18nKey_web_1189: "Location:",
  i18nKey_web_1190: "Source:",
  i18nKey_web_1191: "Time program starts and stops automatically (0 stop",
  i18nKey_web_1192: "Start up",
  i18nKey_web_1193: "Air conditioning system control mode (1 start 2 stop 3 schedule control)",
  i18nKey_web_1194: "Exceed target value",
  i18nKey_web_1195: "Outdoor",
  i18nKey_web_1196: "Humidity",
  i18nKey_web_1197: "Environmental space",
  i18nKey_web_1198: "Total number of alarms",
  i18nKey_web_1199: "Carbon dioxide",
  i18nKey_web_1200: "Formaldehyde",
  i18nKey_web_1201: "Frame",
  i18nKey_web_1202: "Negative oxygen ions",
  i18nKey_web_1203: "Noise",
  i18nKey_web_1204: "Organic volatiles",
  i18nKey_web_1205: "Environmental Index",
  i18nKey_web_1206: "Street lamp statistics",
  i18nKey_web_1207: "Task progress",
  i18nKey_web_1208: "Environmental space title",
  i18nKey_web_1209: "Not set",
  i18nKey_web_1210: "Idle",
  i18nKey_web_1211: "Work order description",
  i18nKey_web_1212: "Please enter a work order description",
  i18nKey_web_1213: "Dispatch work order completed",
  i18nKey_web_1214: "Please select the person to assign the ticket",
  i18nKey_web_1215: "Please enter the ticket content",
  i18nKey_web_1216: "Mr. Li",
  i18nKey_web_1217: "Repair user",
  i18nKey_web_1218: "Home time",
  i18nKey_web_1219: "Distributed",
  i18nKey_web_1220: "Can enter up to 100 characters",
  i18nKey_web_1221: "Responsible person of work order:",
  i18nKey_web_1222: "Processing fee:",
  i18nKey_web_1223: "Processing time:",
  i18nKey_web_1224: "Processing progress:",
  i18nKey_web_1225: "Repair name",
  i18nKey_web_1226: "Repair status",
  i18nKey_web_1227: "Repair level",
  i18nKey_web_1228: "Repair time",
  i18nKey_web_1229: "Repair location",
  i18nKey_web_1230: "Total",
  i18nKey_web_1231: "Concurrent energy consumption",
  i18nKey_web_1232: "Energy consumption per square meter",
  i18nKey_web_1233: "Disable",
  i18nKey_web_1234: "All day",
  i18nKey_web_1235: "Cup",
  i18nKey_web_1236: "Unit: cup",
  i18nKey_web_1237: "today",
  i18nKey_web_1238: "area",
  i18nKey_web_1239: "Please enter the hour",
  i18nKey_web_1240: "Paid facilities",
  i18nKey_web_1241: "Please enter the unit price",
  i18nKey_web_1242: "Half-day unit price (yuan)",
  i18nKey_web_1243: "hour",
  i18nKey_web_1244: "Please enter half-day unit price (yuan)",
  i18nKey_web_1245: "Please select an end date",
  i18nKey_web_1246: "The day cannot be less than 4 hours!",
  i18nKey_web_1247: "Cancellation refund limit cannot exceed 100",
  i18nKey_web_1248: "The online refund input format is incorrect!",
  i18nKey_web_1249: "Cost per day if up to XX hrs",
  i18nKey_web_1250: "Please select month",
  i18nKey_web_1251: "Please select a company",
  i18nKey_web_1252: "Free quota",
  i18nKey_web_1253: "Successfully modified",
  i18nKey_web_1254: "meeting name",
  i18nKey_web_1255: "Meeting place",
  i18nKey_web_1256: "Reservation person",
  i18nKey_web_1257: "Actual number of participants",
  i18nKey_web_1258: "Select month",
  i18nKey_web_1259: "Actual monthly electricity consumption",
  i18nKey_web_1260: "Data summary",
  i18nKey_web_1261: "Energy consumption overview",
  i18nKey_web_1262: "Automatic push",
  i18nKey_web_1263: "Dongli",
  i18nKey_web_1264: "Xili",
  i18nKey_web_1265: "Vankeli",
  i18nKey_web_1266: "Instrument number",
  i18nKey_web_1267: "Please enter the meter number",
  i18nKey_web_1268: "Please enter the name of the power distribution switch",
  i18nKey_web_1269: "Staging",
  i18nKey_web_1270: "Please select the meter type",
  i18nKey_web_1271: "room",
  i18nKey_web_1272: "Take effect",
  i18nKey_web_1273: "Last power consumption",
  i18nKey_web_1274: "Last water consumption",
  i18nKey_web_1275: "The meter reading date cannot be empty",
  i18nKey_web_1276: "The format of the meter reading date is wrong, the format specification is:",
  i18nKey_web_1277: "The date data of this meter reading is wrong, please enter reasonable date data",
  i18nKey_web_1278: "The reading format of this meter reading is wrong",
  i18nKey_web_1279: "The reading of this meter reading is less than the reading of the last meter reading, whether to confirm the modification",
  i18nKey_web_1280: "This time there is",
  i18nKey_web_1281: "Tables not entered",
  i18nKey_web_1282: "Whether to continue to take effect",
  i18nKey_web_1283: "Effective successfully",
  i18nKey_web_1284: "Please select to delete data",
  i18nKey_web_1285: "All the details of the device will be deleted after deletion, you are sure to delete",
  i18nKey_web_1286: "Selected",
  i18nKey_web_1287: "?",
  i18nKey_web_1288: "Deletion failed, please try again later",
  i18nKey_web_1289: "Synchronize",
  i18nKey_web_1290: "Signal 2",
  i18nKey_web_1291: "Facilities system 2",
  i18nKey_web_1292: "Alert label",
  i18nKey_web_1293: "Alarm enable",
  i18nKey_web_1294: "urgent",
  i18nKey_web_1295: "important",
  i18nKey_web_1296: "general",
  i18nKey_web_1297: "uncertain",
  i18nKey_web_1298: "Effective time",
  i18nKey_web_1299: "Plan enable",
  i18nKey_web_1300: "dead zone",
  i18nKey_web_1301: "Please enter the dead zone value",
  i18nKey_web_1302: "Please enter an alarm description",
  i18nKey_web_1303: "Signal 1",
  i18nKey_web_1304: "Import failed, only supports",
  i18nKey_web_1305: "Import failed, no valid data",
  i18nKey_web_1306: "Import failed, duplicate point code data",
  i18nKey_web_1307: "This operation data will be deleted permanently. Are you sure?",
  i18nKey_web_1308: "Can not be empty",
  i18nKey_web_1309: "Can only be composed of numbers and four arithmetic symbols",
  i18nKey_web_1310: "restore",
  i18nKey_web_1311: "Save failed",
  i18nKey_web_1312: "Source system facility number",
  i18nKey_web_1313: "Facilities QR code",
  i18nKey_web_1314: "Importance level",
  i18nKey_web_1315: "Number of classification levels",
  i18nKey_web_1316: "Facility serial number",
  i18nKey_web_1317: "Coordinate-altitude",
  i18nKey_web_1318: "View uploaded facility portraits",
  i18nKey_web_1319: "View upload facility diagram",
  i18nKey_web_1320: "Please select historical version",
  i18nKey_web_1321: "version",
  i18nKey_web_1322: "0 large screens have been connected",
  i18nKey_web_1323: "Save failed!",
  i18nKey_web_1324: "The length cannot exceed 50 characters",
  i18nKey_web_1325: "increase",
  i18nKey_web_1326: "Add failed",
  i18nKey_web_1327: "Are you sure you want to delete the relationship?",
  i18nKey_web_1328: "Delete association successfully",
  i18nKey_web_1329: "Confirm to save?",
  i18nKey_web_1330: "confirm cancel?",
  i18nKey_web_1331: "Failed to add!",
  i18nKey_web_1332: "Please select a room",
  i18nKey_web_1333: "Source system staff code",
  i18nKey_web_1334: "Source system name",
  i18nKey_web_1335: "Logo",
  i18nKey_web_1336: "photo",
  i18nKey_web_1337: "Photo storage address",
  i18nKey_web_1338: "Photo type code",
  i18nKey_web_1339: "Person photo",
  i18nKey_web_1340: "Label Information",
  i18nKey_web_1341: "is it effective",
  i18nKey_web_1342: "Please select a department",
  i18nKey_web_1343: "Passport",
  i18nKey_web_1344: "Added successfully",
  i18nKey_web_1345: "Picture cannot be empty",
  i18nKey_web_1346: "Personnel basic data modified successfully",
  i18nKey_web_1347: "OK to open",
  i18nKey_web_1348: "confirm to close",
  i18nKey_web_1349: "All users",
  i18nKey_web_1350: "All groups",
  i18nKey_web_1351: "Group information",
  i18nKey_web_1352: "Please enter user department",
  i18nKey_web_1353: "OK to delete the group",
  i18nKey_web_1354: "OK to delete the selected user",
  i18nKey_web_1355: "Please select the user to delete",
  i18nKey_web_1356: "Hong Kong and Macau",
  i18nKey_web_1357: "Turn on",
  i18nKey_web_1358: "Resources",
  i18nKey_web_1359: "current",
  i18nKey_web_1360: "Menu resources",
  i18nKey_web_1361: "Role deleted successfully",
  i18nKey_web_1362: "Role deletion failed, please try again",
  i18nKey_web_1363: "Please select the type",
  i18nKey_web_1364: "Role created successfully",
  i18nKey_web_1365: "Network abnormality, please try again later",
  i18nKey_web_1366: "Role modified successfully",
  i18nKey_web_1367: "Modify role",
  i18nKey_web_1368: "Super administrator",
  i18nKey_web_1369: "Platform administrator",
  i18nKey_web_1370: "Park administrator",
  i18nKey_web_1371: "Platform role",
  i18nKey_web_1372: "Ordinary role in the park",
  i18nKey_web_1373: "Park security role",
  i18nKey_web_1374: "Park property role",
  i18nKey_web_1375: "The role of cleaning in the park",
  i18nKey_web_1376: "Role name already exists",
  i18nKey_web_1377: "You sure you want to delete it?",
  i18nKey_web_1378: "Running",
  i18nKey_web_1379: "Berth",
  i18nKey_web_1380: "Overhaul",
  i18nKey_web_1381: "Passenger elevator",
  i18nKey_web_1382: "Elevator",
  i18nKey_web_1383: "Light control",
  i18nKey_web_1384: "Equipment type",
  i18nKey_web_1385: "Device address",
  i18nKey_web_1386: "Loading",
  i18nKey_web_1387: "updating",
  i18nKey_web_1388: "Enter device name",
  i18nKey_web_1389: "playing",
  i18nKey_web_1390: "Every big screen",
  i18nKey_web_1391: "Enabled state",
  i18nKey_web_1392: "Delete the policy",
  i18nKey_web_1393: "The effective time of each strategy cannot be empty",
  i18nKey_web_1394: "Lighting rules",
  i18nKey_web_1395: "Cannot be empty",
  i18nKey_web_1396: "add failed",
  i18nKey_web_1397: "Are you sure you want to delete this plan?",
  i18nKey_web_1398: "Successfully issued the plan",
  i18nKey_web_1399: "Save the circuit breaker configuration successfully!",
  i18nKey_web_1400: "Changed successfully",
  i18nKey_web_1401: "Customer List",
  i18nKey_web_1402: "personnel number",
  i18nKey_web_1403: "Visited the park in the past week",
  i18nKey_web_1404: "Visited the park in the early morning of the past week",
  i18nKey_web_1405: "User cycle",
  i18nKey_web_1406: "Get used to order time",
  i18nKey_web_1407: "Shortest time consuming",
  i18nKey_web_1408: "Longest time consuming",
  i18nKey_web_1409: "time cost",
  i18nKey_web_1410: "Pieces",
  i18nKey_web_1411: "facility",
  i18nKey_web_1412: "Number of facilities",
  i18nKey_web_1413: "Average processing time",
  i18nKey_web_1414: "Facility code",
  i18nKey_web_1415: "Select sub-dimension",
  i18nKey_web_1416: "Please select itemized information",
  i18nKey_web_1417: "Grade sub-items",
  i18nKey_web_1418: "Please select a level",
  i18nKey_web_1419: "Critical alert",
  i18nKey_web_1420: "Meter number:",
  i18nKey_web_1421: "serious",
  i18nKey_web_1422: "Water use",
  i18nKey_web_1423: "Last year",
  i18nKey_web_1424: "Energy consumption",
  i18nKey_web_1425: "Automatic meter reading",
  i18nKey_web_1426: "Manual meter reading",
  i18nKey_web_1427: "Historical heat",
  i18nKey_web_1428: "full screen",
  i18nKey_web_1429: "sub option",
  i18nKey_web_1430: "Energy use this month",
  i18nKey_web_1431: "Exception type",
  i18nKey_web_1432: "Electricity time",
  i18nKey_web_1433: "Statistics Time",
  i18nKey_web_1434: "Actual electricity consumption this month",
  i18nKey_web_1435: "Actual electricity consumption last month",
  i18nKey_web_1436: "Electricity details",
  i18nKey_web_1437: "accident details",
  i18nKey_web_1438: "From",
  i18nKey_web_1439: "The failure occurred repeatedly once, and the cumulative duration",
  i18nKey_web_1440: "hours, can cause waste throughout the year",
  i18nKey_web_1441: "Processing status",
  i18nKey_web_1442: "Diagnostic treatment",
  i18nKey_web_1443: "Fault name",
  i18nKey_web_1444: "Please enter the fault name",
  i18nKey_web_1445: "Failure level",
  i18nKey_web_1446: "Failure statistics",
  i18nKey_web_1447: "Number of failures",
  i18nKey_web_1448: "Total number of failures",
  i18nKey_web_1449: "re-upload",
  i18nKey_web_1450: "Please enter the meter number",
  i18nKey_web_1451: "Please select the category",
  i18nKey_web_1452: "Please select the meter reading period",
  i18nKey_web_1453: "Please select whether it is abnormal",
  i18nKey_web_1454: "Meter numer",
  i18nKey_web_1455: "Point reading",
  i18nKey_web_1456: "Current electricity consumption",
  i18nKey_web_1457: "brand name",
  i18nKey_web_1458: "Exception number",
  i18nKey_web_1459: "Total exception number",
  i18nKey_web_1460: "data lost",
  i18nKey_web_1461: "Summary table",
  i18nKey_web_1462: "spare",
  i18nKey_web_1463: "exist",
  i18nKey_web_1464: "Data, 0 abnormal data, please confirm to submit",
  i18nKey_web_1465: "Input",
  i18nKey_web_1466: "Data status",
  i18nKey_web_1467: "Please select right data status",
  i18nKey_web_1468: "Current meter reading time",
  i18nKey_web_1469: "Enter meter reading data",
  i18nKey_web_1470: "You have entered",
  i18nKey_web_1471: "Compared with the previous data, there are",
  i18nKey_web_1472: "Abnormal data",
  i18nKey_web_1473: "Confirm to submit",
  i18nKey_web_1474: "You have imported",
  i18nKey_web_1475: "Article data",
  i18nKey_web_1476: "Have",
  i18nKey_web_1477: "Total unread",
  i18nKey_web_1478: "Not read in this issue",
  i18nKey_web_1479: "Read",
  i18nKey_web_1480: "Water consumption in this period",
  i18nKey_web_1481: "Export failed, no data on current page",
  i18nKey_web_1482: "Huanglong Vanke",
  i18nKey_web_1483: "use",
  i18nKey_web_1484: "water",
  i18nKey_web_1485: "the amount",
  i18nKey_web_1486: "Total electricity consumption",
  i18nKey_web_1487: "Total use",
  i18nKey_web_1488: "Point information graph",
  i18nKey_web_1489: "Computer room power",
  i18nKey_web_1490: "In the whole project in the year, you have found a total of",
  i18nKey_web_1491: "Energy saving potential",
  i18nKey_web_1492: "South area",
  i18nKey_web_1493: "engine room",
  i18nKey_web_1494: "North District",
  i18nKey_web_1495: "variable",
  i18nKey_web_1496: "Energy efficiency ratio",
  i18nKey_web_1497: "Real-time power",
  i18nKey_web_1498: "unit number",
  i18nKey_web_1499: "Monthly consumption",
  i18nKey_web_1500: "Choose start time",
  i18nKey_web_1501: "Choose end time",
  i18nKey_web_1502: "Select icon",
  i18nKey_web_1503: "Scene lock switch",
  i18nKey_web_1504: "Successfully opened",
  i18nKey_web_1505: "Successfully closed",
  i18nKey_web_1506: "Batch delete",
  i18nKey_web_1507: "Confirm to delete the apartment",
  i18nKey_web_1508: "Unit type",
  i18nKey_web_1509: "Room No.",
  i18nKey_web_1510: "New building units and public areas",
  i18nKey_web_1511: "Please enter the building unit",
  i18nKey_web_1512: "Add room number",
  i18nKey_web_1513: "Please enter the room number",
  i18nKey_web_1514: "Please select unit building to add room number",
  i18nKey_web_1515: "Please select the room to delete",
  i18nKey_web_1516: "Video is paused",
  i18nKey_web_1517: "End of video patrol",
  i18nKey_web_1518: "Play pause 1",
  i18nKey_web_1519: "Pause 1",
  i18nKey_web_1520: "Timer",
  i18nKey_web_1521: "Play pause 3",
  i18nKey_web_1522: "Play",
  i18nKey_web_1523: "The patrol group will be played in turn at regular intervals",
  i18nKey_web_1524: "Currently playing -",
  i18nKey_web_1525: "Patrol groups",
  i18nKey_web_1526: "Cameras",
  i18nKey_web_1527: "Add patrol route",
  i18nKey_web_1528: "List of patrol routes",
  i18nKey_web_1529: "Real-time video",
  i18nKey_web_1530: "Choose start date and time",
  i18nKey_web_1531: "Choose end date and time",
  i18nKey_web_1532: "Associate historical video",
  i18nKey_web_1533: "Intercepted successfully",
  i18nKey_web_1534: "Video capture failed",
  i18nKey_web_1535: "Basic Settings",
  i18nKey_web_1536: "in advance",
  i18nKey_web_1537: "Monthly discount",
  i18nKey_web_1538: "Years and above",
  i18nKey_web_1539: "Months",
  i18nKey_web_1540: "Created successfully",
  i18nKey_web_1541: "Please select a time range",
  i18nKey_web_1542: "Choose a business",
  i18nKey_web_1543: "Reward success",
  i18nKey_web_1544: "Original price",
  i18nKey_web_1545: "administrator",
  i18nKey_web_1546: "Start time of suspension",
  i18nKey_web_1547: "End time of suspension",
  i18nKey_web_1548: "Unsubscribe early",
  i18nKey_web_1549: "How many days in advance can be unsubscribed",
  i18nKey_web_1550: "Please enter the address latitude",
  i18nKey_web_1551: "Additional facilities",
  i18nKey_web_1552: "Add additional facilities",
  i18nKey_web_1553: "For example: 15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter",
  i18nKey_web_1554: "Capped.",
  i18nKey_web_1555: "Parking nearby",
  i18nKey_web_1556: "Example: Ping An",
  i18nKey_web_1557: "15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter",
  i18nKey_web_1558: "Futian District, Shenzhen City, Guangdong Province",
  i18nKey_web_1559: "Add office space",
  i18nKey_web_1560: "Modify office space",
  i18nKey_web_1561: "Office space details",
  i18nKey_web_1562: "Newly added additional facilities menu path: corporate services",
  i18nKey_web_1563: "Meeting room management",
  i18nKey_web_1564: "Value-added services!",
  i18nKey_web_1565: "existed!",
  i18nKey_web_1566: "The address latitude and longitude input must be separated by commas",
  i18nKey_web_1567: "And cannot be greater than 20 characters",
  i18nKey_web_1568: "Please enter the station number",
  i18nKey_web_1569: "Please select what needs to be uploaded",
  i18nKey_web_1570: "Figure!",
  i18nKey_web_1571: "Picture file!",
  i18nKey_web_1572: "Add message pushers only in Yuncheng",
  i18nKey_web_1573: "Registered users,",
  i18nKey_web_1574: "News push information is displayed in Yuncheng",
  i18nKey_web_1575: "Message list",
  i18nKey_web_1576: "phone number:",
  i18nKey_web_1577: "Applicant Information",
  i18nKey_web_1578: "Please enter the name of the applicant",
  i18nKey_web_1579: "application time",
  i18nKey_web_1580: "Please select the application time",
  i18nKey_web_1581: "rejected",
  i18nKey_web_1582: "Applicant's name",
  i18nKey_web_1583: "Applicant Phone",
  i18nKey_web_1584: "usage time",
  i18nKey_web_1585: "Rejected",
  i18nKey_web_1586: "Approver",
  i18nKey_web_1587: "Review time",
  i18nKey_web_1588: "Blacklist deployment",
  i18nKey_web_1589: "Guest invitation",
  i18nKey_web_1590: "Visitor information",
  i18nKey_web_1591: "Please enter visitor name",
  i18nKey_web_1592: "Please enter the company name",
  i18nKey_web_1593: "Please select a visitor",
  i18nKey_web_1594: "Shanghai",
  i18nKey_web_1595: "date:",
  i18nKey_web_1596: "Statistics of staff proportion",
  i18nKey_web_1597: "Number of personnel ranking",
  i18nKey_web_1598: "The day before yesterday",
  i18nKey_web_1599: "Please select an update date",
  i18nKey_web_1600: "Please select device status",
  i18nKey_web_1601: "Park visit",
  i18nKey_web_1602: "Existing strategy",
  i18nKey_web_1603: "New strategy",
  i18nKey_web_1604: "Please select a space location",
  i18nKey_web_1605: "Strategy status",
  i18nKey_web_1606: "Please select strategy status",
  i18nKey_web_1607: "Unopened",
  i18nKey_web_1608: "Interviewed address",
  i18nKey_web_1609: "Please enter guest phone",
  i18nKey_web_1610: "Authorization period",
  i18nKey_web_1611: "Visit reason",
  i18nKey_web_1612: "Pass",
  i18nKey_web_1613: "Pass type",
  i18nKey_web_1614: "Please select the manufacturer name",
  i18nKey_web_1615: "delete or not?",
  i18nKey_web_1616: "Please enter the name of the host",
  i18nKey_web_1617: "Epidemic prevention and control information",
  i18nKey_web_1618: "Invitation successful",
  i18nKey_web_1619: "Visitor Record",
  i18nKey_web_1620: "Guest invitations",
  i18nKey_web_1621: "Visitor invitation statistics",
  i18nKey_web_1622: "Nearly 30 days",
  i18nKey_web_1623: "Park visitor invitation ranking",
  i18nKey_web_1624: "Visitor statistics",
  i18nKey_web_1625: "Park history always invites",
  i18nKey_web_1626: "Historical invitation",
  i18nKey_web_1627: "unit",
  i18nKey_web_1628: "Current proportion",
  i18nKey_web_1629: "Proportion in the same period of the previous year",
  i18nKey_web_1630: "Electricity sub-item configuration",
  i18nKey_web_1631: "Note: Energy consumption proportion of current level and last level exceeds",
  i18nKey_web_1632: "Exception. The total energy consumption of this level is taken from the low-voltage incoming cabinet",
  i18nKey_web_1633: "Itemized energy consumption flow diagram",
  i18nKey_web_1634: "Please select the facility validity period",
  i18nKey_web_1635: "Abbreviation",
  i18nKey_web_1636: "Please enter the person responsible",
  i18nKey_web_1637: "Ownership",
  i18nKey_web_1638: "Please enter the device number",
  i18nKey_web_1639: "Using",
  i18nKey_web_1640: "In maintenance",
  i18nKey_web_1641: "Delete or not",
  i18nKey_web_1642: "Please enter your name or phone number",
  i18nKey_web_1643: "Bus route",
  i18nKey_web_1644: "Remaining times",
  i18nKey_web_1645: "Payment failed",
  i18nKey_web_1646: "No travel record, no need to perform export operation!",
  i18nKey_web_1647: "Report?",
  i18nKey_web_1648: "Package price",
  i18nKey_web_1649: "Payment time",
  i18nKey_web_1650: "Effective date",
  i18nKey_web_1651: "Date of Expiry",
  i18nKey_web_1652: "Please choose an appearance time",
  i18nKey_web_1653: "colour",
  i18nKey_web_1654: "Temporary payment",
  i18nKey_web_1655: "Query list",
  i18nKey_web_1656: "Beijing",
  i18nKey_web_1657: "Tianjin",
  i18nKey_web_1658: "Shanghai",
  i18nKey_web_1659: "Chongqing",
  i18nKey_web_1660: "Xinjiang",
  i18nKey_web_1661: "Jiangsu",
  i18nKey_web_1662: "Zhejiang",
  i18nKey_web_1663: "Heilongjiang",
  i18nKey_web_1664: "Hunan",
  i18nKey_web_1665: "Anhui",
  i18nKey_web_1666: "Shandong",
  i18nKey_web_1667: "Jiangxi",
  i18nKey_web_1668: "Heibei",
  i18nKey_web_1669: "Henan",
  i18nKey_web_1670: "Yunnan",
  i18nKey_web_1671: "Liaoning",
  i18nKey_web_1672: "Hubei",
  i18nKey_web_1673: "Guangxi",
  i18nKey_web_1674: "Gansu",
  i18nKey_web_1675: "Shanxi",
  i18nKey_web_1676: "Inner Mongolia",
  i18nKey_web_1677: "Shaanxi",
  i18nKey_web_1678: "Jilin",
  i18nKey_web_1679: "Fujian",
  i18nKey_web_1680: "Guizhou",
  i18nKey_web_1681: "Guangdong",
  i18nKey_web_1682: "Qinghai",
  i18nKey_web_1683: "Tibet",
  i18nKey_web_1684: "Sichuan",
  i18nKey_web_1685: "Ningxia",
  i18nKey_web_1686: "Hainan",
  i18nKey_web_1687: "Taiwan",
  i18nKey_web_1688: "Hong Kong",
  i18nKey_web_1689: "Macau",
  i18nKey_web_1690: "Greater than 8",
  i18nKey_web_1691: "Greater than 12",
  i18nKey_web_1692: "Greater than 24",
  i18nKey_web_1693: "Grand total",
  i18nKey_web_1694: "Greater than 1200",
  i18nKey_web_1695: "Greater than 1600",
  i18nKey_web_1696: "Cumulative parking payment",
  i18nKey_web_1697: "Basic attributes",
  i18nKey_web_1698: "Appeared",
  i18nKey_web_1699: "Violations",
  i18nKey_web_1700: "Cumulative temporary suspension payment",
  i18nKey_web_1701: "Yuan, you can recommend the monthly card to him",
  i18nKey_web_1702: "Approach",
  i18nKey_web_1703: "There are more overtime work, 20 nights accumulated 10 days in the past month",
  i18nKey_web_1704: "After leaving the park",
  i18nKey_web_1705: "I started working very early and accumulated 10 days in the past month at 8 am",
  i18nKey_web_1706: "Before entering the park",
  i18nKey_web_1707: "Earliest in the past month",
  i18nKey_web_1708: "Enter the park",
  i18nKey_web_1709: "Latest",
  i18nKey_web_1710: "Leave the park",
  i18nKey_web_1711: "The previous month",
  i18nKey_web_1712: "Last September",
  i18nKey_web_1713: "Number of people present",
  i18nKey_web_1714: "lessee",
  i18nKey_web_1715: "type of data",
  i18nKey_web_1716: "Indeed",
  i18nKey_web_1717: "set",
  i18nKey_web_1718: "Removed successfully",
  i18nKey_web_1719: "Removal failed",
  i18nKey_web_1720: "fail to edit!",
  i18nKey_web_1721: "download failed",
  i18nKey_web_1722: "Age ratio",
  i18nKey_web_1723: "Shenzhen Vanke Yuncheng",
  i18nKey_web_1724: "Production Office-Staging",
  i18nKey_web_1725: "Design Commune-Partition",
  i18nKey_web_1726: "Yunchuang 2-partition",
  i18nKey_web_1727: "Park Residence-Installment",
  i18nKey_web_1728: "Park Residence 1-Zone",
  i18nKey_web_1729: "Commercial-installment",
  i18nKey_web_1730: "Dongli-Zone",
  i18nKey_web_1731: "Xili-Zone",
  i18nKey_web_1732: "Shenzhen Yuncheng",
  i18nKey_web_1733: "Yongqingfang",
  i18nKey_web_1734: "Floor",
  i18nKey_web_1735: "Area",
  i18nKey_web_1736: "Passenger flow",
  i18nKey_web_1737: "search engine",
  i18nKey_web_1738: "Passenger flow statistics",
  i18nKey_web_1739: "Please enter the ticket",
  i18nKey_web_1740: "If there are multiple stops, please enter in the following format: Vanke Yuncheng",
  i18nKey_web_1741: "Baoan Center",
  i18nKey_web_1742: "Front bay",
  i18nKey_web_1743: "Bao'an Airport",
  i18nKey_web_1744: "Please select a valid date",
  i18nKey_web_1745: "Automatic conversion price",
  i18nKey_web_1746: "Please enter less than 1",
  i18nKey_web_1747: "Price between",
  i18nKey_web_1748: "Please select the bus schedule to use",
  i18nKey_web_1749: "Modify package",
  i18nKey_web_1750: "check the set menu",
  i18nKey_web_1751: "E.g",
  i18nKey_web_1752: "Please enter the scanning device",
  i18nKey_web_1753: "For example: the bus is limited to 50 people",
  i18nKey_web_1754: "coding",
  i18nKey_web_1755: "synchronizing",
  i18nKey_web_1756: "send messages",
  i18nKey_web_1757: "Access control monitoring",
  i18nKey_web_1758: "All access to the building",
  i18nKey_web_1759: "turn on",
  i18nKey_web_1760: "Access control",
  i18nKey_web_1761: "Deployment control live",
  i18nKey_web_1762: "More news",
  i18nKey_web_1763: "Upload picture",
  i18nKey_web_1764: "Add face control",
  i18nKey_web_1765: "New vehicle deployment control",
  i18nKey_web_1766: "Disarm",
  i18nKey_web_1767: "Transit facility warning",
  i18nKey_web_1768: "Illegal invasion",
  i18nKey_web_1769: "Captain Security-Calling",
  i18nKey_web_1770: "hang up",
  i18nKey_web_1771: "Phone call",
  i18nKey_web_1772: "dial",
  i18nKey_web_1773: "Commonly used",
  i18nKey_web_1774: "Contact person",
  i18nKey_web_1775: "Alarm picture",
  i18nKey_web_1776: "Alarm avatar",
  i18nKey_web_1777: "Upload list",
  i18nKey_web_1778: "View associated videos",
  i18nKey_web_1779: "Can only upload 10",
  i18nKey_web_1780: "Pictures inside",
  i18nKey_web_1781: "Format video",
  i18nKey_web_1782: "Personnel selection",
  i18nKey_web_1783: "Personnel Heat Map",
  i18nKey_web_1784: "No personnel",
  i18nKey_web_1785: "send",
  i18nKey_web_1786: "Send SMS content cannot be empty",
  i18nKey_web_1787: "SMS sent successfully",
  i18nKey_web_1788: "Those who failed to send SMS",
  i18nKey_web_1789: "Please note that the above personnel cannot receive SMS",
  i18nKey_web_1790: "Reminder of Vanke Smart Park Operation Center, Vanke Smart Park Operation Center reminds you:",
  i18nKey_web_1791: "Thank you!",
  i18nKey_web_1792: "SMS recipient cannot be empty",
  i18nKey_web_1793: "Personnel thermal backstage return",
  i18nKey_web_1794: "Address book",
  i18nKey_web_1795: "Find a contact",
  i18nKey_web_1796: "calling",
  i18nKey_web_1797: "video call",
  i18nKey_web_1798: "Park Building",
  i18nKey_web_1799: "Street light management",
  i18nKey_web_1800: "Street lamp name",
  i18nKey_web_1801: "Street light status",
  i18nKey_web_1802: "Open the door",
  i18nKey_web_1803: "Close the door",
  i18nKey_web_1804: "Caveat",
  i18nKey_web_1805: "Suspicious target personnel appeared in",
  i18nKey_web_1806: "Send someone to view",
  i18nKey_web_1807: "Device location",
  i18nKey_web_1808: "Meter code",
  i18nKey_web_1809: "Meter location",
  i18nKey_web_1810: "Number of alarms",
  i18nKey_web_1811: "Electric loss rate",
  i18nKey_web_1812: "Category Name",
  i18nKey_web_1813: "Please enter a category name",
  i18nKey_web_1814: "Category name cannot be empty",
  i18nKey_web_1815: "Prohibit",
  i18nKey_web_1816: "Please enter your phone number or name to search",
  i18nKey_web_1817: "Please select the lock number!",
  i18nKey_web_1818: "close",
  i18nKey_web_1819: "Importing files",
  i18nKey_web_1820: "Import failed, first",
  i18nKey_web_1821: "There are errors in the row data, please check and submit again for import later",
  i18nKey_web_1822: "Please enter a positive number",
  i18nKey_web_1823: "Keep up to two decimal places",
  i18nKey_web_1824: "Please select the instrument category",
  i18nKey_web_1825: "No room at current location",
  i18nKey_web_1826: "Electricity data statistics",
  i18nKey_web_1827: "KWh",
  i18nKey_web_1828: "Water consumption statistics",
  i18nKey_web_1829: "cubic meter",
  i18nKey_web_1830: "Incorrect phone number format",
  i18nKey_web_1831: "Certificate configuration",
  i18nKey_web_1832: "License plate:",
  i18nKey_web_1833: "exceed",
  i18nKey_web_1834: "times, cannot apply for monthly card",
  i18nKey_web_1835: "times, can’t enter the parking lot",
  i18nKey_web_1836: "License plate number cannot be empty",
  i18nKey_web_1837: "Phone number cannot be empty",
  i18nKey_web_1838: "Remarks cannot be empty",
  i18nKey_web_1839: "Open the door together",
  i18nKey_web_1840: "Tip: The video picture delay is about 5-10",
  i18nKey_web_1841: "Please wait.",
  i18nKey_web_1842: "operation hours:",
  i18nKey_web_1843: "Ladder",
  i18nKey_web_1844: "Motor speed:",
  i18nKey_web_1845: "Spindle temperature:",
  i18nKey_web_1846: "Stretch of traction rope:",
  i18nKey_web_1847: "Volume control",
  i18nKey_web_1848: "Total lighting circuit:",
  i18nKey_web_1849: "A,",
  i18nKey_web_1850: "switch",
  i18nKey_web_1851: "No",
  i18nKey_web_1852: "Sure to all",
  i18nKey_web_1853: "A loop?",
  i18nKey_web_1854: "Sure to",
  i18nKey_web_1855: "This",
  i18nKey_web_1856: "Loop",
  i18nKey_web_1857: "Change failed, please try again later",
  i18nKey_web_1858: "Successfully opened",
  i18nKey_web_1859: "Closed successfully",
  i18nKey_web_1860: "Change failed",
  i18nKey_web_1861: "Confirm to cancel?",
  i18nKey_web_1862: "Two on one cycle",
  i18nKey_web_1863: "Gateway name 2",
  i18nKey_web_1864: "Sensing delay dimly bright",
  i18nKey_web_1865: "Please select opening time",
  i18nKey_web_1866: "Please input",
  i18nKey_web_1867: "Please enter points",
  i18nKey_web_1868: "Please enter the phone to search for a match",
  i18nKey_web_1869: "Please select reminder",
  i18nKey_web_1870: "added",
  i18nKey_web_1871: "Actual electricity consumption today",
  i18nKey_web_1872: "Actual electricity consumption last week",
  i18nKey_web_1873: "Succeeded",
  i18nKey_web_1874: "Device name, business",
  i18nKey_web_1875: "Input file",
  i18nKey_web_1876: "Meter reading data cannot be empty",
  i18nKey_web_1877: "Meter number",
  i18nKey_web_1878: "Last meter reading time",
  i18nKey_web_1879: "Electricity data statistics",
  i18nKey_web_1880: "Water consumption statistics",
  i18nKey_web_1881: "Automatic meter reading details",
  i18nKey_web_1882: "Missing data",
  i18nKey_web_1883: "abnormal data",
  i18nKey_web_1884: "Make up",
  i18nKey_web_1885: "energy used",
  i18nKey_web_1886: "Phase voltage",
  i18nKey_web_1887: "Phase current",
  i18nKey_web_1888: "Select sub-item",
  i18nKey_web_1889: "Please select the meter reading date",
  i18nKey_web_1890: "Please enter the meter reading",
  i18nKey_web_1891: "equipment:",
  i18nKey_web_1892: "Update failed",
  i18nKey_web_1893: "Select equipment",
  i18nKey_web_1894: "Dong",
  i18nKey_web_1895: "room",
  i18nKey_web_1896: "Form template",
  i18nKey_web_1897: "Please fill in the necessary information",
  i18nKey_web_1898: "Please enter a legal mobile phone number",
  i18nKey_web_1899: "Parking area",
  i18nKey_web_1900: "Untie",
  i18nKey_web_1901: "Add public area equipment",
  i18nKey_web_1902: "Device information updated successfully",
  i18nKey_web_1903: "Confirm to delete device",
  i18nKey_web_1904: "Add device successfully",
  i18nKey_web_1905: "Two rooms and one living room",
  i18nKey_web_1906: "Reset successfully",
  i18nKey_web_1907: "It cannot be recovered after deletion. Are you sure you want to delete?",
  i18nKey_web_1908: "Staff overview",
  i18nKey_web_1909: "Personnel comparison trend",
  i18nKey_web_1910: "Nearly two weeks",
  i18nKey_web_1911: "Last 12 weeks",
  i18nKey_web_1912: "Last 12 months",
  i18nKey_web_1913: "Video picture delay about 5",
  i18nKey_web_1914: "Please wait",
  i18nKey_web_1915: "Camera loading",
  i18nKey_web_1916: "Failed to obtain camera resources",
  i18nKey_web_1917: "basic information",
  i18nKey_web_1918: "Default 00 next day",
  i18nKey_web_1919: "Refresh",
  i18nKey_web_1920: "After the field is checked, the field is displayed in the applet",
  i18nKey_web_1921: "Visible to users",
  i18nKey_web_1922: "method to informe",
  i18nKey_web_1923: "Yuncheng",
  i18nKey_web_1924: "news",
  i18nKey_web_1925: "Interviewee information",
  i18nKey_web_1926: "Interviewee's name",
  i18nKey_web_1927: "Interviewee's address",
  i18nKey_web_1928: "Access information",
  i18nKey_web_1929: "Visiting reason",
  i18nKey_web_1930: "Companion information collection",
  i18nKey_web_1931: "After opening, the user can enter the number of passers-by",
  i18nKey_web_1932: "Visitors view registration records",
  i18nKey_web_1933: "Exemption from approval",
  i18nKey_web_1934: "After opening, visitors can get access permission directly without the approval process",
  i18nKey_web_1935: "navigation",
  i18nKey_web_1936: "After opening, visitors can view the park navigation information",
  i18nKey_web_1937: "No visitors",
  i18nKey_web_1938: "Number of visitors",
  i18nKey_web_1939: "Total number of interviewed companies",
  i18nKey_web_1940: "Please enter the server",
  i18nKey_web_1941: "Or domain name",
  i18nKey_web_1942: "added successfully!",
  i18nKey_web_1943: "Your browser does not support",
  i18nKey_web_1944: "Temporary suspension payment amount",
  i18nKey_web_1945: "Package payment amount",
  i18nKey_web_1946: "Please enter car brand",
  i18nKey_web_1947: "Audi",
  i18nKey_web_1948: "Benz",
  i18nKey_web_1949: "BMW",
  i18nKey_web_1950: "Honda",
  i18nKey_web_1951: "Toyota",
  i18nKey_web_1952: "public",
  i18nKey_web_1953: "Ford",
  i18nKey_web_1954: "Buick",
  i18nKey_web_1955: "Payment in the past month",
  i18nKey_web_1956: "Cumulative payment",
  i18nKey_web_1957: "More than 6 options for license plate",
  i18nKey_web_1958: "Optimal display for you",
  i18nKey_web_1959: "Brand more than 6 options",
  i18nKey_web_1960: "More than 6 color options",
  i18nKey_web_1961: "More than 6 options for license plates and brands",
  i18nKey_web_1962: "License plate and color more than 6 options",
  i18nKey_web_1963: "More than 6 options for brands and colors",
  i18nKey_web_1964: "License plate, brand and color more than 6 options",
  i18nKey_web_1965: "Number of returning guests",
  i18nKey_web_1966: "people",
  i18nKey_web_1967: "Number of repeat customer visits",
  i18nKey_web_1968: "People",
  i18nKey_web_1969: "direct interview",
  i18nKey_web_1970: "starting point",
  i18nKey_web_1971: "Nearly a week",
  i18nKey_web_1972: "begin",
  i18nKey_web_1973: "The selected date range is greater than",
  i18nKey_web_1974: "Days, please reduce your choices and try again",
  i18nKey_web_1975: "Time selection",
  i18nKey_web_1976: "Please select the filter time",
  i18nKey_web_1977: "Wrong password, please re-enter",
  i18nKey_web_1978: "Error in getting personnel details interface",
  i18nKey_web_1979: "Passenger flow",
  i18nKey_web_1980: "year old",
  i18nKey_web_1981: "Start date",
  i18nKey_web_1982: "Days",
  i18nKey_web_1983: "Calendar details",
  i18nKey_web_1984: "copy",
  i18nKey_web_1985: "Unassigned device",
  i18nKey_web_1986: "Copy successfully",
  i18nKey_web_1987: "Please enter the name of the tenant",
  i18nKey_web_1988: "Please select store status",
  i18nKey_web_1989: "total",
  i18nKey_web_1990: "New Year's Day",
  i18nKey_web_1991: "Compared with last year",
  i18nKey_web_1992: "No equipment",
  i18nKey_web_1993: "Delete room",
  i18nKey_web_1994: "OK to delete the room",
  i18nKey_web_1995: "Scene switch",
  i18nKey_web_1996: "Are you sure to delete these scenes in bulk?",
  i18nKey_web_1997: "OK to delete the scene",
  i18nKey_web_1998: "Please select the area name",
  i18nKey_web_1999: "Choose a company",
  i18nKey_web_2000: "Please select the company to be added",
  i18nKey_web_2001: "Please choose the right company",
  i18nKey_web_2002: "Please select an event",
  i18nKey_web_2003: "Please select a start date",
  i18nKey_web_2004: "Event cannot be empty",
  i18nKey_web_2005: "Please choose a holiday",
  i18nKey_web_2006: "Level 1 heading",
  i18nKey_web_2007: "Secondary heading 1",
  i18nKey_web_2008: "Level 3 heading 1",
  i18nKey_web_2009: "Secondary heading 2",
  i18nKey_web_2010: "Level 3 heading 2",
  i18nKey_web_2011: 'Create',

  "i18nKey_web_2012": 'Update access permission',
  "i18nKey_web_2013": 'After changing company address, please update access permission. Otherwise, it will affect employee is access.',
  "i18nKey_web_2014": 'Update the access permission?',
  "i18nKey_web_2015": 'Update access permission successfully!',
  "i18nKey_web_2016": 'Appoint people',
  "i18nKey_web_2017": 'Responsible',
  "i18nKey_web_2018": 'Business workorder',
  "i18nKey_web_2019": 'Report workorder',
  "i18nKey_web_2020": 'Recent year',
  "i18nKey_web_2021": 'Event',
  "i18nKey_web_2022": 'Order Qty.',
  "i18nKey_web_2023": 'Event proportion',
  "i18nKey_web_2024": 'Order proportion',
  "i18nKey_web_2025": 'All',
  "i18nKey_web_2026": 'remark',
  "i18nKey_web_2027": 'Order details',
  "i18nKey_web_2028": 'Inquire',
  "i18nKey_web_2029": 'Reset',
  "i18nKey_web_2030": 'No data that meets the query conditions',
  "i18nKey_web_2031": 'Report time',
  "i18nKey_web_2032": 'Tips',
  "i18nKey_web_2033": 'Consumed energy per sq.m',
  "i18nKey_web_2034": 'Energy consumption',
  "i18nKey_web_2035": 'cool',
  "i18nKey_web_2036": 'Electricity consumption current period',
  "i18nKey_web_2037": 'Water consumption current period',
  "i18nKey_web_2038": 'Cold consumption current period',
  "i18nKey_web_2039": 'Electricity consumption compared last period',
  "i18nKey_web_2040": 'Water consumption compared last period',
  "i18nKey_web_2041": 'Cold consumption compared last period',
  "i18nKey_web_2042": 'Electricity consumption same period last year',
  "i18nKey_web_2043": 'Water consumption same period last year',
  "i18nKey_web_2044": 'Cold consumption same period last year',
  "i18nKey_web_2045": 'Summary',
  "i18nKey_web_2046": 'Electricity ranking',
  "i18nKey_web_2047": 'Water ranking',
  "i18nKey_web_2048": 'Cold ranking',
  "i18nKey_web_2049": 'No data to export',
  "i18nKey_web_2050": 'Week',
  "i18nKey_web_2051": 'Electricity',
  "i18nKey_web_2052": 'Water use',
  "i18nKey_web_2053": 'Cold consumption',
  "i18nKey_web_2054": 'Day',
  "i18nKey_web_2055": 'Month',
  "i18nKey_web_2056": 'Echarts charts',
  "i18nKey_web_2057": 'Failure trend analysis',
  "i18nKey_web_2058": 'Export data successfully',
  "i18nKey_web_2059": 'Export data failed',
  "i18nKey_web_2060": 'Ten thousand',
  "i18nKey_web_2061": 'Event(s)',
  "i18nKey_web_2062": 'Export',
  "i18nKey_web_2063": 'Import',
  "i18nKey_web_2064": 'Key words',
  "i18nKey_web_2065": 'Please input',
  "i18nKey_web_2066": 'Meter no. device name, merchant/rent name',
  "i18nKey_web_2067": 'position',
  "i18nKey_web_2068": 'Select',
  "i18nKey_web_2069": 'Please select the location',
  "i18nKey_web_2070": 'Sub-item',
  "i18nKey_web_2071": 'Meter read period',
  "i18nKey_web_2072": 'Please select time',
  "i18nKey_web_2073": 'Accrual month',
  "i18nKey_web_2074": 'Not inputted',
  "i18nKey_web_2075": 'Submitted',
  "i18nKey_web_2076": 'Settled',
  "i18nKey_web_2077": 'Abnormal?',
  "i18nKey_web_2078": 'Normal',
  "i18nKey_web_2079": 'Abnormal',
  "i18nKey_web_2080": 'S/N',
  "i18nKey_web_2081": 'Confirm',
  "i18nKey_web_2082": 'Current meter reading',
  "i18nKey_web_2083": 'Meter read period',
  "i18nKey_web_2084": 'Collection frequency',
  "i18nKey_web_2085": 'Day',
  "i18nKey_web_2086": 'Merchant/rent name',
  "i18nKey_web_2087": 'Merchant no',
  "i18nKey_web_2088": 'Device name',
  "i18nKey_web_2089": 'Details',
  "i18nKey_web_2090": 'Back',
  "i18nKey_web_2091": 'Operate',
  "i18nKey_web_2092": 'Manual meter reading details',
  "i18nKey_web_2093": "It can't be null during meter reading",
  "i18nKey_web_2094": 'The accrual month cannot be empty',
  "i18nKey_web_2095": 'Meter reading period cannot be null',
  "i18nKey_web_2096": 'Meter reading import',
  "i18nKey_web_2097": 'Meter(s) not read',
  "i18nKey_web_2098": 'Meter(s) not read /read this month',
  "i18nKey_web_2099": 'Data exception',
  "i18nKey_web_3000": 'Submit successfully',
  "i18nKey_web_3001": 'Exporting, please wait...',
  "i18nKey_web_3002": 'Refresh',
  "i18nKey_web_3003": 'Cold quantity',
  "i18nKey_web_3004": 'Please input electricity meter no., device name, merchant/tenant',
  "i18nKey_web_3005": 'Year',
  "i18nKey_web_3006": 'Self-define',
  "i18nKey_web_3007": 'Please input water meter no., merchant/tenant name',
  "i18nKey_web_3008": 'Please input cold quantity meter no., merchant/tenant name',
  "i18nKey_web_3009": 'Meter reading (kWh)',
  "i18nKey_web_3010": 'Water meter table no.',
  "i18nKey_web_3011": 'Water meter no.',
  "i18nKey_web_3012": 'Rate',
  "i18nKey_web_3013": 'Cold quantity meter no.',
  "i18nKey_web_3014": 'Cold quantity meter no.',
  "i18nKey_web_3015": 'Cold consumption',
  "i18nKey_web_3016": 'Total number of exceptions(s)',
  "i18nKey_web_3017": 'Data loss(s)',
  "i18nKey_web_3018": 'Data exception(s)',
  "i18nKey_web_3019": 'Main meter(s)',
  "i18nKey_web_3020": 'Online(s)',
  "i18nKey_web_3021": 'Offline (s)',
  "i18nKey_web_3022": 'Standbys(s)',
  "i18nKey_web_3023": 'Automatic meter reading details list',
  "i18nKey_web_3024": 'Overview Situation',
  "i18nKey_web_3025": 'Alarm work order',
  "i18nKey_web_3026": 'Associated camera',
  "i18nKey_web_3027": 'Elevator failure analysis',
  "i18nKey_web_3028": 'Elevator location',
  "i18nKey_web_3029": 'Elevator status',
  "i18nKey_web_3030": 'Fire emergency return',
  "i18nKey_web_3031": 'Fire protection',
  "i18nKey_web_3032": 'Out of service',
  "i18nKey_web_3033": 'Under overhauled',
  "i18nKey_web_3034": 'Elevator locking',
  "i18nKey_web_3035": 'Going up',
  "i18nKey_web_3036": 'Going down',
  "i18nKey_web_3037": 'Under operation',
  "i18nKey_web_3038": 'Elevator name',
  "i18nKey_web_3039": 'Event description',
  "i18nKey_web_3040": 'Event status',
  "i18nKey_web_3041": 'Occurrence place',
  "i18nKey_web_3042": 'Occurrence time',
  "i18nKey_web_3043": 'Subsystem',
  "i18nKey_web_3044": 'Event details',
  "i18nKey_web_3045": 'Prompt: The video delays about 5-10s, please wait.',
  "i18nKey_web_3046": 'Camera loading, please wait',
  "i18nKey_web_3047": 'Current time:',
  "i18nKey_web_3048": 'No',
  "i18nKey_web_3049": 'The video pictures display in 5-10s, please wait',
  "i18nKey_web_3050": 'Input camera alias',
  "i18nKey_web_3051": 'View camera',
  "i18nKey_web_3052": "Can't connect more than 3 cameras!",
  "i18nKey_web_3053": 'Camera',
  "i18nKey_web_3054": 'Alarm list',
  "i18nKey_web_3055": 'Order list',
  "i18nKey_web_3056": 'Completed',
  "i18nKey_web_3057": 'Cancel',
  "i18nKey_web_3058": 'Workorder status',
  "i18nKey_web_3059": 'Connect up to 3 cameras',
  "i18nKey_web_3060": 'Modify time',
  "i18nKey_web_3061": 'Edit',
  "i18nKey_web_3062": 'Elevator(s)',
  "i18nKey_web_3063": 'Freight elevator',
  "i18nKey_web_3064": 'Passenger elevator',
  "i18nKey_web_3065": 'Other',
  "i18nKey_web_3066": 'Please input elevator name',
  "i18nKey_web_3067": 'All locations',
  "i18nKey_web_3068": 'Please select elevator status',
  "i18nKey_web_3069": 'Current floor',
  "i18nKey_web_3070": 'Error code',
  "i18nKey_web_3071": 'Update time',
  "i18nKey_web_3072": 'Emergency alarm',
  "i18nKey_web_3073": 'Important alarm',
  "i18nKey_web_3074": 'Ordinary alarm',
  "i18nKey_web_3075": 'Prompt event',
  "i18nKey_web_3076": 'Please select alarm level',
  "i18nKey_web_3077": 'Pending',
  "i18nKey_web_3078": 'Please select event status',
  "i18nKey_web_3079": 'Please select order status',
  "i18nKey_web_3080": 'Order no',
  "i18nKey_web_3081": 'Please input workorder no',
  "i18nKey_web_3082": 'Please input responsible person',
  "i18nKey_web_3083": 'Distribute time',
  "i18nKey_web_3084": 'Please input distribute time',
  "i18nKey_web_3085": 'Task assigner',
  "i18nKey_web_3086": 'Status',
  "i18nKey_web_3087": 'Posted',
  "i18nKey_web_3088": 'Canceled',
  "i18nKey_web_3089": 'Event no/Alarm no',
  "i18nKey_web_3090": 'Event type/report type',
  "i18nKey_web_3091": 'Alarm time/Reporting time',
  "i18nKey_web_3092": 'Associated system details',
  "i18nKey_web_3093": 'Associated order No',
  "i18nKey_web_3094": 'Complete description',
  "i18nKey_web_3095": 'Image details',
  "i18nKey_web_3096": 'Security situation',
  "i18nKey_web_3097": 'Important event',
  "i18nKey_web_3098": 'Device classification',
  "i18nKey_web_3099": 'Please select device classification',
  "i18nKey_web_3100": 'Facility classification',
  "i18nKey_web_3101": 'A',
  "i18nKey_web_3102": 'Online',
  "i18nKey_web_3103": 'Offline ',
  "i18nKey_web_3104": 'Facility level',
  "i18nKey_web_3105": 'Facility system',
  "i18nKey_web_3106": 'Fault description',
  "i18nKey_web_3107": 'Time',
  "i18nKey_web_3108": 'Minute',
  "i18nKey_web_3109": 'Second',
  "i18nKey_web_3110": 'View',
  "i18nKey_web_3111": 'Facility Workorder',
  "i18nKey_web_3112": 'Staff library selection',
  "i18nKey_web_3113": 'Type of work',
  "i18nKey_web_3114": 'Job number',
  "i18nKey_web_3115": 'Phone',
  "i18nKey_web_3116": "can't export null data",
  "i18nKey_web_3117": 'The maximum number of exported data items cannot exceed 200. Enter criteria for filtering',
  "i18nKey_web_3118": 'Property',
  "i18nKey_web_3119": 'New work order successful',
  "i18nKey_web_3120": 'Total',
  "i18nKey_web_3121": 'Report events',
  "i18nKey_web_3122": 'Synchronous data',
  "i18nKey_web_3123": 'Please select',
  "i18nKey_web_3124": 'Build',
  "i18nKey_web_3125": 'Season',
  "i18nKey_web_3126": '7 days',
  "i18nKey_web_3127": '30 days',
  "i18nKey_web_3128": 'Total',
  "i18nKey_web_3129": 'Electric meter details',
  "i18nKey_web_3130": 'Report',
  "i18nKey_web_3131": 'Chart',
  "i18nKey_web_3132": 'Reading',
  "i18nKey_web_3133": 'energy used（kWh ）',
  "i18nKey_web_3134": 'Cold consumption（kWh ）',
  "i18nKey_web_3135": 'Cold consumption data statistics（kW ）',
  "i18nKey_web_3136": 'List of ammeter details',
  "i18nKey_web_3137": 'Instrument serial number',
  "i18nKey_web_3138": 'Water consumption statistics （m³ ）',
  "i18nKey_web_3139": 'Qo meter',
  "i18nKey_web_3140": 'Business event',
  "i18nKey_web_3141": 'Facility event',
  "i18nKey_web_3142": 'Report event',
  "i18nKey_web_3143": 'Push method',
  "i18nKey_web_3144": 'Select date and time',
  "i18nKey_web_3145": 'Distribute type',
  "i18nKey_web_3146": 'Please enter content',
  "i18nKey_web_3147": 'Please select distribute type',
  "i18nKey_web_3148": 'Please select start time',
  "i18nKey_web_3149": 'Please select the end time',
  "i18nKey_web_3150": 'Work order dispatched successfully',
  "i18nKey_web_3151": 'MQDCapp Task',
  "i18nKey_web_3152": 'Push staff',
  "i18nKey_web_3153": 'Please enter your name and phone number',
  "i18nKey_web_3154": 'Avatar',
  "i18nKey_web_3155": 'Cleaning',
  "i18nKey_web_3156": 'Property',
  "i18nKey_web_3157": 'security',
  "i18nKey_web_3158": 'Prompt text',
  "i18nKey_web_3159": 'Export',
  "i18nKey_web_3160": 'Registration time',
  "i18nKey_web_3161": 'Confirm to delete?',
  "i18nKey_web_3162": 'Please enter the cold meter name',
  "i18nKey_web_3163": 'Device operating parameters',
  "i18nKey_web_3164": 'Historical equipment operating parameters',
  "i18nKey_web_3165": 'flow',
  "security_4_gis": "determine",
  "security_12_gis": "Cancel",
  "i18nKey_web_3166": 'Please input region or device name',
  "i18nKey_web_3167": 'Entry (times)',
  "i18nKey_web_3168": 'Leave (times)',
  "i18nKey_web_3169": 'Entrance',
  "i18nKey_web_3170": 'Middle',
  "i18nKey_web_3171": 'Exit',
  "i18nKey_web_3172": 'Area junction',
  "i18nKey_web_3173": 'Interviewed build',
  "i18nKey_web_3174": 'Please enter the interviewed building',
  "i18nKey_web_3175": 'toilet',
  "i18nKey_web_3176": 'Please choose a restroom first',
  "i18nKey_web_3177": 'Reporte type',
  "i18nKey_web_3178": 'Please select the reporting type',
  "i18nKey_web_3179": 'Name of toilet',
  "i18nKey_web_3180": 'The thing matters',
  "i18nKey_web_3181": 'The business center',
  "i18nKey_web_3182": 'The alarm center',
  "i18nKey_web_3183": 'A maximum of 10,000 records can be exported each time',
  "i18nKey_web_3184": 'Car statistics',
  "i18nKey_web_3185": 'Elevator has arrived',
  "i18nKey_web_3186": 'Elevator is closed',
  "i18nKey_web_3187": 'Elevator shut down',
  "i18nKey_web_3188": 'Elevator opening',
  security_0_gis: "The page you visited doesn't exist, please check the link in the address bar and visit again",
  security_1_gis: "Sorry, you don’t have any permission, please contact the administrator",
  security_2_gis: "Video buffering",
  security_3_gis: "Failed to obtain video resource",
  security_5_gis: "Note: Suitable for table pages without nested components on the list page",
  security_6_gis: "Need to pass in the class name",
  security_7_gis: "To find what you need",
  security_8_gis: "Element",
  security_9_gis: "Original",
  security_10_gis: "Need to set initial value properties",
  security_11_gis: "Need to set the outermost label",
  security_13_gis: "Confirm",
  security_14_gis: "Enter keywords to filter",
  security_15_gis: "Select camera",
  security_16_gis: "Video camera",
  security_17_gis: "Staff heat",
  security_18_gis: "Security personnel",
  security_19_gis: "Security box",
  security_20_gis: "Data exception",
  security_21_gis: "No data that meets the query conditions",
  security_22_gis: "Edit",
  security_23_gis: "Last day",
  security_24_gis: "Significant blacklist visitor warning",
  security_25_gis: "Click to view",
  security_26_gis: "Select associated device",
  security_27_gis: "Search",
  security_28_gis: "Equipment name",
  security_29_gis: "device ID",
  security_30_gis: "Vehicle exit record",
  security_31_gis: "",
  security_32_gis: "Fire incident trends",
  security_33_gis: "Cumulative total charge",
  security_34_gis: "Cumulative total discharge",
  security_35_gis: "Details of inspectors",
  security_36_gis: "Name",
  security_37_gis: "Job number",
  security_38_gis: "Phone",
  security_39_gis: "Position",
  security_40_gis: "On-job situation",
  security_41_gis: "Total number of work orders",
  security_42_gis: "Processing",
  security_43_gis: "To be processed",
  security_44_gis: "Save",
  security_45_gis: "Overall trend - National",
  security_46_gis: "Task details",
  security_47_gis: "Responsible",
  security_48_gis: "Status",
  security_49_gis: "Process",
  security_50_gis: "Statistics card configuration",
  security_51_gis: "Current page is",
  security_52_gis: "Please select no more than",
  security_53_gis: "Statistics cards",
  security_54_gis: "Play record",
  security_55_gis: "Clock-in record",
  security_56_gis: "Export",
  security_57_gis: "Record details",
  security_58_gis: "Camera name",
  security_59_gis: "Clock-in person",
  security_60_gis: "Clock-in time",
  security_61_gis: "Not clocked in",
  security_62_gis: "XX patrol group",
  security_63_gis: "play time",
  security_64_gis: "End Time",
  security_65_gis: "Time",
  security_66_gis: "View now",
  security_67_gis: "Create",
  security_68_gis: "Alarm list",
  security_69_gis: "By time",
  security_70_gis: "By similarity",
  security_71_gis: "Camera 001",
  security_72_gis: "Cloth control camera",
  security_73_gis: "Welcome to use",
  security_74_gis: "Platform, please log in to your account after changing the password",
  security_75_gis: "Phone number",
  security_76_gis: "Please enter phone number",
  security_77_gis: "Verification code",
  security_78_gis: "Please enter verification code",
  security_79_gis: "Second",
  security_80_gis: "Obtain verification code",
  security_81_gis: "Password",
  security_82_gis: "Combination of digits and letters",
  security_83_gis: "Submit",
  security_84_gis: "Back to login",
  security_85_gis: "Please enter the correct phone number",
  security_86_gis: "Please enter correct verify code",
  security_87_gis: "Please enter password",
  security_88_gis: "Password can't contain spaces",
  security_89_gis: "Password strength doesn't match",
  security_90_gis: "At least 8",
  security_91_gis: "Include numbers",
  security_92_gis: "capital and lowercase letters",
  security_93_gis: "At least three types of special characters",
  security_94_gis: "Please enter phone number!",
  security_95_gis: "please enter a valid phone number!",
  security_96_gis: "Change the password successfully, please log in again",
  security_97_gis: "You have successfully changed your password, please log in to the platform again.",
  security_98_gis: "Will automatically jump to",
  security_99_gis: "Login page",
  security_100_gis: "Go to",
  security_101_gis: "Congratulations, successful registration!",
  security_102_gis: "Please contact the administrator to obtain related service permissions.",
  security_103_gis: "Platform, please reset your password",
  security_104_gis: "Use mobile phone number verification",
  security_105_gis: "Use domain account verification",
  security_106_gis: "Domain account",
  security_107_gis: "Please enter domain account",
  security_108_gis: "Old password",
  security_109_gis: "Please enter the old password",
  security_110_gis: "New password",
  security_111_gis: "Please enter a new password",
  security_112_gis: "Confirm the new password",
  security_113_gis: "Please enter the new password again",
  security_114_gis: "Confirm the changes",
  security_115_gis: "Please enter the phone number",
  security_116_gis: "Please enter the original password",
  security_117_gis: "The new password can't be the same as the old password",
  security_118_gis: "Please enter the password again",
  security_119_gis: "The two passwords entered are inconsistent",
  security_120_gis: "The password must be 8-20 digits long and contain numbers and letters",
  security_121_gis: "Please enter your mobile phone number or domain account",
  security_122_gis: "Please enter your name or job number",
  security_123_gis: "Personnel pool selection",
  security_124_gis: "Type of work",
  security_125_gis: "Real-time Air Quality Index",
  security_126_gis: "Excellent",
  security_127_gis: "Good",
  security_128_gis: "Light pollution",
  security_129_gis: "Moderately polluted",
  security_130_gis: "Heavy pollution",
  security_131_gis: "Serious pollution",
  security_132_gis: "Tips",
  security_133_gis: "Task",
  security_134_gis: "Hundred million",
  security_135_gis: "Ten million",
  security_136_gis: "Million",
  security_137_gis: "Ten thousand",
  security_138_gis: "Minimize",
  security_139_gis: "Maximize",
  security_140_gis: "Upload image",
  security_141_gis: "Delete image",
  security_142_gis: "Tip: only support",
  security_143_gis: "Upload pictures in a format, the pic size upload  not exceed 3",
  security_144_gis: "Picture size",
  security_145_gis: "Picture type",
  security_146_gis: "Generate time",
  security_147_gis: "Prompt text",
  security_148_gis: "The uploaded image format is incorrect, please upload again",
  security_149_gis: "Picture name uploaded not standardized, only numbers, letters, underscores, and horizontal lines allowed",
  security_150_gis: "Upload image no greater than 3",
  security_151_gis: "Please re-upload",
  security_152_gis: "upload failed",
  security_153_gis: "Public area energy consumption",
  security_154_gis: "Sub public area energy consumption",
  security_155_gis: "the day before",
  security_156_gis: "the day after",
  security_157_gis: "Last year",
  security_158_gis: "last month",
  security_159_gis: "Month",
  security_160_gis: "Last year",
  security_161_gis: "Year",
  security_162_gis: "Total power consumption",
  security_163_gis: "Public area power consumption",
  security_164_gis: "Public area",
  security_165_gis: "Predictive value",
  security_166_gis: "Target value",
  security_167_gis: "Percentage",
  security_168_gis: "Compared",
  security_169_gis: "The same period",
  security_170_gis: "Chain ratio",
  security_171_gis: "YoY (Year on Year)",
  security_172_gis: "View details",
  security_173_gis: "Total discharge yesterday",
  security_174_gis: "Represents abnormal",
  security_175_gis: "Total charge yesterday",
  security_176_gis: "Saved yesterday",
  security_177_gis: "CNY",
  security_178_gis: "Energy storage statistics",
  security_179_gis: "All",
  security_180_gis: "North Gate",
  security_181_gis: "East gate",
  security_182_gis: "Day",
  security_183_gis: "Week",
  security_184_gis: "Quantity",
  security_185_gis: "Switch setting",
  security_186_gis: "Turn on the lights",
  security_187_gis: "Turn off the lights",
  security_188_gis: "Brightness display",
  security_189_gis: "Application",
  security_190_gis: "Offline",
  security_191_gis: "The instruction is issued successfully, and the status is updated in about 30 seconds, please wait patiently",
  security_192_gis: "Slightly bright",
  security_193_gis: "Moderately bright",
  security_194_gis: "High bright",
  security_195_gis: "Welcome screen selected",
  security_196_gis: "Select all",
  security_197_gis: "Move up",
  security_198_gis: "Move down",
  security_199_gis: "Move out",
  security_200_gis: "Selected camera",
  security_201_gis: "Picture has been saved to",
  security_202_gis: "Image capture failed",
  security_203_gis: "Error code:",
  security_204_gis: "License plate number",
  security_205_gis: "Owner type",
  security_206_gis: "Car owner",
  security_207_gis: "Owner's name",
  security_208_gis: "Control period",
  security_209_gis: "to",
  security_210_gis: "Vehicle trajectory",
  security_211_gis: "View",
  security_212_gis: "Related events",
  security_213_gis: "Modify",
  security_214_gis: "Re-deploy",
  security_215_gis: "The task has generated an alarm",
  security_216_gis: "can't be modified!",
  security_217_gis: "The task is starting",
  security_218_gis: "Please modify after pause!",
  security_219_gis: "not initiated",
  security_220_gis: "Activated",
  security_221_gis: "Stopped",
  security_222_gis: "Start up",
  security_223_gis: "Stop",
  security_224_gis: "View warning",
  security_225_gis: "Misjudgment",
  security_226_gis: "Incident report",
  security_227_gis: "time:",
  security_228_gis: "location:",
  security_229_gis: "source:",
  security_230_gis: "level",
  security_231_gis: "Please select level",
  security_232_gis: "Types of",
  security_233_gis: "Please choose the type",
  security_234_gis: "Description",
  security_235_gis: "Report",
  security_236_gis: "Confirm that this alarm is a misjudgment, this alarm information is invalid",
  security_237_gis: "Video patrol manual report",
  security_238_gis: "Temperature",
  security_239_gis: "Humidity",
  security_240_gis: "Formaldehyde",
  security_241_gis: "CO2",
  security_242_gis: "Negative oxygen ions",
  security_243_gis: "individual",
  security_244_gis: "Noise",
  security_245_gis: "Organic volatiles",
  security_246_gis: "Outdoor",
  security_247_gis: "Indoor",
  security_248_gis: "Environmental space",
  security_249_gis: "Article",
  security_250_gis: "Frame",
  security_251_gis: "Environmental Index",
  security_252_gis: "Street lamp statistics",
  security_253_gis: "Asset Statistics",
  security_254_gis: "Usage rate",
  security_255_gis: "task progress",
  security_256_gis: "Environmental space title",
  security_257_gis: "The deployment time can't be null",
  security_258_gis: "The deployment time must be greater than or equal to the current time",
  security_259_gis: "The task name can't be null",
  security_260_gis: "Please upload an image",
  security_261_gis: "Please select person",
  security_262_gis: "The person has no image and can't be deployed",
  security_263_gis: "Please select the associated face library",
  security_264_gis: "Description can't be null",
  security_265_gis: "Please select the type of deployment",
  security_266_gis: "Please select a deployment camera",
  security_267_gis: "Created successfully",
  security_268_gis: "Successfully modified",
  security_269_gis: "Confirm to give up?",
  security_270_gis: "Property announcement",
  security_271_gis: "Sticking to fundamental principles in operation",
  security_272_gis: "Struggle in partnership.",
  security_273_gis: "Leave the comfort zone",
  security_274_gis: "Is the first step forward.",
  security_275_gis: "Cross thousands miles of mountains",
  security_276_gis: "Overcome thousands of difficulties.",
  security_277_gis: "There is no retreat behind the fighter",
  security_278_gis: "There is no danger ahead of courage.",
  security_279_gis: "Unity",
  security_280_gis: "Go all out.",
  security_281_gis: "It's not about the strength",
  security_282_gis: "It depends on how long it can last.",
  security_283_gis: "To find a way to succeed",
  security_284_gis: "No reason for failure.",
  security_285_gis: "A long way to go",
  security_286_gis: "Determined to win",
  security_287_gis: "Consensus, co-creation, responsibility, sharing.",
  security_288_gis: "Only blunt axe",
  security_289_gis: "There is no firewood that can't be split.",
  security_290_gis: "Not supported by current browser",
  security_291_gis: "The control vehicle data has been deleted, it doesn't support modification or re-arrangement, please re-create the deployment control",
  security_292_gis: "License plate number can't be null",
  security_293_gis: "New library",
  security_294_gis: "Delete library",
  security_295_gis: "Public security blacklist",
  security_296_gis: "More",
  security_297_gis: "",
  security_298_gis: "",
  security_299_gis: "Blacklist",
  security_300_gis: "temporary",
  security_301_gis: "Interview",
  security_302_gis: "Other",
  security_303_gis: "Tracking task",
  security_304_gis: "Please enter task or person name",
  security_305_gis: "Tracking time",
  security_306_gis: "Total alarms",
  security_307_gis: "Please enter the minimum number of alarms",
  security_308_gis: "Please enter the maximum number of alarms",
  security_309_gis: "Inquire",
  security_310_gis: "Reset",
  security_311_gis: "Opening time",
  security_312_gis: "Number of warnings",
  security_313_gis: "View track",
  security_314_gis: "China",
  security_315_gis: "Shanghai area",
  security_316_gis: "Shanghai",
  security_317_gis: "Jinqiao Wanchuang",
  security_318_gis: "F",
  security_319_gis: "B2",
  security_320_gis: "Parking lot",
  security_321_gis: "Personnel moved to",
  security_322_gis: "Maximum alarm number no less than or equal to the minimum ",
  security_323_gis: "Event",
  security_324_gis: "Refresh",
  security_325_gis: "On",
  security_326_gis: "Off",
  security_327_gis: "Event processing time",
  security_328_gis: "Average processing time:",
  security_329_gis: "Shortest time consuming",
  security_330_gis: "Longest time consuming",
  security_331_gis: "Rank",
  security_332_gis: "Time cost",
  security_333_gis: "Minute",
  security_334_gis: "Minute",
  security_335_gis: "Day",
  security_336_gis: "Time",
  security_337_gis: "Public security",
  security_338_gis: "Park owns",
  security_339_gis: "Pieces",
  security_340_gis: "Total quantity",
  security_341_gis: "Compared to yesterday",
  security_342_gis: "Yesterday",
  security_343_gis: "Compared to last week",
  security_344_gis: "Last week",
  security_345_gis: "Compared to last month",
  security_346_gis: "Visit source",
  security_347_gis: "Failure and operational optimization",
  security_348_gis: "Fault type",
  security_349_gis: "Type",
  security_350_gis: "Fault number",
  security_351_gis: "Energy saving potential",
  security_352_gis: "Alarm classification statistics",
  security_353_gis: "Alarm type",
  security_354_gis: "Source of alarm",
  security_355_gis: "Alert level",
  security_356_gis: "Number of occurrences",
  security_357_gis: "Processing quantity",
  security_358_gis: "Average processing time",
  security_359_gis: "Video patrol statistics",
  security_360_gis: "Please log in to your account",
  security_361_gis: "Log in",
  security_362_gis: "Registered",
  security_363_gis: "Account login",
  security_364_gis: "Scan code login",
  security_365_gis: "Account number",
  security_366_gis: "Please enter account or mobile number",
  security_367_gis: "Remember current account",
  security_368_gis: "Forgot password",
  security_369_gis: "Log in now",
  security_370_gis: "QR code has expired",
  security_371_gis: "Download",
  security_372_gis: "Yuncheng",
  security_373_gis: "Scan QR code",
  security_374_gis: "Scan to download Yuncheng",
  security_375_gis: "Android version",
  security_376_gis: "Please enter account or mobile number",
  security_377_gis: "Platform, please register",
  security_378_gis: "A combination of at least 2 types of letters, numbers and special characters",
  security_379_gis: "Nickname",
  security_380_gis: "Please enter a nickname",
  security_381_gis: "Sign up now",
  security_382_gis: "Password length is 8",
  security_383_gis: "Bit character",
  security_384_gis: "The password must include at least 2 of letters, numbers, and symbols",
  security_385_gis: "Nickname can't exceed 12 characters in length",
  security_386_gis: "registration failed",
  security_387_gis: "Operator",
  security_388_gis: "Please select operator",
  security_389_gis: "Operating time",
  security_390_gis: "Please select a start date",
  security_391_gis: "End date",
  security_392_gis: "Function",
  security_393_gis: "Please select function",
  security_394_gis: "Please choose an operation",
  security_395_gis: "S/N",
  security_396_gis: "Content",
  security_397_gis: "Microsoft Yahei",
  security_398_gis: "Real-time video",
  security_399_gis: "View camera",
  security_400_gis: "Born",
  security_401_gis: "identification number:",
  security_402_gis: "Domicile:",
  security_403_gis: "Information Sources:",
  security_404_gis: "Shut down",
  security_405_gis: "Deployment task deleted",
  security_406_gis: "View historical video, real-time video, pictures associated with the alarm in 'Alarm Details', and view location info on the map",
  security_407_gis: "Alarm Details shortcut operation bar, click to jump to 'History Track' to view all history",
  security_408_gis: "Distribute alarm work order tasks to the movement of patrol security",
  security_409_gis: "Patrol security is on the move",
  security_410_gis: "View detailed information of tasks and associated alarms: historical videos, real-time videos, pictures, etc.",
  security_411_gis: "Patrol security guards go to the incident site to check on the spot according to the alarm location information",
  security_412_gis: "Handle work order tasks, upload on-site photos, and enter text information of processing results",
  security_413_gis: "Archive the historical video and captured pictures of alarms, as well as the text and pictures of the dispatching task feedback, and save them for long-term archiving for later review.",
  security_414_gis: "Alarm picture",
  security_415_gis: "No picture",
  security_416_gis: "Match",
  security_417_gis: "Time of occurrence",
  security_418_gis: "Event level",
  security_419_gis: "Location occur",
  security_420_gis: "No location information",
  security_421_gis: "Event code",
  security_422_gis: "No device information",
  security_423_gis: "Event source",
  security_424_gis: "Equipment category",
  security_425_gis: "Duration",
  security_426_gis: "Equipment number",
  security_427_gis: "Processor",
  security_428_gis: "Event description",
  security_429_gis: "Handler's cell phone",
  security_430_gis: "Distribution task",
  security_431_gis: "Total",
  security_432_gis: "tasks",
  security_433_gis: "Add",
  security_434_gis: "",
  security_435_gis: "group",
  security_436_gis: "Picture cropping",
  security_437_gis: "",
  security_438_gis: "",
  security_439_gis: "Alarm number",
  security_440_gis: "Alarm status",
  security_441_gis: "Warning level",
  security_442_gis: "Location",
  security_443_gis: "Alarm handler",
  security_444_gis: "Alert title",
  security_445_gis: "User card number",
  security_446_gis: "Closing time",
  security_447_gis: "solution",
  security_448_gis: "user account",
  security_449_gis: "Treatment type",
  security_450_gis: "Handler fixed phone",
  security_451_gis: "Asset Number",
  security_452_gis: "Alarm description",
  security_453_gis: "Event dynamics",
  security_454_gis: "Notify personnel, evacuate building personnel",
  security_455_gis: "Dial 119, 120",
  security_456_gis: "Get in touch with the emergency team and start deploying evacuation",
  security_457_gis: "An alarm occurred on the smoke detector on the first floor of Building 1, Chengdu Park",
  security_458_gis: "Image",
  security_459_gis: "Founder:",
  security_460_gis: "Big",
  security_461_gis: "Small:",
  security_462_gis: "Video",
  security_463_gis: "Complete",
  security_464_gis: "Please select a camera",
  security_465_gis: "View real-time monitoring",
  security_466_gis: "More news",
  security_467_gis: "Snap",
  security_468_gis: "Full screen playback",
  security_469_gis: "Historical video",
  security_470_gis: "End of historical video playback",
  security_471_gis: "Control matching",
  security_472_gis: "Download image",
  security_473_gis: "View alarm",
  security_474_gis: "New task",
  security_475_gis: "More tasks",
  security_476_gis: "location",
  security_477_gis: "source",
  security_478_gis: "Please enter a description",
  security_479_gis: "Manual upload of video patrol",
  security_480_gis: "Robert",
  security_481_gis: "Get up at 3:00 am",
  security_482_gis: "",
  security_483_gis: "Tomorrow 11:30 meeting report",
  security_484_gis: "Tomorrow 14:30 meeting report",
  security_485_gis: "task list",
  security_486_gis: "Re-delegation",
  security_487_gis: "View more",
  security_488_gis: "Alert",
  security_489_gis: "Staff",
  security_490_gis: "Visitors",
  security_491_gis: "Property security",
  security_492_gis: "Single pie chart statistics",
  security_493_gis: "People",
  security_494_gis: "Bulk removal",
  security_495_gis: "Model",
  security_496_gis: "Zhang San",
  security_497_gis: "Northern region",
  security_498_gis: "Southern region",
  security_499_gis: "Midwest region",
  security_500_gis: "Street light management",
  security_501_gis: "Street lamp name",
  security_502_gis: "Street light status",
  security_503_gis: "Open the door",
  security_504_gis: "Close the door",
  security_505_gis: "Alarm",
  security_506_gis: "Similarity:",
  security_507_gis: "Suspicious target personnel appeared in",
  security_508_gis: "area",
  security_509_gis: "Send someone to view",
  security_510_gis: "Number of online cameras",
  security_511_gis: "Enter camera alias",
  security_512_gis: "Online check-in",
  security_513_gis: "Check in successfully",
  security_514_gis: "Check in failed",
  security_515_gis: "Please select status",
  security_516_gis: "title",
  security_517_gis: "Event title must be entered",
  security_518_gis: "Please enter event description",
  security_519_gis: "Vanke Chengdu Park",
  security_520_gis: "Building 103",
  security_521_gis: "Event reported successfully",
  security_522_gis: "Incident reporting failed",
  security_523_gis: "Number of patrol groups",
  security_524_gis: "New patrol group",
  security_525_gis: "Video patrol list",
  security_526_gis: "Video patrol history",
  security_527_gis: "Loaded",
  security_528_gis: "Select label",
  security_529_gis: "Please enter content",
  security_530_gis: "Recommend Signature",
  security_531_gis: "Customer group analysis",
  security_532_gis: "Customer List",
  security_533_gis: "personnel number",
  security_534_gis: "Person Type",
  security_535_gis: "Visited the park in the past week",
  security_536_gis: "Visited the park in the early morning of the past week",
  security_537_gis: "User cycle",
  security_538_gis: "Get used to order time",
  security_539_gis: "the company",
  security_540_gis: "Face tracking is closing",
  security_541_gis: "personnel:",
  security_542_gis: "Following",
  security_543_gis: "Turn off tracking",
  security_544_gis: "Monitoring screenshot",
  security_545_gis: "The task is being tracked. Jumping to the page will cause the tracking task to stop. Are you sure you need to jump to the page?",
  security_546_gis: "You can only track one task at a time. Are you sure you need to abandon the current tracking task and start a new tracking task?",
  security_547_gis: "Turn off tracking exception",
  security_548_gis: "Alarm statistics",
  security_549_gis: "Times",
  security_550_gis: "Equipment alarm ranking",
  security_551_gis: "Processing log",
  security_552_gis: "No eligible data temporarily",
  security_553_gis: "Notify employees and evacuate building personnel",
  security_554_gis: "Notify employees and evacuate building personnel details",
  security_555_gis: "Task number",
  security_556_gis: "Alarm occurrence time",
  security_557_gis: "Location of the alarm",
  security_558_gis: "Task owner",
  security_559_gis: "Task status",
  security_560_gis: "To be received",
  security_561_gis: "Received",
  security_562_gis: "Processed",
  security_563_gis: "Task closing time",
  security_564_gis: "Task assignment time",
  security_565_gis: "Task delegator",
  security_566_gis: "Live feedback",
  security_567_gis: "Task notes",
  security_568_gis: "Live picture",
  security_569_gis: "Live video",
  security_570_gis: "Object name",
  security_571_gis: "Upload time",
  security_572_gis: "Uploader",
  security_573_gis: "Next step",
  security_574_gis: "Forward successfully",
  security_575_gis: "Forwarding failed",
  security_576_gis: "Meter",
  security_577_gis: "Unit",
  security_578_gis: "Statistics of faulty equipment types",
  security_579_gis: "equipment",
  security_580_gis: "Equipment Quantity",
  security_581_gis: "Number of equipment failures",
  security_582_gis: "Number of failed devices",
  security_583_gis: "today",
  security_584_gis: "Yesterday",
  security_585_gis: "Nearly one month",
  security_586_gis: "Nearly a year",
  security_587_gis: "Pass",
  security_588_gis: "People-time",
  security_589_gis: "Vehicle-time",
  security_590_gis: "Task name",
  security_591_gis: "Please enter the task name",
  security_592_gis: "Control status",
  security_593_gis: "Please select deployment status",
  security_594_gis: "Please select the park first",
  security_595_gis: "The task is starting, please don't delete",
  security_596_gis: "Confirm to delete?",
  security_597_gis: "Successfully deleted",
  security_598_gis: "Network Error",
  security_599_gis: "Successful operation",
  security_600_gis: "End of video patrol",
  security_601_gis: "Video is paused",
  security_602_gis: "Delete",
  security_603_gis: "Patrol route name",
  security_604_gis: "Please enter the name of the patrol route",
  security_605_gis: "Patrol route description",
  security_606_gis: "Please enter the patrol route description",
  security_607_gis: "Number of cameras",
  security_608_gis: "Patrol time",
  security_609_gis: "Whether to delete data",
  security_610_gis: "Please select to delete data",
  security_611_gis: "Whether to delete the checked data",
  security_612_gis: "This week",
  security_613_gis: "This month",
  security_614_gis: "Carried out",
  security_615_gis: "Route",
  security_616_gis: "Problem",
  security_617_gis: "Create",
  security_618_gis: "Execution time",
  security_619_gis: "Repeat method",
  security_620_gis: "Patrol plan name",
  security_621_gis: "remind",
  security_622_gis: "activated",
  security_623_gis: "Deactivate",
  security_624_gis: "Whether to loop",
  security_625_gis: "Yes",
  security_626_gis: "No",
  security_627_gis: "Enable",
  security_628_gis: "Please select the park",
  security_629_gis: "Enter name or number",
  security_630_gis: "Smoke",
  security_631_gis: "Access control",
  security_632_gis: "More layers back",
  security_633_gis: "Code",
  security_634_gis: "Backgend return",
  security_635_gis: "Please choose",
  security_636_gis: "Deal with",
  security_637_gis: "Please select processing method",
  security_638_gis: "Block the security incidents of the device",
  security_639_gis: "Transit facility warning",
  security_640_gis: "More layers",
  security_641_gis: "SMS",
  security_642_gis: "Event broadcast",
  security_643_gis: "Records",
  security_644_gis: "Download HD big picture",
  security_645_gis: "enterprise",
  security_646_gis: "Peer people",
  security_647_gis: "Go to deployment control",
  security_648_gis: "Face picture",
  security_649_gis: "Push method",
  security_650_gis: "Push content",
  security_651_gis: "Are you sure to push?",
  security_652_gis: "Push successfully",
  security_653_gis: "Server Error!",
  security_654_gis: "Distribution method",
  security_655_gis: "Dispatcher",
  security_656_gis: "The number of distribution can't exceed 10 people!",
  security_657_gis: "Event handling",
  security_658_gis: "The task was dispatched successfully",
  security_659_gis: "Snap a picture",
  security_660_gis: "No warning picture",
  security_661_gis: "No snapshots",
  security_662_gis: "Control Picture",
  security_663_gis: "Number of tasks",
  security_664_gis: "Associated alarm",
  security_665_gis: "Creation time",
  security_666_gis: "Map",
  security_667_gis: "Total",
  security_668_gis: "Closed",
  security_669_gis: "Please enter the alarm number",
  security_670_gis: "Please select the alarm level",
  security_671_gis: "Please select the alarm status",
  security_672_gis: "Please select the alarm type",
  security_673_gis: "Source system",
  security_674_gis: "Details",
  security_675_gis: "grade",
  security_676_gis: "can't export null data",
  security_677_gis: "List of fire incidents",
  security_678_gis: "Selected",
  security_679_gis: "Please enter a task description",
  security_680_gis: "Tip: After the dispatch task is processed, the event will be closed automatically.",
  security_681_gis: "Previous",
  security_682_gis: "New task completed",
  security_683_gis: "Task distribution",
  security_684_gis: "Select personnel",
  security_685_gis: "Delegate tasks",
  security_686_gis: "distance",
  security_687_gis: "Please select the person to assign the task",
  security_688_gis: "please enter keyword to search",
  security_689_gis: "Please enter the task content",
  security_690_gis: "Successfully added task",
  security_691_gis: "Process failed",
  security_692_gis: "Basic Information",
  security_693_gis: "Whether to start",
  security_694_gis: "Notify staff",
  security_695_gis: "New staff",
  security_696_gis: "Remind camera",
  security_697_gis: "After opening, it will be controlled, if you need to close it can be closed manually",
  security_698_gis: "After closing, it will no longer be controlled, if you need to enable it, you can manually turn it on again",
  security_699_gis: "success",
  security_700_gis: "Please enter task name and select camera",
  security_701_gis: "The person has no movement track during this time period",
  security_702_gis: "Successfully exported track",
  security_703_gis: "Track",
  security_704_gis: "Please enter your name or phone number",
  security_705_gis: "Track view",
  security_706_gis: "Track time period",
  security_707_gis: "Starting time",
  security_708_gis: "Personnel modification cancel",
  security_709_gis: "You haven't installed the video player yet. Do you want to download the video player plug-in now? After downloading, please install it yourself",
  security_710_gis: "No results returned",
  security_711_gis: "No result",
  security_712_gis: "Number of patrol aircraft",
  security_713_gis: "Play",
  security_714_gis: "Pause",
  security_715_gis: "Unfold",
  security_716_gis: "End",
  security_717_gis: "Camera number",
  security_718_gis: "Chart name",
  security_719_gis: "Total charge",
  security_720_gis: "Total discharge",
  security_721_gis: "Cost savings",
  security_722_gis: "Exceed target value",
  security_723_gis: "Security situation",
  security_724_gis: "Beijing",
  security_725_gis: "Chengdu",
  security_726_gis: "Guangzhou",
  security_727_gis: "Shenzhen",
  security_728_gis: "Total alarms",
  security_729_gis: "Major event",
  security_730_gis: "To be executed",
  security_731_gis: "Face tracking failed to start",
  security_732_gis: "Analysis index",
  security_733_gis: "Corporate employees",
  security_734_gis: "Back",
  security_735_gis: "Search by name",
  security_736_gis: "List of dishonest",
  security_737_gis: "Add to",
  security_738_gis: "Import",
  security_739_gis: "Download template",
  security_740_gis: "Select the node when you click the node",
  security_741_gis: "Expand all nodes by default",
  security_742_gis: "Add face",
  security_743_gis: "Only supports",
  security_744_gis: "Format picture and no more than 3",
  security_745_gis: "Please type in your name",
  security_746_gis: "List type",
  security_747_gis: "ID number",
  security_748_gis: "Please enter identification number",
  security_749_gis: "gender",
  security_750_gis: "male",
  security_751_gis: "Female",
  security_752_gis: "Residential Address",
  security_753_gis: "Please enter residential address",
  security_754_gis: "Expand all sections by default",
  security_755_gis: "Please select list type",
  security_756_gis: "Please enter the correct ID number",
  security_757_gis: "Picture only supports",
  security_758_gis: "Picture can't be larger than 3",
  security_759_gis: "Public security blacklist import template",
  security_760_gis: "Imported successfully",
  security_761_gis: "Import failed",
  security_762_gis: "Table",
  security_763_gis: "failed to delete",
  security_764_gis: "Please select the object to be deleted",
  security_765_gis: "confirm to delete",
  security_766_gis: "Please upload a picture",
  security_767_gis: "Successful operation!",
  security_768_gis: "Alarm statistics trend",
  security_769_gis: "General trend",
  security_770_gis: "Smoke alarm",
  security_771_gis: "Temperature alarm",
  security_772_gis: "Manual alarm",
  security_773_gis: "Fire alarm not processed",
  security_774_gis: "Real fire alarm",
  security_775_gis: "False report",
  security_776_gis: "Equipment statistics",
  security_777_gis: "Recent week",
  security_778_gis: "Recent half year",
  security_779_gis: "No statistics",
  security_780_gis: "Communication host",
  security_781_gis: "Offline (station)",
  security_782_gis: "Enable (unit)",
  security_783_gis: "Total quantity (set)",
  security_784_gis: "Smoke sensing equipment",
  security_785_gis: "Failure (unit)",
  security_786_gis: "No fault (set)",
  security_787_gis: "Temperature sensing equipment",
  security_788_gis: "Last week",
  security_789_gis: "Last month",
  security_790_gis: "First half year",
  security_791_gis: "Security incident statistics",
  security_792_gis: "Fire alarm trend",
  security_793_gis: "Percentage of security alarm types",
  security_794_gis: "Event status statistics",
  security_795_gis: "Security Alert Trend",
  security_796_gis: "Task status statistics",
  security_797_gis: "Number of pending alarms",
  security_798_gis: "Total number of tasks",
  security_799_gis: "Number of pending tasks",
  security_800_gis: "Last week",
  security_801_gis: "Last month",
  security_802_gis: "Last three months",
  security_803_gis: "Safety",
  security_804_gis: "Fire fighting",
  security_805_gis: "No data",
  security_806_gis: "Total processing quantity",
  security_807_gis: "Total security alert",
  security_808_gis: "Security alert",
  security_809_gis: "Fire alarm",
  security_810_gis: "Turn on tracking",
  security_811_gis: "Tracking successfully turned on",
  security_812_gis: "Real-time monitoring and manual upload",
  security_813_gis: "Play window replacement mode",
  security_814_gis: "Replace selected",
  security_815_gis: "Replace the earliest",
  security_816_gis: "Screenshot upload failed! Please try again",
  security_817_gis: "Single tour time",
  security_818_gis: "New plan",
  security_819_gis: "Patrol schedule",
  security_820_gis: "Historical statistics",
  security_821_gis: "Patrolman",
  security_822_gis: "Please enter patrol person",
  security_823_gis: "Start and stop time",
  security_824_gis: "Whether to complete the whole process",
  security_825_gis: "Vanke Park",
  security_826_gis: "North China Park",
  security_827_gis: "Please select the region",
  security_828_gis: "Please select the city",
  security_829_gis: "Please choose to the park",
  security_830_gis: "Please select time period",
  security_831_gis: "Access control monitoring",
  security_832_gis: "All access to the building",
  security_833_gis: "turn on",
  security_834_gis: "send messages",
  security_835_gis: "Access control",
  security_836_gis: "Upload picture",
  security_837_gis: "Upload",
  security_838_gis: "Related deployment",
  security_839_gis: "Add deployment control",
  security_840_gis: "Add face control",
  security_841_gis: "New vehicle deployment control",
  security_842_gis: "Deployment control live",
  security_843_gis: "Track now",
  security_844_gis: "Prompt warning",
  security_845_gis: "Uncertain alert",
  security_846_gis: "General warning",
  security_847_gis: "Important warning",
  security_848_gis: "Emergency alert",
  security_849_gis: "Phone call",
  security_850_gis: "Dial",
  security_851_gis: "Commonly used",
  security_852_gis: "Contact person",
  security_853_gis: "Disarm",
  security_854_gis: "Event push",
  security_855_gis: "Type is required",
  security_856_gis: "Captain Security-Calling",
  security_857_gis: "hang up",
  security_858_gis: "Personnel selection",
  security_859_gis: "Personnel Heat Map",
  security_860_gis: "No personnel",
  security_861_gis: "Send",
  security_862_gis: "Send SMS content can't be null",
  security_863_gis: "SMS sent successfully",
  security_864_gis: "Those who failed to send SMS",
  security_865_gis: "Please note that the above personnel can't receive SMS",
  security_866_gis: "Reminder of Vanke Smart Park Operation Center] Vanke Smart Park Operation Center reminds you:",
  security_867_gis: "Thank you!",
  security_868_gis: "SMS recipient can't be null",
  security_869_gis: "Personnel thermal backstage return",
  security_870_gis: "Cleaning",
  security_871_gis: "Property",
  security_872_gis: "security",
  security_873_gis: "Just selected",
  security_874_gis: "Search content",
  security_875_gis: "Put away",
  security_876_gis: "Avatar",
  security_877_gis: "Push staff",
  security_878_gis: "Please enter the task number",
  security_879_gis: "Please enter the task owner",
  security_880_gis: "Please select task status",
  security_881_gis: "Please enter the task delegate",
  security_882_gis: "process result",
  security_883_gis: "Forward",
  security_884_gis: "Business event",
  security_885_gis: "Facility event",
  security_886_gis: "Please select at least one person",
  security_887_gis: "don't enter special characters",
  security_888_gis: "Welcome",
  security_889_gis: "Deployment control",
  security_890_gis: "Failed to obtain registered user information, please contact the administrator!",
  security_891_gis: "Failed to obtain the registered user's picture information, please contact the administrator!",
  security_892_gis: "The control reason can't be null",
  security_893_gis: "Uploaded",
  security_894_gis: "The name of the picture can't be null and the name can only consist of Chinese characters, letters or numbers within 32 characters",
  security_895_gis: "Please select the informant",
  security_896_gis: "Not Enabled",
  security_897_gis: "Terminated",
  security_898_gis: "Closed successfully",
  security_899_gis: "Successfully activated",
  security_900_gis: "Deactivated successfully",
  security_901_gis: "Confirm deactivation",
  security_902_gis: "identification number",
  security_903_gis: "Information Sources",
  security_904_gis: "The network load is abnormal, please try again later",
  security_905_gis: "Network loading abnormal",
  security_906_gis: "No warning",
  security_907_gis: "Screenshot failed",
  security_908_gis: "New patrol route",
  security_909_gis: "List of patrol routes",
  security_910_gis: "Patrol plan",
  security_911_gis: "Patrol route",
  security_912_gis: "Turn off video patrol",
  security_913_gis: "Whether to pause video patrol",
  security_914_gis: "Whether to stop video patrol",
  security_915_gis: "The request to get the video resource failed",
  security_916_gis: "Failed to read video from file server",
  security_917_gis: "Similarity",
  security_918_gis: "Associated camera",
  security_919_gis: "Mine",
  security_920_gis: "Connected",
  security_921_gis: "Connect",
  security_922_gis: "Event-linked camera",
  security_923_gis: "Surrounding camera",
  security_924_gis: "Associated Camera",
  security_925_gis: "Choose start date and time",
  security_926_gis: "Choose end date and time",
  security_927_gis: "Video capture",
  security_928_gis: "The end time needs to be greater than the start time",
  security_929_gis: "Video playback",
  security_930_gis: "Replay",
  security_931_gis: "Please choose time",
  security_932_gis: "The duration should not be less than 30 seconds",
  security_933_gis: "don't exceed one hour",
  security_934_gis: "Intercepted successfully",
  security_935_gis: "The video was successfully intercepted, please go to the associated video in the event details on the left to view",
  security_936_gis: "Video capture failed",
  security_937_gis: "Failure",
  security_938_gis: "Major",
  security_939_gis: "Ordinary",
  security_940_gis: "Enter event title or device name or event number",
  security_941_gis: "Enter alarm title or facility alias or alarm number",
  security_942_gis: "Face tracking is starting",
  security_943_gis: "New face tracking",
  security_944_gis: "New face deployment control",
  security_945_gis: "Event type",
  security_946_gis: "Please select event type",
  security_947_gis: "Event level",
  security_948_gis: "Please select event level",
  security_949_gis: "track",
  security_950_gis: "Face tracking",
  security_951_gis: "Whitelist",
  security_952_gis: "The data can't be restored after deletion, confirm the deletion?",
  security_953_gis: "Data deleted successfully",
  security_954_gis: "Network loading abnormal, please try again later",
  security_955_gis: "Tracking period",
  security_956_gis: "Tracking status",
  security_957_gis: "Please select tracking status",
  security_958_gis: "Tracking camera",
  security_959_gis: "Please select a tracking camera",
  security_960_gis: "Whether to delete the alarms and tasks generated by the deployment task",
  security_961_gis: "human face",
  security_962_gis: "Please filter related tasks",
  security_963_gis: "Reminder period",
  security_964_gis: "Reminder window reached",
  security_965_gis: "Reminder status",
  security_966_gis: "Arrival reminder camera",
  security_967_gis: "Welcome time",
  security_968_gis: "Welcome state",
  security_969_gis: "Please select welcome status",
  security_970_gis: "Enter the welcome screen",
  security_971_gis: "Alarm view",
  security_972_gis: "Determine that this alarm is a misjudgment, this alarm is invalid",
  security_973_gis: "A certain building in Vanke Chengdu Park",
  security_974_gis: "Red emergency warning",
  security_975_gis: "Orange emergency warning",
  security_976_gis: "Yellow emergency warning",
  security_977_gis: "Blue emergency warning",
  security_978_gis: "Particularly serious accident",
  security_979_gis: "Major accident",
  security_980_gis: "Major accident",
  security_981_gis: "General accident",
  security_982_gis: "can't be null, please enter content.",
  security_983_gis: "Image",
  security_984_gis: "Upload local file",
  security_985_gis: "Select from the staff library",
  security_986_gis: "Alarm threshold",
  security_987_gis: "Automatically report events",
  security_988_gis: "Person tag",
  security_989_gis: "personnel",
  security_990_gis: "Person's name",
  security_991_gis: "Control type",
  security_992_gis: "Event notes",
  security_993_gis: "label",
  security_994_gis: "Time can't be null",
  security_995_gis: "Only upload up to 5 pictures",
  security_996_gis: "Picture size can't exceed 2",
  security_997_gis: "Can only upload",
  security_998_gis: "Format picture",
  security_999_gis: "The task name can't exceed 50 characters",
  security_1000_gis: "Fire incident",
  security_1001_gis: "Security incident",
  security_1002_gis: "Face tracking",
  security_1003_gis: "Select from the vehicle library",
  security_1004_gis: "Select vehicle",
  security_1005_gis: "Please enter the license plate number",
  security_1006_gis: "Vehicle label",
  security_1007_gis: "Please enter a note",
  security_1008_gis: "Vehicle library selection",
  security_1009_gis: "People track",
  security_1010_gis: "The deployment control has been activated and can't be modified",
  security_1011_gis: "The deployment has been stopped and can't be modified",
  security_1012_gis: "No related events",
  security_1013_gis: "Guest staff don't have this operation authority",
  security_1014_gis: "No personnel track",
  security_1015_gis: "Network loading abnormal, please try again later",
  security_1016_gis: "Misjudgment success",
  security_1017_gis: "Please select event level",
  security_1018_gis: "Please fill in the event description",
  security_1019_gis: "Event description can't exceed 1000 characters",
  security_1020_gis: "The event status is pending and can't be reported temporarily",
  security_1021_gis: "The event status is processing and can't be reported temporarily",
  security_1022_gis: "The event status is pending and can't be reported temporarily",
  security_1023_gis: "Please enter patrol time",
  security_1024_gis: "Remarks",
  security_1025_gis: "Please enter a description of the patrol group",
  security_1026_gis: "Patrol name can't be null",
  security_1027_gis: "Patrol name can't exceed 200 characters",
  security_1028_gis: "Time can't be 0",
  security_1029_gis: "The time must be a non-zero positive integer",
  security_1030_gis: "Please select at least one camera",
  security_1031_gis: "Created successfully",
  security_1032_gis: "Preview",
  security_1033_gis: "Statistics Time:",
  security_1034_gis: "Please select a date range",
  security_1035_gis: "Only planned patrols participate in statistics",
  security_1036_gis: "Implementation (times)",
  security_1037_gis: "Camera",
  security_1038_gis: "Video punch rate",
  security_1039_gis: "Total execution time (minutes)",
  security_1040_gis: "Route ",
  security_1041_gis: "Alarm events and follow-up status",
  security_1042_gis: "Only display the number of video patrol alarms",
  security_1043_gis: "Patrol plan history",
  security_1044_gis: "Patrol route history",
  security_1045_gis: "All patrol history",
  security_1046_gis: "Event name",
  security_1047_gis: "Event status",
  security_1048_gis: "Period",
  security_1049_gis: "Camera capture",
  security_1050_gis: "Historical track",
  security_1051_gis: "The person is being tracked. Jumping the page will cause the tracking task to stop. Are you sure you need to jump to the page?",
  security_1052_gis: "Select location",
  security_1053_gis: "country",
  security_1054_gis: "Please select a country",
  security_1055_gis: "Please select a region",
  security_1056_gis: "City",
  security_1057_gis: "Please select city",
  security_1058_gis: "Park",
  security_1059_gis: "Building",
  security_1060_gis: "Please select a building",
  security_1061_gis: "floor",
  security_1062_gis: "Please select floor",
  security_1063_gis: "Floor space",
  security_1064_gis: "Please select floor space",
  security_1065_gis: "Room location",
  security_1066_gis: "Please select room location",
  security_1067_gis: "position",
  security_1068_gis: "The park can't be null",
  security_1069_gis: "Floor can't be null",
  security_1070_gis: "Play pause 1-----",
  security_1071_gis: "Pause 1---",
  security_1072_gis: "Timer",
  security_1073_gis: "Play pause 3----",
  security_1074_gis: "Play---",
  security_1075_gis: "The patrol group will be played in turn at regular intervals",
  security_1076_gis: "Currently playing-the first",
  security_1077_gis: "Patrol groups",
  security_1078_gis: "Cameras",
  security_1079_gis: "patrol",
  security_1080_gis: "Video image",
  security_1081_gis: "Alarm avatar",
  security_1082_gis: "Associated video",
  security_1083_gis: "Upload list",
  security_1084_gis: "View associated videos",
  security_1085_gis: "Can only upload 10",
  security_1086_gis: "Pictures inside",
  security_1087_gis: "with",
  security_1088_gis: "Format video",
  security_1089_gis: "Completed",
  security_1090_gis: "Basic information",
  security_1091_gis: "Alarm time",
  security_1092_gis: "Warning location",
  security_1093_gis: "Alarm related information",
  security_1094_gis: "Company Name",
  security_1095_gis: "location information",
  security_1096_gis: "Associate pictures and videos",
  security_1097_gis: "Snap a big picture",
  security_1098_gis: "Notified Person",
  security_1099_gis: "Event reminder",
  security_1100_gis: "Alarm handling",
  security_1101_gis: "Please select processing status",
  security_1102_gis: "Remarks information",
  security_1103_gis: "Information sent successfully",
  security_1104_gis: "Failed to send",
  security_1105_gis: "Submitted successfully",
  security_1106_gis: "Total energy consumption",
  security_1107_gis: "Electricity",
  security_1108_gis: "Power consumption",
  security_1109_gis: "Other electricity",
  security_1110_gis: "Total daily energy consumption",
  security_1111_gis: "There is a fault in the device you currently selected, please arrange for someone to handle it first!",
  security_1112_gis: "The large screen you currently selected is offline and the command is unreachable!",
  security_1113_gis: "There is currently no program, please set the program first!",
  security_1114_gis: "fail to edit",
  security_1115_gis: "Dispel the alarm successfully",
  security_1116_gis: "Successful transfer facility warning",
  security_1117_gis: "Transfer facility warning failure",
  security_1118_gis: "Please select the pusher",
  security_1119_gis: "Dispatch task",
  security_1120_gis: "Emergency plan",
  security_1121_gis: "Trajectory tracking",
  security_1122_gis: "Historical events",
  security_1123_gis: "Other",
  security_1124_gis: "The event doesn't exist or has been deleted",
  security_1125_gis: "Reason for deployment",
  security_1126_gis: "Please enter the reason for deployment, no more than 150 characters",
  security_1127_gis: "registered user",
  security_1128_gis: "Time period",
  security_1129_gis: "Figure",
  security_1130_gis: "Whether to push the pop-up window",
  security_1131_gis: "Please enter the property announcement",
  security_1132_gis: "Name",
  security_1133_gis: "Mobile number",
  security_1134_gis: "Upload failed!",
  security_1135_gis: "The reason for deployment can't exceed 150 characters",
  security_1136_gis: "Reminder",
  security_1137_gis: "Plan name",
  security_1138_gis: "Please enter the plan name",
  security_1139_gis: "Please input",
  security_1140_gis: "Please enter points",
  security_1141_gis: "Please select repeat method",
  security_1142_gis: "Add reminder",
  security_1143_gis: "Please enter the phone number to search for an exact match",
  security_1144_gis: "Please select reminder",
  security_1145_gis: "Route added",
  security_1146_gis: "Please add a route",
  security_1147_gis: "Remove",
  security_1148_gis: "Route list",
  security_1149_gis: "New route",
  security_1150_gis: "Added",
  security_1151_gis: "Monday",
  security_1152_gis: "Tuesday",
  security_1153_gis: "Wednesday",
  security_1154_gis: "Thursday",
  security_1155_gis: "Friday",
  security_1156_gis: "Saturday",
  security_1157_gis: "Sunday",
  security_1158_gis: "Plan details",
  security_1159_gis: "New plan",
  security_1160_gis: "Modify plan",
  security_1161_gis: "Successfully revised patrol plan",
  security_1162_gis: "Successful new patrol plan",
  security_1163_gis: "The time can't be 0 or null!",
  security_1164_gis: "Time must be a number",
  security_1165_gis: "Important warning",
  security_1166_gis: "Hang",
  security_1167_gis: "No corresponding device data in the park",
  security_1168_gis: "No security personnel data in the park",
  security_1169_gis: "Suspended",
  security_1170_gis: "Tip event",
  security_1171_gis: "Processing time rate",
  security_1172_gis: "Event title",
  security_1173_gis: "Report event",
  security_1174_gis: "Event number",
  security_1175_gis: "Distribute",
  security_1176_gis: "Work order dispatch",
  security_1177_gis: "Ticket status",
  security_1178_gis: "Plan start time",
  security_1179_gis: "Please select the planned start time",
  security_1180_gis: "Plan end time",
  security_1181_gis: "Please select the end time of the plan",
  security_1182_gis: "Alarm location",

  "security_1183_gis": "Order details",
  "security_1184_gis": "Actual start time",
  "security_1185_gis": "Actual end time",
  "security_1186_gis": "Work order processing time",
  "security_1187_gis": "Associated equipment",
  "security_1188_gis": "Work order description",
  "security_1189_gis": "Fault description",
  "security_1190_gis": "Associated system details",
  "security_1191_gis": "Associated order No",
  "security_1192_gis": "Complete description",
  "security_1193_gis": "Image details",
  "security_1194_gis": "Event title",
  "security_1195_gis": "Please input a title",
  "security_1196_gis": "Event no",
  "security_1197_gis": "Please select event status",
  "security_1198_gis": "Work order number",
  "security_1199_gis": "Please enter the ticket number",
  "security_1200_gis": "Distribution time",
  "security_1201_gis": "Please select the dispatch time",
  "security_1202_gis": "Work order owner",
  "security_1203_gis": "Please enter the person responsible for the ticket",
  "security_1204_gis": "Assignee",
  "security_1205_gis": "Business workorder",
  "security_1206_gis": "Facility Workorder",
  "security_1207_gis": "Report workorder",
  "security_1208_gis": "Alarm name",
  "security_1209_gis": "Facility level",
  "security_1210_gis": "Facility system",
  "security_1211_gis": "Subsystem",
  "security_1212_gis": "Event details",
  "security_1213_gis": 'Event no/Alarm no',
  "security_1214_gis": 'Event type/report type',
  "security_1215_gis": 'Alarm time/Reporting time',
  "security_1216_gis": "Service users",
  "security_1217_gis": "Subclass",
  "security_1218_gis": "Work order",
  "security_1219_gis": "The number of exported data cannot exceed 20000. Enter criteria to filter",
  "security_1220_gis": "Please enter the post title",
  "security_1221_gis": "Please enter the notification number",
  "security_1222_gis": "Reporte status",
  "security_1223_gis": "Please enter reporting status",
  "security_1224_gis": "Reporte level",
  "security_1225_gis": "Please select reporting level",
  "security_1226_gis": "Reporte type",
  "security_1227_gis": "Please select the reporting type",
  "security_1228_gis": "Reporte Describe",
  "security_1229_gis": "Reporte time",
  "security_1230_gis": "Please enter the event number",
  "security_1231_gis": "There is no work order for this event",
  "security_1232_gis": "Report events",
  "security_1233_gis": "Passenger flow alert",
  "security_1234_gis": "Online",
  "security_1235_gis": "Abnormal",
  "security_1236_gis": "Select date and time",
  "security_1237_gis": "Distribute type",
  "security_1238_gis": "Please select distribute type",
  "security_1239_gis": "Please select start time",
  "security_1240_gis": "Please select the end time",
  "security_1241_gis": "Work order dispatched successfully",
  "security_1242_gis": "MQDCapp Task",
  "security_1243_gis": "Please enter your name and phone number",
  "security_1244_gis": "Please enter an event name",
  "security_1245_gis": "Please enter the location of occurrence",
  "security_1246_gis": "Please enter the processor",
  "security_1247_gis": "Please select a date",
  "security_1248_gis": "Recent year",
  i18nKey_cockpit_1: "Ten thousand",
  i18nKey_cockpit_2: "Hundred million",
  i18nKey_cockpit_3: "Ten million",
  i18nKey_cockpit_4: "Million",
  i18nKey_cockpit_5: "Minute",
  i18nKey_cockpit_6: "Minute",
  i18nKey_cockpit_7: "Day",
  i18nKey_cockpit_8: "Time",
  i18nKey_cockpit_9: "Hour",
  i18nKey_cockpit_10: "This week",
  i18nKey_cockpit_11: "Nearly three months",
  i18nKey_cockpit_12: "This month",
  i18nKey_cockpit_13: "Confirm",
  i18nKey_cockpit_14: "To modify",
  i18nKey_cockpit_15: "Tips",
  i18nKey_cockpit_16: "Task",
  i18nKey_cockpit_17: "Cancel",
  i18nKey_cockpit_18: "All",
  i18nKey_cockpit_19: "Types ",
  i18nKey_cockpit_20: "Map",
  i18nKey_cockpit_21: "Layer configuration",
  i18nKey_cockpit_22: "Layer",
  i18nKey_cockpit_23: "Map operation",
  i18nKey_cockpit_24: "General configuration",
  i18nKey_cockpit_25: "Save",
  i18nKey_cockpit_26: "Configuration is successful",
  i18nKey_cockpit_27: "Configuration failed",
  i18nKey_cockpit_28: "You haven't made any changes",
  i18nKey_cockpit_29: "Urgent",
  i18nKey_cockpit_30: "Real scene",
  i18nKey_cockpit_31: "Details",
  i18nKey_cockpit_32: "View details",
  i18nKey_cockpit_33: "Close the door",
  i18nKey_cockpit_34: "Time flows",
  i18nKey_cockpit_35: "Configuration",
  i18nKey_cockpit_36: "Setting",
  i18nKey_cockpit_37: "Full screen",
  i18nKey_cockpit_38: "Sunny ",
  i18nKey_cockpit_39: "Sunny ",
  i18nKey_cockpit_40: "Rain",
  i18nKey_cockpit_41: "Fog",
  i18nKey_cockpit_42: "Snowy",
  i18nKey_cockpit_43: "Rainfall",
  i18nKey_cockpit_44: "Heavy fog",
  i18nKey_cockpit_45: "Snowfall",
  i18nKey_cockpit_46: "Overall situation-2",
  i18nKey_cockpit_47: "Version",
  i18nKey_cockpit_48: "Overall situation",
  i18nKey_cockpit_49: "Security posture",
  i18nKey_cockpit_50: "Video patrol statistics",
  i18nKey_cockpit_51: "Patrol warning",
  i18nKey_cockpit_52: "Security alarm status",
  i18nKey_cockpit_53: "Equipment alarm statistics",
  i18nKey_cockpit_54: "Security alarm statistics",
  i18nKey_cockpit_55: "Security alert trend",
  i18nKey_cockpit_56: "Security work order trends",
  i18nKey_cockpit_57: "Equipment statistics",
  i18nKey_cockpit_58: "Event center",
  i18nKey_cockpit_59: "Energy consumption trend",
  i18nKey_cockpit_60: "Electricity profile",
  i18nKey_cockpit_61: "Electricity consumption statistics",
  i18nKey_cockpit_62: "Efficiency",
  i18nKey_cockpit_63: "Diagnosis",
  i18nKey_cockpit_64: "Itemized energy",
  i18nKey_cockpit_65: "Energy consumption alarm",
  i18nKey_cockpit_66: "Energy consumption monitoring",
  i18nKey_cockpit_67: "Environmental monitoring",
  i18nKey_cockpit_68: "Equipment energy ranking",
  i18nKey_cockpit_69: "Regional energy consumption per square meter ranking",
  i18nKey_cockpit_70: "Merchant",
  i18nKey_cockpit_71: "Tenant ranking of energy consumption per square meter",
  i18nKey_cockpit_72: "Hydropower",
  i18nKey_cockpit_73: "Facility situation",
  i18nKey_cockpit_74: "Failure statistics",
  i18nKey_cockpit_75: "Facility event status statistics",
  i18nKey_cockpit_76: "Facility work order",
  i18nKey_cockpit_77: "Prevailing situation",
  i18nKey_cockpit_78: "Personnel traffic profile",
  i18nKey_cockpit_79: "Employee traffic statistics",
  i18nKey_cockpit_80: "Visitor traffic statistics",
  i18nKey_cockpit_81: "Traffic statistics",
  i18nKey_cockpit_82: "Traffic statistics",
  i18nKey_cockpit_83: "Parking statistics",
  i18nKey_cockpit_84: "Temporary parking charge",
  i18nKey_cockpit_85: "Parking time",
  i18nKey_cockpit_86: "Smart business",
  i18nKey_cockpit_87: "To visitor flow trends",
  i18nKey_cockpit_88: "Residency",
  i18nKey_cockpit_89: "Popular area",
  i18nKey_cockpit_90: "Business event statistics",
  i18nKey_cockpit_91: "Passenger flow",
  i18nKey_cockpit_92: "Number of people in the venue",
  i18nKey_cockpit_93: "Age sex",
  i18nKey_cockpit_94: "Repeat customers",
  i18nKey_cockpit_95: "Festive passenger flow statistics",
  i18nKey_cockpit_96: "Not found in component",
  i18nKey_cockpit_97: "Method",
  i18nKey_cockpit_98: "Comprehensive situation",
  i18nKey_cockpit_99: "Operation report",
  i18nKey_cockpit_100: "Parameter error",
  i18nKey_cockpit_101: "Should be",
  i18nKey_cockpit_102: "Actually",
  i18nKey_cockpit_103: "No eligible data temporarily",
  i18nKey_cockpit_104: "Zoom",
  i18nKey_cockpit_105: "Mouse wheel zoom",
  i18nKey_cockpit_106: "Mobile",
  i18nKey_cockpit_107: "Spin",
  i18nKey_cockpit_108: "Rotate the map with the left mouse button",
  i18nKey_cockpit_109: "Right click to move the map",
  i18nKey_cockpit_110: "Right mouse button to rotate the map",
  i18nKey_cockpit_111: "Left click to move the map",
  i18nKey_cockpit_112: "Pattern picture",
  i18nKey_cockpit_113: "Picture of the overall situation:",
  i18nKey_cockpit_114: "Product situation picture:",
  i18nKey_cockpit_115: "Picture size cannot exceed 20",
  i18nKey_cockpit_116: "Upload failed",
  i18nKey_cockpit_117: "Can only upload",
  i18nKey_cockpit_118: "Format picture",
  i18nKey_cockpit_119: "Upload failed!",
  i18nKey_cockpit_120: "Back",
  i18nKey_cockpit_121: "Switch scene",
  i18nKey_cockpit_122: "Automatic roaming",
  i18nKey_cockpit_123: "Continue roaming",
  i18nKey_cockpit_124: "Pause",
  i18nKey_cockpit_125: "Exit playback",
  i18nKey_cockpit_126: "Chart settings",
  i18nKey_cockpit_127: "Daytime",
  i18nKey_cockpit_128: "Nighttime",
  i18nKey_cockpit_129: "Manual mode",
  i18nKey_cockpit_130: "Automatic mode",
  i18nKey_cockpit_131: "Select chart",
  i18nKey_cockpit_132: "Please select no more than",
  i18nKey_cockpit_133: "cards",
  i18nKey_cockpit_134: "Please select no less than",
  i18nKey_cockpit_135: "Chart set successfully",
  i18nKey_cockpit_136: "Select scene",
  i18nKey_cockpit_137: "Binhe Road",
  i18nKey_cockpit_138: "Yuanhe Street",
  i18nKey_cockpit_139: "Yongqingfang",
  i18nKey_cockpit_140: "Green and energy saving",
  i18nKey_cockpit_141: "Meter statistics",
  i18nKey_cockpit_142: "Number of facilities",
  i18nKey_cockpit_143: "",
  i18nKey_cockpit_144: "Meter online rate",
  i18nKey_cockpit_145: "Energy saving potential this month",
  i18nKey_cockpit_146: "Abnormal facilities",
  i18nKey_cockpit_147: "Facility statistics",
  i18nKey_cockpit_148: "Facilities online rate",
  i18nKey_cockpit_149: "Abnormal facilities this month",
  i18nKey_cockpit_150: "Pieces",
  i18nKey_cockpit_151: "Important",
  i18nKey_cockpit_152: "Facilities exception handling this month",
  i18nKey_cockpit_153: "Processing rate",
  i18nKey_cockpit_154: "Average processing time",
  i18nKey_cockpit_155: "Property management",
  i18nKey_cockpit_156: "Note: The system refreshes data in 1 hour, and refreshes traffic flow in 10 minutes",
  i18nKey_cockpit_157: "Comprehensive security",
  i18nKey_cockpit_158: "Easy access",
  i18nKey_cockpit_159: "Facility management",
  i18nKey_cockpit_160: "Supporting service",
  i18nKey_cockpit_161: "Alarm event",
  i18nKey_cockpit_162: "Video patrol",
  i18nKey_cockpit_163: "Blacklist",
  i18nKey_cockpit_164: "Warning events this week",
  i18nKey_cockpit_165: "Grand total",
  i18nKey_cockpit_166: "Alarm event processing this week",
  i18nKey_cockpit_167: "Processed",
  i18nKey_cockpit_168: "Video patrol this week",
  i18nKey_cockpit_169: "Times",
  i18nKey_cockpit_170: "Clock-in rate",
  i18nKey_cockpit_171: "Number of blacklisted events this week",
  i18nKey_cockpit_172: "Shared meeting room",
  i18nKey_cockpit_173: "Park bus",
  i18nKey_cockpit_174: "Park order",
  i18nKey_cockpit_175: "Meeting room reservation this month",
  i18nKey_cockpit_176: "Revenue",
  i18nKey_cockpit_177: "yuan",
  i18nKey_cockpit_178: "Meeting room use this month",
  i18nKey_cockpit_179: "Use time",
  i18nKey_cockpit_180: "Usage rate",
  i18nKey_cockpit_181: "Conference room service company",
  i18nKey_cockpit_182: "Home",
  i18nKey_cockpit_183: "Order food in the park this month",
  i18nKey_cockpit_184: "Number of people",
  i18nKey_cockpit_185: "Bus ride this month",
  i18nKey_cockpit_186: "Production Office",
  i18nKey_cockpit_187: "Person-time",
  i18nKey_cockpit_188: "Port Apartment",
  i18nKey_cockpit_189: "Bus occupancy rate this month",
  i18nKey_cockpit_190: "People flow statistics",
  i18nKey_cockpit_191: "Access control",
  i18nKey_cockpit_192: "Mall traffic",
  i18nKey_cockpit_193: "People flow in the park today",
  i18nKey_cockpit_194: "People",
  i18nKey_cockpit_195: "Parking payment today",
  i18nKey_cockpit_196: "Stop",
  i18nKey_cockpit_197: "Package card",
  i18nKey_cockpit_198: "Park parking today",
  i18nKey_cockpit_199: "Vehicles",
  i18nKey_cockpit_200: "Train number",
  i18nKey_cockpit_201: "Parking lot usage rate this week",
  i18nKey_cockpit_202: "Monthly card",
  i18nKey_cockpit_203: "Park",
  i18nKey_cockpit_204: "Alarm statistics",
  i18nKey_cockpit_205: "Emergency alert",
  i18nKey_cockpit_206: "Important warning",
  i18nKey_cockpit_207: "General warning",
  i18nKey_cockpit_208: "Total number of work orders",
  i18nKey_cockpit_209: "To be received",
  i18nKey_cockpit_210: "Received",
  i18nKey_cockpit_211: "Business event",
  i18nKey_cockpit_212: "Enter the alert title",
  i18nKey_cockpit_213: "Grade",
  i18nKey_cockpit_214: "Status",
  i18nKey_cockpit_215: "View more",
  i18nKey_cockpit_216: "Facility event",
  i18nKey_cockpit_217: "General",
  i18nKey_cockpit_218: "Real-time picture",
  i18nKey_cockpit_219: "to",
  i18nKey_cockpit_220: "start date",
  i18nKey_cockpit_221: "End date",
  i18nKey_cockpit_222: "Day",
  i18nKey_cockpit_223: "Today",
  i18nKey_cockpit_224: "Week",
  i18nKey_cockpit_225: "Month",
  i18nKey_cockpit_226: "Year",
  i18nKey_cockpit_227: "This year",
  i18nKey_cockpit_228: "Nowadays",
  i18nKey_cockpit_229: "Carry out",
  i18nKey_cockpit_230: "Total quantity",
  i18nKey_cockpit_231: "Event dynamics",
  i18nKey_cockpit_232: "No data",
  i18nKey_cockpit_233: "Not supported by current browser",
  i18nKey_cockpit_234: "Scan code to report",
  i18nKey_cockpit_235: "To be processed",
  i18nKey_cockpit_236: "Fire Mountain",
  i18nKey_cockpit_237: "Height 120",
  i18nKey_cockpit_240: "Equipment name",
  i18nKey_cockpit_241: "Commerce",
  i18nKey_cockpit_242: "Fire exits",
  i18nKey_cockpit_243: "Work order processing",
  i18nKey_cockpit_244: "Track now",
  i18nKey_cockpit_245: "Level",
  i18nKey_cockpit_246: "Statistics of failure facilities",
  i18nKey_cockpit_247: "No data that meets the query conditions",
  i18nKey_cockpit_248: "Facility name",
  i18nKey_cockpit_249: "Number of facilities",
  i18nKey_cockpit_250: "Number of facility failures",
  i18nKey_cockpit_251: "Number of failed facilities",
  i18nKey_cockpit_252: "Cumulative occurrence",
  i18nKey_cockpit_253: "Cumulative processing",
  i18nKey_cockpit_254: "Pieces",
  i18nKey_cockpit_255: "Minute",
  i18nKey_cockpit_256: "Facility work order overview",
  i18nKey_cockpit_257: "Processing",
  i18nKey_cockpit_258: "Closed",
  i18nKey_cockpit_259: "Average electricity consumption",
  i18nKey_cockpit_260: "Total",
  i18nKey_cockpit_261: "Electricity overview",
  i18nKey_cockpit_262: "Total electricity",
  i18nKey_cockpit_263: "Than yesterday",
  i18nKey_cockpit_264: "Yesterday",
  i18nKey_cockpit_265: "Compared to last week",
  i18nKey_cockpit_266: "Last week",
  i18nKey_cockpit_267: "Compared to last month",
  i18nKey_cockpit_268: "Last month",
  i18nKey_cockpit_269: "Total energy consumption",
  i18nKey_cockpit_270: "Accumulated energy consumption by item",
  i18nKey_cockpit_271: "Tenant electricity",
  i18nKey_cockpit_272: "Public area electricity",
  i18nKey_cockpit_273: "Total electricity consumption",
  i18nKey_cockpit_274: "Work order statistics",
  i18nKey_cockpit_275: "Patrol alarm event",
  i18nKey_cockpit_276: "Processing completion rate",
  i18nKey_cockpit_277: "Processing time rate",
  i18nKey_cockpit_278: "Overview",
  i18nKey_cockpit_279: "Suspended",
  i18nKey_cockpit_280: "Cancelled",
  i18nKey_cockpit_281: "Alarm event trend",
  i18nKey_cockpit_282: "Alarm classification statistics",
  i18nKey_cockpit_283: "Number of occurrences",
  i18nKey_cockpit_284: "Processing quantity",
  i18nKey_cockpit_285: "Average processing time (minutes)",
  i18nKey_cockpit_286: "Alarm type",
  i18nKey_cockpit_287: "Source of alarm",
  i18nKey_cockpit_288: "Alert level",
  i18nKey_cockpit_289: "Blacklist event processing time",
  i18nKey_cockpit_290: "Shortest time consuming",
  i18nKey_cockpit_291: "Rank",
  i18nKey_cockpit_292: "Event",
  i18nKey_cockpit_293: "time cost",
  i18nKey_cockpit_294: "Longest time consuming",
  i18nKey_cockpit_295: "Problem overview",
  i18nKey_cockpit_296: "camera",
  i18nKey_cockpit_297: "Video punch rate",
  i18nKey_cockpit_298: "Total execution time",
  i18nKey_cockpit_299: "Route",
  i18nKey_cockpit_300: "",
  i18nKey_cockpit_301: "Ride statistics",
  i18nKey_cockpit_302: "Early bus",
  i18nKey_cockpit_303: "Late bus",
  i18nKey_cockpit_304: "Package card users",
  i18nKey_cockpit_305: "people)",
  i18nKey_cockpit_306: "Package card user (person)",
  i18nKey_cockpit_307: "Aboard enterprise",
  i18nKey_cockpit_308: "Company name",
  i18nKey_cockpit_309: "Number of passengers",
  i18nKey_cockpit_310: "Dishes sales",
  i18nKey_cockpit_311: "Dishes name",
  i18nKey_cockpit_312: "Number of order",
  i18nKey_cockpit_313: "Share",
  i18nKey_cockpit_314: "Meeting room reservation",
  i18nKey_cockpit_315: "Booking",
  i18nKey_cockpit_316: "Unsubscribe",
  i18nKey_cockpit_317: "Times",
  i18nKey_cockpit_318: "CNY)",
  i18nKey_cockpit_319: "Meeting room use",
  i18nKey_cockpit_320: "All meeting rooms",
  i18nKey_cockpit_321: "Order revenue",
  i18nKey_cockpit_322: "Sales",
  i18nKey_cockpit_323: "",
  i18nKey_cockpit_324: "Corporate bookings",
  i18nKey_cockpit_325: "Number of bookings",
  i18nKey_cockpit_326: "Shuttle bus occupancy rate",
  i18nKey_cockpit_327: "Store sales",
  i18nKey_cockpit_328: "Store name",
  i18nKey_cockpit_329: "Access control traffic statistics",
  i18nKey_cockpit_330: "People",
  i18nKey_cockpit_331: "Access control method",
  i18nKey_cockpit_332: "Shopping mall popularity",
  i18nKey_cockpit_333: "Park traffic",
  i18nKey_cockpit_334: "",
  i18nKey_cockpit_335: "",
  i18nKey_cockpit_336: "Employee access",
  i18nKey_cockpit_337: "heat",
  i18nKey_cockpit_338: "Passenger flow overview",
  i18nKey_cockpit_339: "Weekday passenger flow",
  i18nKey_cockpit_340: "Weekend passenger flow",
  i18nKey_cockpit_341: "Time passenger flow statistics",
  i18nKey_cockpit_342: "Today's passenger flow",
  i18nKey_cockpit_343: "Average passenger flow in the past month",
  i18nKey_cockpit_344: "Regional heat",
  i18nKey_cockpit_345: "area",
  i18nKey_cockpit_346: "People flow in the park",
  i18nKey_cockpit_347: "Stop time",
  i18nKey_cockpit_348: "Charge amount",
  i18nKey_cockpit_349: "Passenger traffic in the bathroom",

  "i18nKey_cockpit_350": "Property work order",
  "i18nKey_cockpit_351": "Water consumption statistics",
  "i18nKey_cockpit_352": "Qo statistics",
  "i18nKey_cockpit_353": "Project work order",
  "i18nKey_cockpit_354": "Total of work order (pieces)",
  "i18nKey_cockpit_355": "Project task process status",
  "i18nKey_cockpit_356": "Completed",
  "i18nKey_cockpit_357": "Timely process rate of project task",
  "i18nKey_cockpit_358": "Timely acceptance rate",
  "i18nKey_cockpit_359": "Timely completion rate",
  "i18nKey_cockpit_360": "Order timeout statistics",
  "i18nKey_cockpit_361": "Not completed (timeout)",
  "i18nKey_cockpit_362": "Not accepeted (timeout)",
  "i18nKey_cockpit_363": "Order classification statistics",
  "i18nKey_cockpit_364": "New (orders)",
  "i18nKey_cockpit_365": "Completed (timeout)",
  "i18nKey_cockpit_366": "Accepted (timeout)",
  "i18nKey_cockpit_367": "Order type",
  "i18nKey_cockpit_368": "Exceed the max export limit of 2,000",
  "i18nKey_cockpit_369": "Exported content is null",
  "i18nKey_cockpit_370": "Export failed",
  "i18nKey_cockpit_371": "Total order",
  "i18nKey_cockpit_372": "Order trend",
  "i18nKey_cockpit_373": "Order classification details",
  "i18nKey_cockpit_374": "Please select",
  "i18nKey_cockpit_375": "Report reason",
  "i18nKey_cockpit_376": "Location",
  "i18nKey_cockpit_377": "Distribute time",
  "i18nKey_cockpit_378": "Handler",
  "i18nKey_cockpit_379": "Export",
  "i18nKey_cockpit_380": "Export time period",
  "i18nKey_cockpit_381": "Please select the period to export",
  "i18nKey_cockpit_382": "Order no",
  "i18nKey_cockpit_383": "Please select occurrence time",
  "i18nKey_cockpit_384": "No data to export in that period",
  "i18nKey_cockpit_385": "Prompt event",
  "i18nKey_cockpit_386": "The total cold quantity",
  "i18nKey_cockpit_387": "Tenant cooling capacity",
  "i18nKey_cockpit_389": "Staff",
  "i18nKey_cockpit_390": "Property",
  "i18nKey_cockpit_391": "Safety",
  "i18nKey_cockpit_392": "voice",
  "i18nKey_cockpit_393": "Air conditioning electricity",
  "i18nKey_cockpit_394": "Power consumption",
  "i18nKey_cockpit_395": "Electricity for lighting and sockets",
  "i18nKey_cockpit_396": "Commercial users",
  "i18nKey_cockpit_397": "security alarm",
  "i18nKey_cockpit_398": "Passenger flow alarm",
  "i18nKey_cockpit_399": "Fire alarm",
  i18nKey_components_1: "Ten thousand",
  i18nKey_components_2: "Hello ",
  i18nKey_components_3: "World",
  i18nKey_components_4: "Online cameras number",
  i18nKey_components_5: "Enter camera alias",
  i18nKey_components_6: "Select camera",
  i18nKey_components_7: "Confirm",
  i18nKey_components_8: "Layer",
  i18nKey_components_9: "More layers",
  i18nKey_components_10: "Phone",
  i18nKey_components_11: "SMS",
  i18nKey_components_12: "View camera",
  i18nKey_components_13: "Tips",
  i18nKey_components_14: "Starting time",
  i18nKey_components_15: "End Time",
  i18nKey_components_16: "Choose start date and time",
  i18nKey_components_17: "Choose end date and time",
  i18nKey_components_18: "Video capture",
  i18nKey_components_19: "End time  greater than start time",
  i18nKey_components_20: "submit",
  i18nKey_components_21: "Cancel",
  i18nKey_components_22: "Real-time video",
  i18nKey_components_23: "Historical video",
  i18nKey_components_24: "Video playback",
  i18nKey_components_25: "Replay",
  i18nKey_components_26: "Stop",
  i18nKey_components_27: "Please choose time",
  i18nKey_components_28: "Duration no less than 30s",
  i18nKey_components_29: "Do not exceed one hour",
  i18nKey_components_30: "Intercepted successfully",
  i18nKey_components_31: "Video successfully intercepted, please view in associated video in event details on the left ",
  i18nKey_components_32: "Video capture failed",
  i18nKey_components_33: "Microsoft Accor black",
  i18nKey_components_34: "Video camera",
  i18nKey_components_35: "Time",
  i18nKey_components_36: "day",
  i18nKey_components_37: "week",
  i18nKey_components_38: "month",
  i18nKey_components_39: "year",
  i18nKey_components_40: "Age",
  i18nKey_components_41: "Equipment statistics",
  i18nKey_components_42: "Statistics time up",
  i18nKey_components_43: "Total equipment",
  i18nKey_components_44: "Equipment online rate",
  i18nKey_components_45: "stand",
  i18nKey_components_46: "Equipment alarm statistics",
  i18nKey_components_47: "Alarm numbers",
  i18nKey_components_48: "Not processed",
  i18nKey_components_49: "Close rate",
  i18nKey_components_50: "Urgent",
  i18nKey_components_51: "Serious",
  i18nKey_components_52: "Ordinary",
  i18nKey_components_53: "Popular area",
  i18nKey_components_54: "Passenger flow",
  i18nKey_components_55: "Number of people in the venue",
  i18nKey_components_56: "Number of people entering",
  i18nKey_components_57: "Number of people leaving",
  i18nKey_components_58: "Number of people present",
  i18nKey_components_59: "People",
  i18nKey_components_60: "Patrol warning",
  i18nKey_components_61: "Cumulative occurrence",
  i18nKey_components_62: "Cumulative processing",
  i18nKey_components_63: "Pcs",
  i18nKey_components_64: "Unit",
  i18nKey_components_65: "Energy consumption statistics",
  i18nKey_components_66: "Total passenger flow",
  i18nKey_components_67: "Park",
  i18nKey_components_68: "People-time",
  i18nKey_components_69: "Security alarm statistics",
  i18nKey_components_70: "Total alarm",
  i18nKey_components_71: "Important alarm",
  i18nKey_components_72: "Ordinary alarm",
  i18nKey_components_73: "Emergency alarm",
  i18nKey_components_74: "Security alarm status",
  i18nKey_components_75: "To be processed",
  i18nKey_components_76: "Processing",
  i18nKey_components_77: "Suspended",
  i18nKey_components_78: "Closed",
  i18nKey_components_79: "Cancelled",
  i18nKey_components_80: "Total events",
  i18nKey_components_81: "Security alert trend",
  i18nKey_components_82: "Number of alarms",
  i18nKey_components_83: "Number of alarm processing",
  i18nKey_components_84: "Security work order trends",
  i18nKey_components_85: "Total work order",
  i18nKey_components_86: "Number of occurrences",
  i18nKey_components_87: "Processing number",
  i18nKey_components_88: "Video patrol statistics",
  i18nKey_components_89: "Execution times",
  i18nKey_components_90: "Times",
  i18nKey_components_91: "Video play rate",
  i18nKey_components_92: "Route",
  i18nKey_components_93: "",
  i18nKey_components_94: "Camera",
  i18nKey_components_95: "",
  i18nKey_components_96: "Total execution time",
  i18nKey_components_97: "Minute",
  i18nKey_components_98: "Task",
  i18nKey_components_99: "Minimize",
  i18nKey_components_100: "Maximize",
  i18nKey_components_101: "Video buffering",
  i18nKey_components_102: "Failed to obtain video resource",
  i18nKey_components_103: "Cancel screenshot",
  i18nKey_components_104: "Picture cropping",
  i18nKey_components_105: "Cancel",
  i18nKey_components_106: "Cancel",
  i18nKey_components_107: "Confirm",
  i18nKey_components_108: "Male",
  i18nKey_components_109: "Compared",
  i18nKey_components_110: "QoQ",
  i18nKey_components_111: "Number of people",
  i18nKey_components_112: "Year-on-year",
  i18nKey_components_113: "The same period last year",
  i18nKey_components_114: "Female",
  i18nKey_components_115: "Yesterday",
  i18nKey_components_116: "Last week",
  i18nKey_components_117: "last month",
  i18nKey_components_118: "last year",
  i18nKey_components_119: "Online",
  i18nKey_components_120: "Dormant",
  i18nKey_components_121: "Exception",
  i18nKey_components_122: "Disable",
  i18nKey_components_123: "Offline",
  i18nKey_components_124: "Total quantity",
  i18nKey_components_125: "Electricity",
  i18nKey_components_126: "Water use",
  i18nKey_components_127: "Rank",
  i18nKey_components_128: "Energy consumption / ㎡",
  i18nKey_components_129: "Compared to yesterday",
  i18nKey_components_130: "Last year",
  i18nKey_components_131: "Equipment",
  i18nKey_components_132: "Area",
  i18nKey_components_133: "Merchant",
  i18nKey_components_134: "Tenant",
  i18nKey_components_135: "Energy consumption",
  i18nKey_components_136: "Energy saving type",
  i18nKey_components_137: "Type",
  i18nKey_components_138: "Diagnostic measures",
  i18nKey_components_139: "Energy saving potential",
  i18nKey_components_140: "Cumulative energy saving potential",
  i18nKey_components_141: "No data that meets the query conditions",
  i18nKey_components_142: "Total area",
  i18nKey_components_143: "Total companies",
  i18nKey_components_144: "Power consumption yesterday",
  i18nKey_components_145: "Electricity consumption last week",
  i18nKey_components_146: "Electricity consumption last month",
  i18nKey_components_147: "Passenger flow (person-time)",
  i18nKey_components_148: "Efficiency",
  i18nKey_components_149: "Diagnosis",
  i18nKey_components_150: "Public area energy consumption",
  i18nKey_components_151: "Tenant energy consumption",
  i18nKey_components_152: "Total energy consumption",
  i18nKey_components_153: "unit:",
  i18nKey_components_154: "Local",
  i18nKey_components_155: "Indoor",
  i18nKey_components_156: "Local temperature",
  i18nKey_components_157: "Local humidity",
  i18nKey_components_158: "Room temperature",
  i18nKey_components_159: "Indoor humidity",
  i18nKey_components_160: "Tenant water consumption",
  i18nKey_components_161: "Electricity profile",
  i18nKey_components_162: "Energy used",
  i18nKey_components_163: "Total electricity",
  i18nKey_components_164: "Electricity consumption statistics",
  i18nKey_components_165: "Tenant electricity",
  i18nKey_components_166: "Public area electricity",
  i18nKey_components_167: "Environmental monitoring",
  i18nKey_components_168: "Energy consumption alarm",
  i18nKey_components_169: "Equipment abnormal",
  i18nKey_components_170: "Abnormal power usage",
  i18nKey_components_171: "Abnormal data",
  i18nKey_components_172: "Energy consumption monitoring",
  i18nKey_components_173: "Public area",
  i18nKey_components_174: "YoY",
  i18nKey_components_175: "Itemized energy",
  i18nKey_components_176: "Accumulated energy consumption by item",
  i18nKey_components_177: "Equipment energy ranking",
  i18nKey_components_178: "Regional energy consumption per square meter ranking",
  i18nKey_components_179: "Hydropower",
  i18nKey_components_180: "Facility event status statistics",
  i18nKey_components_181: "Facilities report",
  i18nKey_components_182: "Average processing time",
  i18nKey_components_183: "Failure statistics",
  i18nKey_components_184: "Number of failures",
  i18nKey_components_185: "Failure rate",
  i18nKey_components_186: "Traffic statistics",
  i18nKey_components_187: "Access control number of people",
  i18nKey_components_188: "Employee access statistics",
  i18nKey_components_189: "Total access people",
  i18nKey_components_190: "Temporary suspension fee",
  i18nKey_components_191: "Stop time",
  i18nKey_components_192: "Charge amount",
  i18nKey_components_193: "yuan",
  i18nKey_components_194: "Parking statistics",
  i18nKey_components_195: "Total traffic",
  i18nKey_components_196: "Number of parking spaces",
  i18nKey_components_197: "Parking space utilization rate",
  i18nKey_components_198: "Parking time",
  i18nKey_components_199: "Quantity",
  i18nKey_components_200: "",
  i18nKey_components_201: "Personnel access overview",
  i18nKey_components_202: "Traffic statistics",
  i18nKey_components_203: "Drive in",
  i18nKey_components_204: "Drive out",
  i18nKey_components_205: "Visitor access statistics",
  i18nKey_components_206: "Business event statistics",
  i18nKey_components_207: "Types ",
  i18nKey_components_208: "Event description",
  i18nKey_components_209: "Time",
  i18nKey_components_210: "Repeat customers",
  i18nKey_components_211: "Total number of repeat customers (person)",
  i18nKey_components_212: "First visit (person)",
  i18nKey_components_213: "last week",
  i18nKey_components_214: "Last month",
  i18nKey_components_215: "To visitor flow trends",
  i18nKey_components_216: "Total number of visitors (person-time)",
  i18nKey_components_217: "Current passenger flow",
  i18nKey_components_218: "No parking lot",
  i18nKey_components_219: "Idle state",
  i18nKey_components_220: "status of use",
  i18nKey_components_221: "Use",
  i18nKey_components_222: "Come in! ! ! !",
  i18nKey_components_223: "Implemented the management method",
  i18nKey_components_224: "Some data",
  i18nKey_components_225: "Clicked building",
  i18nKey_components_226: "The new trajectory method was implemented",
  i18nKey_components_227: "Clear track",
  i18nKey_components_228: "3D model",
  i18nKey_components_229: "Crystal",
  i18nKey_components_230: "Building",
  i18nKey_components_231: "Real scene",
  i18nKey_components_232: "Street view",
  i18nKey_components_233: "Main view",
  i18nKey_components_234: "View",
  i18nKey_components_235: "Aerial view",
  i18nKey_components_236: "Set as main view",
  i18nKey_components_237: "Reset the main view",
  i18nKey_components_238: "Restored",
  i18nKey_components_239: "Festive passenger flow statistics",
  i18nKey_components_240: "Total passenger flow",
  i18nKey_components_241: "Search engine",
  i18nKey_components_242: "Resident status",
  i18nKey_components_243: "Average length of stay",
  i18nKey_components_244: "Online service has been suspended, please wait for the recovery time",

  "i18nKey_components_245": "Season",
  "i18nKey_components_246": "Today",
  "i18nKey_components_247": "7 days",
  "i18nKey_components_248": "30 days",
  "i18nKey_components_249": "Please select",
  "i18nKey_components_250": "Hd surveillance camera",
  "i18nKey_components_251": "Face recognition camera",
  "i18nKey_components_252": "Air conditioning electricity",
  "i18nKey_components_253": "Power consumption",
  "i18nKey_components_254": "Electricity for lighting and sockets",
  "i18nKey_components_255": "Commercial users",
  "i18nKey_components_256": "Staff",
  "i18nKey_components_257": "Swipe",

  "i18nKey_components_258": "Alarm numbers\n(Pcs)",
  "i18nKey_components_259": "Ranking of energy consumption per square meter of merchants / tenants",
  i18nKey_layout_1: "Your login information has expired, please log in again",
  i18nKey_layout_2: "Overall trend",
  i18nKey_layout_3: "Overall trend display",
  i18nKey_layout_4: "Overall trend",
  i18nKey_layout_5: "Security posture",
  i18nKey_layout_6: "Energy consumption situation",
  i18nKey_layout_7: "Facility situation",
  i18nKey_layout_8: "Prevailing situation",
  i18nKey_layout_9: "Create spatial basic data",
  i18nKey_layout_10: "Modify spatial basic data",
  i18nKey_layout_11: "Security alarm details",
  i18nKey_layout_12: "New basic data",
  i18nKey_layout_13: "Basic data details",
  i18nKey_layout_14: "Modify basic data",
  i18nKey_layout_15: "Historical version of basic data",
  i18nKey_layout_16: "Face tracking",
  i18nKey_layout_17: "New track",
  i18nKey_layout_18: "New face deployment control",
  i18nKey_layout_19: "Modify deployment control",
  i18nKey_layout_20: "Redeploy",
  i18nKey_layout_21: "View details",
  i18nKey_layout_22: "New vehicle deployment control",
  i18nKey_layout_23: "Vehicle deployment details",
  i18nKey_layout_24: "Vehicle basic data details",
  i18nKey_layout_25: "Vehicle basic data modification",
  i18nKey_layout_26: "Vehicle basic data creation",
  i18nKey_layout_27: "Configuration",
  i18nKey_layout_28: "New personnel basic data",
  i18nKey_layout_29: "Modify basic data of personnel",
  i18nKey_layout_30: "View basic personnel data",
  i18nKey_layout_31: "Energy storage details",
  i18nKey_layout_32: "Modify the rules",
  i18nKey_layout_33: "Add rule",
  i18nKey_layout_34: "Energy consumption details",
  i18nKey_layout_35: "Facilities portrait",
  i18nKey_layout_36: "Create strategy",
  i18nKey_layout_37: "Modify strategy",
  i18nKey_layout_38: "Strategy details",
  i18nKey_layout_39: "Track trajectory",
  i18nKey_layout_40: "Historical events",
  i18nKey_layout_41: "Event details",
  i18nKey_layout_42: "New patrol route",
  i18nKey_layout_43: "Patrol route modification",
  i18nKey_layout_44: "List of patrol routes",
  i18nKey_layout_45: "List of patrol plans",
  i18nKey_layout_46: "Video patrol statistics",
  i18nKey_layout_47: "Video patrol history",
  i18nKey_layout_48: "Patrol route details",
  i18nKey_layout_49: "New plan",
  i18nKey_layout_50: "Modify the plan",
  i18nKey_layout_51: "Plan details",
  i18nKey_layout_52: "Address management",
  i18nKey_layout_53: "Face library details",
  i18nKey_layout_54: "Face tracking details",
  i18nKey_layout_55: "Face tracking event management",
  i18nKey_layout_56: "Create",
  i18nKey_layout_57: "Modify",
  i18nKey_layout_58: "Show welcome screen",
  i18nKey_layout_59: "Alarm list",
  i18nKey_layout_60: "View",
  i18nKey_layout_61: "Add",
  i18nKey_layout_62: "Edit",
  i18nKey_layout_63: "Unit price configuration",
  i18nKey_layout_64: "Transformer loss (line loss) configuration",
  i18nKey_layout_65: "Floor template details",
  i18nKey_layout_66: "Scenario template details",
  i18nKey_layout_67: "Scenario details",
  i18nKey_layout_68: "Associated camera",
  i18nKey_layout_69: "Vehicle details",
  i18nKey_layout_70: "Personnel details",
  i18nKey_layout_71: "Events List",
  i18nKey_layout_72: "Notice",
  i18nKey_layout_73: "Personal center",
  i18nKey_layout_74: "change Password",
  i18nKey_layout_75: "Drop out",
  i18nKey_layout_76: "Tips",
  i18nKey_layout_77: "Are you sure you want to exit?",
  i18nKey_layout_78: "Panorama mode",
  i18nKey_layout_79: "Panoramic",
  i18nKey_layout_80: "Mode",
  i18nKey_layout_81: "China",
  i18nKey_layout_82: "Year",
  i18nKey_layout_83: "Month",
  i18nKey_layout_84: "Day",
  i18nKey_layout_85: "Cancel",
  i18nKey_layout_86: "Confirm",
  i18nKey_layout_87: "Old password",
  i18nKey_layout_88: "New password",
  i18nKey_layout_89: "New key confirmation",
  i18nKey_layout_90: "Please enter the current password",
  i18nKey_layout_91: "Please enter a new password",
  i18nKey_layout_92: "Please enter the new password again",
  i18nKey_layout_93: "New password can't be the same as the old one",
  i18nKey_layout_94: "The new password is not entered before and after",
  i18nKey_layout_95: "Please enter password",
  i18nKey_layout_96: "Password can't contain spaces",
  i18nKey_layout_97: "Password strength does not match",
  i18nKey_layout_98: "At least 8",
  i18nKey_layout_99: "Include numbers",
  i18nKey_layout_100: "Capital and lowercase letters",
  i18nKey_layout_101: "At least three types of special characters",
  i18nKey_layout_102: "After the password is changed, you need to log in again",
  i18nKey_layout_103: "Successfully modified",
  i18nKey_layout_104: "Go to login",
  i18nKey_layout_105: "Avatar",
  i18nKey_layout_106: "Name",
  i18nKey_layout_107: "Phone",
  i18nKey_layout_108: "Nickname",
  i18nKey_layout_109: "Gender",
  i18nKey_layout_110: "Upload avatar",
  i18nKey_layout_111: "Please enter a nickname",
  i18nKey_layout_112: "Male",
  i18nKey_layout_113: "Female",
  i18nKey_layout_114: "Picture size can't exceed 1",
  i18nKey_layout_115: "Can only upload",
  i18nKey_layout_116: "Format picture",
  i18nKey_layout_117: "Upload failed",
  i18nKey_layout_118: "Save",
  i18nKey_layout_119: "AutoNavi",
  i18nKey_layout_120: "plane",
  i18nKey_enterprise_1: "Title",
  i18nKey_enterprise_2: "Bold",
  i18nKey_enterprise_3: "Font size",
  i18nKey_enterprise_4: "Font",
  i18nKey_enterprise_5: "Italic",
  i18nKey_enterprise_6: "Underscore",
  i18nKey_enterprise_7: "Strikethrough",
  i18nKey_enterprise_8: "Text color",
  i18nKey_enterprise_9: "Background color",
  i18nKey_enterprise_10: "Insert link",
  i18nKey_enterprise_11: "List",
  i18nKey_enterprise_12: "Alignment",
  i18nKey_enterprise_13: "Insert table",
  i18nKey_enterprise_14: "Insert code",
  i18nKey_enterprise_15: "Revoke",
  i18nKey_enterprise_16: "Repeat",
  i18nKey_enterprise_17: "Failed to upload picture",
  i18nKey_enterprise_18: "Simulation",
  i18nKey_enterprise_19: "Event generator",
  i18nKey_enterprise_20: "Time:",
  i18nKey_enterprise_21: "Event title:",
  i18nKey_enterprise_22: "Event description:",
  i18nKey_enterprise_23: "Event address:",
  i18nKey_enterprise_24: "Alarm equipment:",
  i18nKey_enterprise_25: "Event source:",
  i18nKey_enterprise_26: "Visitor system",
  i18nKey_enterprise_27: "Access control",
  i18nKey_enterprise_28: "Fire fighting",
  i18nKey_enterprise_29: "Perimeter",
  i18nKey_enterprise_30: "Event level:",
  i18nKey_enterprise_31: "Important warning",
  i18nKey_enterprise_32: "Event type:",
  i18nKey_enterprise_33: "Fire incident",
  i18nKey_enterprise_34: "Security incident",
  i18nKey_enterprise_35: "Generate event",
  i18nKey_enterprise_36: "Vanke Technology Company",
  i18nKey_enterprise_37: "all rights reserved",
  i18nKey_enterprise_38: "Technical support telephone: 000-00000000",
  i18nKey_enterprise_39: "",
  i18nKey_enterprise_40: "Current position",
  i18nKey_enterprise_41: "Assets",
  i18nKey_enterprise_42: "Information",
  i18nKey_enterprise_43: "Personnel modification cancel",
  i18nKey_enterprise_44: "Video buffering",
  i18nKey_enterprise_45: "Misjudgment",
  i18nKey_enterprise_46: "Report",
  i18nKey_enterprise_47: "Time",
  i18nKey_enterprise_48: "Location",
  i18nKey_enterprise_49: "A certain building in Vanke Chengdu Park",
  i18nKey_enterprise_50: "Video patrol manual report",
  i18nKey_enterprise_51: "Level",
  i18nKey_enterprise_52: "Red emergency warning",
  i18nKey_enterprise_53: "Orange emergency warning",
  i18nKey_enterprise_54: "Yellow emergency warning",
  i18nKey_enterprise_55: "Blue emergency warning",
  i18nKey_enterprise_56: "Description",
  i18nKey_enterprise_57: "Especially serious accident ",
  i18nKey_enterprise_58: "Serious accident",
  i18nKey_enterprise_59: "Large accident ",
  i18nKey_enterprise_60: "Ordinary accident ",
  i18nKey_enterprise_61: "Can't be null, please enter content.",
  i18nKey_enterprise_62: "Note: Suitable for table pages without nested components on the list page",
  i18nKey_enterprise_63: "Need to upload class name",
  i18nKey_enterprise_64: "To find what you need",
  i18nKey_enterprise_65: "Element",
  i18nKey_enterprise_66: "Original",
  i18nKey_enterprise_67: "Need to set initial value properties",
  i18nKey_enterprise_68: "Need to set the outermost label",
  i18nKey_enterprise_69: "Confirm",
  i18nKey_enterprise_70: "Please enter your name or job number",
  i18nKey_enterprise_71: "Task",
  i18nKey_enterprise_72: "Select camera",
  i18nKey_enterprise_73: "Security box",
  i18nKey_enterprise_74: "Unit:",
  i18nKey_enterprise_75: "Unit: Piece",
  i18nKey_enterprise_76: "Unit: person-time",
  i18nKey_enterprise_77: "Related data exception, can't check",
  i18nKey_enterprise_78: "View details",
  i18nKey_enterprise_79: "North Gate",
  i18nKey_enterprise_80: "East gate",
  i18nKey_enterprise_81: "Property security",
  i18nKey_enterprise_82: "Single pie chart statistics",
  i18nKey_enterprise_83: "Minimize",
  i18nKey_enterprise_84: "Maximize",
  i18nKey_enterprise_85: "The model is not mounted in the parking lot, please contact the administrator to mount the model!",
  i18nKey_enterprise_86: "Last day",
  i18nKey_enterprise_87: "Select associated device",
  i18nKey_enterprise_88: "Device ID",
  i18nKey_enterprise_89: "Public area energy consumption",
  i18nKey_enterprise_90: "The next day",
  i18nKey_enterprise_91: "Last year",
  i18nKey_enterprise_92: "Total power consumption",
  i18nKey_enterprise_93: "Public area power consumption",
  i18nKey_enterprise_94: "Public area",
  i18nKey_enterprise_95: "Percentage",
  i18nKey_enterprise_96: "Compared",
  i18nKey_enterprise_97: "The same period",
  i18nKey_enterprise_98: "Unit",
  i18nKey_enterprise_99: "Delete picture",
  i18nKey_enterprise_100: "Tip: only support",
  i18nKey_enterprise_101: "Upload pictures in a format, the size of the uploaded picture doesn't exceed 3",
  i18nKey_enterprise_102: "Picture size",
  i18nKey_enterprise_103: "Picture type",
  i18nKey_enterprise_104: "Build time",
  i18nKey_enterprise_105: "Upload image can't be greater than 3",
  i18nKey_enterprise_106: "Please upload again",
  i18nKey_enterprise_107: "Quantity",
  i18nKey_enterprise_108: "Cumulative total charge",
  i18nKey_enterprise_109: "Cumulative total discharge",
  i18nKey_enterprise_110: "Monitoring interval",
  i18nKey_enterprise_111: "Please enter the minimum",
  i18nKey_enterprise_112: "Please enter the maximum",
  i18nKey_enterprise_113: "Successfully saved!",
  i18nKey_enterprise_114: "No data",
  i18nKey_enterprise_115: "Complete",
  i18nKey_enterprise_116: "Select associated monitor",
  i18nKey_enterprise_117: "Select associated smoke",
  i18nKey_enterprise_118: "Business type",
  i18nKey_enterprise_119: "telephone number",
  i18nKey_enterprise_120: "Please enter your phone number",
  i18nKey_enterprise_121: "Add",
  i18nKey_enterprise_122: "Strategy name composed of less than 20 characters, hyphen, and underscores",
  i18nKey_enterprise_123: "Monitoring period",
  i18nKey_enterprise_124: "Human traffic",
  i18nKey_enterprise_125: "Smoking monitoring",
  i18nKey_enterprise_126: "Monitoring threshold",
  i18nKey_enterprise_127: "Correlation monitor",
  i18nKey_enterprise_128: "Please select a monitor",
  i18nKey_enterprise_129: "Number of people",
  i18nKey_enterprise_130: "People number can only enter a positive integer greater than 0 and less than or equal to 99999",
  i18nKey_enterprise_131: "Smoke concentration alarm threshold",
  i18nKey_enterprise_132: "Connected smoke sensor",
  i18nKey_enterprise_133: "Please select smoke sensor",
  i18nKey_enterprise_134: "Please enter the alarm threshold",
  i18nKey_enterprise_135: "Threshold can only enter a positive integer greater than 0 and less than or equal to 5000",
  i18nKey_enterprise_136: "This is a tip",
  i18nKey_enterprise_137: "Tips",
  i18nKey_enterprise_138: "Concentration",
  i18nKey_enterprise_139: "Moderate smoke",
  i18nKey_enterprise_140: "Cigarette",
  i18nKey_enterprise_141: "Download pictures",
  i18nKey_enterprise_142: "View alarm",
  i18nKey_enterprise_143: "No eligible data temporarily",
  i18nKey_enterprise_144: "Please enter a description",
  i18nKey_enterprise_145: "Manual upload of video patrol",
  i18nKey_enterprise_146: "Work order dispatch",
  i18nKey_enterprise_147: "Select personnel",
  i18nKey_enterprise_148: "Dispatch work orders",
  i18nKey_enterprise_149: "Enter name or job number",
  i18nKey_enterprise_150: "Alarm name",
  i18nKey_enterprise_151: "Alarm status",
  i18nKey_enterprise_152: "Warning level",
  i18nKey_enterprise_153: "Alarm time",
  i18nKey_enterprise_154: "Person in charge",
  i18nKey_enterprise_155: "Plan start time",
  i18nKey_enterprise_156: "Please select the planned start time",
  i18nKey_enterprise_157: "Plan end time",
  i18nKey_enterprise_158: "Please select the end time of the plan",
  i18nKey_enterprise_159: "Alarm location",
  i18nKey_enterprise_160: "Previous",
  i18nKey_enterprise_161: "Dispatch task",
  i18nKey_enterprise_162: "Must choose a person",
  i18nKey_enterprise_163: "Create work order",
  i18nKey_enterprise_164: "Work order type",
  i18nKey_enterprise_165: "Task details description",
  i18nKey_enterprise_166: "Please enter a detailed description of the task",
  i18nKey_enterprise_167: "Associated equipment",
  i18nKey_enterprise_168: "Please select associated device",
  i18nKey_enterprise_169: "Task level",
  i18nKey_enterprise_170: "Please select task level",
  i18nKey_enterprise_171: "Please choose the person responsible",
  i18nKey_enterprise_172: "Please select the time of occurrence",
  i18nKey_enterprise_173: "Please select a work order delegate",
  i18nKey_enterprise_174: "Site contact",
  i18nKey_enterprise_175: "Please select the on-site contact",
  i18nKey_enterprise_176: "Please enter the device name",
  i18nKey_enterprise_177: "Created successfully",
  i18nKey_enterprise_178: "Fill in the form manually",
  i18nKey_enterprise_179: "The task detail description can't be null",
  i18nKey_enterprise_180: "Length in",
  i18nKey_enterprise_181: "To",
  i18nKey_enterprise_182: "Characters",
  i18nKey_enterprise_183: "Failed to create a new ticket",
  i18nKey_enterprise_184: "Work order",
  i18nKey_enterprise_185: "Work order dispatch completed",
  i18nKey_enterprise_186: "No data",
  i18nKey_enterprise_187: "This device has no superior device",
  i18nKey_enterprise_188: "Please choose a location",
  i18nKey_enterprise_189: "Please enter the work order delegate",
  i18nKey_enterprise_190: "Please enter the site contact",
  i18nKey_enterprise_191: "Option 1",
  i18nKey_enterprise_192: "Sponge Cake",
  i18nKey_enterprise_193: "Option 2",
  i18nKey_enterprise_194: "Stewed Milk Beancurd",
  i18nKey_enterprise_195: "Option 3",
  i18nKey_enterprise_196: "Oyster omelette",
  i18nKey_enterprise_197: "Option 4",
  i18nKey_enterprise_198: "Fine noodles",
  i18nKey_enterprise_199: "Actual start time",
  i18nKey_enterprise_200: "Actual end time",
  i18nKey_enterprise_201: "Work order processing time",
  i18nKey_enterprise_202: "Details of inspectors",
  i18nKey_enterprise_203: "Position",
  i18nKey_enterprise_204: "On-the-job situation",
  i18nKey_enterprise_205: "Total number of work orders",
  i18nKey_enterprise_206: "Processing",
  i18nKey_enterprise_207: "Event dynamics",
  i18nKey_enterprise_208: "Notify personnel, evacuate building personnel",
  i18nKey_enterprise_209: "Dial 119, 120",
  i18nKey_enterprise_210: "Get in touch with the emergency team and start deploying evacuation",
  i18nKey_enterprise_211: "An alarm occurred on the smoke detector on the first floor of Building 1, Chengdu Park",
  i18nKey_enterprise_212: "Local files upload",
  i18nKey_enterprise_213: "Select from the staff library",
  i18nKey_enterprise_214: "Upload to server",
  i18nKey_enterprise_215: "Personnel",
  i18nKey_enterprise_216: "Gao Fei",
  i18nKey_enterprise_217: "Gao Zhengwei",
  i18nKey_enterprise_218: "Gao Linfei",
  i18nKey_enterprise_219: "Li Chuangye",
  i18nKey_enterprise_220: "Yao Yun",
  i18nKey_enterprise_221: "Avatar",
  i18nKey_enterprise_222: "Label",
  i18nKey_enterprise_223: "Enter name or number",
  i18nKey_enterprise_224: "Smoke",
  i18nKey_enterprise_225: "More layers back",
  i18nKey_enterprise_226: "Code",
  i18nKey_enterprise_227: "Background return",
  i18nKey_enterprise_228: "Chengdu Vanke Park - building 1 F1",
  i18nKey_enterprise_229: "District air conditioning fresh air unit",
  i18nKey_enterprise_230: "Level",
  i18nKey_enterprise_231: "Cooling System",
  i18nKey_enterprise_232: "Area",
  i18nKey_enterprise_233: "Chengdu Vanke Park",
  i18nKey_enterprise_234: "Owning system",
  i18nKey_enterprise_235: "HVAC system",
  i18nKey_enterprise_236: "Lin Sheng",
  i18nKey_enterprise_237: "Subclass",
  i18nKey_enterprise_238: "Subclass placeholder",
  i18nKey_enterprise_239: "Facility description placeholder",
  i18nKey_enterprise_240: "New task",
  i18nKey_enterprise_241: "Task details",
  i18nKey_enterprise_242: "Minei",
  i18nKey_enterprise_243: "Unlink",
  i18nKey_enterprise_244: "Connected",
  i18nKey_enterprise_245: "Event-linked camera",
  i18nKey_enterprise_246: "Surrounding camera",
  i18nKey_enterprise_247: "Associated camera",
  i18nKey_enterprise_248: "Processing log",
  i18nKey_enterprise_249: "Notify employees and evacuate building personnel",
  i18nKey_enterprise_250: "Notify employees and evacuate building personnel details",
  i18nKey_enterprise_251: "Incident time",
  i18nKey_enterprise_252: "Location of the incident",
  i18nKey_enterprise_253: "Received",
  i18nKey_enterprise_254: "Reassign",
  i18nKey_enterprise_255: "Task closing time",
  i18nKey_enterprise_256: "Task assignment time",
  i18nKey_enterprise_257: "Task assignee",
  i18nKey_enterprise_258: "Live feedback",
  i18nKey_enterprise_259: "Task notes",
  i18nKey_enterprise_260: "Founder:",
  i18nKey_enterprise_261: "Big",
  i18nKey_enterprise_262: "Small:",
  i18nKey_enterprise_263: "Task list",
  i18nKey_enterprise_264: "View more",
  i18nKey_enterprise_265: "Comprehensive Situation-National",
  i18nKey_enterprise_266: "Statistics cards",
  i18nKey_enterprise_267: "Comprehensive situation",
  i18nKey_enterprise_268: "Security incident statistics",
  i18nKey_enterprise_269: "Comprehensive situation display",
  i18nKey_enterprise_270: "Please select no less than",
  i18nKey_enterprise_271: "Please enter the meter name",
  i18nKey_enterprise_272: "Electric meter",
  i18nKey_enterprise_273: "Water meter",
  i18nKey_enterprise_274: "Please enter the meter name",
  i18nKey_enterprise_275: "Energy use today",
  i18nKey_enterprise_276: "QoQ this month",
  i18nKey_enterprise_277: "YoY this month",
  i18nKey_enterprise_278: "Tenant electricity",
  i18nKey_enterprise_279: "Electricity for lighting",
  i18nKey_enterprise_280: "Total daily energy consumption",
  i18nKey_enterprise_281: "Enter event number",
  i18nKey_enterprise_282: "Please select the dispatcher",
  i18nKey_enterprise_283: "Planned start time can't be null",
  i18nKey_enterprise_284: "Planned end time can't be null",
  i18nKey_enterprise_285: "Network loading abnormal",
  i18nKey_enterprise_286: "Confirm Password",
  i18nKey_enterprise_287: "Password",
  i18nKey_enterprise_288: "Phone number",
  i18nKey_enterprise_289: "Mailbox",
  i18nKey_enterprise_290: "Age",
  i18nKey_enterprise_291: "Industry",
  i18nKey_enterprise_292: "Personnel details",
  i18nKey_enterprise_293: "Image",
  i18nKey_enterprise_294: "Personnel location",
  i18nKey_enterprise_295: "Vehicle Information",
  i18nKey_enterprise_296: "message notification",
  i18nKey_enterprise_297: "Jump to track view",
  i18nKey_enterprise_298: "Portrait label",
  i18nKey_enterprise_299: "Delete basemap",
  i18nKey_enterprise_300: "Times",
  i18nKey_enterprise_301: "mark",
  i18nKey_enterprise_302: "Similarity:",
  i18nKey_enterprise_303: "Only display high similarity faces filtered by the system",
  i18nKey_enterprise_304: "Information Sources:",
  i18nKey_enterprise_305: "No suspect information",
  i18nKey_enterprise_306: "This operation will completely delete the face map",
  i18nKey_enterprise_307: "Enter the park blacklist to deploy control",
  i18nKey_enterprise_308: "Park blacklist deployment control",
  i18nKey_enterprise_309: "Personnel portrait",
  i18nKey_enterprise_310: "Face information",
  i18nKey_enterprise_311: "Suspect information",
  i18nKey_enterprise_312: "Cancel deployment",
  i18nKey_enterprise_313: "Cancel the park blacklist deployment control",
  i18nKey_enterprise_314: "Interface status is abnormal",
  i18nKey_enterprise_315: "Person has at least one mark",
  i18nKey_enterprise_316: "fail to edit",
  i18nKey_enterprise_317: "Modification failed, the interface status is abnormal",
  i18nKey_enterprise_318: "Failed to replace the basemap",
  i18nKey_enterprise_319: "This person enters the blacklist",
  i18nKey_enterprise_320: "This person cancels the blacklist control",
  i18nKey_enterprise_321: "operation failed",
  i18nKey_enterprise_322: "Operation failed, the interface status is abnormal",
  i18nKey_enterprise_323: "Park location",
  i18nKey_enterprise_324: "Welcome",
  i18nKey_enterprise_325: "Welcome screen",
  i18nKey_enterprise_326: "The control reason can't be null",
  i18nKey_enterprise_327: "Please choose who ",
  i18nKey_enterprise_328: "Please select the deployment control welcome screen",
  i18nKey_enterprise_329: "Tracking period",
  i18nKey_enterprise_330: "to",
  i18nKey_enterprise_331: "Tracking status",
  i18nKey_enterprise_332: "Please select tracking status",
  i18nKey_enterprise_333: "Tracking camera",
  i18nKey_enterprise_334: "Please select a tracking camera",
  i18nKey_enterprise_335: "Data deleted successfully",
  i18nKey_enterprise_336: "Successful operation",
  i18nKey_enterprise_337: "Network loading abnormal, please try again later",
  i18nKey_enterprise_338: "No warning",
  i18nKey_enterprise_339: "identification number",
  i18nKey_enterprise_340: "Information Sources",
  i18nKey_enterprise_341: "Robert",
  i18nKey_enterprise_342: "Get up at three in the morning",
  i18nKey_enterprise_343: "Found Haitanghuaweimian",
  i18nKey_enterprise_344: "Tomorrow 11:30 meeting report",
  i18nKey_enterprise_345: "Tomorrow 14:30 meeting report",
  i18nKey_enterprise_346: "Not supported by current browser",
  i18nKey_enterprise_347: "Reminder window reached",
  i18nKey_enterprise_348: "Reminder status",
  i18nKey_enterprise_349: "Arrival reminder camera",
  i18nKey_enterprise_350: "Welcome time",
  i18nKey_enterprise_351: "Welcome state",
  i18nKey_enterprise_352: "Please select welcome status",
  i18nKey_enterprise_353: "Deployment control welcome screen",
  i18nKey_enterprise_354: "Enter the welcome screen",
  i18nKey_enterprise_355: "Enter the name of the welcome screen",
  i18nKey_enterprise_356: "Choose welcome screen",
  i18nKey_enterprise_357: "Vehicle information modification",
  i18nKey_enterprise_358: "Car owner",
  i18nKey_enterprise_359: "The number of words entered can't be greater than 300",
  i18nKey_enterprise_360: "Cancel this modification request",
  i18nKey_enterprise_361: "Suspension record details",
  i18nKey_enterprise_362: "Left",
  i18nKey_enterprise_363: "Parking",
  i18nKey_enterprise_364: "Parking time",
  i18nKey_enterprise_365: "Parking information",
  i18nKey_enterprise_366: "Parking space",
  i18nKey_enterprise_367: "Play record",
  i18nKey_enterprise_368: "Clock-in record",
  i18nKey_enterprise_369: "Record details",
  i18nKey_enterprise_370: "Camera name",
  i18nKey_enterprise_371: "Clock-in person",
  i18nKey_enterprise_372: "Clock-in time",
  i18nKey_enterprise_373: "Not clocked in",
  i18nKey_enterprise_374: "XX patrol group",
  i18nKey_enterprise_375: "play time",
  i18nKey_enterprise_376: "View now",
  i18nKey_enterprise_377: "To be executed",
  i18nKey_enterprise_378: "Sticking to fundamental principles in operation",
  i18nKey_enterprise_379: "Leave the comfort zone",
  i18nKey_enterprise_380: "Is the first step forward.",
  i18nKey_enterprise_381: "Cross ten thousands miles of mountains",
  i18nKey_enterprise_382: "Overcome thousands of difficulties.",
  i18nKey_enterprise_383: "There is no retreat behind the fighter",
  i18nKey_enterprise_384: "There is no danger ahead of courage.",
  i18nKey_enterprise_385: "Unity",
  i18nKey_enterprise_386: "Go all out.",
  i18nKey_enterprise_387: "It's not about the strength",
  i18nKey_enterprise_388: "It depends on how long it can last.",
  i18nKey_enterprise_389: "To find a way to succeed",
  i18nKey_enterprise_390: "No reason for failure.",
  i18nKey_enterprise_391: "A long way to go",
  i18nKey_enterprise_392: "Determined to win",
  i18nKey_enterprise_393: "Only blunt axe",
  i18nKey_enterprise_394: "There is no firewood that can't be split.",
  i18nKey_enterprise_395: "Original value of assets",
  i18nKey_enterprise_396: "Latest reporting time",
  i18nKey_enterprise_397: "Utilization rate (by type)",
  i18nKey_enterprise_398: "installation",
  i18nKey_enterprise_399: "Number of assets",
  i18nKey_enterprise_400: "Total original value of assets",
  i18nKey_enterprise_401: "Asset Name",
  i18nKey_enterprise_402: "Total power consumption",
  i18nKey_enterprise_403: "Data doesn't exist",
  i18nKey_enterprise_404: "Deposit setting",
  i18nKey_enterprise_405: "Deposit payment date",
  i18nKey_enterprise_406: "Please select the deposit payment date",
  i18nKey_enterprise_407: "Refund date",
  i18nKey_enterprise_408: "Please select a refund date",
  i18nKey_enterprise_409: "Applicant",
  i18nKey_enterprise_410: "Payment successful date",
  i18nKey_enterprise_411: "Date of refund request",
  i18nKey_enterprise_412: "Deposit amount",
  i18nKey_enterprise_413: "Refund amount",
  i18nKey_enterprise_414: "Under review",
  i18nKey_enterprise_415: "Request a refund",
  i18nKey_enterprise_416: "Refund deposit",
  i18nKey_enterprise_417: "Successful operation!",
  i18nKey_enterprise_418: "The network load is abnormal, please try again later!",
  i18nKey_enterprise_419: "The deposit is set successfully!",
  i18nKey_enterprise_420: "Refunding",
  i18nKey_enterprise_421: "examination passed",
  i18nKey_enterprise_422: "Both customers and administrators can initiate deposit refunds",
  i18nKey_enterprise_423: "Administrator initiates deposit refund",
  i18nKey_enterprise_424: "Creation date",
  i18nKey_enterprise_425: "Remove",
  i18nKey_enterprise_426: "The network load is abnormal, please try again later!",
  i18nKey_enterprise_427: "Cancel success!",
  i18nKey_enterprise_428: "Park space name",
  i18nKey_enterprise_429: "Please enter the park space name",
  i18nKey_enterprise_430: "Conference room code",
  i18nKey_enterprise_431: "Please enter the conference room code",
  i18nKey_enterprise_432: "successfully deleted!",
  i18nKey_enterprise_433: "Please enter the space name",
  i18nKey_enterprise_434: "Download the QR code",
  i18nKey_enterprise_435: "Select date",
  i18nKey_enterprise_436: "Please select a date",
  i18nKey_enterprise_437: "No scheduled time",
  i18nKey_enterprise_438: "Park space list",
  i18nKey_enterprise_439: "on Sunday",
  i18nKey_enterprise_440: "Reservation person",
  i18nKey_enterprise_441: "Open the door",
  i18nKey_enterprise_442: "close the door",
  i18nKey_enterprise_443: "Unlock time",
  i18nKey_enterprise_444: "The operation failed, and there is no door opening record on the current page!",
  i18nKey_enterprise_445: "Configuration",
  i18nKey_enterprise_446: "Transformer configuration",
  i18nKey_enterprise_447: "There is no transformer yet!",
  i18nKey_enterprise_448: "Add transformer",
  i18nKey_enterprise_449: "After the modification, the system calculates the amount of electricity consumption from the next day at the modified unit price",
  i18nKey_enterprise_450: "Transformer name",
  i18nKey_enterprise_451: "Total share",
  i18nKey_enterprise_452: "Whether to On/Off",
  i18nKey_enterprise_453: "Modify record",
  i18nKey_enterprise_454: "No modification record",
  i18nKey_enterprise_455: "Change the time",
  i18nKey_enterprise_456: "Modify content",
  i18nKey_enterprise_457: "Operator",
  i18nKey_enterprise_458: "Please fill in the transformer information",
  i18nKey_enterprise_459: "Unit price configuration",
  i18nKey_enterprise_460: "Currently there is no unit price set!",
  i18nKey_enterprise_461: "Set unit price",
  i18nKey_enterprise_462: "The value must be a positive number with a maximum of two decimal places",
  i18nKey_enterprise_463: "Share consumption amount",
  i18nKey_enterprise_464: "Modified amount (yuan)",
  i18nKey_enterprise_465: "Transformer loss (line loss) configuration",
  i18nKey_enterprise_466: "Merchant",
  i18nKey_enterprise_467: "Tenant type",
  i18nKey_enterprise_468: "Please select a business",
  i18nKey_enterprise_469: "Please select or enter a business",
  i18nKey_enterprise_470: "Tenant name",
  i18nKey_enterprise_471: "power consumption",
  i18nKey_enterprise_472: "Amount of consumption",
  i18nKey_enterprise_473: "Shared power consumption",
  i18nKey_enterprise_474: "Share percentage",
  i18nKey_enterprise_475: "Distribution details",
  i18nKey_enterprise_476: "Disable",
  i18nKey_enterprise_477: "Apportionment management",
  i18nKey_enterprise_478: "Date",
  i18nKey_enterprise_479: "No data to export yet!",
  i18nKey_enterprise_480: "BoYu Health Check-in",
  i18nKey_enterprise_481: "Business daily clock in",
  i18nKey_enterprise_482: "Report the police on the epidemic",
  i18nKey_enterprise_483: "Are you sure to delete? It can't be restored after deletion!",
  i18nKey_enterprise_484: "Please enter the name of the person",
  i18nKey_enterprise_485: "Please enter a contact number in the correct format",
  i18nKey_enterprise_486: "Modify team members",
  i18nKey_enterprise_487: "Only support Chinese and English case and numbers",
  i18nKey_enterprise_488: "Group coding",
  i18nKey_enterprise_489: "Please enter the group code",
  i18nKey_enterprise_490: "Dictionary name",
  i18nKey_enterprise_491: "Please enter the dictionary name",
  i18nKey_enterprise_492: "Dictionary number",
  i18nKey_enterprise_493: "Please enter the dictionary number",
  i18nKey_enterprise_494: "Dictionary content",
  i18nKey_enterprise_495: "Sort",
  i18nKey_enterprise_496: "Expand filed",
  i18nKey_enterprise_497: "Vanzhihui",
  i18nKey_enterprise_498: "Vanke Production Office",
  i18nKey_enterprise_499: "New data dictionary",
  i18nKey_enterprise_500: "Data dictionary details",
  i18nKey_enterprise_501: "Modify the data dictionary",
  i18nKey_enterprise_502: "Please enter the serial number",
  i18nKey_enterprise_503: "Please enter the dictionary content",
  i18nKey_enterprise_504: "Please enter order",
  i18nKey_enterprise_505: "Please enter the extension field",
  i18nKey_enterprise_506: "Can't enter emoticons",
  i18nKey_enterprise_507: "Please select facility level",
  i18nKey_enterprise_508: "Facility classification",
  i18nKey_enterprise_509: "Please enter the person in charge of management",
  i18nKey_enterprise_510: "Please enter facility description",
  i18nKey_enterprise_511: "Device status",
  i18nKey_enterprise_512: "Automatic fire alarm and linkage system",
  i18nKey_enterprise_513: "Fire alarm host",
  i18nKey_enterprise_514: "Smoke detector",
  i18nKey_enterprise_515: "Thermal fire detector",
  i18nKey_enterprise_516: "manual alarm button",
  i18nKey_enterprise_517: "Upper limit",
  i18nKey_enterprise_518: "Availability",
  i18nKey_enterprise_519: "Failure rate",
  i18nKey_enterprise_520: "Total alarms",
  i18nKey_enterprise_521: "Barrier gate",
  i18nKey_enterprise_522: "Distribute",
  i18nKey_enterprise_523: "Successfully distributed!",
  i18nKey_enterprise_524: "Event description can't exceed 1000 characters",
  i18nKey_enterprise_525: "Network Error",
  i18nKey_enterprise_526: "Camera 001",
  i18nKey_enterprise_527: "start up",
  i18nKey_enterprise_528: "No related events",
  i18nKey_enterprise_529: "Misjudgment success",
  i18nKey_enterprise_530: "The event status is pending and can't be reported temporarily",
  i18nKey_enterprise_531: "The event status is processing and can't be reported temporarily",
  i18nKey_enterprise_532: "The event status is pending and can't be reported temporarily",
  i18nKey_enterprise_533: "Face tracking is starting",
  i18nKey_enterprise_534: "New face deployment control",
  i18nKey_enterprise_535: "Search by name",
  i18nKey_enterprise_536: "Select all",
  i18nKey_enterprise_537: "Select the node when you click the node",
  i18nKey_enterprise_538: "Expand all nodes by default",
  i18nKey_enterprise_539: "Only supports",
  i18nKey_enterprise_540: "Format picture and no more than 3",
  i18nKey_enterprise_541: "Please type in your name",
  i18nKey_enterprise_542: "List type",
  i18nKey_enterprise_543: "Please enter identification number",
  i18nKey_enterprise_544: "Male",
  i18nKey_enterprise_545: "Female",
  i18nKey_enterprise_546: "Please enter residential address",
  i18nKey_enterprise_547: "Expand all sections by default",
  i18nKey_enterprise_548: "Please select list type",
  i18nKey_enterprise_549: "Picture only supports",
  i18nKey_enterprise_550: "Picture can't be larger than 3",
  i18nKey_enterprise_551: "Public security blacklist import template",
  i18nKey_enterprise_552: "Meter",
  i18nKey_enterprise_553: "Failed to delete",
  i18nKey_enterprise_554: "Please select the object to be deleted",
  i18nKey_enterprise_555: "confirm to delete",
  i18nKey_enterprise_556: "Please upload a picture",
  i18nKey_enterprise_557: "New library",
  i18nKey_enterprise_558: "Delete library",
  i18nKey_enterprise_559: "More",
  i18nKey_enterprise_560: "Face tracking is closing",
  i18nKey_enterprise_561: "Personnel:",
  i18nKey_enterprise_562: "Monitoring screenshot",
  i18nKey_enterprise_563: "Tracking time",
  i18nKey_enterprise_564: "Number of warnings",
  i18nKey_enterprise_565: "Shanghai area",
  i18nKey_enterprise_566: "Shanghai",
  i18nKey_enterprise_567: "Jinqiao Wanchuang",
  i18nKey_enterprise_568: "floor",
  i18nKey_enterprise_569: "B2",
  i18nKey_enterprise_570: "Parking lot",
  i18nKey_enterprise_571: "Please enter a description of the patrol group",
  i18nKey_enterprise_572: "Hang",
  i18nKey_enterprise_573: "Total number of events",
  i18nKey_enterprise_574: "Number of pending events",
  i18nKey_enterprise_575: "Total number of tasks",
  i18nKey_enterprise_576: "Number of pending tasks",
  i18nKey_enterprise_577: "Safety",
  i18nKey_enterprise_578: "Always processed",
  i18nKey_enterprise_579: "Total security incident",
  i18nKey_enterprise_580: "Riding company",
  i18nKey_enterprise_581: "Company booking",
  i18nKey_enterprise_582: "Frequency",
  i18nKey_enterprise_583: "Store sales",
  i18nKey_enterprise_584: "Dishes sales",
  i18nKey_enterprise_585: "Facilities exception",
  i18nKey_enterprise_586: "Forward",
  i18nKey_enterprise_587: "Upload time",
  i18nKey_enterprise_588: "Event",
  i18nKey_enterprise_589: "Closing time",
  i18nKey_enterprise_590: "The task has generated an alarm",
  i18nKey_enterprise_591: "can't be modified!",
  i18nKey_enterprise_592: "The task is starting",
  i18nKey_enterprise_593: "Please modify after pause!",
  i18nKey_enterprise_594: "Confirm to delete?",
  i18nKey_enterprise_595: "Start and stop time",
  i18nKey_enterprise_596: "Patrol time",
  i18nKey_enterprise_597: "Problem",
  i18nKey_enterprise_598: "Yes",
  i18nKey_enterprise_599: "No",
  i18nKey_enterprise_600: "closed",
  i18nKey_enterprise_601: "Patrol route history",
  i18nKey_enterprise_602: "All patrol history",
  i18nKey_enterprise_603: "Alert",
  i18nKey_enterprise_604: "Event name",
  i18nKey_enterprise_605: "efficiency",
  i18nKey_enterprise_606: "Vanke Wisdom Exhibition Area",
  i18nKey_enterprise_607: "Major event alert",
  i18nKey_enterprise_608: "Park",
  i18nKey_enterprise_609: "Tianfu Yuncheng",
  i18nKey_enterprise_610: "The project site is located in Jinqiao Wanchuang Center",
  i18nKey_enterprise_611: "Underground garage, configuration three sets, 250",
  i18nKey_enterprise_612: "Energy storage system, outlets are located at 10",
  i18nKey_enterprise_613: "Under A line 2",
  i18nKey_enterprise_614: "Transformer (1000",
  i18nKey_enterprise_615: "Busbar side, 10",
  i18nKey_enterprise_616: "B line 5",
  i18nKey_enterprise_617: "Busbar side and 6",
  i18nKey_enterprise_618: "Transformer (2000",
  i18nKey_enterprise_619: "Busbar side",
  i18nKey_enterprise_620: "Total charge and discharge report",
  i18nKey_enterprise_621: "Charge",
  i18nKey_enterprise_622: "Discharge",
  i18nKey_enterprise_623: "Prompt content",
  i18nKey_enterprise_624: "Box size",
  i18nKey_enterprise_625: "Inch",
  i18nKey_enterprise_626: "Number of subsystems",
  i18nKey_enterprise_627: "User type",
  i18nKey_enterprise_628: "Rated Capacity",
  i18nKey_enterprise_629: "Rated power",
  i18nKey_enterprise_630: "Transformer capacity",
  i18nKey_enterprise_631: "Access voltage level",
  i18nKey_enterprise_632: "Total charge",
  i18nKey_enterprise_633: "Total discharge",
  i18nKey_enterprise_634: "Confirm download",
  i18nKey_enterprise_635: "Report?",
  i18nKey_enterprise_636: "Meter",
  i18nKey_enterprise_637: "Point reading",
  i18nKey_enterprise_638: "Energy used",
  i18nKey_enterprise_639: "Tenant electricity consumption",
  i18nKey_enterprise_640: "Energy consumption changes",
  i18nKey_enterprise_641: "Actual energy consumption value",
  i18nKey_enterprise_642: "Target energy consumption value",
  i18nKey_enterprise_643: "Forecast energy consumption value",
  i18nKey_enterprise_644: "Predicted value: 1",
  i18nKey_enterprise_645: "In the daily dimension, total energy consumption, public area energy consumption and the next-level sub-items of public area. In the February dimension, total energy consumption, public area energy consumption, and public area sub-items",
  i18nKey_enterprise_646: "Target value: 1",
  i18nKey_enterprise_647: "In the monthly dimension, total energy consumption and public area energy consumption. 2",
  i18nKey_enterprise_648: "In the annual dimension, total energy consumption and public area energy consumption",
  i18nKey_enterprise_649: "Please enter the target value",
  i18nKey_enterprise_650: "Please input the value",
  i18nKey_enterprise_651: "Annual actual value",
  i18nKey_enterprise_652: "Month",
  i18nKey_enterprise_653: "Annual target value",
  i18nKey_enterprise_654: "Public area energy consumption target",
  i18nKey_enterprise_655: "Public area energy consumption",
  i18nKey_enterprise_656: "Energy consumption per square meter",
  i18nKey_enterprise_657: "Park overview",
  i18nKey_enterprise_658: "Please choose the time",
  i18nKey_enterprise_659: "Same period last year:",
  i18nKey_enterprise_660: "Last month:",
  i18nKey_enterprise_661: "Compared with the previous year:",
  i18nKey_enterprise_662: "Compared with last month:",
  i18nKey_enterprise_663: "Last month",
  i18nKey_enterprise_664: "Energy consumption change graph",
  i18nKey_enterprise_665: "Suggest",
  i18nKey_enterprise_666: "change",
  i18nKey_enterprise_667: "Instrument type",
  i18nKey_enterprise_668: "Please select the meter type",
  i18nKey_enterprise_669: "Instrument sub-item",
  i18nKey_enterprise_670: "Please select the instrument sub-item",
  i18nKey_enterprise_671: "Instrument status",
  i18nKey_enterprise_672: "Smart meter number",
  i18nKey_enterprise_673: "equipment number",
  i18nKey_enterprise_674: "Select dimension",
  i18nKey_enterprise_675: "Terminated",
  i18nKey_enterprise_676: "Room",
  i18nKey_enterprise_677: "Please select an instrument first",
  i18nKey_enterprise_678: "Water and electricity meter maintenance",
  i18nKey_enterprise_679: "Unopened",
  i18nKey_enterprise_680: "Contract No",
  i18nKey_enterprise_681: "Instrument serial number",
  i18nKey_enterprise_682: "Total number of switches",
  i18nKey_enterprise_683: "Meter bottom",
  i18nKey_enterprise_684: "Valid period",
  i18nKey_enterprise_685: "Cable square",
  i18nKey_enterprise_686: "location information",
  i18nKey_enterprise_687: "Store",
  i18nKey_enterprise_688: "Unit No.",
  i18nKey_enterprise_689: "Tenant Information",
  i18nKey_enterprise_690: "Reading situation",
  i18nKey_enterprise_691: "Meter bottom will changes along with meter reading, which is the latest reading ",
  i18nKey_enterprise_692: "Current reading",
  i18nKey_enterprise_693: "Last meter reading time",
  i18nKey_enterprise_694: "Change log",
  i18nKey_enterprise_695: "Last change time",
  i18nKey_enterprise_696: "meter number before change",
  i18nKey_enterprise_697: "Last reading",
  i18nKey_enterprise_698: "Meter bottom before change",
  i18nKey_enterprise_699: "Last meter reading time",
  i18nKey_enterprise_700: "Current bottom reading",
  i18nKey_enterprise_701: "After deletion, the association relationship of the instrument will also be cancelled. Do you want to delete it?",
  i18nKey_enterprise_702: "Please select the instrument category",
  i18nKey_enterprise_703: "Instrument information",
  i18nKey_enterprise_704: "Please select the meter number",
  i18nKey_enterprise_705: "Please select the meter number first",
  i18nKey_enterprise_706: "The value must be positive and an integer",
  i18nKey_enterprise_707: "Please enter the instrument serial number",
  i18nKey_enterprise_708: "Please enter the total number of switches",
  i18nKey_enterprise_709: "Once the bottom of the form is filled in, no changes are allowed",
  i18nKey_enterprise_710: "Please enter the bottom of the meter",
  i18nKey_enterprise_711: "Please select the validity period",
  i18nKey_enterprise_712: "Please enter ownership admin",
  i18nKey_enterprise_713: "Please enter the cable square",
  i18nKey_enterprise_714: "ownership",
  i18nKey_enterprise_715: "Please enter ownership",
  i18nKey_enterprise_716: "Please search or select device code",
  i18nKey_enterprise_717: "Please select device code first",
  i18nKey_enterprise_718: "Please enter shop",
  i18nKey_enterprise_719: "Please select contract number",
  i18nKey_enterprise_720: "Please search or select",
  i18nKey_enterprise_721: "Select equipment",
  i18nKey_enterprise_722: "Select instrument",
  i18nKey_enterprise_723: "The instrument has no instrument sub-items",
  i18nKey_enterprise_724: "Edit successfully",
  i18nKey_enterprise_725: "Instrument code",
  i18nKey_enterprise_726: "Please enter the meter code",
  i18nKey_enterprise_727: "Please enter the smart meter code",
  i18nKey_enterprise_728: "Please enter the type",
  i18nKey_enterprise_729: "username",
  i18nKey_enterprise_730: "Payment amount",
  i18nKey_enterprise_731: "Park card payment",
  i18nKey_enterprise_732: "WeChat Pay",
  i18nKey_enterprise_733: "Electricity bill",
  i18nKey_enterprise_734: "Water bill",
  i18nKey_enterprise_735: "Real time",
  i18nKey_enterprise_736: "Day knot",
  i18nKey_enterprise_737: "Real-time: Real-time synchronization",
  i18nKey_enterprise_738: "Data, merchant recharge or",
  i18nKey_enterprise_739: "Pre-deposit, the remaining amount changes in real time; the same",
  i18nKey_enterprise_740: "The remaining amount checked by the merchant;",
  i18nKey_enterprise_741: "Daily knot: updated once a day for",
  i18nKey_enterprise_742: "Yesterday data; statistics on merchants whose remaining amount is less than the threshold and the bills have not been deducted;",
  i18nKey_enterprise_743: "Merchant Name",
  i18nKey_enterprise_744: "Merchant type",
  i18nKey_enterprise_745: "Amount range",
  i18nKey_enterprise_746: "Pre-deposit balance",
  i18nKey_enterprise_747: "Undeducted amount of bills issued",
  i18nKey_enterprise_748: "6",
  i18nKey_enterprise_749: "Individual merchants",
  i18nKey_enterprise_750: "Export successfully",
  i18nKey_enterprise_751: "Power off",
  i18nKey_enterprise_752: "Restore",
  i18nKey_enterprise_753: "Work order time",
  i18nKey_enterprise_754: "Please select the ticket time",
  i18nKey_enterprise_755: "Range of arrears",
  i18nKey_enterprise_756: "Please enter the unit number",
  i18nKey_enterprise_757: "Arrears time",
  i18nKey_enterprise_758: "Arrears amount",
  i18nKey_enterprise_759: "Contract Number",
  i18nKey_enterprise_760: "Instrument number",
  i18nKey_enterprise_761: "Processing failed",
  i18nKey_enterprise_762: "Remote power off",
  i18nKey_enterprise_763: "Power off again",
  i18nKey_enterprise_764: "Close work order",
  i18nKey_enterprise_765: "Manual recovery",
  i18nKey_enterprise_766: "Recharge amount",
  i18nKey_enterprise_767: "Recharge time",
  i18nKey_enterprise_768: "Resume",
  i18nKey_enterprise_769: "Closing completed",
  i18nKey_enterprise_770: "Power failure recovery",
  i18nKey_enterprise_771: "Note: The work orders for manual closing and remote closing need to be issued a power-off recovery command first!",
  i18nKey_enterprise_772: "After the remote closing and manual closing are restored, you need to click and click 'closing completed'",
  i18nKey_enterprise_773: "To finally complete the processing of the work order",
  i18nKey_enterprise_774: "The maximum amount of arrears is incorrectly entered",
  i18nKey_enterprise_775: "The minimum amount of arrears is incorrectly entered",
  i18nKey_enterprise_776: "The arrears amount range is incorrectly entered",
  i18nKey_enterprise_777: "Close the ticket successfully",
  i18nKey_enterprise_778: "Successfully created recovery ticket",
  i18nKey_enterprise_779: "The remote power-off command is issued successfully",
  i18nKey_enterprise_780: "Work order is completed",
  i18nKey_enterprise_781: "Work order details",
  i18nKey_enterprise_782: "Statistics from the beginning to yesterday",
  i18nKey_enterprise_783: "The top-up amount is subject to arrival",
  i18nKey_enterprise_784: "Electricity bill - Yuncheng top-up",
  i18nKey_enterprise_785: "Water bill - Yuncheng top-up",
  i18nKey_enterprise_786: "Electricity bill",
  i18nKey_enterprise_787: "Prestore",
  i18nKey_enterprise_788: "Water bill",
  i18nKey_enterprise_789: "Undeducted amount of recharge",
  i18nKey_enterprise_790: "Electricity bill has not been deducted",
  i18nKey_enterprise_791: "Water bill has not been deducted",
  i18nKey_enterprise_792: "Arrears merchants overview",
  i18nKey_enterprise_793: "Actual power consumption",
  i18nKey_enterprise_794: "The total electricity consumption of the bill is",
  i18nKey_enterprise_795: "Actual water consumption",
  i18nKey_enterprise_796: "The total water consumption on the bill is",
  i18nKey_enterprise_797: "Bill consumption statistics from the beginning to yesterday",
  i18nKey_enterprise_798: "Water consumption",
  i18nKey_enterprise_799: "Detailed",
  i18nKey_enterprise_800: "Please select contract number",
  i18nKey_enterprise_801: "Please enter the unit",
  i18nKey_enterprise_802: "Threshold amount",
  i18nKey_enterprise_803: "Contract period",
  i18nKey_enterprise_804: "Power-off threshold (yuan)",
  i18nKey_enterprise_805: "Meter threshold (yuan)",
  i18nKey_enterprise_806: "Water meter threshold (yuan)",
  i18nKey_enterprise_807: "After deleting this alert setting, no new work order will be generated. Are you sure to delete it?",
  i18nKey_enterprise_808: "Hello and welcome to",
  i18nKey_enterprise_809: "Yuncheng",
  i18nKey_enterprise_810: "Scan to download Yuncheng",
  i18nKey_enterprise_811: "Service Agreement",
  i18nKey_enterprise_812: "Privacy Policy",
  i18nKey_enterprise_813: "Welcome to use",
  i18nKey_enterprise_814: "Platform, please log in to your account after changing the password",
  i18nKey_enterprise_815: "Digits, letters, some special characters",
  i18nKey_enterprise_816: "Password strength doesn't match",
  i18nKey_enterprise_817: "At least 8",
  i18nKey_enterprise_818: "Include numbers",
  i18nKey_enterprise_819: "capital and lowercase letters",
  i18nKey_enterprise_820: "At least three types of special characters",
  i18nKey_enterprise_821: "Please enter phone number!",
  i18nKey_enterprise_822: "please enter a valid phone number!",
  i18nKey_enterprise_823: "Will automatically jump to",
  i18nKey_enterprise_824: "Login page",
  i18nKey_enterprise_825: "Go to",
  i18nKey_enterprise_826: "Platform, please register",
  i18nKey_enterprise_827: "log in",
  i18nKey_enterprise_828: "registered",
  i18nKey_enterprise_829: "Verification code",
  i18nKey_enterprise_830: "A combination of at least 2 types of letters, numbers and special characters",
  i18nKey_enterprise_831: "Please enter the phone number",
  i18nKey_enterprise_832: "Password length is 8",
  i18nKey_enterprise_833: "Bit character",
  i18nKey_enterprise_834: "Platform, please reset your password",
  i18nKey_enterprise_835: "Or Smart Operation Center",
  i18nKey_enterprise_836: "Service Agreement",
  i18nKey_enterprise_837: "Bold",
  i18nKey_enterprise_838: "Colour",
  i18nKey_enterprise_839: "Add a link",
  i18nKey_enterprise_840: "Font size",
  i18nKey_enterprise_841: "Superscript",
  i18nKey_enterprise_842: "Subscript",
  i18nKey_enterprise_843: "Quote",
  i18nKey_enterprise_844: "Indent",
  i18nKey_enterprise_845: "Text alignment",
  i18nKey_enterprise_846: "Text direction",
  i18nKey_enterprise_847: "Code block",
  i18nKey_enterprise_848: "Formula",
  i18nKey_enterprise_849: "Clear font style",
  i18nKey_enterprise_850: "File",
  i18nKey_enterprise_851: "The meeting is logged out",
  i18nKey_enterprise_852: "Please enter here",
  i18nKey_enterprise_853: "Indent right",
  i18nKey_enterprise_854: "Indent left",
  i18nKey_enterprise_855: "Picture size can't exceed 3",
  i18nKey_enterprise_856: "Can only upload",
  i18nKey_enterprise_857: "Format picture",
  i18nKey_enterprise_858: "Please fill in the move-in information below",
  i18nKey_enterprise_859: "Please fill in the moving out information below",
  i18nKey_enterprise_860: "Please select a meeting room name",
  i18nKey_enterprise_861: "Please enter a certified company",
  i18nKey_enterprise_862: "Please select a certified company",
  i18nKey_enterprise_863: "Please select a booking date",
  i18nKey_enterprise_864: "Booking time",
  i18nKey_enterprise_865: "Meeting agenda",
  i18nKey_enterprise_866: "Please enter the meeting topic",
  i18nKey_enterprise_867: "Please enter the order amount",
  i18nKey_enterprise_868: "Please enter the actual amount",
  i18nKey_enterprise_869: "Please enter most 2",
  i18nKey_enterprise_870: "One Chinese character counts as two characters.",
  i18nKey_enterprise_871: "Only enter 0",
  i18nKey_enterprise_872: "And one Chinese character counts as two characters.",
  i18nKey_enterprise_873: "Please select the scheduled end time",
  i18nKey_enterprise_874: "Scheduled date",
  i18nKey_enterprise_875: "WeChat",
  i18nKey_enterprise_876: "payment ongoing",
  i18nKey_enterprise_877: "Cancel payment",
  i18nKey_enterprise_878: "payment successful",
  i18nKey_enterprise_879: "Payment status",
  i18nKey_enterprise_880: "Payment time",
  i18nKey_enterprise_881: "Order status",
  i18nKey_enterprise_882: "Participant Information",
  i18nKey_enterprise_883: "Pending liquidation",
  i18nKey_enterprise_884: "Liquidated",
  i18nKey_enterprise_885: "Withdrawn",
  i18nKey_enterprise_886: "Withdrawal failed",
  i18nKey_enterprise_887: "Reject",
  i18nKey_enterprise_888: "Pass",
  i18nKey_enterprise_889: "Name",
  i18nKey_enterprise_890: "Please enter a name",
  i18nKey_enterprise_891: "Category",
  i18nKey_enterprise_892: "Please select category",
  i18nKey_enterprise_893: "Name is required",
  i18nKey_enterprise_894: "No camera data in the park",
  i18nKey_enterprise_895: "Patrol plan",
  i18nKey_enterprise_896: "Turn off video patrol",
  i18nKey_enterprise_897: "Whether to pause video patrol",
  i18nKey_enterprise_898: "Whether to stop video patrol",
  i18nKey_enterprise_899: "Untreated",
  i18nKey_enterprise_900: "Pending",
  i18nKey_enterprise_901: "To start",
  i18nKey_enterprise_902: "Please select space type",
  i18nKey_enterprise_903: "Shared activity room",
  i18nKey_enterprise_904: "Modify information must be disabled first!",
  i18nKey_enterprise_905: "Booking time",
  i18nKey_enterprise_906: "Please choose a booking time",
  i18nKey_enterprise_907: "User phone number",
  i18nKey_enterprise_908: "Applying",
  i18nKey_enterprise_909: "Shared office order report",
  i18nKey_enterprise_910: "Way of access",
  i18nKey_enterprise_911: "Transit time",
  i18nKey_enterprise_912: "Please select transit time",
  i18nKey_enterprise_913: "Swipe",
  i18nKey_enterprise_914: "Bluetooth",
  i18nKey_enterprise_915: "Remotely",
  i18nKey_enterprise_916: "Intercom",
  i18nKey_enterprise_917: "Select camera",
  i18nKey_enterprise_918: "Choose time period",
  i18nKey_enterprise_919: "Zhongkonan",
  i18nKey_enterprise_920: "Huawei",
  i18nKey_enterprise_921: "All",
  i18nKey_enterprise_922: "Query staff label is abnormal",
  i18nKey_enterprise_923: "Please select floor first",
  i18nKey_enterprise_924: "List of people",
  i18nKey_enterprise_925: "Customer group analysis",
  i18nKey_enterprise_926: "Please select a person tab",
  i18nKey_enterprise_927: "Please select a camera",
  i18nKey_enterprise_928: "Query time",
  i18nKey_enterprise_929: "Company",
  i18nKey_enterprise_930: "Please enter company",
  i18nKey_enterprise_931: "Total people:",
  i18nKey_enterprise_932: "Registered user",
  i18nKey_enterprise_933: "Visitor statistics",
  i18nKey_enterprise_934: "By park",
  i18nKey_enterprise_935: "Total number of people in the park",
  i18nKey_enterprise_936: "Total visitors",
  i18nKey_enterprise_937: "Total people",
  i18nKey_enterprise_938: "Service type",
  i18nKey_enterprise_939: "Please select service type",
  i18nKey_enterprise_940: "Application time",
  i18nKey_enterprise_941: "Please select the application time",
  i18nKey_enterprise_942: "Applicant's mobile phone number",
  i18nKey_enterprise_943: "Please enter the applicant's mobile phone number",
  i18nKey_enterprise_944: "Applicant's company",
  i18nKey_enterprise_945: "Please enter the applicant’s company",
  i18nKey_enterprise_946: "Item description",
  i18nKey_enterprise_947: "Service number",
  i18nKey_enterprise_948: "Third party",
  i18nKey_enterprise_949: "Please enter the third party",
  i18nKey_enterprise_950: "Morgan Platform Meeting Room",
  i18nKey_enterprise_951: "Please enter the Morgan platform meeting room",
  i18nKey_enterprise_952: "Please select data source",
  i18nKey_enterprise_953: "Please enter the most 1",
  i18nKey_enterprise_954: "One Chinese character counts as two characters",
  i18nKey_enterprise_955: "And there can be no spaces!",
  i18nKey_enterprise_956: "Scenes",
  i18nKey_enterprise_957: "Please enter the scene",
  i18nKey_enterprise_958: "Policy setting",
  i18nKey_enterprise_959: "Please select a policy setting",
  i18nKey_enterprise_960: "No less than 2 min and no greater than 10 min",
  i18nKey_enterprise_961: "Data source type",
  i18nKey_enterprise_962: "Scenario strategy",
  i18nKey_enterprise_963: "Successfully activated",
  i18nKey_enterprise_964: "Deactivated successfully",
  i18nKey_enterprise_965: "Please select meeting status",
  i18nKey_enterprise_966: "Before the meeting: start",
  i18nKey_enterprise_967: "After the meeting started: not started",
  i18nKey_enterprise_968: "After the meeting starts: start",
  i18nKey_enterprise_969: "Last execution time",
  i18nKey_enterprise_970: "Scheduled business",
  i18nKey_enterprise_971: "After the meeting",
  i18nKey_enterprise_972: "Add meeting room scenario strategy",
  i18nKey_enterprise_973: "Modify meeting room scenario strategy",
  i18nKey_enterprise_974: "Please enter license plate number",
  i18nKey_enterprise_975: "Parking space",
  i18nKey_enterprise_976: "Please enter a parking space",
  i18nKey_enterprise_977: "No picture",
  i18nKey_enterprise_978: "Vehicle management",
  i18nKey_enterprise_979: "Vehicle query",
  i18nKey_enterprise_980: "The difference between the start and end time can't be more than 30 days",
  i18nKey_enterprise_981: "Vehicle entry record",
  i18nKey_enterprise_982: "Total parking spaces",
  i18nKey_enterprise_983: "Number of vehicle packages",
  i18nKey_enterprise_984: "Parking fee",
  i18nKey_enterprise_985: "Within hours",
  i18nKey_enterprise_986: "Drive out record",
  i18nKey_enterprise_987: "Drive-in record",
  i18nKey_enterprise_988: "No parking lot",
  i18nKey_enterprise_989: "Query type",
  i18nKey_enterprise_990: "License plate no.",
  i18nKey_enterprise_991: "Parking number",
  i18nKey_enterprise_992: "Please enter license plate number",
  i18nKey_enterprise_993: "Please enter parking lot number",
  i18nKey_enterprise_994: "Press down",
  i18nKey_enterprise_995: "key",
  i18nKey_enterprise_996: "Departure time",
  i18nKey_enterprise_997: "ticket",
  i18nKey_enterprise_998: "unit price",
  i18nKey_enterprise_999: "Whether multi-stage charges",
  i18nKey_enterprise_1000: "The bus schedule has been activated, please pause before operating!",
  i18nKey_enterprise_1001: "Are you sure to delete this bus schedule? It can't be restored after deletion!",
  i18nKey_enterprise_1002: "Price",
  i18nKey_enterprise_1003: "Discount",
  i18nKey_enterprise_1004: "Effective date",
  i18nKey_enterprise_1005: "Please enter the business name",
  i18nKey_enterprise_1006: "Please select order time range",
  i18nKey_enterprise_1007: "Bus order report",
  i18nKey_enterprise_1008: "Hundred million",
  i18nKey_enterprise_1009: "Ten million",
  i18nKey_enterprise_1010: "Million",
  i18nKey_enterprise_1011: "You haven't installed the video player yet. Do you want to download the video player plug-in now? After downloading, please install it yourself",
  i18nKey_enterprise_1012: "No results returned",
  i18nKey_enterprise_1013: "No result",
  i18nKey_enterprise_1014: "Level 7",
  i18nKey_enterprise_1015: "Level 8",
  i18nKey_enterprise_1016: "Level 9",
  i18nKey_enterprise_1017: "Level 10",
  i18nKey_enterprise_1018: "Level 11",
  i18nKey_enterprise_1019: "Level 12",
  i18nKey_enterprise_1020: "Application system number",
  i18nKey_enterprise_1021: "Please select the application system number",
  i18nKey_enterprise_1022: "Coordinate type",
  i18nKey_enterprise_1023: "Coordinate",
  i18nKey_enterprise_1024: "Please select the person responsible for management",
  i18nKey_enterprise_1025: "The attachment only supports the new version",
  i18nKey_enterprise_1026: "Format",
  i18nKey_enterprise_1027: "Not enabled",
  i18nKey_enterprise_1028: "Address",
  i18nKey_enterprise_1029: "No data",
  i18nKey_enterprise_1030: "Confirm delete",
  i18nKey_enterprise_1031: "File size can't exceed 20",
  i18nKey_enterprise_1032: "The classification must be selected to the third level",
  i18nKey_enterprise_1033: "Please enter legal",
  i18nKey_enterprise_1034: "Address, such as 00-01-6",
  i18nKey_enterprise_1035: "Or 00",
  i18nKey_enterprise_1036: "Coordinates, such as",
  i18nKey_enterprise_1037: "Coordinates already exist, please re-enter",
  i18nKey_enterprise_1038: "The source system equipment number can only consist of 50 characters in Chinese and English, numbers and '-'",
  i18nKey_enterprise_1039: "composition",
  i18nKey_enterprise_1040: "The external model can only be Chinese and English, numbers and underscores within 50 characters",
  i18nKey_enterprise_1041: "The integer part of longitude is -180 to 180",
  i18nKey_enterprise_1042: "The integer part of the latitude is -90 to 90",
  i18nKey_enterprise_1043: "The facility description can only be written in Chinese and English, numbers and",
  i18nKey_enterprise_1044: "Manufacturers can only use Chinese and English, numbers and",
  i18nKey_enterprise_1045: "Service providers can only use Chinese and English, numbers and",
  i18nKey_enterprise_1046: "Maintenance providers can only use Chinese and English, numbers and",
  i18nKey_enterprise_1047: "Brands can only be written in Chinese and English, numbers and",
  i18nKey_enterprise_1048: "Supported format is",
  i18nKey_enterprise_1049: "and",
  i18nKey_enterprise_1050: "Facility data import",
  i18nKey_enterprise_1051: "Equipment name",
  i18nKey_enterprise_1052: "Source system code",
  i18nKey_enterprise_1053: "Source system description",
  i18nKey_enterprise_1054: "Source system device code",
  i18nKey_enterprise_1055: "Class 1 equipment name",
  i18nKey_enterprise_1056: "Class 1 equipment classification code",
  i18nKey_enterprise_1057: "Class name of secondary equipment",
  i18nKey_enterprise_1058: "Secondary equipment classification code",
  i18nKey_enterprise_1059: "Class III equipment category name",
  i18nKey_enterprise_1060: "Three-level equipment classification code",
  i18nKey_enterprise_1061: "Spatial model coding",
  i18nKey_enterprise_1062: "Upload successfully",
  i18nKey_enterprise_1063: "The batch number is",
  i18nKey_enterprise_1064: "Please sync to the main table later",
  i18nKey_enterprise_1065: "Upload failed!",
  i18nKey_enterprise_1066: "Please upload a new version",
  i18nKey_enterprise_1067: "Export failed!",
  i18nKey_enterprise_1068: "The batch number for synchronization is",
  i18nKey_enterprise_1069: "Confirm synchronization?",
  i18nKey_enterprise_1070: "Confirm to synchronize all facility data?",
  i18nKey_enterprise_1071: "Synchronization failed, there is no facility data to synchronize temporarily!",
  i18nKey_enterprise_1072: "Synchronization succeeded!",
  i18nKey_enterprise_1073: "Sync failed!",
  i18nKey_enterprise_1074: "Facility classification level",
  i18nKey_enterprise_1075: "Add level",
  i18nKey_enterprise_1076: "Facility importance",
  i18nKey_enterprise_1077: "The facility classification code can only consist of numbers, letters, and underscores",
  i18nKey_enterprise_1078: "Please enter the level to be added",
  i18nKey_enterprise_1079: "Please fill in the data completely, you can't add data across levels",
  i18nKey_enterprise_1080: "Please enter the corresponding category code or category name",
  i18nKey_enterprise_1081: "Category code and category name can't be null",
  i18nKey_enterprise_1082: "Please enter the park code",
  i18nKey_enterprise_1083: "Microsoft Accor black",
  i18nKey_enterprise_1084: "View enterprise",
  i18nKey_enterprise_1085: "Creation method",
  i18nKey_enterprise_1086: "Manual type-in",
  i18nKey_enterprise_1087: "Batch Import",
  i18nKey_enterprise_1088: "Please enter the company's English name",
  i18nKey_enterprise_1089: "Business license registration number",
  i18nKey_enterprise_1090: "Unified social credit code",
  i18nKey_enterprise_1091: "Drag the file here, or",
  i18nKey_enterprise_1092: "Click upload",
  i18nKey_enterprise_1093: "File only supports",
  i18nKey_enterprise_1094: "Please enter the ID number in the correct format",
  i18nKey_enterprise_1095: "Maximum length is 50 characters",
  i18nKey_enterprise_1096: "New business",
  i18nKey_enterprise_1097: "Modify enterprise",
  i18nKey_enterprise_1098: "File, and the suffix is",
  i18nKey_enterprise_1099: "Total",
  i18nKey_enterprise_1100: "  , success",
  i18nKey_enterprise_1101: "  ,  error",
  i18nKey_enterprise_1102: "Big screen connected",
  i18nKey_enterprise_1103: "Large screen can be associated",
  i18nKey_enterprise_1104: "Unlinked successfully",
  i18nKey_enterprise_1105: "Choose category",
  i18nKey_enterprise_1106: "Eight categories",
  i18nKey_enterprise_1107: "Nine categories",
  i18nKey_enterprise_1108: "Please choose one category",
  i18nKey_enterprise_1109: "Please choose two category",
  i18nKey_enterprise_1110: "Please choose three categories",
  i18nKey_enterprise_1111: "Please choose four categories",
  i18nKey_enterprise_1112: "Please select five categories",
  i18nKey_enterprise_1113: "Please choose six categories",
  i18nKey_enterprise_1114: "Select location",
  i18nKey_enterprise_1115: "Failed to query group list",
  i18nKey_enterprise_1116: "Building",
  i18nKey_enterprise_1117: "The park can't be null",
  i18nKey_enterprise_1118: "Floor can't be null",
  i18nKey_enterprise_1119: "File name",
  i18nKey_enterprise_1120: "Facility creation method",
  i18nKey_enterprise_1121: "Please enter the port number",
  i18nKey_enterprise_1122: "Please input Username",
  i18nKey_enterprise_1123: "Address can't be null!",
  i18nKey_enterprise_1124: "Address format is wrong!",
  i18nKey_enterprise_1125: "Port number can't be null!",
  i18nKey_enterprise_1126: "Port number format error!",
  i18nKey_enterprise_1127: "Please enter the circle name",
  i18nKey_enterprise_1128: "Please enter the circle code",
  i18nKey_enterprise_1129: "Configuration is successful",
  i18nKey_enterprise_1130: "Choose a campus",
  i18nKey_enterprise_1131: "Select subsystem",
  i18nKey_enterprise_1132: "Make an appointment to visit",
  i18nKey_enterprise_1133: "Please select the park subsystem",
  i18nKey_enterprise_1134: "Please select a person to make an appointment",
  i18nKey_enterprise_1135: "Please select the estimated time of departure",
  i18nKey_enterprise_1136: "Please select an appointment status",
  i18nKey_enterprise_1137: "Please select the visit time",
  i18nKey_enterprise_1138: "Please select the reason for the visit",
  i18nKey_enterprise_1139: "Choose hometown",
  i18nKey_enterprise_1140: "province",
  i18nKey_enterprise_1141: "Please select province",
  i18nKey_enterprise_1142: "Select department",
  i18nKey_enterprise_1143: "Group name",
  i18nKey_enterprise_1144: "Please enter the group name",
  i18nKey_enterprise_1145: "Group description",
  i18nKey_enterprise_1146: "Can enter 100 characters",
  i18nKey_enterprise_1147: "Add group",
  i18nKey_enterprise_1148: "Group name can't be null",
  i18nKey_enterprise_1149: "Group name already exists",
  i18nKey_enterprise_1150: "The group description can't be null!",
  i18nKey_enterprise_1151: "Modify group information",
  i18nKey_enterprise_1152: "Group information",
  i18nKey_enterprise_1153: "User Info",
  i18nKey_enterprise_1154: "User Department",
  i18nKey_enterprise_1155: "User role",
  i18nKey_enterprise_1156: "User group",
  i18nKey_enterprise_1157: "Modify user information",
  i18nKey_enterprise_1158: "500 characters can be input",
  i18nKey_enterprise_1159: "Add user",
  i18nKey_enterprise_1160: "Load more",
  i18nKey_enterprise_1161: "Please select the user to add!",
  i18nKey_enterprise_1162: "No more",
  i18nKey_enterprise_1163: "User",
  i18nKey_enterprise_1164: "Group",
  i18nKey_enterprise_1165: "No users",
  i18nKey_enterprise_1166: "No groups",
  i18nKey_enterprise_1167: "View group list",
  i18nKey_enterprise_1168: "please enter user name",
  i18nKey_enterprise_1169: "Phone number search",
  i18nKey_enterprise_1170: "Add group",
  i18nKey_enterprise_1171: "Group list",
  i18nKey_enterprise_1172: "Confirm to delete?",
  i18nKey_enterprise_1173: "No role list",
  i18nKey_enterprise_1174: "Please select the user to be deleted",
  i18nKey_enterprise_1175: "Please select the group to delete",
  i18nKey_enterprise_1176: "Please select the user to be added",
  i18nKey_enterprise_1177: "Please select the group to add",
  i18nKey_enterprise_1178: "No more data",
  i18nKey_enterprise_1179: "Choose vehicle brand",
  i18nKey_enterprise_1180: "Select vehicle",
  i18nKey_enterprise_1181: "Select classification path",
  i18nKey_enterprise_1182: "Basic vehicle data information",
  i18nKey_enterprise_1183: "Vehicle classification code",
  i18nKey_enterprise_1184: "License plate",
  i18nKey_enterprise_1185: "Driving license number",
  i18nKey_enterprise_1186: "Vehicle classification name",
  i18nKey_enterprise_1187: "Vehicle parent classification code",
  i18nKey_enterprise_1188: "Owner code",
  i18nKey_enterprise_1189: "Label effective time",
  i18nKey_enterprise_1190: "Label expiration time",
  i18nKey_enterprise_1191: "Valid logo",
  i18nKey_enterprise_1192: "Last updated by",
  i18nKey_enterprise_1193: "Please enter the license plate",
  i18nKey_enterprise_1194: "Please select a license plate",
  i18nKey_enterprise_1195: "Please enter vehicle color",
  i18nKey_enterprise_1196: "Please enter the driving license number",
  i18nKey_enterprise_1197: "Please enter the full path of the vehicle classification",
  i18nKey_enterprise_1198: "Please enter the vehicle category name",
  i18nKey_enterprise_1199: "Please enter the person code",
  i18nKey_enterprise_1200: "Please enter the unit",
  i18nKey_enterprise_1201: "invalid",
  i18nKey_enterprise_1202: "License plate number can't be null!",
  i18nKey_enterprise_1203: "Vehicle color can't be null!",
  i18nKey_enterprise_1204: "Full route of vehicle classification can't be null!",
  i18nKey_enterprise_1205: "Vehicle category name can't be null!",
  i18nKey_enterprise_1206: "Owner name can't be null!",
  i18nKey_enterprise_1207: "Ownership can't be null!",
  i18nKey_enterprise_1208: "The vehicle label can't be null!",
  i18nKey_enterprise_1209: "The effective time can't be null!",
  i18nKey_enterprise_1210: "The expiration time can't be null!",
  i18nKey_enterprise_1211: "The valid ID can't be null!",
  i18nKey_enterprise_1212: "Please enter the correct license plate!",
  i18nKey_enterprise_1213: "The driving license number or personnel code can't contain Chinese!",
  i18nKey_enterprise_1214: "blue",
  i18nKey_enterprise_1215: "Plan end time 222",
  i18nKey_enterprise_1216: "The planned end time should be greater than the planned start time",
  i18nKey_enterprise_1217: "I choose the later",
  i18nKey_enterprise_1218: "Start date",
  i18nKey_enterprise_1219: "End date",
  i18nKey_enterprise_1220: "Facility maintenance records",
  i18nKey_enterprise_1221: "Historical operation record",
  i18nKey_enterprise_1222: "Facility alarm record",
  i18nKey_enterprise_1223: "Maintenance start and end time",
  i18nKey_enterprise_1224: "Maintenance department",
  i18nKey_enterprise_1225: "Wang Xiaohu",
  i18nKey_enterprise_1226: "Jinshajiang Road, Putuo District, Shanghai",
  i18nKey_enterprise_1227: "Long",
  i18nKey_enterprise_1228: "All right",
  i18nKey_enterprise_1229: "Engineering Department",
  i18nKey_enterprise_1230: "The query time can't be null! ! !",
  i18nKey_enterprise_1231: "Class facilities",
  i18nKey_enterprise_1232: "Major:",
  i18nKey_enterprise_1233: "Ordinary:",
  i18nKey_enterprise_1234: "Send SMS 2",
  i18nKey_enterprise_1235: "Start method",
  i18nKey_enterprise_1236: "Manual",
  i18nKey_enterprise_1237: "Automatic",
  i18nKey_enterprise_1238: "Temperature setting",
  i18nKey_enterprise_1239: "Preset temperature",
  i18nKey_enterprise_1240: "Return air temperature",
  i18nKey_enterprise_1241: "Filter pressure difference",
  i18nKey_enterprise_1242: "Water valve control",
  i18nKey_enterprise_1243: "Power monitoring",
  i18nKey_enterprise_1244: "Fan commands",
  i18nKey_enterprise_1245: "Alert level",
  i18nKey_enterprise_1246: "Alarm operation",
  i18nKey_enterprise_1247: "Equipment Service Provider",
  i18nKey_enterprise_1248: "Device Description",
  i18nKey_enterprise_1249: "Equipment Brand",
  i18nKey_enterprise_1250: "equipment manufactory",
  i18nKey_enterprise_1251: "Equipment Maintenance Service",
  i18nKey_enterprise_1252: "Facility start-up time",
  i18nKey_enterprise_1253: "Facility downtime",
  i18nKey_enterprise_1254: "Running period this time",
  i18nKey_enterprise_1255: "Maintenance date",
  i18nKey_enterprise_1256: "Maintenance responsible person",
  i18nKey_enterprise_1257: "Reasons for maintenance",
  i18nKey_enterprise_1258: "Maintenance content",
  i18nKey_enterprise_1259: "Maintenance results",
  i18nKey_enterprise_1260: "Facility alarm record",
  i18nKey_enterprise_1261: "Facility maintenance records",
  i18nKey_enterprise_1262: "Switch setting",
  i18nKey_enterprise_1263: "Turn on the lights",
  i18nKey_enterprise_1264: "Turn off the lights",
  i18nKey_enterprise_1265: "Application",
  i18nKey_enterprise_1266: "The instruction is issued successfully, and the status is updated in about 30 seconds, please wait patiently",
  i18nKey_enterprise_1267: "Dimming",
  i18nKey_enterprise_1268: "Tip: The brightness will only take effect when the light is on",
  i18nKey_enterprise_1269: "important events",
  i18nKey_enterprise_1270: "General event",
  i18nKey_enterprise_1271: "Track now",
  i18nKey_enterprise_1272: "Access control system",
  i18nKey_enterprise_1273: "Video image",
  i18nKey_enterprise_1274: "Associated picture",
  i18nKey_enterprise_1275: "Upload",
  i18nKey_enterprise_1276: "Associated video",
  i18nKey_enterprise_1277: "Related deployment",
  i18nKey_enterprise_1278: "Add deployment control",
  i18nKey_enterprise_1279: "Out of control",
  i18nKey_enterprise_1280: "Control Picture",
  i18nKey_enterprise_1281: "Source System",
  i18nKey_enterprise_1282: "disperse the crowd",
  i18nKey_enterprise_1283: "To be accepted",
  i18nKey_enterprise_1284: "Associated camera back in  backend",
  i18nKey_enterprise_1285: "Perimeter cameras back in backend",
  i18nKey_enterprise_1286: "Add an associated camera back to the background",
  i18nKey_enterprise_1287: "Delete the associated camera background return",
  i18nKey_enterprise_1288: "Tip: After the dispatch task is processed, the event will be closed automatically.",
  i18nKey_enterprise_1289: "Distribution task",
  i18nKey_enterprise_1290: "New task completed",
  i18nKey_enterprise_1291: "Task distribution",
  i18nKey_enterprise_1292: "Delegate tasks",
  i18nKey_enterprise_1293: "Distance",
  i18nKey_enterprise_1294: "Please select the person to assign the task",
  i18nKey_enterprise_1295: "please enter keyword to search",
  i18nKey_enterprise_1296: "Please enter the task content",
  i18nKey_enterprise_1297: "Successfully added task",
  i18nKey_enterprise_1298: "Internet celebrity fraud",
  i18nKey_enterprise_1299: "Hyun Bin",
  i18nKey_enterprise_1300: "Camera 002",
  i18nKey_enterprise_1301: "Camera 003",
  i18nKey_enterprise_1302: "Camera 004",
  i18nKey_enterprise_1303: "Camera 005",
  i18nKey_enterprise_1304: "Automatically report events",
  i18nKey_enterprise_1305: "model",
  i18nKey_enterprise_1306: "Zhang San",
  i18nKey_enterprise_1307: "Northern region",
  i18nKey_enterprise_1308: "Southern region",
  i18nKey_enterprise_1309: "Midwest region",
  i18nKey_enterprise_1310: "Welcome screen selected",
  i18nKey_enterprise_1311: "Selected camera",
  i18nKey_enterprise_1312: "Number of online cameras",
  i18nKey_enterprise_1313: "Total number of cameras",
  i18nKey_enterprise_1314: "Enter camera name",
  i18nKey_enterprise_1315: "Event title must be entered",
  i18nKey_enterprise_1316: "Please enter event description",
  i18nKey_enterprise_1317: "Vanke Chengdu Park",
  i18nKey_enterprise_1318: "Building 103",
  i18nKey_enterprise_1319: "Incident reporting failed",
  i18nKey_enterprise_1320: "Picture has been saved to",
  i18nKey_enterprise_1321: "Image capture failed",
  i18nKey_enterprise_1322: "Error code:",
  i18nKey_enterprise_1323: "Number of patrol groups",
  i18nKey_enterprise_1324: "New patrol group",
  i18nKey_enterprise_1325: "Video patrol list",
  i18nKey_enterprise_1326: "Video patrol history",
  i18nKey_enterprise_1327: "End",
  i18nKey_enterprise_1328: "Equipment category",
  i18nKey_enterprise_1329: "Event handler",
  i18nKey_enterprise_1330: "User card number",
  i18nKey_enterprise_1331: "Handler's cell phone",
  i18nKey_enterprise_1332: "Solution",
  i18nKey_enterprise_1333: "User account",
  i18nKey_enterprise_1334: "Treatment type",
  i18nKey_enterprise_1335: "Handler fixed phone",
  i18nKey_enterprise_1336: "Control type",
  i18nKey_enterprise_1337: "Event notes",
  i18nKey_enterprise_1338: "Only upload up to 5 pictures",
  i18nKey_enterprise_1339: "Picture size can't exceed 2",
  i18nKey_enterprise_1340: "Format picture",
  i18nKey_enterprise_1341: "The task name can't exceed 50 characters",
  i18nKey_enterprise_1342: "Face tracking",
  i18nKey_enterprise_1343: "Reason for deployment",
  i18nKey_enterprise_1344: "Please enter the reason for deployment, no more than 150 characters",
  i18nKey_enterprise_1345: "Name:",
  i18nKey_enterprise_1346: "Time period",
  i18nKey_enterprise_1347: "Similarity",
  i18nKey_enterprise_1348: "Figure",
  i18nKey_enterprise_1349: "Whether to push the pop-up window",
  i18nKey_enterprise_1350: "Please enter the property announcement",
  i18nKey_enterprise_1351: "Notify staff",
  i18nKey_enterprise_1352: "Name",
  i18nKey_enterprise_1353: "Please enter your name or phone number",
  i18nKey_enterprise_1354: "The reason for deployment can't exceed 150 characters",
  i18nKey_enterprise_1355: "Select from the vehicle library",
  i18nKey_enterprise_1356: "Vehicle library selection",
  i18nKey_enterprise_1357: "Vehicle trajectory",
  i18nKey_enterprise_1358: "View warning",
  i18nKey_enterprise_1359: "Location:",
  i18nKey_enterprise_1360: "Source:",
  i18nKey_enterprise_1361: "Exceed target value",
  i18nKey_enterprise_1362: "Total number of alarms",
  i18nKey_enterprise_1363: "Outdoor",
  i18nKey_enterprise_1364: "Humidity",
  i18nKey_enterprise_1365: "Environmental space",
  i18nKey_enterprise_1366: "carbon dioxide",
  i18nKey_enterprise_1367: "formaldehyde",
  i18nKey_enterprise_1368: "frame",
  i18nKey_enterprise_1369: "Negative oxygen ions",
  i18nKey_enterprise_1370: "noise",
  i18nKey_enterprise_1371: "Organic volatiles",
  i18nKey_enterprise_1372: "Environmental Index",
  i18nKey_enterprise_1373: "Street lamp statistics",
  i18nKey_enterprise_1374: "Task progress",
  i18nKey_enterprise_1375: "Environmental space title",
  i18nKey_enterprise_1376: "Not set",
  i18nKey_enterprise_1377: "Idle",
  i18nKey_enterprise_1378: "Work order description",
  i18nKey_enterprise_1379: "Please enter a work order description",
  i18nKey_enterprise_1380: "Dispatch work order completed",
  i18nKey_enterprise_1381: "Please select the person to assign the ticket",
  i18nKey_enterprise_1382: "Please enter the ticket content",
  i18nKey_enterprise_1383: "Mr. Li",
  i18nKey_enterprise_1384: "Responsible person of work order:",
  i18nKey_enterprise_1385: "Processing fee:",
  i18nKey_enterprise_1386: "Processing time:",
  i18nKey_enterprise_1387: "Processing progress:",
  i18nKey_enterprise_1388: "Repair user",
  i18nKey_enterprise_1389: "Home time",
  i18nKey_enterprise_1390: "Distributed",
  i18nKey_enterprise_1391: "Can enter up to 100 characters",
  i18nKey_enterprise_1392: "Repair name",
  i18nKey_enterprise_1393: "Repair status",
  i18nKey_enterprise_1394: "Repair level",
  i18nKey_enterprise_1395: "Repair time",
  i18nKey_enterprise_1396: "Repair location",
  i18nKey_enterprise_1397: "And also delete all shops under the node in the lease list",
  i18nKey_enterprise_1398: "All day",
  i18nKey_enterprise_1399: "Cup",
  i18nKey_enterprise_1400: "Unit: cup",
  i18nKey_enterprise_1401: "today",
  i18nKey_enterprise_1402: "Please enter the deposit amount",
  i18nKey_enterprise_1403: "When the deposit amount is CNY 0 , the deposit entrance under the current park meeting room will be closed",
  i18nKey_enterprise_1404: "Deposit unit",
  i18nKey_enterprise_1405: "Deposit refund rules",
  i18nKey_enterprise_1406: "Deposit description",
  i18nKey_enterprise_1407: "Please enter a deposit description",
  i18nKey_enterprise_1408: "Please confirm the refund amount: 0 yuan",
  i18nKey_enterprise_1409: "Cancel",
  i18nKey_enterprise_1410: "Cancel",
  i18nKey_enterprise_1411: "Confirm",
  i18nKey_enterprise_1412: "Confirm",
  i18nKey_enterprise_1413: "The maximum deposit amount is 9999",
  i18nKey_enterprise_1414: "Please select user type",
  i18nKey_enterprise_1415: "Please select deposit refund rules",
  i18nKey_enterprise_1416: "Please enter refund amount",
  i18nKey_enterprise_1417: "The refund amount can't be greater than the deposit amount!",
  i18nKey_enterprise_1418: "Deposit refund",
  i18nKey_enterprise_1419: "Paid",
  i18nKey_enterprise_1420: "Area",
  i18nKey_enterprise_1421: "Please enter the hour",
  i18nKey_enterprise_1422: "Paid facilities",
  i18nKey_enterprise_1423: "Please enter the unit price",
  i18nKey_enterprise_1424: "Half-day unit price (CNY",
  i18nKey_enterprise_1425: "hrs)",
  i18nKey_enterprise_1426: "Please enter half-day unit price (yuan)",
  i18nKey_enterprise_1427: "Please select an end date",
  i18nKey_enterprise_1428: "Refund",
  i18nKey_enterprise_1429: "Custom refund policy",
  i18nKey_enterprise_1430: "full refund",
  i18nKey_enterprise_1431: "No refund",
  i18nKey_enterprise_1432: "Review refund",
  i18nKey_enterprise_1433: "Refund policy",
  i18nKey_enterprise_1434: "Before time starts",
  i18nKey_enterprise_1435: "Deposit",
  i18nKey_enterprise_1436: "The day can't be less than 4 hours!",
  i18nKey_enterprise_1437: "Please configure refund options!",
  i18nKey_enterprise_1438: "When customizing a refund, enter at least one complete refund policy!",
  i18nKey_enterprise_1439: "Cancellation refund limit can't exceed 100",
  i18nKey_enterprise_1440: "The format of the refund policy input is incorrect!",
  i18nKey_enterprise_1441: "Please configure deposit options!",
  i18nKey_enterprise_1442: "No more than 5 cancellation rules!",
  i18nKey_enterprise_1443: "Deposit order details",
  i18nKey_enterprise_1444: "Cost per day if up to XX hours",
  i18nKey_enterprise_1445: "Please select month",
  i18nKey_enterprise_1446: "Please select a company",
  i18nKey_enterprise_1447: "Free quota",
  i18nKey_enterprise_1448: "Successfully modified!",
  i18nKey_enterprise_1449: "Meeting name",
  i18nKey_enterprise_1450: "Meeting place",
  i18nKey_enterprise_1451: "Reservation person",
  i18nKey_enterprise_1452: "Actual number of participants",
  i18nKey_enterprise_1453: "Select month",
  i18nKey_enterprise_1454: "Actual monthly electricity consumption",
  i18nKey_enterprise_1455: "Data summary",
  i18nKey_enterprise_1456: "Energy consumption overview",
  i18nKey_enterprise_1457: "Dongli",
  i18nKey_enterprise_1458: "Dongli",
  i18nKey_enterprise_1459: "Vankeli",
  i18nKey_enterprise_1460: "Please enter the meter number",
  i18nKey_enterprise_1461: "Please enter the name of the power distribution switch",
  i18nKey_enterprise_1462: "Staging",
  i18nKey_enterprise_1463: "Take effect",
  i18nKey_enterprise_1464: "Last power consumption",
  i18nKey_enterprise_1465: "Last water consumption",
  i18nKey_enterprise_1466: "The meter reading date can't be null",
  i18nKey_enterprise_1467: "Meter reading date format error, format specification is:",
  i18nKey_enterprise_1468: "Meter reading date data error, please enter reasonable date data",
  i18nKey_enterprise_1469: "Meter reading format error",
  i18nKey_enterprise_1470: "Meter reading this time less than last time, confirm modify",
  i18nKey_enterprise_1471: "Have",
  i18nKey_enterprise_1472: "tables not entered",
  i18nKey_enterprise_1473: "Whether to continue to take effect",
  i18nKey_enterprise_1474: "Effective successfully",
  i18nKey_enterprise_1475: "Please select to delete data",
  i18nKey_enterprise_1476: "All the details of the device will be deleted after deletion, you are sure to delete",
  i18nKey_enterprise_1477: "Selected",
  i18nKey_enterprise_1478: "?",
  i18nKey_enterprise_1479: "Deletion failed, please try again later",
  i18nKey_enterprise_1480: "Arrival reminder type, the role is optional. non-",
  i18nKey_enterprise_1481: "Arrival reminder type, the role is required.",
  i18nKey_enterprise_1482: "Synchronize",
  i18nKey_enterprise_1483: "Push",
  i18nKey_enterprise_1484: "Service",
  i18nKey_enterprise_1485: "Submitted successfully!",
  i18nKey_enterprise_1486: "Submission Failed!",
  i18nKey_enterprise_1487: "Signal 2",
  i18nKey_enterprise_1488: "Facilities system 2",
  i18nKey_enterprise_1489: "Alert label",
  i18nKey_enterprise_1490: "Alarm enable",
  i18nKey_enterprise_1491: "Urgent",
  i18nKey_enterprise_1492: "Important",
  i18nKey_enterprise_1493: "Ordinary",
  i18nKey_enterprise_1494: "Uncertain",
  i18nKey_enterprise_1495: "Effective time",
  i18nKey_enterprise_1496: "Plan enable",
  i18nKey_enterprise_1497: "Dead zone",
  i18nKey_enterprise_1498: "Please enter the dead zone value",
  i18nKey_enterprise_1499: "Please enter an alarm description",
  i18nKey_enterprise_1500: "Signal 1",
  i18nKey_enterprise_1501: "Import failed, only supports",
  i18nKey_enterprise_1502: "Import failed, no valid data",
  i18nKey_enterprise_1503: "Import failed, duplicate point code data",
  i18nKey_enterprise_1504: "This operation data will be deleted permanently. Are you sure?",
  i18nKey_enterprise_1505: "Can't be null",
  i18nKey_enterprise_1506: "Can only be composed of numbers and four arithmetic symbols",
  i18nKey_enterprise_1507: "Import failed, only new version is supported",
  i18nKey_enterprise_1508: "confirm deletion?",
  i18nKey_enterprise_1509: "synchronizing",
  i18nKey_enterprise_1510: "Save failed!",
  i18nKey_enterprise_1511: "Source system facility number",
  i18nKey_enterprise_1512: "Facilities QR code",
  i18nKey_enterprise_1513: "Importance level",
  i18nKey_enterprise_1514: "Number of classification levels",
  i18nKey_enterprise_1515: "Facility serial number",
  i18nKey_enterprise_1516: "Coordinate-Longitude",
  i18nKey_enterprise_1517: "Coordinate-Latitude",
  i18nKey_enterprise_1518: "Coordinate-altitude",
  i18nKey_enterprise_1519: "View uploaded facility portraits",
  i18nKey_enterprise_1520: "View upload facility diagram",
  i18nKey_enterprise_1521: "Please select historical version",
  i18nKey_enterprise_1522: "version",
  i18nKey_enterprise_1523: "0 large screens have been connected",
  i18nKey_enterprise_1524: "The length can't exceed 50 characters",
  i18nKey_enterprise_1525: "Add failed",
  i18nKey_enterprise_1526: "Are you sure you want to delete the relationship?",
  i18nKey_enterprise_1527: "Delete association successfully",
  i18nKey_enterprise_1528: "coding",
  i18nKey_enterprise_1529: "Confirm to save?",
  i18nKey_enterprise_1530: "confirm cancel?",
  i18nKey_enterprise_1531: "Please send the current level",
  i18nKey_enterprise_1532: "Fill in the code, business code and space level name completely!",
  i18nKey_enterprise_1533: "The code and business code can only be a combination of letters, numbers and underscores!",
  i18nKey_enterprise_1534: "added successfully!",
  i18nKey_enterprise_1535: "Failed to add!",
  i18nKey_enterprise_1536: "Please select a room",
  i18nKey_enterprise_1537: "Person tag expiration time",
  i18nKey_enterprise_1538: "Label Information",
  i18nKey_enterprise_1539: "is it effective",
  i18nKey_enterprise_1540: "Source system staff code",
  i18nKey_enterprise_1541: "Source system name",
  i18nKey_enterprise_1542: "Effective time of staff tag",
  i18nKey_enterprise_1543: "photo",
  i18nKey_enterprise_1544: "Photo storage address",
  i18nKey_enterprise_1545: "Photo type code",
  i18nKey_enterprise_1546: "Upload locally, upload photos no more than 3",
  i18nKey_enterprise_1547: "Person photo",
  i18nKey_enterprise_1548: "Please select a department",
  i18nKey_enterprise_1549: "Select box",
  i18nKey_enterprise_1550: "Passport",
  i18nKey_enterprise_1551: "Picture can't be null",
  i18nKey_enterprise_1552: "Personnel basic data modified successfully",
  i18nKey_enterprise_1553: "phone number:",
  i18nKey_enterprise_1554: "Numbering:",
  i18nKey_enterprise_1555: "Mobile phone number needs to be registered in Yuncheng",
  i18nKey_enterprise_1556: "Added successfully",
  i18nKey_enterprise_1557: "Confirm to open",
  i18nKey_enterprise_1558: "Confirm to close",
  i18nKey_enterprise_1559: "Intervals",
  i18nKey_enterprise_1560: "All users",
  i18nKey_enterprise_1561: "All groups",
  i18nKey_enterprise_1562: "Group information",
  i18nKey_enterprise_1563: "Please enter user department",
  i18nKey_enterprise_1564: "OK to delete the group",
  i18nKey_enterprise_1565: "OK to delete the selected user",
  i18nKey_enterprise_1566: "Please select the user to delete",
  i18nKey_enterprise_1567: "Turn on",
  i18nKey_enterprise_1568: "Resources",
  i18nKey_enterprise_1569: "Current",
  i18nKey_enterprise_1570: "Menu resources",
  i18nKey_enterprise_1571: "Role deleted successfully",
  i18nKey_enterprise_1572: "Role deletion failed, please try again",
  i18nKey_enterprise_1573: "Please select the type",
  i18nKey_enterprise_1574: "Role created successfully",
  i18nKey_enterprise_1575: "Network abnormality, please try again later",
  i18nKey_enterprise_1576: "Role modified successfully",
  i18nKey_enterprise_1577: "Modify role",
  i18nKey_enterprise_1578: "Super administrator",
  i18nKey_enterprise_1579: "Platform administrator",
  i18nKey_enterprise_1580: "Park administrator",
  i18nKey_enterprise_1581: "Platform role",
  i18nKey_enterprise_1582: "Ordinary role in the park",
  i18nKey_enterprise_1583: "Park security role",
  i18nKey_enterprise_1584: "Park property role",
  i18nKey_enterprise_1585: "The role of cleaning in the park",
  i18nKey_enterprise_1586: "Role name already exists",
  i18nKey_enterprise_1587: "School Police Hong Kong and Macau",
  i18nKey_enterprise_1588: "You sure you want to delete it?",
  i18nKey_enterprise_1589: "Monthly power consumption",
  i18nKey_enterprise_1590: "KWh",
  i18nKey_enterprise_1591: "Monthly shared power consumption",
  i18nKey_enterprise_1592: "Monthly consumption amount",
  i18nKey_enterprise_1593: "Monthly allocation amount",
  i18nKey_enterprise_1594: "Share power",
  i18nKey_enterprise_1595: "Share ratio",
  i18nKey_enterprise_1596: "Amount to share",
  i18nKey_enterprise_1597: "Actual power consumption",
  i18nKey_enterprise_1598: "Actual consumption amount",
  i18nKey_enterprise_1599: "Operation",
  i18nKey_enterprise_1600: "Elevator start-stop",
  i18nKey_enterprise_1601: "Overhaul",
  i18nKey_enterprise_1602: "Passenger elevator",
  i18nKey_enterprise_1603: "Elevator",
  i18nKey_enterprise_1604: "Light control",
  i18nKey_enterprise_1605: "Equipment type",
  i18nKey_enterprise_1606: "Device address",
  i18nKey_enterprise_1607: "Loading",
  i18nKey_enterprise_1608: "Updating",
  i18nKey_enterprise_1609: "Enter device name",
  i18nKey_enterprise_1610: "Playing",
  i18nKey_enterprise_1611: "Every big screen",
  i18nKey_enterprise_1612: "Add failed",
  i18nKey_enterprise_1613: "Are you sure you want to delete this plan?",
  i18nKey_enterprise_1614: "Successfully issued the plan",
  i18nKey_enterprise_1615: "Enabled state",
  i18nKey_enterprise_1616: "Delete the policy",
  i18nKey_enterprise_1617: "The effective time of each strategy can't be null",
  i18nKey_enterprise_1618: "Lighting rules",
  i18nKey_enterprise_1619: "Can't be null",
  i18nKey_enterprise_1620: "Save the circuit breaker configuration successfully!",
  i18nKey_enterprise_1621: "Changed successfully",
  i18nKey_enterprise_1622: "Customer List",
  i18nKey_enterprise_1623: "personnel number",
  i18nKey_enterprise_1624: "Visited the park in the past week",
  i18nKey_enterprise_1625: "Visited the park in the early morning of the past week",
  i18nKey_enterprise_1626: "User cycle",
  i18nKey_enterprise_1627: "Get used to order time",
  i18nKey_enterprise_1628: "Shortest time consuming",
  i18nKey_enterprise_1629: "Longest time consuming",
  i18nKey_enterprise_1630: "time cost",
  i18nKey_enterprise_1631: "Pieces",
  i18nKey_enterprise_1632: "Facility",
  i18nKey_enterprise_1633: "Number of facilities",
  i18nKey_enterprise_1634: "Average processing time",
  i18nKey_enterprise_1635: "Facility code",
  i18nKey_enterprise_1636: "Split dimension",
  i18nKey_enterprise_1637: "Please select the dimension",
  i18nKey_enterprise_1638: "Split maintenance",
  i18nKey_enterprise_1639: "Please select the distribution information",
  i18nKey_enterprise_1640: "The meter is successfully maintained!",
  i18nKey_enterprise_1641: "sub-items",
  i18nKey_enterprise_1642: "Please select the item",
  i18nKey_enterprise_1643: "Exception type",
  i18nKey_enterprise_1644: "Please select a level",
  i18nKey_enterprise_1645: "Actual electricity consumption today",
  i18nKey_enterprise_1646: "Actual electricity consumption last week",
  i18nKey_enterprise_1647: "Electricity details",
  i18nKey_enterprise_1648: "Critical alert",
  i18nKey_enterprise_1649: "Save failed",
  i18nKey_enterprise_1650: "Electricity time",
  i18nKey_enterprise_1651: "Statistics Time",
  i18nKey_enterprise_1652: "Actual electricity consumption this month",
  i18nKey_enterprise_1653: "Actual electricity consumption last month",
  i18nKey_enterprise_1654: "accident details",
  i18nKey_enterprise_1655: "From",
  i18nKey_enterprise_1656: "The failure occurred repeatedly once, and the cumulative duration",
  i18nKey_enterprise_1657: "hours, can cause waste throughout the year",
  i18nKey_enterprise_1658: "Processing status",
  i18nKey_enterprise_1659: "Diagnostic treatment",
  i18nKey_enterprise_1660: "Fault name",
  i18nKey_enterprise_1661: "Please enter the fault name",
  i18nKey_enterprise_1662: "Failure level",
  i18nKey_enterprise_1663: "Failure statistics",
  i18nKey_enterprise_1664: "Number of failures",
  i18nKey_enterprise_1665: "Total failures",
  i18nKey_enterprise_1666: "Huanglong Vanke",
  i18nKey_enterprise_1667: "use",
  i18nKey_enterprise_1668: "electricity",
  i18nKey_enterprise_1669: "ater",
  i18nKey_enterprise_1670: "quantity",
  i18nKey_enterprise_1671: "Total electricity consumption",
  i18nKey_enterprise_1672: "Always use",
  i18nKey_enterprise_1673: "Point information equipment report",
  i18nKey_enterprise_1674: "Computer room power",
  i18nKey_enterprise_1675: "Peak power",
  i18nKey_enterprise_1676: "Ring diagram",
  i18nKey_enterprise_1677: "In the whole project in the year, you have found a total of",
  i18nKey_enterprise_1678: "Energy saving potential",
  i18nKey_enterprise_1679: "South area",
  i18nKey_enterprise_1680: "engine room",
  i18nKey_enterprise_1681: "North District",
  i18nKey_enterprise_1682: "Nowadays",
  i18nKey_enterprise_1683: "Variable",
  i18nKey_enterprise_1684: "Energy efficiency ratio",
  i18nKey_enterprise_1685: "Real-time power",
  i18nKey_enterprise_1686: "Recharge amount (CNY)",
  i18nKey_enterprise_1687: "Amount consumed (CNY)",
  i18nKey_enterprise_1688: "Monthly consumption",
  i18nKey_enterprise_1689: "Monthly recharge amount",
  i18nKey_enterprise_1690: "Monthly consumption amount",
  i18nKey_enterprise_1691: "Monthly remaining amount",
  i18nKey_enterprise_1692: "Tips: No operations will be performed after work order closed. Close it?",
  i18nKey_enterprise_1693: "After clicking OK to create a recovery ticket",
  i18nKey_enterprise_1694: "You can check the progress in the list of power failure recovery work orders!",
  i18nKey_enterprise_1695: "Tips: Can manually restore the power-off work order",
  i18nKey_enterprise_1696: "No power-off work orders will be generated within a few days, and the power-off work orders will continue to be generated after the power-off threshold is not selected.",
  i18nKey_enterprise_1697: "Table number",
  i18nKey_enterprise_1698: "Power off processing",
  i18nKey_enterprise_1699: "Power failure",
  i18nKey_enterprise_1700: "Powered off",
  i18nKey_enterprise_1701: "Complete time",
  i18nKey_enterprise_1702: "You can try to power off again, or close the ticket and proceed to the site for processing",
  i18nKey_enterprise_1703: "Closing method",
  i18nKey_enterprise_1704: "Manual closing on site",
  i18nKey_enterprise_1705: "Remote closing",
  i18nKey_enterprise_1706: "Power failure recovery",
  i18nKey_enterprise_1707: "Recovery failed",
  i18nKey_enterprise_1708: "Restored",
  i18nKey_enterprise_1709: "Can try to resume again, or close the ticket and proceed to the site for processing",
  i18nKey_enterprise_1710: "Tips: Some meters in this contract can't be powered off remotely and need to go to the site to resume closing",
  i18nKey_enterprise_1711: "Processing complete",
  i18nKey_enterprise_1712: "Choose a merchant:",
  i18nKey_enterprise_1713: "Choose a brand:",
  i18nKey_enterprise_1714: "Meter threshold",
  i18nKey_enterprise_1715: "Water meter threshold",
  i18nKey_enterprise_1716: "Power-off threshold",
  i18nKey_enterprise_1717: "No contract data yet, please select a merchant and brand first",
  i18nKey_enterprise_1718: "After the power-off threshold of the meter is set, a processing order will be generated when the threshold is reached",
  i18nKey_enterprise_1719: "Whether to start power off",
  i18nKey_enterprise_1720: "Power down enabled",
  i18nKey_enterprise_1721: "Power off is not enabled",
  i18nKey_enterprise_1722: "Choose start time",
  i18nKey_enterprise_1723: "Choose end time",
  i18nKey_enterprise_1724: "Select icon",
  i18nKey_enterprise_1725: "Scene lock switch",
  i18nKey_enterprise_1726: "Successfully opened",
  i18nKey_enterprise_1727: "Successfully closed",
  i18nKey_enterprise_1728: "batch deletion",
  i18nKey_enterprise_1729: "Example format of house name:",
  i18nKey_enterprise_1730: "Room 2 Hall",
  i18nKey_enterprise_1731: "Confirm to delete the apartment",
  i18nKey_enterprise_1732: "Unit type",
  i18nKey_enterprise_1733: "success",
  i18nKey_enterprise_1734: "Room No",
  i18nKey_enterprise_1735: "New building units and public areas",
  i18nKey_enterprise_1736: "Please enter the building unit",
  i18nKey_enterprise_1737: "Add room number",
  i18nKey_enterprise_1738: "Please enter the room number",
  i18nKey_enterprise_1739: "Please select unit building to add room number",
  i18nKey_enterprise_1740: "Please select the room to delete",
  i18nKey_enterprise_1741: "Play pause 1",
  i18nKey_enterprise_1742: "Pause 1",
  i18nKey_enterprise_1743: "Timer",
  i18nKey_enterprise_1744: "Play pause 3",
  i18nKey_enterprise_1745: "Play",
  i18nKey_enterprise_1746: "The patrol group will be played in turn at regular intervals",
  i18nKey_enterprise_1747: "Currently playing ---",
  i18nKey_enterprise_1748: "Patrol groups ---",
  i18nKey_enterprise_1749: "Cameras",
  i18nKey_enterprise_1750: "Video is paused",
  i18nKey_enterprise_1751: "End of video patrol",
  i18nKey_enterprise_1752: "New patrol route",
  i18nKey_enterprise_1753: "List of patrol routes",
  i18nKey_enterprise_1754: "Real-time video",
  i18nKey_enterprise_1755: "Choose start date and time",
  i18nKey_enterprise_1756: "Choose end date and time",
  i18nKey_enterprise_1757: "Associate historical video",
  i18nKey_enterprise_1758: "Intercepted successfully",
  i18nKey_enterprise_1759: "Video capture failed",
  i18nKey_enterprise_1760: "Please select the station type",
  i18nKey_enterprise_1761: "Basic Settings",
  i18nKey_enterprise_1762: "In advance",
  i18nKey_enterprise_1763: "Monthly discount",
  i18nKey_enterprise_1764: "Years and above",
  i18nKey_enterprise_1765: "Months",
  i18nKey_enterprise_1766: "Created successfully!",
  i18nKey_enterprise_1767: "Original price",
  i18nKey_enterprise_1768: "administrator",
  i18nKey_enterprise_1769: "Start time of suspension",
  i18nKey_enterprise_1770: "End time of suspension",
  i18nKey_enterprise_1771: "Unsubscribe early",
  i18nKey_enterprise_1772: "How many days in advance can be unsubscribed",
  i18nKey_enterprise_1773: "Please enter the address latitude",
  i18nKey_enterprise_1774: "Additional facilities",
  i18nKey_enterprise_1775: "Add additional facilities",
  i18nKey_enterprise_1776: "For example: CNY 15 for the first hour (free for the first 15 minutes), CNY 10 per hour thereafter",
  i18nKey_enterprise_1777: "Capped.",
  i18nKey_enterprise_1778: "Parking nearby",
  i18nKey_enterprise_1779: "Example: Ping An",
  i18nKey_enterprise_1780: "15 yuan for the first hour (free for the first 15 minutes), 10 yuan per hour thereafter",
  i18nKey_enterprise_1781: "Futian District, Shenzhen City, Guangdong Province",
  i18nKey_enterprise_1782: "Add office space",
  i18nKey_enterprise_1783: "Modify office space",
  i18nKey_enterprise_1784: "Office space details",
  i18nKey_enterprise_1785: "Newly added additional facilities menu path: corporate services",
  i18nKey_enterprise_1786: "Meeting room management",
  i18nKey_enterprise_1787: "Value-added services!",
  i18nKey_enterprise_1788: "Existed!",
  i18nKey_enterprise_1789: "The address latitude and longitude input must be separated by commas",
  i18nKey_enterprise_1790: "And can't be greater than 20 characters",
  i18nKey_enterprise_1791: "Please enter the station number",
  i18nKey_enterprise_1792: "Please select what needs to be uploaded",
  i18nKey_enterprise_1793: "Figure!",
  i18nKey_enterprise_1794: "Picture file!",
  i18nKey_enterprise_1795: "Blacklist deployment",
  i18nKey_enterprise_1796: "Guest invitation",
  i18nKey_enterprise_1797: "Visitor information",
  i18nKey_enterprise_1798: "Please enter visitor name",
  i18nKey_enterprise_1799: "Please enter work unit",
  i18nKey_enterprise_1800: "Please select a visitor",
  i18nKey_enterprise_1801: "Shanghai",
  i18nKey_enterprise_1802: "Date:",
  i18nKey_enterprise_1803: "Statistics of staff proportion",
  i18nKey_enterprise_1804: "Number of personnel ranking",
  i18nKey_enterprise_1805: "The day before yesterday",
  i18nKey_enterprise_1806: "Please select an update date",
  i18nKey_enterprise_1807: "Location type",
  i18nKey_enterprise_1808: "Please select location type",
  i18nKey_enterprise_1809: "Please select device status",
  i18nKey_enterprise_1810: "Park visit",
  i18nKey_enterprise_1811: "Pass",
  i18nKey_enterprise_1812: "Interviewed address",
  i18nKey_enterprise_1813: "Please enter guest phone",
  i18nKey_enterprise_1814: "Authorization period",
  i18nKey_enterprise_1815: "Visit reason",
  i18nKey_enterprise_1816: "Pass type",
  i18nKey_enterprise_1817: "Please select the manufacturer name",
  i18nKey_enterprise_1818: "delete or not?",
  i18nKey_enterprise_1819: "Export table",
  i18nKey_enterprise_1820: "Epidemic prevention and control information",
  i18nKey_enterprise_1821: "Invitation successful",
  i18nKey_enterprise_1822: "Guest invitations",
  i18nKey_enterprise_1823: "Visitor invitation statistics",
  i18nKey_enterprise_1824: "Nearly 30 days",
  i18nKey_enterprise_1825: "Park visitor invitation ranking",
  i18nKey_enterprise_1826: "Visitor statistics",
  i18nKey_enterprise_1827: "Total historial park invition",
  i18nKey_enterprise_1828: "Historical invitation",
  i18nKey_enterprise_1829: "Please enter the device number",
  i18nKey_enterprise_1830: "Using",
  i18nKey_enterprise_1831: "Spare",
  i18nKey_enterprise_1832: "in maintenance",
  i18nKey_enterprise_1833: "Pass record",
  i18nKey_enterprise_1834: "Delete or not",
  i18nKey_enterprise_1835: "Add route",
  i18nKey_enterprise_1836: "Route name",
  i18nKey_enterprise_1837: "Please enter the route name",
  i18nKey_enterprise_1838: "Whether enterprise certification",
  i18nKey_enterprise_1839: "Whether to park tenants",
  i18nKey_enterprise_1840: "Please enter your name or phone number",
  i18nKey_enterprise_1841: "Bus route",
  i18nKey_enterprise_1842: "remaining times",
  i18nKey_enterprise_1843: "Payment failed",
  i18nKey_enterprise_1844: "No vehicle record, no need to perform export operation!",
  i18nKey_enterprise_1845: "Report?",
  i18nKey_enterprise_1846: "Temporary payment",
  i18nKey_enterprise_1847: "Query list",
  i18nKey_enterprise_1848: "Beijing",
  i18nKey_enterprise_1849: "Tianjin",
  i18nKey_enterprise_1850: "Shanghai",
  i18nKey_enterprise_1851: "Chongqing",
  i18nKey_enterprise_1852: "Xinjiang",
  i18nKey_enterprise_1853: "Jiangsu",
  i18nKey_enterprise_1854: "Zhejiang",
  i18nKey_enterprise_1855: "Heilongjiang",
  i18nKey_enterprise_1856: "Hunan",
  i18nKey_enterprise_1857: "Anhui",
  i18nKey_enterprise_1858: "Shandong",
  i18nKey_enterprise_1859: "Jiangxi",
  i18nKey_enterprise_1860: "Hebei",
  i18nKey_enterprise_1861: "Henan",
  i18nKey_enterprise_1862: "Yunnan",
  i18nKey_enterprise_1863: "Liaoning",
  i18nKey_enterprise_1864: "Hubei",
  i18nKey_enterprise_1865: "Guangxi",
  i18nKey_enterprise_1866: "Gansu",
  i18nKey_enterprise_1867: "Shanxi",
  i18nKey_enterprise_1868: "Inner Mongolian",
  i18nKey_enterprise_1869: "Shaanxi",
  i18nKey_enterprise_1870: "Jilin",
  i18nKey_enterprise_1871: "Fujian",
  i18nKey_enterprise_1872: "Guizhou",
  i18nKey_enterprise_1873: "Guangdong",
  i18nKey_enterprise_1874: "Qinghai",
  i18nKey_enterprise_1875: "Tibet",
  i18nKey_enterprise_1876: "Sichuan",
  i18nKey_enterprise_1877: "Ningxia",
  i18nKey_enterprise_1878: "Hainan",
  i18nKey_enterprise_1879: "Taiwan",
  i18nKey_enterprise_1880: "Hong Kong",
  i18nKey_enterprise_1881: "Macau",
  i18nKey_enterprise_1882: "Greater than 8",
  i18nKey_enterprise_1883: "Greater than 12",
  i18nKey_enterprise_1884: "Greater than 24",
  i18nKey_enterprise_1885: "Grand total",
  i18nKey_enterprise_1886: "Greater than 1200",
  i18nKey_enterprise_1887: "Greater than 1600",
  i18nKey_enterprise_1888: "Less than 5",
  i18nKey_enterprise_1889: "Cumulative parking payment",
  i18nKey_enterprise_1890: "Basic attributes",
  i18nKey_enterprise_1891: "Label preset",
  i18nKey_enterprise_1892: "5 times in the past week",
  i18nKey_enterprise_1893: "21 times in the past month",
  i18nKey_enterprise_1894: "The longest stay in the past week is 5 hours",
  i18nKey_enterprise_1895: "Frequent access to Namdaemun parking lot",
  i18nKey_enterprise_1896: "In and out time period",
  i18nKey_enterprise_1897: "Use package 200",
  i18nKey_enterprise_1898: "Purchase the package twice in the past year",
  i18nKey_enterprise_1899: "Cumulative consumption",
  i18nKey_enterprise_1900: "the above",
  i18nKey_enterprise_1901: "The consumption amount of the e-commerce platform is 500 yuan",
  i18nKey_enterprise_1902: "Approach",
  i18nKey_enterprise_1903: "The previous month",
  i18nKey_enterprise_1904: "Last September",
  i18nKey_enterprise_1905: "Number of people present",
  i18nKey_enterprise_1906: "Age ratio",
  i18nKey_enterprise_1907: "Shenzhen Yuncheng",
  i18nKey_enterprise_1908: "Yongqingfang",
  i18nKey_enterprise_1909: "Floor",
  i18nKey_enterprise_1910: "Area",
  i18nKey_enterprise_1911: "Please enter the ticket",
  i18nKey_enterprise_1912: "If there are multiple stops, please enter in the following format: Vanke Yuncheng",
  i18nKey_enterprise_1913: "Baoan Center",
  i18nKey_enterprise_1914: "Front bay",
  i18nKey_enterprise_1915: "Bao'an Airport",
  i18nKey_enterprise_1916: "Ride conditions",
  i18nKey_enterprise_1917: "Route map",
  i18nKey_enterprise_1918: "Do you want company certification",
  i18nKey_enterprise_1919: "Is it a mooring tenant?",
  i18nKey_enterprise_1920: "Modify route",
  i18nKey_enterprise_1921: "View route",
  i18nKey_enterprise_1922: "E.g",
  i18nKey_enterprise_1923: "Please enter the scanning device",
  i18nKey_enterprise_1924: "Please select multiple charges",
  i18nKey_enterprise_1925: "For example: the bus is limited to 50 people",
  i18nKey_enterprise_1926: "Please choose whether to charge for multiple periods",
  i18nKey_enterprise_1927: "Please select a valid date",
  i18nKey_enterprise_1928: "Automatic conversion price",
  i18nKey_enterprise_1929: "Please enter less than 1",
  i18nKey_enterprise_1930: "Price between",
  i18nKey_enterprise_1931: "Please select the bus schedule to use",
  i18nKey_enterprise_1932: "Modify package",
  i18nKey_enterprise_1933: "check the set menu",
  i18nKey_enterprise_1934: "send messages",
  i18nKey_enterprise_1935: "Access control monitoring",
  i18nKey_enterprise_1936: "All access to the building",
  i18nKey_enterprise_1937: "Open",
  i18nKey_enterprise_1938: "Access control",
  i18nKey_enterprise_1939: "Deployment control live",
  i18nKey_enterprise_1940: "More news",
  i18nKey_enterprise_1941: "upload picture",
  i18nKey_enterprise_1942: "Add face control",
  i18nKey_enterprise_1943: "New vehicle deployment control",
  i18nKey_enterprise_1944: "Disarm",
  i18nKey_enterprise_1945: "Transit facility warning",
  i18nKey_enterprise_1946: "Illegal invasion",
  i18nKey_enterprise_1947: "Phone call",
  i18nKey_enterprise_1948: "Dial",
  i18nKey_enterprise_1949: "Commonly used",
  i18nKey_enterprise_1950: "Contact person",
  i18nKey_enterprise_1951: "Captain Security-Calling",
  i18nKey_enterprise_1952: "hang up",
  i18nKey_enterprise_1953: "Alarm picture",
  i18nKey_enterprise_1954: "Alarm avatar",
  i18nKey_enterprise_1955: "Upload list (",
  i18nKey_enterprise_1956: "View associated videos",
  i18nKey_enterprise_1957: "Can only upload 10",
  i18nKey_enterprise_1958: "Pictures inside",
  i18nKey_enterprise_1959: "Format video",
  i18nKey_enterprise_1960: "Personnel selection",
  i18nKey_enterprise_1961: "Personnel Heat Map",
  i18nKey_enterprise_1962: "No personnel",
  i18nKey_enterprise_1963: "Send",
  i18nKey_enterprise_1964: "Send SMS content can't be null",
  i18nKey_enterprise_1965: "SMS sent successfully",
  i18nKey_enterprise_1966: "Those who failed to send SMS",
  i18nKey_enterprise_1967: "Please note that the above personnel can't receive SMS",
  i18nKey_enterprise_1968: "Reminds from Vanke Smart Park Operation Center] Vanke Smart Park Operation Center reminds you:",
  i18nKey_enterprise_1969: "Thank you!",
  i18nKey_enterprise_1970: "SMS recipient can't be null",
  i18nKey_enterprise_1971: "Personnel thermal backstage return",
  i18nKey_enterprise_1972: "Address book",
  i18nKey_enterprise_1973: "Find a contact",
  i18nKey_enterprise_1974: "Calling",
  i18nKey_enterprise_1975: "Video call",
  i18nKey_enterprise_1976: "Street light management",
  i18nKey_enterprise_1977: "Street lamp name",
  i18nKey_enterprise_1978: "Street light status",
  i18nKey_enterprise_1979: "Park Building",
  i18nKey_enterprise_1980: "Open the door",
  i18nKey_enterprise_1981: "Close the door",
  i18nKey_enterprise_1982: "caveat",
  i18nKey_enterprise_1983: "Suspicious target personnel appeared in",
  i18nKey_enterprise_1984: "Send someone to view",
  i18nKey_enterprise_1985: "Category Name",
  i18nKey_enterprise_1986: "Please enter a category name",
  i18nKey_enterprise_1987: "Category name can't be null",
  i18nKey_enterprise_1988: "Park space type",
  i18nKey_enterprise_1989: "Park space information",
  i18nKey_enterprise_1990: "Door opening method",
  i18nKey_enterprise_1991: "Space service staff",
  i18nKey_enterprise_1992: "Space description",
  i18nKey_enterprise_1993: "Space map",
  i18nKey_enterprise_1994: "Password to open the door",
  i18nKey_enterprise_1995: "QR code to open the door",
  i18nKey_enterprise_1996: "Face open the door",
  i18nKey_enterprise_1997: "Space number",
  i18nKey_enterprise_1998: "QR code platform",
  i18nKey_enterprise_1999: "Please choose QR code platform",
  i18nKey_enterprise_2000: "QR code download",
  i18nKey_enterprise_2001: "platform",
  i18nKey_enterprise_2002: "Applets",
  i18nKey_enterprise_2003: "Please select QR code type",
  i18nKey_enterprise_2004: "Please select the park space type",
  i18nKey_enterprise_2005: "Please choose an online password",
  i18nKey_enterprise_2006: "Please select the door lock type",
  i18nKey_enterprise_2007: "Door lock type",
  i18nKey_enterprise_2008: "New park space type",
  i18nKey_enterprise_2009: "Please enter a space description",
  i18nKey_enterprise_2010: "Please select the lock number!",
  i18nKey_enterprise_2011: "New park space",
  i18nKey_enterprise_2012: "Modify the campus space",
  i18nKey_enterprise_2013: "Park space details",
  i18nKey_enterprise_2014: "Close",
  i18nKey_enterprise_2015: "Water use",
  i18nKey_enterprise_2016: "Please enter a positive number",
  i18nKey_enterprise_2017: "Keep up to two decimal places",
  i18nKey_enterprise_2018: "No room at current location",
  i18nKey_enterprise_2019: "Importing files",
  i18nKey_enterprise_2020: "Import failed, first",
  i18nKey_enterprise_2021: "There are errors in the row data, please check and submit again for import later",
  i18nKey_enterprise_2022: "Electricity data statistics",
  i18nKey_enterprise_2023: "effective date",
  i18nKey_enterprise_2024: "Water consumption statistics",
  i18nKey_enterprise_2025: "Cubic meter",
  i18nKey_enterprise_2026: "Operator’s phone number needs to be registered in Yuncheng",
  i18nKey_enterprise_2027: "Incorrect phone number format",
  i18nKey_enterprise_2028: "Please enter the name of the parking lot",
  i18nKey_enterprise_2029: "exceed",
  i18nKey_enterprise_2030: "imes, can't apply for monthly card",
  i18nKey_enterprise_2031: "imes, can’t enter the parking lot",
  i18nKey_enterprise_2032: "Open the door together",
  i18nKey_enterprise_2033: "Tips: The video picture delay is about 5-10",
  i18nKey_enterprise_2034: "Please wait.",
  i18nKey_enterprise_2035: "Operation hours:",
  i18nKey_enterprise_2036: "elevator",
  i18nKey_enterprise_2037: "Motor speed:",
  i18nKey_enterprise_2038: "Spindle temperature:",
  i18nKey_enterprise_2039: "Stretch of traction rope:",
  i18nKey_enterprise_2040: "volume control",
  i18nKey_enterprise_2041: "Total lighting circuit:",
  i18nKey_enterprise_2042: "",
  i18nKey_enterprise_2043: "Switch",
  i18nKey_enterprise_2044: "No",
  i18nKey_enterprise_2045: "Sure to all",
  i18nKey_enterprise_2046: "A loop?",
  i18nKey_enterprise_2047: "Sure to",
  i18nKey_enterprise_2048: "This",
  i18nKey_enterprise_2049: "Loop",
  i18nKey_enterprise_2050: "Change failed, please try again later",
  i18nKey_enterprise_2051: "Successfully opened",
  i18nKey_enterprise_2052: "Closed successfully",
  i18nKey_enterprise_2053: "Change failed",
  i18nKey_enterprise_2054: "Two on one cycle",
  i18nKey_enterprise_2055: "Sensing delay dimly bright",
  i18nKey_enterprise_2056: "Gateway name 2",
  i18nKey_enterprise_2057: "Please select opening time",
  i18nKey_enterprise_2058: "Please input",
  i18nKey_enterprise_2059: "Please enter points",
  i18nKey_enterprise_2060: "Please enter the phone to search for an exact match",
  i18nKey_enterprise_2061: "Please select reminder",
  i18nKey_enterprise_2062: "Added",
  i18nKey_enterprise_2063: "Please enter the new instrument information",
  i18nKey_enterprise_2064: "Submit changes",
  i18nKey_enterprise_2065: "The change was successful!",
  i18nKey_enterprise_2066: "Download the meter template",
  i18nKey_enterprise_2067: "Download water meter template",
  i18nKey_enterprise_2068: "Please fill in the instrument category",
  i18nKey_enterprise_2069: "Please fill in the meter table number",
  i18nKey_enterprise_2070: "The number is",
  i18nKey_enterprise_2071: "Composed of numbers!",
  i18nKey_enterprise_2072: "Please fill in the validity period correctly, the format is:",
  i18nKey_enterprise_2073: "Please fill in the status",
  i18nKey_enterprise_2074: "The current imported file is checked incorrectly, please modify it locally and re-import!",
  i18nKey_enterprise_2075: "Existing instrument, is it covered?",
  i18nKey_enterprise_2076: "equipment:",
  i18nKey_enterprise_2077: "Update failed",
  i18nKey_enterprise_2078: "Building",
  i18nKey_enterprise_2079: "Room",
  i18nKey_enterprise_2080: "Form template",
  i18nKey_enterprise_2081: "Please fill in the necessary information",
  i18nKey_enterprise_2082: "Please enter a legal mobile phone number",
  i18nKey_enterprise_2083: "houses",
  i18nKey_enterprise_2084: "Two rooms and one living room",
  i18nKey_enterprise_2085: "Reset successfully",
  i18nKey_enterprise_2086: "Parking area",
  i18nKey_enterprise_2087: "Untie",
  i18nKey_enterprise_2088: "Add public area equipment",
  i18nKey_enterprise_2089: "Device information updated successfully",
  i18nKey_enterprise_2090: "Confirm to delete device",
  i18nKey_enterprise_2091: "Add device successfully",
  i18nKey_enterprise_2092: "It can't be recovered after deletion. Are you sure you want to delete?",
  i18nKey_enterprise_2093: "Staff overview",
  i18nKey_enterprise_2094: "Personnel comparison trend",
  i18nKey_enterprise_2095: "Nearly two weeks",
  i18nKey_enterprise_2096: "Last 12 weeks",
  i18nKey_enterprise_2097: "Last 12 months",
  i18nKey_enterprise_2098: "Video picture delay about 5",
  i18nKey_enterprise_2099: "Please wait",
  i18nKey_enterprise_2100: "Video loading",
  i18nKey_enterprise_2101: "please wait",
  i18nKey_enterprise_2102: "Failure",
  i18nKey_enterprise_2103: "Please enter the server",
  i18nKey_enterprise_2104: "Or domain name",
  i18nKey_enterprise_2105: "Your browser doesn't support",
  i18nKey_enterprise_2106: "Temporary suspension payment amount",
  i18nKey_enterprise_2107: "Package payment amount",
  i18nKey_enterprise_2108: "Please enter car brand",
  i18nKey_enterprise_2109: "Audi",
  i18nKey_enterprise_2110: "Benz",
  i18nKey_enterprise_2111: "BMW",
  i18nKey_enterprise_2112: "Honda",
  i18nKey_enterprise_2113: "Toyota",
  i18nKey_enterprise_2114: "Public",
  i18nKey_enterprise_2115: "Ford",
  i18nKey_enterprise_2116: "Buick",
  i18nKey_enterprise_2117: "Payment in the past month",
  i18nKey_enterprise_2118: "Cumulative payment",
  i18nKey_enterprise_2119: "More than 6 options for license plate",
  i18nKey_enterprise_2120: "Optimal display for you",
  i18nKey_enterprise_2121: "Brand more than 6 options",
  i18nKey_enterprise_2122: "More than 6 color options",
  i18nKey_enterprise_2123: "More than 6 options for license plates and brands",
  i18nKey_enterprise_2124: "License plate and color more than 6 options",
  i18nKey_enterprise_2125: "More than 6 options for brands and colors",
  i18nKey_enterprise_2126: "License plate, brand and color more than 6 options",
  i18nKey_enterprise_2127: "Number of returning guests",
  i18nKey_enterprise_2128: "people)",
  i18nKey_enterprise_2129: "Number of repeat customer visits",
  i18nKey_enterprise_2130: "People)",
  i18nKey_enterprise_2131: "direct interview",
  i18nKey_enterprise_2132: "Time selection",
  i18nKey_enterprise_2133: "Please select the filter time",
  i18nKey_enterprise_2134: "starting point",
  i18nKey_enterprise_2135: "Nearly a week",
  i18nKey_enterprise_2136: "Passenger flow",
  i18nKey_enterprise_2137: "year old",
  i18nKey_enterprise_2138: "Start date",
  i18nKey_enterprise_2139: "Days",
  i18nKey_enterprise_2140: "Festival name",
  i18nKey_enterprise_2141: "Calendar details",
  i18nKey_enterprise_2142: "lessee",
  i18nKey_enterprise_2143: "Removed successfully",
  i18nKey_enterprise_2144: "Removal failed",
  i18nKey_enterprise_2145: "fail to edit!",
  i18nKey_enterprise_2146: "Unassigned device",
  i18nKey_enterprise_2147: "download failed",
  i18nKey_enterprise_2148: "Please enter the name of the tenant",
  i18nKey_enterprise_2149: "Please select store status",
  i18nKey_enterprise_2150: "No equipment",
  i18nKey_enterprise_2151: "number of rooms:",
  i18nKey_enterprise_2152: "Delete room",
  i18nKey_enterprise_2153: "OK to delete the room",
  i18nKey_enterprise_2154: "Number of scenes:",
  i18nKey_enterprise_2155: "Scene switch",
  i18nKey_enterprise_2156: "Are you sure to delete these scenes in bulk?",
  i18nKey_enterprise_2157: "OK to delete the scene",
  i18nKey_enterprise_2158: "Please select the area name",
  i18nKey_enterprise_2159: "Please select a start date",
  i18nKey_enterprise_2160: "Event name can't be null",
  i18nKey_enterprise_2161: "Please enter the festival name",
  i18nKey_enterprise_2162: "Level 1 heading",
  i18nKey_enterprise_2163: "Secondary heading 1",
  i18nKey_enterprise_2164: "Level 3 heading 1",
  i18nKey_enterprise_2165: "Secondary heading 2",
  i18nKey_enterprise_2166: "Level 3 heading 2",
  i18nKey_enterprise_2167: "Qo meter",
  i18nKey_enterprise_2168: "Download the cold table template",
  i18nKey_enterprise_2169: "The number consists of ×× digits!",
  i18nKey_enterprise_2170: "Business/tenant type",
  i18nKey_enterprise_2171: "Business/Tenant Name",
  i18nKey_3066: "Exit time",
  i18nKey_3067: "Formula config",
  i18nKey_3068: "Sub-item config",
  i18nKey_3069: "Branch config",
  i18nKey_3070: "Building area config",
  i18nKey_3071: "Tenant config",
  i18nKey_3072: "Cooling plant",
  i18nKey_3073: "Cooling engine",
  i18nKey_3074: "Cooling tower",
  i18nKey_3075: "Cooling pump",
  i18nKey_3076: "Thermal power plant",
  i18nKey_3077: "Heating engine",
  i18nKey_3078: "Heating pump",
  i18nKey_3079: "AC terminal",
  i18nKey_3080: "AC box",
  i18nKey_3081: "VMC",
  i18nKey_3082: "Other terminala",
  i18nKey_3083: "Escalator",
  i18nKey_3084: "Lift",
  i18nKey_3085: "Sightseeing lift",
  i18nKey_3086: "Draught fan",
  i18nKey_3087: "Blower",
  i18nKey_3088: "Exhaust fan",
  i18nKey_3089: "Smoke ventilator",
  i18nKey_3090: "Ventilator",
  i18nKey_3091: "Pump",
  i18nKey_3092: "Domestic water pump",
  i18nKey_3093: "Sewage pump",
  i18nKey_3094: "Reclaimed water system",
  i18nKey_3095: "Emergency lighting",
  i18nKey_3096: "Public lighting",
  i18nKey_3097: "Flood lighting",
  i18nKey_3098: "Landscape lighting",
  i18nKey_3099: "Charging pile",
  i18nKey_3100: "Mechanical parking",
  i18nKey_3101: "Fire shutter",
  i18nKey_3102: "HV/LV room",
  i18nKey_3103: "HV room",
  i18nKey_3104: "LV room",
  i18nKey_3105: "Apartment power",
  i18nKey_3106: "Anchor store",
  i18nKey_3107: "2nd-level anchor store",
  i18nKey_3108: "Select the device",
  i18nKey_3109: "Meter no",
  i18nKey_3110: "Please select meter no",
  i18nKey_3111: "Please input meter no",
  i18nKey_3112: "Name: Total elec. consumption",
  i18nKey_3113: "Tenant water consumption",
  i18nKey_3114: "Public water consumption",
  i18nKey_3115: "Name: Water meter",
  i18nKey_3116: "Tenant cooling",
  i18nKey_3117: "Add item",
  i18nKey_3118: "Item name",
  i18nKey_3119: "Add item of new level",
  i18nKey_3120: "Add item of new level",
  i18nKey_3121: "Device ownership",
  i18nKey_3122: "Please input device ownership",
  i18nKey_3123: "Device valid to",
  i18nKey_3124: "Please input device brand",
  i18nKey_3125: "Elec. use range",
  i18nKey_3126: "Please select elec. use range",
  i18nKey_3127: "Building area config.",
  i18nKey_3128: "Query tenant info",
  i18nKey_3129: "Please input tenant or brand name",
  i18nKey_3130: "Tenant name",
  i18nKey_3131: "Meter search",
  i18nKey_3132: "Please input meter no., smart meter no., meter name",
  i18nKey_3133: "Total number of parking spaces",
  i18nKey_3134: "On site vehicles",
  i18nKey_3135: "Package car",
  i18nKey_3136: "Temporary parking",
  i18nKey_3137: "White name bicycle",
  i18nKey_3138: "Other vehicles",
  i18nKey_3139: "Other vehicles: non package vehicles, temporary parking vehicles and vehicles on the white list",
  i18nKey_3140: "License plate type",
  i18nKey_3141: "Please select license plate type",
  i18nKey_3142: "Count events",
  i18nKey_3143: "Number of work orders",
  i18nKey_3144: "Merchant / tenant",
  i18nKey_3145: "Auto table",
  i18nKey_3146: "There are currently no sub items",
  i18nKey_3147: "Create with template",
  i18nKey_3148: "Preview template",
  i18nKey_3149: "Numeric value is a numeric type greater than 0",
  i18nKey_3150: "Select meter configuration",
  i18nKey_3151: "Select itemized configuration",
  i18nKey_3152: "Note: to add or delete an instrument in the next level sub item, you need to manually select the subordinate equipment to pull to update the upper level sub item",
  i18nKey_3153: "Remarks: ① the final level sub items are configured by selecting a meter; If there is a meter for the current sub item, it shall be configured by selecting a meter< Br / > ② if an instrument is added or deleted in the next level sub item, and the upper level is configured through the sub item, the system will automatically update the publicity of the upper level sub item.",
  i18nKey_3154: "Value is 0~1 numeric type",
  i18nKey_3155: "Value is numeric",
  i18nKey_3156: "Please select item name",
  i18nKey_3157: "Energy consumption formula",
  i18nKey_3158: "Formula per square meter",
  i18nKey_3159: "Select sub item",
  i18nKey_3160: "Failed to save, please enter the area",
  i18nKey_3161: "Meter No. cannot be empty",
  i18nKey_3162: "Item ID cannot be empty",
  i18nKey_3163: "Instrument location",
  i18nKey_3164: "Subitem abbreviation",
  i18nKey_3165: "Failed to save, the meter number cannot be empty",
  i18nKey_3166: "Please enter the primary device name",
  i18nKey_3167: "Please enter the first level equipment abbreviation",
  i18nKey_3168: "Download import template",
  i18nKey_3169: "Edge configuration import template",
  i18nKey_3170: "Commercial electricity",
  i18nKey_3171: "Household electricity",
  i18nKey_3172: "Agricultural electricity",
  i18nKey_3173: "Industrial power",
  i18nKey_3174: "Mains 10KV",
  i18nKey_3175: "Pull lower level equipment",
};
