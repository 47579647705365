const faciliInfoManage = () =>
  import(
    /* webpackChunkName: "faciliInfoManage" */ "@/pages/facilityManagement/faciliInfoManage"
  ); // 设施信息管理--维护中
const facilityOrderManagement = () =>
  import(
    /* webpackChunkName: "facilityOrderManagement" */ "@/pages/facilityManagement/facilityOrderManagement"
  ); // 设施工单管理--维护中
const facilityOperateStatistics = () =>
  import(
    /* webpackChunkName: "facilityOperateStatistics" */ "@/pages/facilityManagement/facilityOperateStatistics"
  ); // 设施事件统计--维护中
const globalStatus = () =>
  import(
    /* webpackChunkName: "globalStatus" */ "@/pages/globalStatus/globalStatus"
  ); // 综合态势展示--已废弃
const storedEnergyStatisDetail = () =>
  import(
    /* webpackChunkName: "storedEnergyStatisDetail" */ "@/pages/globalStatus/storedEnergyStatisDetail"
  ); // 综合态势展示--储能详情页面--已废弃
const operateReport = () =>
  import(
    /* webpackChunkName: "operateReport" */ "@/pages/operateReport/operateReport.vue"
  ); // 运营报告--维护中
const attendanceReport = () =>
  import(
    /* webpackChunkName: "operateReport" */ "@/pages/attendanceReport/index.vue"
  ); // 运营报告--维护中

const patrolReport = () =>
  import(
    /* webpackChunkName: "operateReport" */ "@/pages/patrolReport/patrolReport.vue"
  ); // 巡检报告--维护中
const facilityPortrait = () =>
  import(
    /* webpackChunkName: "facilityPortrait" */ "@/pages/facilityManagement/facilityPortrait"
  ); // 设施画像--维护中
const demo = () =>
  import(/* webpackChunkName: "componentsDemo" */ "@/components/Demo"); // DEMO--维护中
const facilityEventCenter = () =>
  // eslint-disable-next-line
  (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode")))
    ? import(
      /* webpackChunkName: "facilityEventCenterDT" */ "@/pages/propertyWarranty/propertyWarrantyCenterDT"
    )
    : import(
      /* webpackChunkName: "facilityEventCenter" */ "@/pages/facilityManagement/facilityEventCenter"
    ); // 设施事件中心--维护中
const personQuery = () =>
  import(
    /* webpackChunkName: "staffManagementCheck" */ "@/pages/staffManagement/check"
  ); // 人员查询--人员管理--已废弃
const staffManagementStatistics = () =>
  import(
    /* webpackChunkName: "staffManagementStatistics" */ "@/pages/staffManagement/staffManagementStatistics"
  ); // 人员统计--人员管理--已废弃
const staffManagementClassify = () =>
  import(
    /* webpackChunkName: "staffManagementClassify" */ "@/pages/staffManagement/staffManagementClassify"
  ); // 人员统计--人员标记--已废弃
const staffManagementRecord = () =>
  import(
    /* webpackChunkName: "staffManagementRecord" */ "@/pages/staffManagement/staffManagementRecord"
  ); // 人员统计--人员档案--维护中-仅供深国际私有化项目使用
const personLabel = () =>
  import(
    /* webpackChunkName: "personLabel" */ "@/pages/staffManagement/personLabel/personLabel"
  ); // 人员统计--人员标注--维护中
const staffTracking = () =>
  import(
    /* webpackChunkName: "staffTracking" */ "@/pages/globalSecurity/staffTracking"
  ); // 人员追踪--已废弃
const staffTrackingDetails = () =>
  import(
    /* webpackChunkName: "staffTrackingDetails" */ "@/pages/globalSecurity/staffTrackingDetails"
  ); // 人员追踪详情--已废弃
const addStaffTracking = () =>
  import(
    /* webpackChunkName: "addStaffTracking" */ "@/components/staffTracking/addStaffTracking"
  ); // 人员追踪新建--已废弃
const showStaffTracking = () =>
  import(
    /* webpackChunkName: "showStaffTracking" */ "@/components/staffTracking/showStaffTracking"
  ); // 人员追踪新建--已废弃
const faceControls = () =>
  import(
    /* webpackChunkName: "faceControl" */ "@/pages/globalSecurity/faceControls"
  ); // 人脸布控--已废弃
const faceControlDetails = () =>
  import(
    /* webpackChunkName: "faceControlDetails" */ "@/pages/globalSecurity/faceControlDetails"
  ); // 人脸布控详情--已废弃
const vehicleControls = () =>
  import(
    /* webpackChunkName: "vehicleControls" */ "@/pages/globalSecurity/vehicleControls"
  ); // su车辆布控--已废弃
const faceTrackingManagement = () =>
  import(
    /* webpackChunkName: "faceTrackingManagement" */ "@/pages/globalSecurity/faceTrackingManagement"
  ); // 人脸追踪事件管理--已废弃
const carQurey = () =>
  import(
    /* webpackChunkName: "carQurey" */ "@/pages/vehicleManagement/carQurey.vue"
  ); // 车辆查询--车辆管理--已废弃
const carQureys = () =>
  import(
    /* webpackChunkName: "carQureys" */ "@/pages/vehicleManagement/carQureys.vue"
  ); // 车辆查询--车辆管理--维护中
const vehicleManagementStatistics = () =>
  import(
    /* webpackChunkName: "vehicleManagementStatistics" */ "@/pages/vehicleManagement/vehicleManagementStatistics"
  ); // 车辆统计--车辆管理--维护中
const monthlyCardApplicationRecord = () =>
  import(
    /* webpackChunkName: "monthlyCardApplicationRecord" */ "@/pages/vehicleManagement/monthlyCardApplicationRecord"
  ); // 车辆统计--月卡申请记录--维护中
const visitStatistics = () =>
  import(
    /* webpackChunkName: "visitStatistics" */ "@/pages/vehicleManagement/visitStatistics"
  ); // 访车统计--车辆管理--维护中
const vehicleStopDriveRecord = () =>
  import(
    /* webpackChunkName: "vehicleStopDriveRecord" */ "@/pages/vehicleManagement/vehicleStopDriveRecord"
  ); // 停驶记录--车辆管理--已废弃
const vehicleStopDriveRecords = () =>
  import(
    /* webpackChunkName: "vehicleStopDriveRecords" */ "@/pages/vehicleManagement/vehicleStopDriveRecords"
  ); // 车辆管理-车位记录查询--维护中
const roleConfig = () =>
  import(
    /* webpackChunkName: "roleConfig" */ "@/pages/configCenter/systemConfiguration/roleConfig.vue"
  ); // 系统配置-角色权限配置--已废弃
const alarmPush = () =>
  import(
    /* webpackChunkName: "alarmPush" */ "@/pages/configCenter/alarmManage/alarmPush.vue"
  ); // 系统配置-告警推送--维护中
const editAlarmType = () =>
  import(
    /* webpackChunkName: "editAlarmType" */ "@/pages/configCenter/alarmManage/editAlarmType.vue"
  ); // 已废弃
const addAlarmType = () =>
  import(
    /* webpackChunkName: "addAlarmType" */ "@/pages/configCenter/alarmManage/addAlarmType.vue"
  ); // 已废弃
const userConfig = () =>
  import(
    /* webpackChunkName: "userConfig" */ "@/pages/configCenter/systemConfiguration/userConfig"
  ); // 已废弃
const alarmManagement = () =>
  import(
    /* webpackChunkName: "alarmManagement" */ "@/pages/configCenter/systemConfiguration/alarmManagementNew"
  ); // 已废弃
const globalAlarmConfiguration = () =>
  import(
    /* webpackChunkName: "globalAlarmConfiguration" */ "@/pages/configCenter/systemConfiguration/globalAlarmConfiguration"
  ); // 告警配置-全局告警配置--维护中
const parkInformationManagement = () =>
  import(
    /* webpackChunkName: "parkInformationManagement" */ "@/pages/configCenter/systemConfiguration/parkInformationManagement"
  ); // 系统配置-园区信息管理--维护中
const alarmConfiguration = () =>
  import(
    /* webpackChunkName: "alarmConfiguration" */ "@/pages/configCenter/businessConfiguration/alarmConfiguration.vue"
  );
const siAuthorization = () =>
  import(
    /* webpackChunkName: "siAuthorization" */ "@/pages/configCenter/siAuthorization/siAuthorization.vue"
  );
const houseTemp = () =>
  import(
    /* webpackChunkName: "houseTemp" */ "@/pages/itgHouse/houseTemp/index.vue"
  );
const houseDetail = () =>
  import(
    /* webpackChunkName: "houseDetail" */ "@/pages/itgHouse/houseDetail/index.vue"
  );
const buildScene = () =>
  import(
    /* webpackChunkName: "buildScene" */ "@/pages/itgHouse/buildScene/index.vue"
  );
const spaceManage = () =>
  import(
    /* webpackChunkName: "spaceManage" */ "@/pages/itgHouse/spaceManage/index.vue"
  );
const companyManagement = () =>
  import(
    /* webpackChunkName: "companyManagement" */ "@/pages/configCenter/businessConfiguration/companyManagement"
  );
const externalProject = () =>
  import(
    /* webpackChunkName: "externalProject" */ "@/pages/configCenter/businessConfiguration/externalProject"
  );
const externalProjectDetail = () =>
  import(
    /* webpackChunkName: "externalProjectDetail" */ "@/pages/configCenter/businessConfiguration/externalProjectDetail"
  );
const companyAddress = () =>
  import(
    /* webpackChunkName: "companyAddress" */ "@/pages/configCenter/businessConfiguration/companyAddress"
  );
const facilityMasterData = () =>
  import(
    /* webpackChunkName: "facilityMasterData" */ "@/pages/configCenter/businessConfiguration/facilityMasterData"
  );
const facilityMasterDetail = () =>
  import(
    /* webpackChunkName: "facilityMasterDetail" */ "@/pages/configCenter/businessConfiguration/facilityMasterDetail"
  );
const cloudConfiguration = () =>
  import(
    /* webpackChunkName: "cloudConfiguration" */ "@/pages/configCenter/businessConfiguration/cloudConfiguration"
  ); // 混合云配置
const vcmConfiguration = () =>
  import(
    /* webpackChunkName: "vcmConfiguration" */ "@/pages/configCenter/businessConfiguration/vcmConfiguration"
  ); // vcm配置
const vcnConfiguration = () =>
  import(
    /* webpackChunkName: "vcnConfiguration" */ "@/pages/configCenter/businessConfiguration/vcnConfiguration"
  ); // vcn配置
const mainEditFacilityMaster = () =>
  import(
    /* webpackChunkName: "mainEditFacilityMaster" */ "@/pages/configCenter/businessConfiguration/mainEditFacilityMaster"
  );
const editFacilityMaster = () =>
  import(
    /* webpackChunkName: "editFacilityMaster" */ "@/pages/configCenter/businessConfiguration/editFacilityMaster"
  );
const addDataInBatches = () =>
  import(
    /* webpackChunkName: "addDataInBatches" */ "@/pages/configCenter/businessConfiguration/addDataInBatches.vue"
  );
const fireAreaManage = () =>
  import(
    /* webpackChunkName: "fireAreaManage" */ "@/pages/configCenter/fireAreaManage/list.vue"
  );
const addVehicleControl = () =>
  import(
    /* webpackChunkName: "addVehicleControl" */ "@/pages/globalSecurity/addVehicleControl.vue"
  );
const carControlDetail = () =>
  import(
    /* webpackChunkName: "carControlDetail" */ "@/pages/globalSecurity/carControlDetail.vue"
  );
const assetInquire = () =>
  import(
    /* webpackChunkName: "assetInquire" */ "@/pages/assetManagement/assetInquire.vue"
  );
const assetStatistics = () =>
  import(
    /* webpackChunkName: "assetStatistics" */ "@/pages/assetManagement/assetStatistics.vue"
  );
const addFaceControl = () =>
  import(
    /* webpackChunkName: "addFaceControl" */ "@/pages/globalSecurity/addFaceControl.vue"
  );
const addFaceTrack = () =>
  import(
    /* webpackChunkName: "addFaceTrack" */ "@/pages/globalSecurity/addFaceTrack.vue"
  );
const noCascadeMenu = () =>
  import(/* webpackChunkName: "noCascadeMenu" */ "@/pages/noCascadeMenu");
const globalOverview = () =>
  import(
    /* webpackChunkName: "greenEnergySaving" */ "@/pages/greenEnergySaving/globalOverview"
  ); // 绿色节能--全局概况
const energyConsumptionSankeyStatis = () =>
  import(
    /* webpackChunkName: "energyConsumptionSankeyStatis" */ "@/pages/greenEnergySaving/energyConsumptionSankeyStatis"
  ); // 绿色节能--能效统计与报表--能耗统计树
const energyConsumptionStatisDetail = () =>
  import(
    /* webpackChunkName: "energyConsumptionStatisDetail" */ "@/pages/greenEnergySaving/energyConsumptionStatisDetail"
  ); // 绿色节能--能效统计与报表--能耗详情
const energyConsumptionReportManage = () =>
  import(
    /* webpackChunkName: "energyConsumptionReportManage" */ "@/pages/greenEnergySaving/energyConsumptionReportManage"
  ); // 绿色节能--能效统计与报表--能耗报表管理
const energyConsumptionTargetValueManage = () =>
  import(
    /* webpackChunkName: "energyConsumptionTargetValueManage" */ "@/pages/greenEnergySaving/energyConsumptionTargetValueManage"
  ); // 绿色节能--能效统计与报表--能耗目标值管理
const runOptimizationStrategy = () =>
  import(
    /* webpackChunkName: "runOptimizationStrategy" */ "@/pages/greenEnergySaving/runOptimizationStrategy"
  ); // 绿色节能--运行优化（建议-诊断-节能潜力）
const mainFacMasterDetail = () =>
  import(
    /* webpackChunkName: "mainFacMasterDetail" */ "@/pages/configCenter/businessConfiguration/mainFacMasterDetail"
  );
const facMasterDetail = () =>
  import(
    /* webpackChunkName: "mainFacMasterDetail" */ "@/pages/configCenter/businessConfiguration/facMasterDetail"
  );
const staffMasterData = () =>
  import(
    /* webpackChunkName: "staffMasterData" */ "@/pages/configCenter/personnelMasterData/staffMasterData"
  ); // 人员基础数据
const personnelMasterDataDetails = () =>
  import(
    /* webpackChunkName: "personnelMasterDataDetails" */ "@/pages/configCenter/personnelMasterData/personnelMasterDataDetails"
  ); // 人员基础数据详情
const addPersonnelMasterData = () =>
  import(
    /* webpackChunkName: "addPersonnelMasterData" */ "@/pages/configCenter/personnelMasterData/addPersonnelMasterData"
  ); // 添加人员基础数据
const updatePersonnelMasterData = () =>
  import(
    /* webpackChunkName: "updatePersonnelMasterData" */ "@/pages/configCenter/personnelMasterData/updatePersonnelMasterData"
  ); // 修改人员基础数据
const vehicleMasterData = () =>
  import(
    /* webpackChunkName: "vehicleMasterData" */ "@/pages/configCenter/vehicleMasterData/vehicleMasterData"
  ); // 车辆基础数据
const vehicleMasterDetail = () =>
  import(
    /* webpackChunkName: "vehicleMasterDetail" */ "@/components/configCenter/vehicleMasterData/vehicleMasterDetail"
  ); // 车辆基础数据详情
const vehicleMasterModify = () =>
  import(
    /* webpackChunkName: "vehicleMasterModify" */ "@/components/configCenter/vehicleMasterData/vehicleMasterModify"
  ); // 车辆基础数据修改
const spaceDataManagement = () =>
  import(
    /* webpackChunkName: "spaceDataManagement" */ "@/pages/configCenter/masterData/spaceDataManagement"
  ); // 空间数据管理
const spaceMasterData = () =>
  import(
    /* webpackChunkName: "spaceMasterData" */ "@/pages/configCenter/masterData/spaceMasterData"
  ); // 空间基础数据
const spaceMasterDataManage = () =>
  import(
    /* webpackChunkName: "spaceMasterDataManage" */ "@/pages/configCenter/masterData/manage"
  ); // 空间基础数据管理
const modifySpaceMasterData = () =>
  import(
    /* webpackChunkName: "modifySpaceMasterData" */ "@/pages/configCenter/masterData/modifySpaceMasterData"
  ); // 修改空间基础数据
const classificationFacility = () =>
  import(
    /* webpackChunkName: "classificationFacility" */ "@/pages/configCenter/businessConfiguration/classificationFacility"
  );
const propertyWarrantyCenter = () =>
  // eslint-disable-next-line
  (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode")))
    ? import(
      /* webpackChunkName: "propertyWarrantyCenterDT" */ "@/pages/propertyWarranty/propertyWarrantyCenterDT"
    )
    : import(
      /* webpackChunkName: "propertyWarrantyCenter" */ "@/pages/propertyWarranty/propertyWarrantyCenter"
    ); // 物业保修中心
const warranyWorkOrderManagement = () =>
  import(
    /* webpackChunkName: "warranyWorkOrderManagement" */ "@/pages/propertyWarranty/warranyWorkOrderManagement"
  ); // 报修工单管理
const reportQRCode = () =>
  import(
    /* webpackChunkName: "reportQRCode" */ "@/pages/propertyWarranty/reportQRCode"
  ); // 报事二维码
const workplaceGatherAndSlackOff = () =>
  import(
    /* webpackChunkName: "workplaceGatherAndSlackOff" */ "@/pages/propertyWarranty/workplaceGatherAndSlackOff"
  ); // 报修工单管理
const faceLibraryPage = () =>
  import(
    /* webpackChunkName: "faceControl" */ "@/pages/globalSecurity/faceLibraryPage"
  ); // 人脸库
const faceLibraryDetail = () =>
  import(
    /* webpackChunkName: "faceControl" */ "@/pages/globalSecurity/faceLibraryDetail"
  ); // 人脸库详情
const policyControl = () =>
  import(
    /* webpackChunkName: "policyControl" */ "@/pages/facilityManagement/streetLight/policyControl"
  ); // 路灯策略控制
const addPolicyControl = () =>
  import(
    /* webpackChunkName: "addPolicyControl" */ "@/pages/facilityManagement/streetLight/addPolicyControl"
  ); // 新增路灯策略控制
const lightControlMap = () =>
  import(
    /* webpackChunkName: "lightControlMap" */ "@/pages/facilityManagement/streetLight/lightControlMap"
  ); // 实时监控--路灯控制--设施管理
const environmentalStrategy = () =>
  import(
    /* webpackChunkName: "environmentalStrategy" */ "@/pages/environmentalSpace/environmentalStrategy"
  ); // 环境空间策略配置
const sanitationEventCenter = () =>
  import(
    /* webpackChunkName: "sanitationEventCenter" */ "@/pages/environmentalSpace/sanitationEventCenter"
  ); // 环卫事件中心
const smartToilet = () =>
  import(
    /* webpackChunkName: "sanitationEventCenter" */ "@/pages/environmentalSpace/smartToilet"
  ); // 智慧洗手间
const pointLocationInformation = () =>
  import(
    /* webpackChunkName: "pointLocationInformation" */ "@/pages/greenEnergySaving/pointLocation/pointLocationInformation"
  ); // 绿色节能 > 能耗统计与报表 > 点位信息图
const securityStatus = () =>
  import(
    /* webpackChunkName: "securityStatus" */ "@/pages/globalSecurity/securityStatus"
  );
const faceSearchPage = () =>
  import(
    /* webpackChunkName: "faceSearchPage" */ "@/pages/staffManagement/faceSearch/faceSearchPage"
  );
const visitorManagement = () =>
  import(
    /* webpackChunkName: "visitorManagement" */ "@/pages/staffManagement/visitorManagement/visitorManagement"
  ); // 人员管理 > 访客管理
const outbreakVisitorData = () =>
  import(
    /* webpackChunkName: "outbreakVisitorData" */ "@/pages/staffManagement/outbreakVisitor/outbreakVisitorData"
  ); // 人员管理 > 入园申请记录
const personStatisticPage = () =>
  import(
    /* webpackChunkName: "personStatisticPage" */ "@/pages/staffManagement/personStatistic/personStatisticPage"
  );
const customAnalyze = () =>
  import(
    /* webpackChunkName: "customAnalyze" */ "@/pages/globalSecurity/customAnalyze"
  ); // 人员客群分析
const attribute = () =>
  import(
    /* webpackChunkName: "attribute" */ "@/pages/staffManagement/configuration/attribute/attribute"
  ); // 人员管理-配置-属性
const faultDiagnosis = () =>
  import(
    /* webpackChunkName: "faultDiagnosis" */ "@/pages/greenEnergySaving/faultDiagnosis/faultDiagnosis"
  ); // 能效AI诊断
const faultHistory = () =>
  import(
    /* webpackChunkName: "faultHistory" */ "@/pages/greenEnergySaving/faultDiagnosis/faultHistory"
  ); // 能效AI诊断

const repMessageCenter = () =>
  import(
    /* webpackChunkName: "repMessageCenter" */ "@/pages/facilityManagement/erpControl/repMessageCenter"
  ); // 设施管理---电子屏控制---实时监控
const repFaciliInfoManage = () =>
  import(
    /* webpackChunkName: "repFaciliInfoManage" */ "@/pages/facilityManagement/erpControl/repFaciliInfoManage"
  ); // 设施管理---电子屏控制---电子屏管理
const strategyControl = () =>
  import(
    /* webpackChunkName: "strategyControl" */ "@/pages/facilityManagement/erpControl/strategyControl"
  ); // 设施管理---电子屏控制---策略控制
const facilityIntelligentLighting = () =>
  import(
    /* webpackChunkName: "facilityIntelligentLighting" */ "@/pages/facilityManagement/facilityIntelligentLighting/facilityIntelligentLighting"
  ); // 智能照明--设施信息管理
const addStrategy = () =>
  import(
    /* webpackChunkName: "facilityIntelligentLighting" */ "@/pages/facilityManagement/facilityIntelligentLighting/addStrategy"
  ); // 智能照明--新增回路策略
const lightCameraPage = () =>
  import(
    /* webpackChunkName: "lightCameraPage" */ "@/pages/facilityManagement/facilityIntelligentLighting/components/lightCameraPage"
  ); // 智能照明--查看编辑
const iotAdmin = () =>
  import(/* webpackChunkName: "iotAdmin" */ "@/pages/iotAdmin/index"); // 云橙后台入口

const elevatorDetection = () =>
  import(
    /* webpackChunkName: "elevatorDetection" */ "@/pages/facilityManagement/elevatorDetection/elevatorDetection"
  ); // 设施管理---梯控统计
const wisdomElevator = () =>
  import(
    /* webpackChunkName: "wisdomElevator" */ "@/pages/facilityManagement/wisdomElevator/wisdomElevator"
  ); // 设施管理---智慧电梯
const elevatorCameraPage = () =>
  import(
    /* webpackChunkName: "elevatorCameraPage" */ "@/pages/facilityManagement/wisdomElevator/elevatorCameraPage"
  ); // 设施管理---智慧电梯--编辑

const alohaSystemPage = () =>
  import(/* webpackChunkName: "alohaSystemPage" */ "@/pages/AlohaSystem/index"); // 迎宾系统

const accessControl = () =>
  import(
    /* webpackChunkName: "accessControl" */ "@/pages/staffManagement/accessControl"
  ); // 人员管理---门禁记录
const remoteDoorOpen = () =>
  import(
    /* webpackChunkName: "accessControl" */ "@/pages/staffManagement/remoteOpen/remoteDoorOpen"
  ); // 人员管理---远程开门
const associatedCamera = () =>
  import(
    /* webpackChunkName: "accessControl" */ "@/pages/staffManagement/remoteOpen/associatedCamera"
  ); // 人员管理---远程开门---关联摄像头
const automaticelectricityAbnormal = () =>
  import(
    /* webpackChunkName: "automaticelectricityAbnormal" */ "@/pages/greenEnergySaving/automaticelectricityAbnormal/automaticelectricityAbnormal"
  ); // 自动用电异常监控
const powerLoad = () =>
  import(
    /* webpackChunkName: "powerLoad" */ "@/pages/greenEnergySaving/powerLoad/powerLoad"
  ); // 用电负荷
const theLease = () =>
  import(
    /* webpackChunkName: "theLease" */ "@/pages/WisdomBusiness/configuration/TheLease/theLease"
  ); // 租约
const Calendar = () =>
  import(
    /* webpackChunkName: "Calendar" */ "@/pages/WisdomBusiness/configuration/Calendar/Calendar"
  ); // 日历
const Activity = () =>
  import(
    /* webpackChunkName: "Activity" */ "@/pages/WisdomBusiness/configuration/Activity/Activity"
  ); // 活动
const activeListPage = () =>
  import(
    /* webpackChunkName: "activeListPage" */ "@/pages/WisdomBusiness/configuration/Activity/components/activeListPage"
  ); // 新增活动列表

const repeatCustomer = () =>
  import(
    /* webpackChunkName: "repeatCustomer" */ "@/pages/WisdomBusiness/accurateTraffic/repeatCustomer"
  ); // 回头客
const businessPersonnel = () =>
  import(
    /* webpackChunkName: "businessPersonnel" */ "@/pages/WisdomBusiness/accurateTraffic/personnel/personnel"
  ); // 商业-人员
const compositivePassengerFlow = () =>
  import(
    /* webpackChunkName: "compositivePassengerFlow" */ "@/pages/WisdomBusiness/accurateTraffic/compositivePassengerFlow/compositivePassengerFlow"
  ); // 综合客流首页
const pathTransformation = () =>
  import(
    /* webpackChunkName: "pathTransformation" */ "@/pages/WisdomBusiness/accurateTraffic/pathTransformation/pathTransformation"
  ); // 路径转化
const wisdomBusinessPage = () =>
  import(
    /* webpackChunkName: "pathTransformation" */ "@/pages/WisdomBusiness/wisdomBusinessIndex/wisdomBusinessPage"
  ); // 智慧商业-首页
const basicPassengerFlow = () =>
  import(
    /* webpackChunkName: "basicPassengerFlow" */ "@/pages/WisdomBusiness/basicPassengerFlow/basicPassengerFlow"
  ); // 基础客流
const trafficStatistics = () =>
  import(
    /* webpackChunkName: "trafficStatistics" */ "@/pages/WisdomBusiness/trafficStatistics"
  ); // 客流统计
const periodFlowDistribution = () =>
  import(
    /* webpackChunkName: "periodFlowDistribution" */ "@/pages/WisdomBusiness/periodFlowDistribution"
  ); // 智慧商业--时段流量分布
const periodFlowManageBoard = () =>
  import(
    /* webpackChunkName: "periodFlowManageBoard" */ "@/pages/WisdomBusiness/periodFlowManageBoard"
  ); // 智慧商业 -- 客流管理看板
const businessDevices = () =>
  import(
    /* webpackChunkName: "businessDevices" */ "@/pages/WisdomBusiness/configuration/Devices"
  ); // 智慧商业设施
const businessDevicesLibrary = () =>
  import(
    /* webpackChunkName: "businessDevicesLibrary" */ "@/pages/WisdomBusiness/configuration/Devices/library"
  ); // 智慧商业设备库
const businessEventCenter = () =>
  import(
    /* webpackChunkName: "businessEventCenter" */ "@/pages/WisdomBusiness/businessEventCenter/index"
  ); // 智慧商业事件中心
const personnelDetail = () =>
  import(
    /* webpackChunkName: "personnelDetail" */ "@/pages/WisdomBusiness/accurateTraffic/personnel/personnelDetail"
  ); // 商业-人员-详情
const lightingSolution = () =>
  import(
    /* webpackChunkName: "lightingControl" */ "@/pages/facilityManagement/lightingSolution/lightingSolution"
  ); // 照明方案
const equipmentControl = () =>
  import(
    /* webpackChunkName: "equipmentControl" */ "@/pages/facilityManagement/equipmentControl/equipmentControl"
  ); // 灯控开关
const energyConsumptionStatistics = () =>
  import(
    /* webpackChunkName: "energyConsumptionStatistics" */ "@/pages/carParkLighting/energyConsumptionStatistics"
  ); // 能耗统计
const realTimeReadElectricityMeter = () =>
  import(
    /* webpackChunkName: "realTimeReadElectricityMeter" */ "@/pages/carParkLighting/realTimeReadElectricityMeter"
  ); // 实时抄读电表
const areaConfigure = () =>
  import(
    /* webpackChunkName: "areaConfigure" */ "@/pages/business/configure/areaConfigure"
  ); // 区域配置
const businessHours = () =>
  import(
    /* webpackChunkName: "businessHours" */ "@/pages/business/configure/businessHours"
  ); // 营业时间
const businessTypeConfigure = () =>
  import(
    /* webpackChunkName: "businessTypeConfigure" */ "@/pages/business/configure/businessTypeConfigure"
  ); // 业态配置
const IntegratedKanban = () =>
  import(
    /* webpackChunkName: "IntegratedKanban" */ "@/pages/WisdomBusiness/IntegratedKanban/IntegratedKanban"
  ); // 综合看板
const VehicleDataAnalysis = () =>
  import(
    /* webpackChunkName: "VehicleDataAnalysis" */ "@/pages/vehicleManagement/VehicleDataAnalysis/VehicleDataAnalysis"
  ); // 车辆数据分析
const VehicleDataDetail = () =>
  import(
    /* webpackChunkName: "VehicleDataAnalysis" */ "@/pages/vehicleManagement/VehicleDataAnalysis/VehicleDataDetail"
  ); // 车辆数据分析-车辆详情
const ammeterDividing = () =>
  import(
    /* webpackChunkName: "ammeterDividing" */ "@/pages/greenEnergySaving/ammeterDividing/ammeterDividing"
  ); // 电表分项维护
const amr = () =>
  import(
    /* webpackChunkName: "amr" */ "@/pages/greenEnergySaving/meterReading/amr"
  ); // 绿色节能-自动抄表
const manualMeter = () =>
  import(
    /* webpackChunkName: "manualMeter" */ "@/pages/greenEnergySaving/meterReading/manualMeter"
  ); // 绿色节能-手工动抄表
const visitorConfig = () =>
  import(
    /* webpackChunkName: "visitorConfig" */ "@/pages/staffManagement/visitorManagement/visitorConfig"
  ); // 访客配置页面
const energyConsumptionFlow = () =>
  import(
    /* webpackChunkName: "energyConsumptionFlow" */ "@/pages/subitemEnergy/energyConsumptionFlow/index"
  ); // 绿色节能-分项用能-分项能耗流向
const itemizedEquipment = () =>
  import(
    /* webpackChunkName: "itemizedEquipment" */ "@/pages/subitemEnergy/energyConsumptionFlow/itemizedEquipment"
  ); // 绿色节能-分项用能-分项能耗流向-分项设备
const energyAnalysis = () =>
  import(
    /* webpackChunkName: "energyAnalysis" */ "@/pages/subitemEnergy/energyAnalysis"
  ); // 绿色节能-分项用能-分项用能分析
const energyStatistics = () =>
  import(
    /* webpackChunkName: "energyStatistics" */ "@/pages/subitemEnergy/energyStatistics"
  ); // 绿色节能-分项用能-分项用能统计
const branchEnergyFlow = () =>
  import(
    /* webpackChunkName: "branchEnergyFlow" */ "@/pages/branchEnergy/branchEnergyFlow"
  ); // 绿色节能-支路用能-支路用能流向图
const branchEnergyAnalysis = () =>
  import(
    /* webpackChunkName: "branchEnergyAnalysis" */ "@/pages/branchEnergy/branchEnergyAnalysis"
  ); // 绿色节能-支路用能-支路用能分析
const branchEnergyStatistics = () =>
  import(
    /* webpackChunkName: "branchEnergyStatistics" */ "@/pages/branchEnergy/branchEnergyStatistics"
  ); // 绿色节能-支路用能-支路用能统计
const buildingEnergyAnalysis = () =>
  import(
    /* webpackChunkName: "buildingEnergyAnalysis" */ "@/pages/buildingEnergy/buildingEnergyAnalysis"
  ); // 绿色节能-建筑用能-建筑用能分析
const buildingEnergyStatistics = () =>
  import(
    /* webpackChunkName: "buildingEnergyStatistics" */ "@/pages/buildingEnergy/buildingEnergyStatistics"
  ); // 绿色节能-建筑用能-建筑用能统计
const merchantEnergyAnalysis = () =>
  import(
    /* webpackChunkName: "merchantEnergyAnalysis" */ "@/pages/merchantEnergy/merchantEnergyAnalysis"
  ); // 绿色节能-商/租户用能-商/租户用能分析
const merchantEnergyStatistics = () =>
  import(
    /* webpackChunkName: "merchantEnergyStatistics" */ "@/pages/merchantEnergy/merchantEnergyStatistics"
  ); // 绿色节能-商/租户用能-商/租户用能统计
const visitorAccess = () =>
  import(
    /* webpackChunkName: "visitorAccess" */ "@/pages/staffManagement/visitorAccessConfig/visitorAccess"
  ); // 基础配置-业务配置-访客权限管理
const visitorAccessList = () =>
  import(
    /* webpackChunkName: "visitorAccessList" */ "@/pages/staffManagement/visitorAccessConfig/visitorAccessList"
  ); // 基础配置-业务配置-访客权限列表页
const elevatorApplication = () =>
  import(
    /* webpackChunkName: "elevatorApplication" */ "@/pages/staffManagement/elevatorApplication/eleApplicationList"
  ); // 人员管理-电梯申请
const companyFloorManagement = () =>
  import(
    /* webpackChunkName: "companyFloorManagement" */ "@/pages/configCenter/businessConfiguration/companyFloorManagement"
  );
const companyFloor = () =>
  import(
    /* webpackChunkName: "companyFloor" */ "@/pages/configCenter/businessConfiguration/companyFloor"
  );
const vehicleConfigData = () =>
  import(
    /* webpackChunkName: "vehicleConfigData" */ "@/pages/configCenter/vehicleConfig/vehicleConfigData"
  );
const restroomStatistics = () =>
  import(
    /* webpackChunkName: "vehicleConfigData" */ "@/pages/restroom/restroomStatistics"
  );
const WorkOrder = () =>
  import(
    /* webpackChunkName: "vehicleConfigData" */ "@/pages/restroom/WorkOrder"
  );

const accessAuthorityQuery = () =>
  import(
    /* webpackChunkName: "accessAuthorityQuery" */ "@/pages/staffManagement/accessAuthorityQuery"
  ); // 人员管理 > 通行权限查询
const accessControlParameterConfiguration = () =>
  import(
    /* webpackChunkName: "accessControlParameterConfiguration" */ "@/pages/staffManagement/visitorManagement/accessControlParameterConfiguration"
  ); // 门禁参数配置
const accessControlParameterConfigurationQingDao = () =>
  import(
    /* webpackChunkName: "accessControlParameterConfigurationQingDao" */ "@/pages/staffManagement/visitorManagement/accessControlParameterConfigurationQingDao"
  ); // 门禁参数配置
const visitorOrderConfigure = () =>
  import(
    /* webpackChunkName: "visitorOrderConfigure" */ "@/pages/staffManagement/visitorOrderConfigure/visitorOrderConfigure"
  ); // 基础配置-业务配置-访客权限管理
const emergencyPlanning = () =>
  import(
    /* webpackChunkName: "emergencyPlanning" */ "@/pages/emergencyPlan/emergencyPlanning"
  );
const addPlan = () =>
  import(/* webpackChunkName: "addPlan" */ "@/pages/emergencyPlan/addPlan");
const emergencyCenter = () =>
  import(
    /* webpackChunkName: "vehicleConfigData" */ "@/pages/emergencyPlan/emergencyCenter"
  );
const newAddReport = () =>
  import(
    /* webpackChunkName: "vehicleConfigData" */ "@/pages/emergencyPlan/newReport"
  );
const parkConfigure = () =>
  import(
    /* webpackChunkName: "parkConfigure" */ "@/pages/staffManagement/parkConfigure/parkConfigure"
  ); // 基础配置-业务配置-停车场配置
const choke = () =>
  import(
    /* webpackChunkName: "choke" */ "@/pages/staffManagement/parkConfigure/choke"
  ); // 基础配置-业务配置-闸口配置
const plan = () =>
  import(/* webpackChunkName: "plan" */ "@/pages/Inspection/plan"); // 数字巡检-巡检计划
const recordxun = () =>
  import(/* webpackChunkName: "recordxun" */ "@/pages/Inspection/recordxun");
const bimMarker = () =>
  import(
    /* webpackChunkName: "bimMarker" */ "@/pages/configCenter/bimConfig/bimMarker"
  );
const monitoringFilterConfiguration = () =>
  import(
    /* webpackChunkName: "monitoringFilterConfiguration" */ "@/pages/configCenter/securityConfig/monitoringFilterConfiguration"
  );
const workconfig = () =>
  import(
    /* webpackChunkName: "monitoringFilterConfiguration" */ "@/pages/worke/workconfig"
  );
const passthroughConfig = () =>
  import(
    /* webpackChunkName: "passthroughConfig" */ "@/pages/configCenter/passthroughConfig"
  );

export default [
  {
    path: "/workconfig", // 配置中心-业务配置-工单请求类配置--维护中
    name: "workconfig",
    meta: {
      title: "工单请求类配置"
    },
    component: workconfig
  },
  {
    path: "/emergencyCenter", // 应急预案--应急中心--维护中
    name: "emergencyCenter",
    meta: {
      title: "应急中心"
    },
    component: emergencyCenter
  },
  {
    path: "/emergencyCenter/newAddReport", // 应急预案--新增上报
    name: "newAddReport",
    meta: {
      title: "新增上报"
    },
    component: newAddReport
  },
  {
    path: "/plan", // 设施管理-数字巡检-巡检计划---维护中
    name: "plan",
    meta: {
      title: "巡检计划"
    },
    component: plan
  },
  {
    path: "/recordxun", // 设施管理-数字巡检-巡检记录--维护中
    name: "recordxun",
    meta: {
      title: "巡检记录"
    },
    component: recordxun
  },
  {
    path: "/restroomStatistics", // 环境空间-智慧洗手间-智慧洗手间统计--维护中
    name: "restroomStatistics",
    authKey: "restroomStatistics",
    meta: {
      title: "智慧洗手间统计"
    },
    component: restroomStatistics
  },
  {
    path: "/WorkOrder", // 环境空间-智慧洗手间-智慧洗手间工单管理--维护中
    name: "WorkOrder",
    authKey: "WorkOrder",
    meta: {
      title: "智慧洗手间工单管理"
    },
    component: WorkOrder
  },
  {
    path: "/accessAuthorityQuery", // 人员管理-通行权限查询--维护中
    name: "accessAuthorityQuery",
    authKey: "accessAuthorityQuery",
    meta: {
      title: "通行权限"
    },
    component: accessAuthorityQuery
  },
  {
    path: "/accessControlParameterConfiguration", // 配置中心-业务配置-通行配置-门禁参数配置--维护中
    name: "accessControlParameterConfiguration",
    component: accessControlParameterConfiguration
  },
  {
    path: "/accessControlParameterConfigurationQingDao", // 配置中心-业务配置-通行配置-门禁参数配置--维护中
    name: "accessControlParameterConfigurationQingDao",
    component: accessControlParameterConfigurationQingDao
  },
  {
    path: "/faciliInfoManage", // 设施管理-设施信息管理--维护中
    name: "faciliInfoManage",
    authKey: "faciliInfoManage",
    meta: {
      title: "设施信息管理"
    },
    component: faciliInfoManage
  },
  {
    path: "/faciliInfoManage/facilityPortrait/:id", // 设施管理-设施信息管理-设施画像--维护中
    name: "facilityPortrait",
    component: facilityPortrait
  },
  {
    path: "/facilityOrderManagement", // 设施管理-设施工单管理--维护中
    name: "facilityOrderManagement",
    authKey: "facilityOrderManagement",
    meta: {
      title: "设施工单管理"
    },
    component: facilityOrderManagement
  },
  {
    path: "/warranyWorkOrderManagement", // 报修管理-工单管理--维护中
    name: "warranyWorkOrderManagement",
    authKey: "warranyWorkOrderManagement",
    meta: {
      title: "报修事件"
    },
    component: warranyWorkOrderManagement
  },
  {
    path: "/reportQRCode", // 报事二维码--维护中
    name: "reportQRCode",
    authKey: "reportQRCode",
    meta: {
      title: "报事二维码"
    },
    component: reportQRCode
  },
  {
    path: "/workplaceGatherAndSlackOff", // 报修管理-工单管理--维护中
    name: "workplaceGatherAndSlackOff",
    authKey: "workplaceGatherAndSlackOff",
    meta: {
      title: "聚岗怠岗"
    },
    component: workplaceGatherAndSlackOff
  },
  {
    path: "/facilityOperateStatistics", // 设施管理-设施事件统计--维护中
    name: "facilityOperateStatistics",
    authKey: "facilityOperateStatistics",
    meta: {
      title: "设施事件统计",
      mapType: "gis"
    },
    component: facilityOperateStatistics
  },
  {
    path: "/demo", // demo--维护中
    name: "demo",
    component: demo
  },
  {
    path: "/globalStatus", // 综合态势-综合态势展示--已废弃
    authKey: "globalStatus",
    component: { template: "<router-view/>" },
    children: [
      {
        path: "", // 综合态势展示
        name: "globalStatus",
        meta: {
          mapType: "gis"
        },
        component: globalStatus
      },
      {
        path: "storedEnergyStatisDetail", // 综合态势展示--储能详情
        name: "storedEnergyStatisDetail",
        component: storedEnergyStatisDetail
      }
    ]
  },
  {
    path: "/operateReport", // 综合态势-运营报告--维护中
    name: "operateReport",
    authKey: "operateReport",
    component: operateReport
  },
  {
    path: "/attendanceReport", // 综合态势-保洁人员考勤报表--维护中
    name: "attendanceReport",
    authKey: "attendanceReport",
    component: attendanceReport
  },
  {
    path: "/patrolReport",
    name: "patrolReport",
    authKey: "patrolReport",
    component: patrolReport
  },
  {
    path: "/facilityEventCenter", // 设施管理-设施事件中心--维护中
    name: "facilityEventCenter",
    authKey: "facilityEventCenter",
    meta: {
      title: "设施事件中心"
    },
    component: facilityEventCenter
  },
  {
    path: "/facilityEventCenter/:id", // 设施事件中心详情
    name: "facilityEventCenterDetail",
    authKey: "facilityEventCenterDetailById",
    meta: {
      title: "设施事件中心详情"
    },
    component: facilityEventCenter
  },
  {
    path: "/personQuery", // 人员管理（一级菜单）--已废弃
    name: "personQuery",
    component: personQuery
  },
  {
    path: "/staffManagementStatistics", // 人员管理-人员统计--已废弃
    name: "staffManagementStatistics",
    component: staffManagementStatistics
  },
  {
    path: "/staffManagementClassify", // 人员统计--人员标记--已废弃
    name: "staffManagementClassify",
    component: staffManagementClassify
  },
  {
    path: "/staffManagementRecord", // 人员管理--人员档案--维护中
    authKey: "staffManagementRecord",
    name: "staffManagementRecord",
    component: staffManagementRecord
  },
  {
    path: "/personLabel", // 人员管理--人员标注--维护中
    name: "personLabel",
    component: personLabel
  },
  {
    path: "/remoteDoorOpen", // 人员管理--远程开门--维护中
    name: "remoteDoorOpen",
    component: remoteDoorOpen
  },
  {
    path: "/remoteDoorOpen/associatedCamera/:equipCode/:position/:equipName", // 人员管理--远程开门---关联摄像头--维护中
    name: "associatedCamera",
    component: associatedCamera
  },
  {
    path: "/staffTracking", // 人员追踪--已废弃
    name: "staffTracking",
    component: staffTracking
  },
  {
    path: "/staffTracking/staffTrackingDetails/", // 人员追踪详情--已废弃
    name: "staffTrackingDetails",
    component: staffTrackingDetails
  },
  {
    path: "/staffTracking/addStaffTracking/", // 人员追踪新建--已废弃
    name: "addStaffTracking",
    component: addStaffTracking
  },
  {
    path: "/staffConfiguration/attribute/", // 配置中心-业务配置-门禁配置-属性--废弃
    name: "attribute",
    component: attribute
  },
  {
    path: "/staffConfiguration/visitorConfig", // 配置中心-业务配置-门禁配置-访客管理配置--废弃
    name: "visitorConfig",
    component: visitorConfig
  },
  {
    path: "/staffTracking/showStaffTracking/", // 展示迎宾屏--废弃
    name: "showStaffTracking",
    component: showStaffTracking
  },
  {
    path: "/staffTracking/modifyStaffTracking/", // 人员追踪修改--废弃
    name: "modifyStaffTracking",
    component: addStaffTracking
  },
  {
    path: "/faceControls", // 人脸布控--废弃
    name: "faceControls",
    component: faceControls
  },
  {
    path: "/faceControls/faceControlDetails/", // 人脸布控详情--废弃
    name: "faceControlDetails",
    component: faceControlDetails
  },
  {
    path: "/vehicleControls/carControlDetail/:id", // su车辆布控详情--废弃
    name: "carControlDetail",
    component: carControlDetail
  },
  {
    path: "/vehicleControls", // su车辆布控--废弃
    name: "vehicleControls",
    component: vehicleControls
  },
  {
    path: "/staffTracking/faceTrackingManagement", // 人脸追踪事件管理--废弃
    name: "faceTrackingManagement",
    component: faceTrackingManagement
  },
  {
    path: "/carQurey", // 车辆管理-车辆查询--废弃
    name: "carQurey",
    authKey: "carQurey",
    meta: {
      title: "车辆查询"
    },
    component: carQurey
  },
  {
    path: "/carQureys", // 车辆管理-车辆查询--维护中
    name: "carQureys",
    authKey: "carQureys",
    meta: {
      title: "车辆查询"
    },
    component: carQureys
  },
  {
    path: "/VehicleDataAnalysis", // 车辆管理-车辆数据分析--废弃
    name: "VehicleDataAnalysis",
    authKey: "VehicleDataAnalysis",
    meta: {
      title: "车辆数据分析",
      keepAlive: true
    },
    component: VehicleDataAnalysis
  },
  {
    path:
      "/VehicleDataAnalysis/VehicleDataDetail/:oriImg/:plateNo/:color/:brandName/:amount/:paymentPackage/:sixMonthEntry/:sixMonthPayment", // 车辆管理-车辆数据分析-车辆详情--废弃
    name: "VehicleDataDetail",
    authKey: "VehicleDataDetail",
    meta: {
      title: "车辆详情"
    },
    component: VehicleDataDetail
  },
  {
    path: "/vehicleManagementStatistics", // 车辆管理-车辆统计--维护中
    name: "vehicleManagementStatistics",
    authKey: "vehicleManagementStatistics",
    meta: {
      title: "车辆统计",
      mapType: "gis"
    },
    component: vehicleManagementStatistics
  },
  {
    path: "/monthlyCardApplicationRecord", // 车辆管理-月卡申请记录--维护中
    name: "monthlyCardApplicationRecord",
    authKey: "monthlyCardApplicationRecord",
    meta: {
      title: "月卡申请记录",
      mapType: "gis"
    },
    component: monthlyCardApplicationRecord
  },
  {
    path: "/visitStatistics", // 车辆管理-访车统计--维护中
    name: "visitStatistics",
    meta: {
      title: "访车统计"
    },
    component: visitStatistics
  },
  {
    path: "/vehicleStopDriveRecord", // 车辆管理-车位记录查询--废弃
    name: "vehicleStopDriveRecord",
    authKey: "vehicleStopDriveRecord",
    meta: {
      title: "停驶记录"
    },
    component: vehicleStopDriveRecord
  },
  {
    path: "/vehicleStopDriveRecords", // 车辆管理-车位记录查询--维护中
    name: "vehicleStopDriveRecords",
    authKey: "vehicleStopDriveRecords",
    meta: {
      title: "车位记录查询"
    },
    component: vehicleStopDriveRecords
  },
  {
    path: "/roleConfig", // 配置中心-系统配置-角色权限配置--废弃
    name: "roleConfig",
    component: roleConfig
  },
  {
    path: "/userConfig", // 配置中心-系统配置-用户群组管理--废弃
    name: "userConfig",
    component: userConfig
  },
  {
    path: "/alarmManagement", // 配置中心-系统配置-告警管理--维护中
    name: "alarmManagement",
    component: alarmManagement
  },
  {
    path: "/globalAlarmConfiguration", // 配置中心-高进配置-全局告警配置--维护中
    name: "globalAlarmConfiguration",
    component: globalAlarmConfiguration
  },
  {
    path: "/alarmConfiguration", // 业务配置（一级菜单）--废弃
    name: "alarmConfiguration",
    component: alarmConfiguration
  },
  {
    path: "/parkInformationManagement", // 配置中心-系统配置-园区信息管理--维护中
    name: "parkInformationManagement",
    component: parkInformationManagement
  },
  {
    path: "/cloudConfiguration", // 配置中心-业务配置-混合云配置--废弃
    name: "cloudConfiguration",
    component: cloudConfiguration
  },
  {
    path: "/cloudConfiguration/vcmConfiguration/:parkName", // 配置中心-业务配置-混合云配置-vcm配置--废弃
    name: "vcmConfiguration",
    component: vcmConfiguration
  },
  {
    path: "/cloudConfiguration/vcnConfiguration/:parkName", // 配置中心-业务配置-混合云配置-vcn配置--废弃
    name: "vcnConfiguration",
    component: vcnConfiguration
  },
  {
    path: "/externalProject", // 配置中心-基础数据-外部项目映射--维护中
    name: "externalProject",
    component: externalProject
  },
  {
    path: "/externalProjectDetail", // 配置中心-基础数据-外部项目映射-项目详情--维护中
    name: "externalProjectDetail",
    component: externalProjectDetail
  },
  {
    path: "/companyManagement", // 配置中心-基础数据-企业数据管理--维护中
    name: "companyManagement",
    component: companyManagement
  },
  // 机场卫星厅大屏-一体化运营
  {
    path: "/airPortTerminalScreenDataConfig", // 配置中心-基础数据-机场卫星厅大屏
    name: "airPortTerminalScreenDataConfig",
    component: () =>
      import(
        /* webpackChunkName: "airPortTerminalScreenDataConfig" */ "@/pages/configCenter/businessConfiguration/airPortTerminalScreenDataConfig"
      )
  },
  {
    path: "/companyManagement/companyAddress", // 配置中心-基础数据-企业数据管理-地址管理-维护中
    name: "companyAddress",
    component: companyAddress
  },
  {
    path: "/companyFloorManagement", // 配置中心-业务配置-通行配置-公司楼层设置-维护中
    name: "companyFloorManagement",
    component: companyFloorManagement
  },
  {
    path: "/companyFloorManagement/companyFloor", // 配置中心-业务配置-通行配置-公司楼层设置-地址管理-维护中
    name: "companyFloor",
    component: companyFloor
  },
  {
    path: "/facilityMasterData", // 配置中心-基础数据-设施基础数据--维护中
    name: "facilityMasterData",
    component: facilityMasterData
  },
  {
    path: "/facilityMasterData/facilityMasterDetail/:id", // 配置中心-基础数据-设施基础数据-设施基础数据详情--维护中
    name: "facilityMasterDetail",
    component: facilityMasterDetail
  },
  {
    path: "/facilityMasterData/mainEditFacilityMaster/:id", // 配置中心-基础数据-设施基础数据-设施基础数据修改--维护中
    name: "mainEditFacilityMaster",
    component: mainEditFacilityMaster
  },
  {
    path: "/facilityMasterData/editFacilityMaster/:id", // 设施基础数据修改--废弃
    name: "editFacilityMaster",
    component: editFacilityMaster
  },
  {
    path: "/fireAreaManage", // 配置中心-基础数据-消防分区管理--维护中
    name: "fireAreaManage",
    component: fireAreaManage
  },
  {
    path: "/facilityMasterData/addDataInBatches", // 上传告警提示
    name: "addDataInBatches",
    component: addDataInBatches
  },
  {
    path: "/vehicleControls/addVehicleControl", // 新建车辆布控
    name: "addVehicleControl",
    component: addVehicleControl
  },
  {
    path: "/vehicleControls/modifyVehicleControl", // 修改车辆布控
    name: "modifyVehicleControl",
    component: addVehicleControl
  },
  {
    path: "/vehicleControls/aginVehicleControl", // 重新车辆布控
    name: "aginVehicleControl",
    component: addVehicleControl
  },
  {
    path: "/assetInquire", // 资产管理
    name: "assetInquire",
    component: assetInquire
  },
  {
    path: "/assetStatistics", // 资产统计
    name: "assetStatistics",
    component: assetStatistics
  },
  {
    path: "/faceControls/addFaceControl", // 新建人脸布控
    name: "addFaceControl",
    component: addFaceControl
  },
  {
    path: "/faceControls/addFaceTrack", // 新建人脸追踪
    name: "addFaceTrack",
    component: addFaceTrack
  },
  {
    path: "/faceControls/modifyFaceControl", // 修改人脸布控
    name: "modifyFaceControl",
    component: addFaceControl
  },
  {
    path: "/faceControls/againFaceControl", // 重新人脸布控
    name: "againFaceControl",
    component: addFaceControl
  },
  {
    path: "/noCascadeMenu",
    name: "noCascadeMenu",
    component: noCascadeMenu
  },
  {
    path: "/globalOverview", // 绿色节能--全局概况
    name: "globalOverview",
    component: globalOverview
  },

  {
    path: "/energyConsumptionSankeyStatis", // 绿色节能--能耗统计报表--能耗统计树
    authKey: "energyConsumptionSankeyStatis",
    component: { template: "<router-view/>" },
    children: [
      {
        path: "", // 绿色节能--能耗统计报表--能耗统计树
        name: "energyConsumptionSankeyStatis",
        meta: {
          title: "能耗统计树"
        },
        component: energyConsumptionSankeyStatis
      },
      {
        path: "energyConsumptionStatisDetail", // 绿色节能--能耗统计报表--能耗统计树--能耗详情
        name: "energyConsumptionStatisDetail",
        authKey: "energyConsumptionStatisDetail",
        meta: {
          title: "能耗详情"
        },
        component: energyConsumptionStatisDetail
      }
    ]
  },
  {
    path: "/energyConsumptionReportManage", // 绿色节能--能耗统计报表--能耗报表管理
    name: "energyConsumptionReportManage",
    authKey: "energyConsumptionReportManage",
    meta: {
      title: "能耗报表管理"
    },
    component: energyConsumptionReportManage
  },
  {
    path: "/energyConsumptionTargetValueManage", // 绿色节能--能耗统计报表--能耗目标值管理
    name: "energyConsumptionTargetValueManage",
    authKey: "energyConsumptionTargetValueManage",
    meta: {
      title: "能耗目标值管理"
    },
    component: energyConsumptionTargetValueManage
  },
  {
    path: "/runOptimizationStrategy", // 绿色节能--运行优化（建议-诊断-节能潜力）
    name: "runOptimizationStrategy",
    authKey: "runOptimizationStrategy",
    meta: {
      title: "运行优化"
    },
    component: runOptimizationStrategy
  },
  {
    path: "/facilityMasterData/mainFacMasterDetail/:id",
    name: "mainFacMasterDetail",
    component: mainFacMasterDetail
  },
  {
    path: "/facilityMasterData/mainFacMasterVersionDetail/:id",
    name: "mainFacMasterVersionDetail",
    component: mainFacMasterDetail
  },
  {
    path: "/facilityMasterData/facMasterDetail/:id",
    name: "facMasterDetail",
    component: facMasterDetail
  },
  {
    path: "/staffMasterData",
    name: "staffMasterData",
    component: staffMasterData
  },
  {
    path: "/staffMasterData/personnelMasterDataDetails/:id", // 人员基础数据详情
    name: "personnelMasterDataDetails",
    component: personnelMasterDataDetails
  },
  {
    path: "/staffMasterData/addPersonnelMasterData", // 添加人员基础数据
    name: "addPersonnelMasterData",
    component: addPersonnelMasterData
  },
  {
    path: "/staffMasterData/updatePersonnelMasterData/:id", // 添加人员基础数据
    name: "updatePersonnelMasterData",
    component: updatePersonnelMasterData
  },
  {
    // 车辆基础数据
    path: "/vehicleMasterData",
    name: "vehicleMasterData",
    component: vehicleMasterData
  },
  {
    // 车辆基础数据详情
    path: "/vehicleMasterData/vehicleMasterDetail/:id",
    name: "vehicleMasterDetail",
    component: vehicleMasterDetail
  },
  {
    // 车辆基础数据修改
    path: "/vehicleMasterData/vehicleMasterModify",
    name: "vehicleMasterModify",
    component: vehicleMasterModify
  },
  {
    path: "/pointLocationInformation", // 绿色节能 > 能耗统计与报表 > 点位信息图
    name: "pointLocationInformation",
    authKey: "pointLocationInformation",
    meta: {
      title: "点位信息图"
    },
    component: pointLocationInformation
  },
  {
    // 车辆基础数据创建
    path: "/vehicleMasterData/vehicleMasterCreated",
    name: "vehicleMasterCreated",
    component: vehicleMasterModify
  },
  {
    path: "/spaceMasterData",
    name: "spaceMasterData",
    component: spaceMasterData
  },
  {
    path: "/spaceDataManagement",
    name: "spaceDataManagement",
    component: spaceDataManagement
  },
  {
    path: "/spaceMasterData/modifySpaceMasterData/:id",
    name: "modifySpaceMasterData",
    component: modifySpaceMasterData
  },
  {
    path: "/spaceMasterData/createSpaceMasterData/:id",
    name: "createSpaceMasterData",
    component: modifySpaceMasterData
  },
  {
    path: "/spaceMasterData/manage",
    name: "spaceMasterDataManage",
    component: spaceMasterDataManage
  },
  {
    path: "/classificationFacility",
    name: "classificationFacility",
    component: classificationFacility
  },
  {
    path: "/estatesWarrantyCenter", // 物业报修中心
    name: "propertyWarrantyCenter",
    component: propertyWarrantyCenter
  },

  /** 绿色节能-支路用能 */
  {
    path: "/branchEnergy",
    name: "branchEnergy",
    component: { template: "<router-view/>" },
    children: [
      /** 绿色节能-支路用能-支路能耗流向图 */
      {
        path: "/branchEnergyFlow",
        name: "branchEnergyFlow",
        meta: {
          title: "支路能耗流向图"
        },
        component: branchEnergyFlow
      },
      /** 绿色节能-支路用能-支路用能分析 */
      {
        path: "/branchEnergyAnalysis",
        name: "branchEnergyAnalysis",
        meta: {
          title: "支路用能分析"
        },
        component: branchEnergyAnalysis
      },
      /** 绿色节能-支路用能-支路用能统计 */
      {
        path: "/branchEnergyStatistics",
        name: "branchEnergyStatistics",
        meta: {
          title: "支路用能统计"
        },
        component: branchEnergyStatistics
      }
    ]
  },
  /** 绿色节能-建筑用能 */
  {
    path: "/buildingEnergy",
    name: "buildingEnergy",
    component: { template: "<router-view/>" },
    children: [
      /** 绿色节能-建筑用能-建筑用能分析 */
      {
        path: "/buildingEnergyAnalysis",
        name: "buildingEnergyAnalysis",
        meta: {
          title: "建筑用能分析"
        },
        component: buildingEnergyAnalysis
      },
      /** 绿色节能-建筑用能-建筑用能统计 */
      {
        path: "/buildingEnergyStatistics",
        name: "buildingEnergyStatistics",
        meta: {
          title: "建筑用能统计"
        },
        component: buildingEnergyStatistics
      }
    ]
  },
  /** 绿色节能-商/租户用能 */
  {
    path: "/merchantEnergy",
    name: "merchantEnergy",
    component: { template: "<router-view/>" },
    children: [
      /** 绿色节能-商/租户用能-商/租户用能分析 */
      {
        path: "/merchantEnergyAnalysis",
        name: "merchantEnergyAnalysis",
        meta: {
          title: "商/租户用能分析"
        },
        component: merchantEnergyAnalysis
      },
      /** 绿色节能-商/租户用能-商/租户用能统计 */
      {
        path: "/merchantEnergyStatistics",
        name: "merchantEnergyStatistics",
        meta: {
          title: "商/租户用能统计"
        },
        component: merchantEnergyStatistics
      }
    ]
  },

  {
    path: "/policyControl", // 路灯策略控制
    authKey: "lightPolicyControl",
    component: { template: "<router-view/>" },
    children: [
      {
        path: "",
        name: "lightPolicyControl",
        meta: {
          title: "路灯策略控制"
        },
        component: policyControl
      },
      {
        path: "addPolicyControl", // 新增路灯策略控制
        name: "addPolicyControl",
        authKey: "addPolicyControl",
        meta: {
          title: "新增路灯策略控制"
        },
        component: addPolicyControl
      },
      {
        path: "modifyPolicyControl", // 修改路灯策略控制
        name: "modifyPolicyControl",
        authKey: "modifyPolicyControl",
        meta: {
          title: "修改路灯策略控制"
        },
        component: addPolicyControl
      },
      {
        path: "detailPolicyControl", // 查看路灯策略控制
        name: "detailPolicyControl",
        authKey: "detailPolicyControl",
        meta: {
          title: "查看路灯策略控制"
        },
        component: addPolicyControl
      }
    ]
  },

  {
    path: "/lightControlMap", // 实时监控--路灯控制--设施管理
    name: "lightControlMap",
    authKey: "lightControlMap",
    meta: {
      title: "实时监控"
    },
    component: lightControlMap
  },
  {
    path: "/alarmPush", // 告警推送
    name: "alarmConfigurationPush",
    component: alarmPush
  },
  {
    path: "/alarmPush/editAlarmType", // 告警推送
    name: "editType",
    component: editAlarmType
  },
  {
    path: "/alarmPush/addAlarmType", // 告警推送
    name: "addType",
    component: addAlarmType
  },
  {
    path: "/faceLibraryPage", // 人脸库
    name: "faceLibraryPage",
    component: faceLibraryPage
  },
  {
    path: "/faceLibraryPage/faceLibraryDetail/:type", // 人脸库详情
    name: "faceLibraryDetail",
    component: faceLibraryDetail
  },
  {
    path: "/environmentalStrategy", // 环境空间策略配置
    name: "environmentalStrategy",
    component: environmentalStrategy
  },
  {
    path: "/smartToilet", // 环境空间-智慧洗手间
    name: "smartToilet",
    component: smartToilet
  },
  {
    path: "/sanitationEventCenter", // 环卫事件中心
    component: { template: "<router-view />" }, //  :key='$route.path'
    children: [
      {
        path: "", // 环卫事件中心
        name: "sanitationEventCenter",
        meta: {
          title: "环卫事件中心"
        },
        component: sanitationEventCenter
      },
      {
        path: ":id", // 环卫事件详情
        name: "sanitationEventCenterDetail",
        meta: {
          title: "环卫事件详情",
          breadcrumb: false
        },
        component: sanitationEventCenter
      }
    ]
  },
  {
    path: "/securityStatus", // 各种图
    name: "securityStatus",
    authKey: "securityStatus",
    meta: {
      title: "运营报表"
    },
    component: securityStatus
  },
  {
    path: "/siAuthorization",
    name: "SiAuthorization",
    component: siAuthorization
  },
  {
    path: "/houseTemp",
    name: "HouseTemp",
    component: houseTemp
  },
  {
    path: "/houseTemp/houseDetail/:houseName",
    name: "HouseDetail",
    component: houseDetail,
    meta: {
      keepAlive: true
    },
    props: true
  },
  {
    path: "/houseTemp/buildScene",
    name: "TempBuildScene",
    component: buildScene
  },
  {
    path: "/spaceManage/buildScene",
    name: "SpaceBuildScene",
    component: buildScene
  },
  {
    path: "/spaceManage",
    name: "SpaceManage",
    meta: {
      keepAlive: true
    },
    component: spaceManage
  },
  {
    path: "/faceSearchPage", // 人脸搜索
    name: "faceSearchPage",
    component: faceSearchPage
  },
  {
    path: "/personStatisticPage", // 人员统计
    name: "personStatisticPage",
    component: personStatisticPage
  },
  {
    path: "/visitorManagement", // 人员管理 > 访客管理
    name: "visitorManagement",
    authKey: "visitorManagement",
    meta: {
      title: "访客管理"
    },
    component: visitorManagement
  },
  {
    path: "/outbreakVisitorData", // 人员管理 > 入园申请记录
    name: "outbreakVisitorData",
    authKey: "outbreakVisitorData",
    meta: {
      title: "入园申请记录"
    },
    component: outbreakVisitorData
  },
  {
    path: "/visitorStatistics", // 人员管理 > 访客统计
    name: "visitorStatistics",
    authKey: "visitorStatistics",
    meta: {
      title: "访客统计"
    },
    component: () =>
      import(
        /* webpackChunkName: "VisitorCounts" */ "@/pages/staffManagement/visitorStatistics/visitorCounts"
      )
  },
  {
    path: "/employeeStatistics", // 人员管理 > 员工统计
    name: "employeeStatistics",
    authKey: "employeeStatistics",
    meta: {
      title: "员工统计"
    },
    component: () =>
      import(
        /* webpackChunkName: "VisitorCounts" */ "@/pages/staffManagement/employeeStatistics/visitorCounts"
      )
  },
  {
    path: "/customAnalyze", // 访客管理
    name: "customAnalyze",
    component: customAnalyze
  },
  {
    path: "/faultDiagnosis", // 绿色节能---能效AI诊断---概览
    name: "faultDiagnosis",
    authKey: "faultDiagnosis",
    meta: {
      title: "概览"
    },
    component: faultDiagnosis
  },
  {
    path: "/energyConsumptionFlow", // 绿色节能-分项用能-分项能耗流向
    name: "energyConsumptionFlow",
    authKey: "energyConsumptionFlow",
    meta: {
      title: "分项能耗流向"
    },
    component: energyConsumptionFlow
  },
  {
    path: "/itemizedEquipment", // 绿色节能-分项用能-分项能耗流向-分项设备
    name: "itemizedEquipment",
    authKey: "itemizedEquipment",
    meta: {
      title: "分项设备"
    },
    component: itemizedEquipment
  },
  {
    path: "/energyAnalysis/:energyCode?/:dateType?/:timeStr?", // 绿色节能-分项用能-分项用能分析
    name: "energyAnalysis",
    authKey: "energyAnalysis",
    meta: {
      title: "分项用能分析"
    },
    component: energyAnalysis
  },
  {
    path: "/energyStatistics", // 绿色节能-分项用能-分项用能统计
    name: "energyStatistics",
    authKey: "energyStatistics",
    meta: {
      title: "分项用能统计"
    },
    component: energyStatistics
  },
  {
    path: "/faultHistory", // 绿色节能---能效AI诊断---历史
    name: "faultHistory",
    authKey: "faultHistory",
    meta: {
      title: "概览"
    },
    component: faultHistory
  },
  {
    path: "/repMessageCenter", // 设施管理---电子屏控制---实时监控
    name: "repMessageCenter",
    authKey: "repMessageCenter",
    meta: {
      title: "实时监控"
    },
    component: repMessageCenter
  },
  {
    path: "/repFaciliInfoManage", // 设施管理---电子屏控制---电子屏管理
    name: "repFaciliInfoManage",
    authKey: "repFaciliInfoManage",
    meta: {
      title: "电子屏管理"
    },
    component: repFaciliInfoManage
  },
  {
    path: "/strategyControl", // 设施管理---电子屏控制---策略控制
    name: "strategyControl",
    authKey: "ledStrategyControl",
    meta: {
      title: "策略控制"
    },
    component: strategyControl
  },
  {
    path: "/facilityIntelligentLighting", // 智能照明
    name: "facilityIntelligentLighting",
    authKey: "facilityIntelligentLighting",
    meta: {
      title: "智能照明",
      keepAlive: true
    },
    component: facilityIntelligentLighting
  },
  {
    path: "/facilityIntelligentLighting", // 智能照明
    name: "facilityIntelligentLightingObj",
    authKey: "facilityIntelligentLighting",
    component: { template: "<router-view />" },
    children: [
      {
        path: "addStrategy", // 新增回路策略
        name: "addStrategy",
        component: addStrategy
      },
      {
        path: "checkStrategy", // 查看回路策略
        name: "checkStrategy",
        component: addStrategy
      },
      {
        path: "editStrategy", // 编辑回路策略
        name: "editStrategy",
        component: addStrategy
      },
      {
        path: "lightCameraPage", // 详情
        name: "lightCameraPage",
        component: lightCameraPage
      }
    ]
  },
  {
    path: "/icloudcityua/url*", // 云橙后台
    name: "iotAdmin",
    component: iotAdmin
  },
  {
    path: "/elevatorDetection", // 设施管理---梯控统计
    name: "elevatorDetection",
    authKey: "elevatorDetection",
    meta: {
      title: "梯控统计"
    },
    component: elevatorDetection
  },
  {
    path: "/wisdomElevator", // 设施管理---设施管理
    name: "wisdomElevator",
    authKey: "wisdomElevator",
    meta: {
      title: "智慧电梯"
    },
    component: wisdomElevator,
    children: [
      {
        path: "elevatorCameraPage", // 设施管理---智慧电梯
        name: "elevatorCameraPage",
        authKey: "elevatorCameraPage",
        meta: {
          title: "关联摄像头"
        },
        component: elevatorCameraPage
      }
    ]
  },
  {
    path: "/alohaSystemPage", // 迎宾系统
    name: "alohaSystemPage",
    component: alohaSystemPage
  },
  {
    path: "/accessControl", // 人员管理---门禁记录
    name: "accessControl",
    authKey: "accessControl",
    meta: {
      title: "门禁记录"
    },
    component: accessControl
  },
  {
    path: "/automaticelectricityAbnormal", // 绿色节能--能耗统计报表--用电异常监控
    name: "automaticelectricityAbnormal",
    authKey: "automaticelectricityAbnormal",
    meta: {
      title: "用电异常监控"
    },
    component: automaticelectricityAbnormal
  },
  {
    path: "/powerLoad", // 绿色节能--能耗统计报表--用电负荷
    name: "powerLoad",
    authKey: "powerLoad",
    meta: {
      title: "用电负荷"
    },
    component: powerLoad
  },
  {
    path: "/ammeterDividing", // 绿色节能--能耗统计报表--电表分项维护
    name: "ammeterDividing",
    authKey: "ammeterDividing",
    meta: {
      title: "电表分项维护"
    },
    component: ammeterDividing
  },
  {
    path: "/amr", // 绿色节能--自动抄表
    name: "amr",
    authKey: "amr",
    meta: {
      title: "自动抄表"
    },
    component: amr
  },
  {
    path: "/manualMeter", // 绿色节能--手工抄表
    name: "manualMeter",
    authKey: "manualMeter",
    meta: {
      title: "手工抄表"
    },
    component: manualMeter
  },
  {
    path: "/third",
    component: () =>
      import(/* webpackChunkName: "go-third" */ "@/pages/go-third.vue")
  },
  {
    path: "/thirdSgj",
    component: () =>
      import(/* webpackChunkName: "go-third-sgj" */ "@/pages/go-third-sgj.vue")
  },
  {
    path: "/thirdIframe",
    component: () =>
      import(
        /* webpackChunkName: "go-third-iframe" */ "@/pages/go-third-iframe.vue"
      )
  },
  {
    path: "/thirdSkip",
    component: () =>
      import(
        /* webpackChunkName: "go-third-skip" */ "@/pages/go-third-skip.vue"
      )
  },
  {
    path: "/redirect/:pageKey",
    component: () =>
      import(/* webpackChunkName: "redirectPage" */ "@/pages/redirect.vue")
  },
  {
    path: "/smart-business", // 智慧商业
    name: "/smart-business",
    authKey: "smart-business",
    component: { template: "<router-view/>" },
    children: [
      {
        path: "theLease",
        name: "theLease",
        authKey: "wisdomBusinessTheLease",
        meta: {
          title: "租约"
        },
        component: theLease
      },
      {
        path: "Calendar", // 日历
        name: "Calendar",
        authKey: "wisdomBusinessCalendar",
        meta: {
          title: "日历"
        },
        component: Calendar
      },
      {
        path: "Activity", // 活动
        name: "Activity",
        authKey: "wisdomBusinessActivity",
        meta: {
          title: "活动"
        },
        component: Activity
      },
      {
        path: "/smart-business/Activity/activeListPage", // 活动
        name: "activeListPage",
        authKey: "wisdomBusinessActivity",
        meta: {
          title: "活动"
        },
        component: activeListPage
      },
      {
        path: "businessHours", // 营业时间
        name: "businessHours",
        authKey: "wisdomBusinessActivity",
        meta: {
          title: "营业时间"
        },
        component: businessHours
      },
      {
        path: "areaConfigure", // 区域配置
        name: "areaConfigure",
        authKey: "areaConfigure",
        meta: {
          title: "区域"
        },
        component: areaConfigure
      },
      {
        path: "businessTypeConfigure", // 业态配置
        name: "businessTypeConfigure",
        authKey: "businessTypeConfigure",
        meta: {
          title: "业态"
        },
        component: businessTypeConfigure
      },
      {
        path: "businessDevices", // 智慧商业--配置--设施
        name: "businessDevices",
        component: businessDevices
      },
      {
        path: "businessDevicesLibrary", // 智慧商业--配置--设备库
        name: "businessDevicesLibrary",
        component: businessDevicesLibrary
      },
      {
        path: "businessEventCenter", // 智慧商业--配置--事件中心
        name: "businessEventCenter",
        component: businessEventCenter
      },
      {
        path: "integratedKanban", // 智慧商业--综合看板
        name: "IntegratedKanban",
        component: IntegratedKanban
      },
      {
        path: "repeatCustomer", // 智慧商业--精准客流--回头客
        name: "repeatCustomer",
        component: repeatCustomer
      },
      {
        path: "compositivePassengerFlow", // 智慧商业--综合客流--首页
        name: "compositivePassengerFlow",
        component: compositivePassengerFlow
      },
      {
        path: "pathTransformation", // 智慧商业--综合客流--路径转化
        name: "pathTransformation",
        component: pathTransformation
      },
      {
        path: "businessPersonnel", // 智慧商业--综合客流--人员列表
        name: "businessPersonnel",
        component: businessPersonnel
      },
      {
        path: "/smart-business/businessPersonnel/personnelDetail", // 智慧商业--综合客流--人员详情
        name: "personnelDetail",
        component: personnelDetail
      },
      {
        path: "basicPassengerFlow", // 智慧商业--基础客流
        name: "basicPassengerFlow",
        component: basicPassengerFlow
      },
      {
        path: "wisdomBusinessPage", // 智慧商业--首页
        name: "wisdomBusinessPage",
        component: wisdomBusinessPage
      },
      {
        path: "trafficStatistics", // 智慧商业--客流统计
        name: "trafficStatistics",
        component: trafficStatistics
      },
      {
        path: "periodFlowDistribution", // 智慧商业--时段流量分布
        name: "periodFlowDistribution",
        component: periodFlowDistribution
      },
      {
        path: "periodFlowManageBoard", // 智慧商业 -- 客流管理看板
        name: "periodFlowManageBoard",
        component: periodFlowManageBoard
      }
    ]
  },
  {
    path: "/eCStatistics", // 能耗统计
    name: "energyConsumptionStatistics",
    component: energyConsumptionStatistics
  },
  {
    path: "/rTRElectricityMeter", // 实时抄读电表
    name: "realTimeReadElectricityMeter",
    component: realTimeReadElectricityMeter
  },

  {
    path: "/carLighting",
    authKey: "carLighting",
    component: { template: "<router-view />" },
    children: [
      {
        path: "",
        name: "carLighting",
        component: lightingSolution
      },
      {
        path: "lightingSolution",
        name: "lightingSolution",
        authKey: "lightingSolution",
        component: lightingSolution
      },
      {
        path: "equipmentControl",
        name: "equipmentControl",
        authKey: "equipmentControl",
        component: equipmentControl
      }
    ]
  },
  {
    path: "/visitorAccessList/visitorAccess",
    name: "visitorAccess",
    component: visitorAccess
  },
  {
    path: "/visitorAccessList",
    name: "visitorAccessList",
    component: visitorAccessList
  },
  {
    path: "/entranceAccessList/entranceAccess",
    name: "entranceAccess",
    component: visitorAccess
  },
  {
    path: "/entranceAccessList",
    name: "entranceAccessList",
    component: visitorAccessList
  },
  {
    path: "/visitorOrderConfigure",
    name: "visitorOrderConfigure",
    component: visitorOrderConfigure
  },
  {
    path: "/parkConfigure",
    authKey: "parkConfigure",
    component: { template: "<router-view/>" },
    children: [
      {
        path: "",
        name: "parkConfigure",
        component: parkConfigure
      },
      {
        path: "choke",
        name: "choke",
        component: choke
      }
    ]
  },
  {
    path: "/elevatorApplication",
    name: "elevatorApplication",
    component: elevatorApplication
  },
  {
    path: "/vehicleConfigData",
    name: "vehicleConfigData",
    component: vehicleConfigData
  },
  {
    // 积分联盟-公司管理
    path: "/scoreUnion/companyManage",
    name: "CompanyManage",
    component: () =>
      import(
        /* webpackChunkName: "CompanyManage" */ "@/pages/scoreUnion/CompanyManage"
      )
  },
  {
    // 积分联盟-区块链账号管理
    path: "/scoreUnion/accountManage",
    name: "AccountManage",
    component: () =>
      import(
        /* webpackChunkName: "AccountManage" */ "@/pages/scoreUnion/AccountManage"
      )
  },
  {
    // 积分联盟-积分奖励
    path: "/scoreUnion/scoreAward",
    name: "scoreAward",
    component: () =>
      import(
        /* webpackChunkName: "rewardPoints" */ "@/pages/scoreUnion/scoreAward"
      )
  },
  {
    // 积分联盟-积分发行
    path: "/scoreUnion/scorePublish",
    name: "ScorePublish",
    component: () =>
      import(
        /* webpackChunkName: "ScorePublish" */ "@/pages/scoreUnion/ScorePublish"
      )
  },
  {
    // 积分联盟-交易明细
    path: "/scoreUnion/transactionDetail",
    name: "transactionDetail",
    component: () =>
      import(
        /* webpackChunkName: "transactionDetail" */ "@/pages/scoreUnion/transactionDetail"
      )
  },
  {
    path: "/energyFormula",
    name: "energyFormula",
    component: () =>
      import(
        /* webpackChunkName: "energyFormula" */ "@/pages/configCenter/energyConfig/energyFormula"
      )
  },
  {
    path: "/emergencyPlanning", // 预案编制
    name: "emergencyPlanning",
    authKey: "emergencyPlanning",
    meta: {
      title: "预案编制"
    },
    component: emergencyPlanning
  },
  {
    path: "/emergencyPlanning/addPlan", // 新增预案
    name: "addPlan",
    authKey: "addPlan",
    meta: {
      title: "新增预案"
    },
    component: addPlan
  },
  // 公式配置-分项配置
  {
    path: "/ItemizedConfiguration",
    name: "ItemizedConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "ItemizedConfiguration" */ "@/pages/configCenter/energyConfig/ItemizedConfiguration"
      )
  },
  // 公式配置-支路配置
  {
    path: "/BranchConfiguration",
    name: "BranchConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "ItemizedConfiguration" */ "@/pages/configCenter/energyConfig/BranchConfiguration"
      )
  },
  // 公式配置-建筑区域配置
  {
    path: "/BuildingAreaConfiguration",
    name: "BuildingAreaConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "ItemizedConfiguration" */ "@/pages/configCenter/energyConfig/BuildingAreaConfiguration"
      )
  },
  // 公式配置-租商户配置
  {
    path: "/TenantConfiguration",
    name: "TenantConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "ItemizedConfiguration" */ "@/pages/configCenter/energyConfig/TenantConfiguration"
      )
  },
  // 资产经营-商户销售报表
  {
    path: "/assetsManagement/merchantSalesReport",
    name: "merchantSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "assetsManagement" */ "@/pages/assetsManagement/merchantSalesReport"
      )
  },
  // 经营数据填报
  {
    path: "/assetsManagement/fillin",
    name: "fillin",
    component: () =>
      import(
        /* webpackChunkName: "assetsManagement" */ "@/pages/assetsManagement/fillin"
      )
  },
  {
    path: "/assetsManagement/investmentPortalManagement",
    name: "portalManagement",
    component: () =>
      import(
        /* webpackChunkName: "assetsManagement" */ "@/pages/assetsManagement/investmentPortalManagement"
      )
  },
  // 轻量化BIM打点
  {
    path: "/bimMarker",
    name: "bimMarker",
    component: bimMarker
  },
  // 实时监控筛选配置
  {
    path: "/monitoringFilterConfiguration",
    name: "monitoringFilterConfiguration",
    component: monitoringFilterConfiguration
  },
  // 通行态势配置
  {
    path: "/passthroughConfig",
    name: "passthroughConfig",
    component: passthroughConfig
  }
];
