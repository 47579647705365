export default {
  namespaced: true,
  state: {
    countChartsArray: [],
    proprtyworker: {}
  },
  mutations: {
    getCountOfCharts(state, countChartsArray) {
      state.countChartsArray = countChartsArray;
    },
    getproprtyworker(state, val) {
      state.proprtyworker = val;
    }
  }
};
