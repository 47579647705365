
import store from "@/store";
import comComponents from "comComponents";
let getRequest = comComponents.utils.getRequest;
export default getRequest({
  beforeRequest(config, {addPositionParams = true}) {
    let {data = {}} = config;
    if (data.config && data.config.isExport) {
      config.responseType = "blob";
    }

    for (const key in data) {
      let val = data[key];
      if (val === "" || val === null || val === undefined || val === "undefined") {
        delete data[key];
      }
    }

    let isDataObject = Object.prototype.toString.call(data) === "[object Object]";
    if (addPositionParams && isDataObject) {
      let {posCode = "", region = "", spaceModelId = ""} = store.getters["basePosition/positionInfo"];
      if (posCode && region && spaceModelId) {
        posCode = posCode.indexOf("CN_43_001_PARK00000") > -1 ? "CN_EC_33_001_HXHYQ" : posCode;
        config.data = {posCode, region, spaceModelId, ...data};
      }
    }
    store.commit("fillNowToXHRTimestamp");
    return config;
  }
});
