export default {
  namespaced: true,
  state: {
    detailDialog: { // 详情弹窗
      status: null
    },
    genjinDialog: { // 跟进弹窗
      status: null
    },
    newAddDetailDialog: { // 详情新增内容弹窗
      status: null
    },
    followUpRecordDialog: { // 跟进记录弹窗
      status: null
    },
    desiPersonDialog: false, // 详情指定人员弹窗

    isReloadTable: false// 点击跟进确定后

  },
  mutations: {
    detailDialogOp(state, params) {
      state.detailDialog = params;
    },
    genjinDialogOp(state, params) {
      state.genjinDialog = params;
    },
    newAddDetailOp(state, params) {
      state.newAddDetailDialog = params;
    },
    followUpRecordDialogOp(state, params) {
      state.followUpRecordDialog = params;
    },
    setdesiPersonDialog(state, params) {
      state.desiPersonDialog = params;
    },
    setIsReloadTable(state, params) {
      state.isReloadTable = params;
    }

  },
  actions: {
    setPopShowAction(context, status) {
      context.commit("setPopShow", status);
    }
  }
};