import Ajax from "@/common/utils/common";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
const EQUIPMENTMGMT = Ajax.getRegistry("EQUIPMENTMGMT");
export default {
  namespaced: true,
  state: {
    listData: [],
    statusObj: {
      onlinCount: 0, // 在线 离线 个数
      offlinCount: 0,
      errorlinCount: 0,
      staylinCount: 0,
      loadFlag: 1
    },
    loading: false,
    // 策略控制
    strategyData: [],
    saveStrategyData: []
  },
  getters: {},
  mutations: {
    setListData(state, {data}) {
      state.listData = data.screenDeviceList;
      state.statusObj.onlinCount = data.onCount; // 在线 离线 个数
      state.statusObj.offlinCount = data.offCount;
      state.statusObj.errorlinCount = data.unCount;
      state.statusObj.staylinCount = data.conCount;
    },
    setFlag(state) {
      state.statusObj.loadFlag = 1;
    },
    changeFlag(state) {
      state.statusObj.loadFlag = 0;
    },
    setLoading(state) {
      state.loading = true;
    },
    changeLoadng(state) {
      state.loading = false;
    },
    setStrategyData(state, {data}) {
      state.strategyData = data;
      state.saveStrategyData = data;
    },
    getChangeData(state, data) {
      state.saveStrategyData = state.saveStrategyData && Array.isArray(state.saveStrategyData) && state.saveStrategyData.map(item => {
        return item.equipCode === data.equipCode ? data : item;
      });
    }
  },
  actions: {
    // 获取列表数据
    getListData({commit}, {path, params}) {
      Ajax.axiosData(path, "POST", params, EQUIPMENTMGMT).then(
        ({data}) => {
          if (data.code === RES_CODE_SUCCESS) {
            // data && data.data && data.data.screenDeviceList && data.data.screenDeviceList.forEach(ele => {
            //   ele.status = 1;
            // });
            commit("setListData", data);
            commit("changeFlag");
          }
          commit("changeLoadng");
        }
      ).catch(() => {
        commit("changeLoadng");
      });
    },
    // 切换停止播放状态
    cutStatusFunc({dispatch, rootGetters, commit}, {path, params}) {
      commit("setLoading");
      Ajax.axiosData(path, "POST", params, EQUIPMENTMGMT).then(
        () => {
          setTimeout(() => {
            //   if (data.code === RES_CODE_SUCCESS) {
            dispatch("getListData", {
              path: "/screen/v1.0/getScreenDeviceList",
              params: {
                loadFlag: 1,
                parkCode: rootGetters["basePosition/positionInfo"].posCode}
            });
            //   }
          }, 2000);
        }
      ).catch(() => {
        commit("changeLoadng");
      });
    },
    // 策略控制
    getStrategyData({commit}, {path, params}) {
      Ajax.axiosData(path, "GET", params, EQUIPMENTMGMT).then(
        ({data}) => {
          commit("changeLoadng");
          //   if (data.code === RES_CODE_SUCCESS) {
          commit("setStrategyData", data);

          //   }
        }
      ).catch(() => {
        commit("changeLoadng");
      });
    },
    // 重置
    resetData({commit, dispatch, rootGetters}, {path, params}) {
      Ajax.axiosData(path, "POST", params, EQUIPMENTMGMT).then(
        ({data}) => {
          commit("setLoading");
          //   if (data.code === RES_CODE_SUCCESS) {
          dispatch("getStrategyData", {
            path: "/screen/v1.0/queryAllStrategy",
            params: {parkCode: rootGetters["basePosition/positionInfo"].posCode}
          });
        }
      );
    },
    // 停用
    stopsetData({commit, dispatch, rootGetters}, {path, params}) {
      Ajax.axiosData(path, "POST", params, EQUIPMENTMGMT).then(
        ({data}) => {
          commit("setLoading");
          //   if (data.code === RES_CODE_SUCCESS) {
          dispatch("getStrategyData", {
            path: "/screen/v1.0/queryAllStrategy",
            params: {parkCode: rootGetters["basePosition/positionInfo"].posCode}
          });
        }
      );
    },
    // 一键开启PUT
    openData({commit, dispatch, rootGetters}, {path, params}) {
      Ajax.axiosData(path, "PUT", params, EQUIPMENTMGMT).then(
        ({data}) => {
          commit("setLoading");
          //   if (data.code === RES_CODE_SUCCESS) {
          dispatch("getStrategyData", {
            path: "/screen/v1.0/queryAllStrategy",
            params: {parkCode: rootGetters["basePosition/positionInfo"].posCode}
          });
        }
      );
    }
  }
};