<template>
<div id="m-cropper-wrapper">
    <div class="real-content">
        <div class="header">
            <span class="m-title">{{ $t('i18nKey_906') }}</span>
            <span class="m-icon el-icon-close" @click="close"></span>
        </div>
        <div class="cropper-content">
            <VueCropper
                ref="cropper"
                :img="options.img"
                :outputSize="options.size"
                :outputType="options.outputType"
                :info="true"
                :full="options.full"
                :canMove="options.canMove"
                :canMoveBox="options.canMoveBox"
                :original="options.original"
                :autoCrop="options.autoCrop"
                :fixed="options.fixed"
                :fixedNumber="options.fixedNumber"
                :centerBox="options.centerBox"
                :infoTrue="options.infoTrue"
                :fixedBox="options.fixedBox"
                @realTime="realTime"
            ></VueCropper>
        </div>
        <div class="footer">
            <el-button class="m-btn" @click="close">{{ $t('i18nKey_web_36') }} {{ $t('i18nKey_web_37') }}</el-button>
            <el-button type="primary" @click="finish" class="m-btn">{{ $t('i18nKey_web_38') }}</el-button>
        </div>
    </div>
</div>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
    //   img: "", // 裁剪图片的地址
    //   info: true, // 裁剪框的大小信息
    //   outputSize: 0.8, // 裁剪生成图片的质量
    //   outputType: "jpeg", // 裁剪生成图片的格式
    //   canScale: false, // 图片是否允许滚轮缩放
    //   autoCrop: true, // 是否默认生成截图框
    //   // autoCropWidth: 300, // 默认生成截图框宽度
    //   // autoCropHeight: 200, // 默认生成截图框高度
    //   fixedBox: false, // 固定截图框大小 不允许改变
    //   fixed: false, // 是否开启截图框宽高固定比例
    //   fixedNumber: [7, 5], // 截图框的宽高比例
    //   full: true, // 是否输出原图比例的截图
    //   canMoveBox: false, // 截图框能否拖动
    //   original: false, // 上传图片按照原始比例渲染
    //   centerBox: false, // 截图框是否被限制在图片里面
    //   infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    };
  },
  methods: {
    realTime() {},
    finish() {
      this.$refs.cropper.getCropBlob((content) => {
        const blob = new Blob([content], {
          type: "image/png"
        });
        const fileName = "video_img_" + new Date().getTime() + ".png";
        if ("download" in document.createElement("a")) {
        // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
        // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        this.$emit("getCropper", content);
      });
    },
    // 关闭弹窗
    close() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="less" scoped>
#m-cropper-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .real-content{
        width: 50%;
        height: 70%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .header{
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            background-color: #202c4e;
            font-size: 14px;
            color: #ffffff;
            .m-icon{
                cursor: pointer;
            }
        }
        .cropper-content{
            flex: 1;
        }
        .footer{
            height: 68px;
            display: flex;
            justify-content: center;
            align-items: center;
            .m-btn:first-child{
                margin-right: 16px;
            }
        }
    }
}
</style>