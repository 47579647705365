export default {
  namespaced: true,
  state: {
    equipInfo: {},
    equipAbnormalInfo: {}
  },
  mutations: {
    equipDetail(state, data) {
      state.equipInfo = data;
    },
    equipAbnormalDetail(state, data) {
      state.equipAbnormalInfo = data;
    }
  }
};